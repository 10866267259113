import _isEqual from 'lodash.isequal';
import * as types from '@/store/mutation-types';
import api from '@/api/course';

const state = {
  courses: [],
  workbench: null,
  atSync: false,
  previewLoginJWT: null,
};

const getters = {
  userCourses: state => state.courses,
  userCoursesAtSync: state => state.atSync,
  userCourseAtWork: state => state.workbench,
  previewLoginJWT: state => state.previewLoginJWT,
};

const actions = {
  syncCourses: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_USER_COURSES_BUSY, true);
    api.getAll().then(
      (courses) => {
        commit(types.SET_USER_COURSES, courses);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve();
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),

  putCourseToWorkbench({ commit }, course) {
    commit(types.SET_COURSE_AT_WORKBENCH, course);
  },

  createCourse: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_COURSES_BUSY, true);
    api.create(data).then(
      (courseAndProduct) => {
        commit(types.SET_COURSE_AT_WORKBENCH, courseAndProduct.course);
        commit(types.ADD_PRODUCT_TO_MEMBERSHIP, courseAndProduct.product);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve(courseAndProduct.course);
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),

  updateCourseInStore: ({ commit }, course) => new Promise((resolve) => {
    commit(types.UPDATE_MEMBERSHIP_COURSE, course);
    resolve(course);
  }),
  updateCourseWithContent: ({ commit }, course) => new Promise((resolve, reject) => {
    commit(types.SET_USER_COURSES_BUSY, true);
    return api.update(course.id, course, true).then(
      (res) => {
        commit(types.UPDATE_MEMBERSHIP_COURSE, res.course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),
  updateCourse: ({ commit }, course) => new Promise((resolve, reject) => {
    if (!course || !course.id) {
      return reject(new Error('Course ID is required.'));
    }
    commit(types.SET_USER_COURSES_BUSY, true);
    return api.update(course.id, course).then(
      (res) => {
        commit(types.UPDATE_MEMBERSHIP_COURSE, res.course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),


  updateCourseIfChanged: ({ state, dispatch }, course) => new Promise((resolve, reject) => {
    if (!course) {
      return reject(new Error('No course supplied.'));
    }

    if (!course.id) {
      return reject(new Error('The course has no ID.'));
    }

    let courseOriginal = null;
    state.courses.forEach((c) => {
      if (c.id === Number(course.id)) {
        courseOriginal = c;
      }
    });


    if (!courseOriginal) {
      return reject(new Error('The provided course ID was not found.'));
    }


    // console.log(course.title, courseOriginal.title);

    if (_isEqual(course, courseOriginal)) {
      return reject(new Error('No changes to the course data were made.'));
    }

    dispatch('updateCourse', course).then(
      res => resolve(res),
    )
      .catch(err => reject(err));

    return resolve(true);
  }),


  uploadCourseAssets: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data || !data.course.id) {
      return reject(new Error('Course ID is required.'));
    }
    commit(types.SET_USER_COURSES_BUSY, true);
    return api.uploadAsset(data.course.id, data.type, data.file).then(
      (res) => {
        const { course } = data;
        // if (!course.media) {
        //   course.media = {};
        // }
        if (data.type && data.type === 'cover') {
          course.cover = res;
        }
        commit(types.UPDATE_MEMBERSHIP_COURSE, course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),
  deleteCourseAssets: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data || !data.course.id) {
      reject(new Error('Course ID is required.'));
    }
    commit(types.SET_USER_COURSES_BUSY, true);
    api.deleteAsset(data.course.id, data.type).then(
      (res) => {
        const { course } = data;
        // course.media[data.type] = '';
        if (data.type && data.type === 'cover') {
          course.cover = null;
        }
        commit(types.UPDATE_MEMBERSHIP_COURSE, course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject(error);
      },
    );
  }),
  togglePublish: ({ dispatch, rootState }, course) => new Promise((resolve, reject) => {
    const newCourse = { ...course, isDraft: !course.isDraft };
    dispatch('updateCourse', newCourse).then(
      (res) => {
        let alertMessage = 'Course unpublished!';

        if (!newCourse.isDraft) {
          if (rootState.users.whoIam && rootState.users.whoIam.publishedCourseBefore) {
            alertMessage = 'Your course has been successfully published!';
          } else {
            alertMessage = 'Congratulations! You\'ve Published Your First Course';
            dispatch('updateWhoIam', { publishedCourseBefore: true });
          }
        }

        dispatch('showAlert', alertMessage);
        resolve(res);
      },
    ).catch(
      (error) => {
        dispatch('showAlert', { message: `Oops, something went wrong: ${error.message}`, type: 'danger' });
        reject(error);
      },
    );
  }),


  deleteCourse: ({ dispatch, commit }, course) => new Promise((resolve, reject) => {
    commit(types.SET_USER_COURSES_BUSY, true);
    api.delete(course.id).then(
      () => {
        dispatch('deleteMembershipCourse', course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve();
      },
      () => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject();
      },
    );
  }),
  archiveCourse: ({ dispatch, commit }, course) => new Promise((resolve, reject) => {
    commit(types.SET_USER_COURSES_BUSY, true);
    api.archive(course.id).then(
      () => {
        dispatch('deleteMembershipCourse', course);
        commit(types.SET_USER_COURSES_BUSY, false);
        resolve();
      },
      () => {
        commit(types.SET_USER_COURSES_BUSY, false);
        reject();
      },
    );
  }),
  previewLoginJWT: ({ commit }, membership) => new Promise((resolve, reject) => {
    api.previewLoginJWT(membership.id)
      .then((res) => {
        commit(types.PREVIEW_LOGIN_JWT, res.token);
        resolve(res);
      })
      .catch(err => reject(err));
  }),
  copyCourse: ({ commit }, course) => new Promise((resolve, reject) => {
    api.copy(course.id)
      .then((res) => {
        commit(types.ADD_MEMBERSHIP_COURSE, res);
        resolve();
      })
      .catch(err => reject(err));
  }),
  // eslint-disable-next-line
  uploadImageContent: ({}, file) => new Promise((resolve, reject) => {
    api.uploadImageContent(file)
      .then((res) => {
        resolve(res);
      })
      .catch(err => reject(err));
  }),
};

const mutations = {
  [types.SET_USER_COURSES](state, payload) {
    state.courses = payload;
  },
  [types.SET_COURSE_AT_WORKBENCH](state, payload) {
    state.workbench = payload;
  },
  [types.SET_USER_COURSES_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.PREVIEW_LOGIN_JWT](state, token) {
    state.previewLoginJWT = token;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
