export default {
  getClientList: () => new Promise((resolve, reject) => {
    window.http.get('clients')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getClientDetail: id => new Promise((resolve, reject) => {
    window.http.get(`client/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  addNewClient: formData => new Promise((resolve, reject) => {
    window.http.post('client', formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateClient: formData => new Promise((resolve, reject) => {
    window.http.put(`client/admin-update/${formData.id}`, formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deleteClient: clientId => new Promise((resolve, reject) => {
    window.http.delete(`client/${clientId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getClientInvoices: data => new Promise((resolve, reject) => {
    window.http.get(`client/${data.clientId}/invoices`, { params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createInvoice: formData => new Promise((resolve, reject) => {
    window.http.post('client/create-invoice', formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  sendActiveEmail: clientId => new Promise((resolve, reject) => {
    window.http.post(`client/${clientId}/send-active-mail`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  resendInvoice: clientId => new Promise((resolve, reject) => {
    window.http.post(`client/${clientId}/resend-invoice-mail`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAllClientProfiles: () => new Promise((resolve, reject) => {
    window.http.get('/client/profiles/get')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createClientProfile: data => new Promise((resolve, reject) => {
    window.http.post('/client/profile', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateClientProfile: data => new Promise((resolve, reject) => {
    window.http.put(`/client/profile/${data.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  duplicateProfile: id => new Promise((resolve, reject) => {
    window.http.post(`client/profile/${id}/duplicate`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deleteProfile: id => new Promise((resolve, reject) => {
    window.http.delete(`client/profile/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getClientProfileDetail: id => new Promise((resolve, reject) => {
    window.http.get(`client/profile/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  resetProfileIntegration: id => new Promise((resolve, reject) => {
    window.http.post(`client/profile/${id}/reset-integration`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  resetProfilePayment: id => new Promise((resolve, reject) => {
    window.http.post(`client/profile/${id}/reset-stripe`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  resetProfileEmailSender: id => new Promise((resolve, reject) => {
    window.http.post(`client/profile/${id}/reset-email-sender`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
