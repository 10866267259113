<template>
  <div>
    <div class="flex-start">
      <div class="mr-1 primary-text lg semi-bold">Integrations</div>
      <div class="primary-text lg semi-bold text-gray-text-n">(Optional)</div>
    </div>

    <div class="connect-stripe flex-between cursor-pointer">
      <div class="primary-text lg semi-bold" @click="connectStripe">Connect Stripe Account</div>
      <icon-stripe-normal />
    </div>

    <div class="line-break" />

    <div class="flex-start">
      <div class="mr-1 primary-text lg semi-bold">Add a Sender Email</div>
      <div class="primary-text lg semi-bold text-gray-text-n">(Optional)</div>
    </div>

    <div class="primary-text text-gray-text-n mb-20 mt-2">
      This is an optional step and you can fill in this information later in the account settings.
    </div>
    <div v-if="!userEmailSenders.length" class="add-email mb-20 flex-start" @click="addNewEmail">
      <span class="primary-text lg semi-bold text-dark mr-2">+</span>
      <span class="primary-text semi-bold text-dark">Add Email</span>
    </div>
    <div v-else class="add-email mb-20 flex-start">
      <span class="primary-text semi-bold">{{ userEmailSenders[0].mail }}</span>
    </div>

    <div class="flex-between">
      <v-spacer />
      <v-btn
        class="custom-btn-secondary fixed-width"
        @click="onComplete">
        Complete Registration
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import EmailSenderForm from '@/components/modal/EmailSenderForm';

  export default {
    computed: {
      ...mapGetters(['whoIam', 'userEmailSenders']),
    },
    methods: {
      ...mapActions([
        'syncEmailSenders',
        'openCustomModal',
      ]),
      onComplete() {
        this.$router.push({ name: 'membership_dashboard' });
      },
      connectStripe() {
        if (this.whoIam) {
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/stripe/authorize/${this.whoIam.id}?clientProfileId=${this.whoIam.clientProfile.id}`;
        }

        return null;
      },
      addNewEmail() {
        this.openCustomModal({
          component: EmailSenderForm,
          maxWidth: '450px',
          width: '450px',
          title: 'Add New Email',
          modalClass: 'membership-form',
          data: {
            clientProfile: this.whoIam.clientProfile,
            isEdit: false,
            onSubmitCallback: () => {
              this.syncEmailSenders();
            },
          },
        });
      },
    },
  };
</script>
