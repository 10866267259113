export default {
  getAllCommissions: () => new Promise((resolve, reject) => {
    window.http.get('/affiliate-management/commissions')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getCommission: id => new Promise((resolve, reject) => {
    window.http.get(`/affiliate-management/commission/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAllProductRequest: filter => new Promise((resolve, reject) => {
    window.http.get('/affiliate-management/promote-request', { params: filter })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  confirmPromoteRequest: data => new Promise((resolve, reject) => {
    window.http.put(`/affiliate-management/update-request/${data.id}`, data.params)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  approveAllProduct: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-management/approve-all-request', { ids: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAffiliateUserById: id => new Promise((resolve, reject) => {
    window.http.get(`/affiliate-management/affiliate-user/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAffiliateProductsByAffiliateUserId: id => new Promise((resolve, reject) => {
    window.http.get(`/affiliate-management/affiliate-user/${id}/products`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAffiliateCommissionsByAffiliateUserId: data => new Promise((resolve, reject) => {
    window.http.get(`/affiliate-management/affiliate-user/${data.id}/commissions`, { params: data.params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateCommissions: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-management/update-commissions', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAnalytic: data => new Promise((resolve, reject) => {
    window.http.get('/affiliate-management/analytic', {
      params: {
        start: data.start,
        end: data.end,
        userId: data.userId,
        affiliateUserId: data.affiliateUserId,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
