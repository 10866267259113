<template>
  <div class="account-setting__form">
    <div class="layout justify-space-between align-center">
      <div class="domain-setting__title primary-text xl semi-bold">Sender Emails</div>
      <v-btn class="custom-btn-secondary fixed-width" @click="addNewEmail">
        Add Email
      </v-btn>
    </div>
    <p class="primary-text dark-text mt-2">
      You can add multiple emails that might be used as sender email in your courses
    </p>
    <table class="my-datatable mt-20 last-column-right partnership-table">
      <table-header :headers="headers" />
      <tbody>
        <tr
          v-for="(email, index) in userEmailSenders"
          :key="index"
        >
          <td>{{ email.mail }}</td>
          <td>{{ email.name }}</td>
          <td>
            <span v-if="email.status === 'confirmed'" class="badge badge-success">
              Confirmed
            </span>
            <span v-else class="badge badge-warning">
              Pending
            </span>
          </td>
          <td>
            <v-menu
              transition="slide-y-transition"
              content-class="custom-menu membership__box-menu"
              offset-y bottom left>
              <template v-slot:activator="{ on }">
                <button
                  class="custom-action-dropdown__btn ml-10"
                  v-on="on"
                >
                  <icon-more/>
                </button>
              </template>
              <div class="custom-action-dropdown">
                <button
                  class="dropdown-btn"
                  @click="editEmail(email)"
                >
                  <icon-edit-x class="icon-default" />
                  Edit
                </button>
                <button
                  v-if="email.status === 'not_confirmed'"
                  class="dropdown-btn"
                  @click="requestConfirmTrigger(email)">
                  <icon-recheck/>
                  <span>Resend</span>
                </button>
                <button class="dropdown-btn delete" @click="deleteEmail(email)">
                  <icon-trash class="icon-default" />
                  <span>Delete</span>
                </button>
              </div>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import EmailSenderForm from '@/components/modal/EmailSenderForm';
  import TableHeader from '@/components/table/TableHeader';

  export default {
    components: {
      TableHeader,
    },
    props: ['clientProfile'],
    data() {
      return {
        headers: [
          {
            label: 'email',
            width: '35%',
          },
          {
            label: 'name',
            width: '25%',
          },
          {
            label: 'status',
            width: '25%',
          },
          {
            label: 'action',
            textAlign: 'flex-center',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['userEmailSenders']),
    },
    mounted() {
      if (this.clientProfile) {
        this.setEmailSenders(this.clientProfile.emailSenders);
      } else {
        this.syncEmailSenders();
      }
    },
    methods: {
      ...mapActions([
        'syncEmailSenders',
        'setEmailSenders',
        'openCustomModal',
        'deleteEmailSender',
        'requestConfirmEmail',
        'showAlert',
      ]),
      requestConfirmTrigger(email) {
        this.requestConfirmEmail(email)
          .then(
            (res) => {
              this.showAlert(res.message);
            },
          ).catch(
            () => {
            },
          );
      },
      deleteEmail(email) {
        this.$confirm('Do you really want to delete this email?').then((res) => {
          if (res) {
            this.deleteEmailSender(email)
              .then(
                () => {
                  this.showAlert('The email has been successfully deleted.');
                },
                (err) => {
                  this.showAlert(err);
                },
              );
          }
        });
      },
      editEmail(email) {
        this.openCustomModal({
          component: EmailSenderForm,
          maxWidth: '450px',
          width: '450px',
          title: 'Edit Email',
          modalClass: 'membership-form',
          data: {
            isEdit: true,
            sender: email,
            onSubmitCallback: (data) => {
              const index = this.userEmailSenders.findIndex(e => e.id === data.id);
              this.$set(this.userEmailSenders, index, data);
            },
          },
        });
      },
      addNewEmail() {
        this.openCustomModal({
          component: EmailSenderForm,
          maxWidth: '450px',
          width: '450px',
          title: 'Add New Email',
          modalClass: 'membership-form',
          data: {
            clientProfile: this.clientProfile,
            isEdit: false,
          },
        });
      },
    },
  };
</script>
