export const FEATURE_ZAPIER = 'zapier';
export const FEATURE_MEDIA_UPLOADER = 'media_uploader';
export const FEATURE_OWN_LEGAL_DOCUMENTS = 'own_legal_documents';
export const FEATURE_ANALYTICS = 'analytics';
export const FEATURE_COMMENTS = 'comments';
export const FEATURE_FEEDBACK = 'feedback';
export const FEATURE_REMOVE_BRANDING = 'remove_branding';
export const FEATURE_COURSE_CHECKOUT = 'course_checkout';
export const FEATURE_MEDIA_ANALYTICS = 'media_analytics';
export const FEATURE_BUNDLES = 'bundles';
export const FEATURE_LECTURERS = 'lecturers';
export const FEATURE_SUB_USERS = 'sub_users';
export const FEATURE_POLLS_QUIZES = 'polls_quizes';
export const FEATURE_PARTNERSHIPS = 'partnerships';
export const FEATURE_FUNNELS = 'funnels';
export const FEATURE_AFFILIATES = 'affiliates';
export const FEATURE_PPTX = 'pptx';
export const FEATURE_DUPLICATE_COURSE = 'duplicate_course';
export const FEATURE_CUSTOM_EMAILS = 'custom_emails';
export const FEATURE_TEAM_ACCESS = 'team_access';
export const FEATURE_CUSTOM_HTML = 'custom_html';
export const FEATURE_ADVANCED_THEME = 'advanced_theme';
export const FEATURE_UPLOAD_IMAGE_TO_LESSON = 'upload_image_to_lesson';
export const FEATURE_CHALLENGE = 'challenge';

export const FEATURE_CHALLENGE__NEW = {
  GROUP_CHAT: 'challenge_group-chat',
  VIDEO_DATA_STORAGE: 'challenge_video-data-storage',
  LEGAL_DOC: 'challenge_legal-doc',
  GAMIFICATION: 'challenge_gamification',
  REVIEW: 'challenge_review',
  CHECKOUT_SETTING: 'challenge_checkout-setting',
  BUNDLE_CHALLENGE: 'challenge_bundle-challenge',
  EMAIL_TEMPLATE: 'challenge_email-template',
  INTEGRATION: 'challenge_integration',
  ASSIGNMENT: 'challenge_assignment',
  TEAM_MANAGEMENT: 'challenge_team-management',
  AFFILIATE: 'challenge_affiliate',
  COUPON: 'challenge_coupon',
  TRANSACTION: 'challenge_transaction',
  CLIENT_MANAGEMENT: 'client_management',
  PARTNERSHIP: 'partnership',
  EMAIL_SENDER: 'email-sender',
  ACTIVE_SESSION: 'active-session',
};

export const PLAN_STARTER = 'starter';
export const PLAN_PROFESSIONAL = 'professional';
export const PLAN_FE_PRO = 'fe-pro';
export const PLAN_FE = 'fe';
export const PLAN_OTO_1 = 'oto1';
export const PLAN_OTO_2 = 'oto2';

export const displayNames = {};

displayNames[PLAN_STARTER] = 'Starter';
displayNames[PLAN_PROFESSIONAL] = 'Pro';
displayNames[PLAN_FE_PRO] = 'Pro';
displayNames[PLAN_FE] = 'FE';
displayNames[PLAN_OTO_1] = 'All Access';
displayNames[PLAN_OTO_2] = 'Agency';

const permissions = {};

permissions.plusPlan = [
  FEATURE_CHALLENGE__NEW.GROUP_CHAT,
  FEATURE_CHALLENGE__NEW.VIDEO_DATA_STORAGE,
  FEATURE_CHALLENGE__NEW.LEGAL_DOC,
  FEATURE_CHALLENGE__NEW.GAMIFICATION,
  FEATURE_CHALLENGE__NEW.REVIEW,
  FEATURE_CHALLENGE__NEW.CHECKOUT_SETTING,
  FEATURE_CHALLENGE__NEW.BUNDLE_CHALLENGE,
  FEATURE_CHALLENGE__NEW.EMAIL_TEMPLATE,
  FEATURE_CHALLENGE__NEW.INTEGRATION,
  FEATURE_CHALLENGE__NEW.ASSIGNMENT,
  FEATURE_CHALLENGE__NEW.AFFILIATE,
  FEATURE_CHALLENGE__NEW.COUPON,
  FEATURE_CHALLENGE__NEW.TRANSACTION,
  FEATURE_CHALLENGE__NEW.EMAIL_SENDER,
  FEATURE_CHALLENGE__NEW.TEAM_MANAGEMENT,
];

permissions[PLAN_OTO_1] = [
  FEATURE_CHALLENGE__NEW.GROUP_CHAT,
  FEATURE_CHALLENGE__NEW.VIDEO_DATA_STORAGE,
  FEATURE_CHALLENGE__NEW.LEGAL_DOC,
  FEATURE_CHALLENGE__NEW.GAMIFICATION,
  FEATURE_CHALLENGE__NEW.REVIEW,
  FEATURE_CHALLENGE__NEW.CHECKOUT_SETTING,
  FEATURE_CHALLENGE__NEW.BUNDLE_CHALLENGE,
  FEATURE_CHALLENGE__NEW.EMAIL_TEMPLATE,
  FEATURE_CHALLENGE__NEW.INTEGRATION,
  FEATURE_CHALLENGE__NEW.ASSIGNMENT,
  FEATURE_CHALLENGE__NEW.AFFILIATE,
  FEATURE_CHALLENGE__NEW.COUPON,
  FEATURE_CHALLENGE__NEW.TRANSACTION,
  FEATURE_CHALLENGE__NEW.EMAIL_SENDER,
  FEATURE_CHALLENGE__NEW.ACTIVE_SESSION,
];

permissions[PLAN_OTO_2] = [
  FEATURE_CHALLENGE__NEW.TEAM_MANAGEMENT,
  FEATURE_CHALLENGE__NEW.CLIENT_MANAGEMENT,
  FEATURE_CHALLENGE__NEW.PARTNERSHIP,
];

permissions[PLAN_FE] = [
];

permissions[PLAN_FE_PRO] = [
  FEATURE_ZAPIER,
  FEATURE_OWN_LEGAL_DOCUMENTS,
  FEATURE_REMOVE_BRANDING,
  FEATURE_POLLS_QUIZES,
  FEATURE_COMMENTS,
  FEATURE_CUSTOM_HTML,
  FEATURE_ADVANCED_THEME,
  FEATURE_UPLOAD_IMAGE_TO_LESSON,
  FEATURE_CUSTOM_EMAILS,
];

permissions[PLAN_STARTER] = [
  FEATURE_ZAPIER,
  FEATURE_MEDIA_UPLOADER,
  FEATURE_OWN_LEGAL_DOCUMENTS,
  FEATURE_ANALYTICS,
  FEATURE_COURSE_CHECKOUT,
  FEATURE_COMMENTS,
  FEATURE_PPTX,
  FEATURE_DUPLICATE_COURSE,
  FEATURE_ADVANCED_THEME,
  FEATURE_UPLOAD_IMAGE_TO_LESSON,
];

permissions[PLAN_PROFESSIONAL] = [
  ...permissions[PLAN_STARTER], // professional includes all from starter
  FEATURE_FUNNELS,
  FEATURE_AFFILIATES,
  FEATURE_MEDIA_ANALYTICS,
  FEATURE_BUNDLES,
  FEATURE_PARTNERSHIPS,
  FEATURE_LECTURERS,
  FEATURE_SUB_USERS,
  FEATURE_POLLS_QUIZES,
  FEATURE_REMOVE_BRANDING,
  FEATURE_FEEDBACK,
  FEATURE_CUSTOM_EMAILS,
  FEATURE_TEAM_ACCESS,
  FEATURE_CUSTOM_HTML,
];

export default permissions;
