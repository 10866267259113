import moment from 'moment';
import * as types from '@/store/mutation-types';
import api from '@/api/video';

const state = {
  stats: [],
  analytics: [],
  sessionGroups: [],
  courseList: [],
  statsFetching: false,

  selectedVideoUuid: null,

};

const getters = {
  videoAnalyticsStats: state => state.stats,
  selectedVideoUuid: state => state.selectedVideoUuid,
  // --------------------------------------------------------------------------------------

  totalVideoPlaybacks: (state) => {
    const sessions = state.analytics.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.sessionUuid) === -1) {
          a.push(currentValue.sessionUuid);
        }
        return a;
      },
      [],
    );
    return sessions.length;
  },

  // --------------------------------------------------------------------------------------

  totalVideoFinishViews: (state) => {
    const sessions = state.analytics.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.playbackStatus === 'ended'
              && a.indexOf(currentValue.sessionUuid) === -1
        ) {
          a.push(currentValue.sessionUuid);
        }
        return a;
      },
      [],
    );
    return sessions.length;
  },

  // --------------------------------------------------------------------------------------

  totalHoursVideoViews: (state) => {
    // playback time within each group (longest duration value)
    // duration is a difference between the lastStoppedTime and the currect player position
    // the last stopped time is reset on plyr 'ended' event and on page reload

    const totalPlaybackTimeInSeconds = state.sessionGroups.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        let longestDuration = 0;
        currentValue.forEach((val) => {
          longestDuration = (longestDuration < Number(val.duration))
            ? Number(val.duration) : longestDuration;
        });
        return a + longestDuration;
      },
      0,
    );

    return totalPlaybackTimeInSeconds;
  },


  // --------------------------------------------------------------------------------------

  averageTimeViews: (state) => {
    if (!state.sessionGroups && !state.sessionGroups.length) {
      return 0;
    }

    const totalPlaybackTimeInSeconds = state.sessionGroups.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        let longestDuration = 0;
        currentValue.forEach((val) => {
          longestDuration = (longestDuration < Number(val.duration))
            ? Number(val.duration) : longestDuration;
        });
        return a + longestDuration;
      },
      0,
    );

    return totalPlaybackTimeInSeconds / state.sessionGroups.length;
  },

  // --------------------------------------------------------------------------------------


  detailedVideoViewingStatisticsItems: (state) => {
    const list = state.stats.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        const found = a.find(item => item.courseId === currentValue.courseId);
        if (!found) {
          a.push({
            courseId: currentValue.courseId,
            label: currentValue.courseTitle,
            items: [],
          });
        }
        return a;
      },
      [],
    );

    list.forEach((course, index) => {
      list[index].items = (state.stats.filter(s => s.courseId === course.courseId)).map(p => ({
        title: p.title,
        id: p.video,
        // id: p.lessonIndex,

      }));
    });

    return list;
  },

  // --------------------------------------------------------------------------------------

  selectedVideoData: state => state.stats.find(s => s.video === state.selectedVideoUuid) || {},

  // --------------------------------------------------------------------------------------

  // detailedVideoViewingStatistics: (state) => {
  //   // find longest durations for each session
  //   // get playback position for such records
  //   //
  //   const playbackPositions = state.sessionGroups.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       const itemWithLongestDuration = currentValue.reduce(
  //         (prev, current) => ((Number(prev.duration) >
  // Number(current.duration)) ? prev : current),
  //       );

  //       if (itemWithLongestDuration.uuid === state.selectedVideoUuid) {
  //         a.push(Number.parseInt(itemWithLongestDuration.playbackPosition, 10));
  //       }
  //       return a;
  //     },
  //     [],
  //   );


  //   // count occurences of each playback position
  //   //
  //   const list = playbackPositions.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       const index = a.findIndex(item => item.date === currentValue);

  //       if (index === -1) {
  //         a.push({
  //           date: currentValue,
  //           value: 1,
  //         });
  //       } else {
  //         a[index].value += 1;
  //       }
  //       return a;
  //     },
  //     [],
  //   );

  //   list.sort((a, b) => ((a.date > b.date) ? 1 : -1));

  //   // format dates
  //   // and return the list
  //   //

  //     const result = list.map((item) => {
  //     const perc = (item.value / playbackPositions.length * 100).toFixed(0);
  //     const users = (perc / 100 * state.sessionGroups.length).toFixed(0);

  //     return {
  //       date: moment(item.date * 1000).format('mm:ss'),
  //       label: `${perc}% (${users} users)`,
  //       value: perc,
  //     };
  //   });

  //   console.log('result', result);
  //   return result;
  // },

  // // --------------------------------------------------------------------------------------

  detailedVideoViewingStatistics: (state) => {
    // find longest durations for each session
    // get playback position for such records
    //
    const playbackPositions = state.sessionGroups.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        const itemWithLongestDuration = currentValue.reduce(
          (prev, current) => ((Number(prev.duration) > Number(current.duration)) ? prev : current),
        );

        if (itemWithLongestDuration.uuid === state.selectedVideoUuid) {
          a.push(Number.parseInt(itemWithLongestDuration.playbackPosition, 10));
        }
        return a;
      },
      [],
    );

    // count occurences of each playback position
    //
    const list = playbackPositions.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        const index = a.findIndex(item => item.date === currentValue);

        if (index === -1) {
          a.push({
            date: currentValue,
            value: 1,
          });
        } else {
          a[index].value += 1;
        }
        return a;
      },
      [],
    );

    list.sort((a, b) => ((a.date > b.date) ? 1 : -1));
    list.unshift({
      date: 0,
      value: 0,
    });

    // format dates
    // and return the list
    //

    let burndown = playbackPositions.length;


    list.forEach((item, index) => {
      list[index].burndown = burndown;
      burndown -= item.value;
    });


    const result = list.map((item) => {
      const perc = (item.burndown / playbackPositions.length * 100).toFixed(0);
      const users = item.burndown.toFixed(0);

      return {
        date: moment(item.date * 1000).format('mm:ss'),
        label: `${perc}% (${users} users)`,
        value: perc,
      };
    });

    // console.log('result', result);
    return result;
  },

  // --------------------------------------------------------------------------------------


};


// ====================================================


const actions = {

  fetchVideoAnalyticsStats: ({ state, commit }, data) => new Promise((resolve, reject) => {
    if (state.statsFetching) return resolve(state.stats);

    if (!data.courseIds
      || !data.courseIds.length
      || (!data.startDate && !data.endDate)) {
      commit(types.SET_VIDEO_ANALYTICS_STATS, []);
      return resolve([]);
    }

    commit(types.SET_VIDEO_ANALYTICS_FETCHING, true);

    return api.stats(data)
      .then((res) => {
        commit(types.SET_VIDEO_ANALYTICS_STATS, res);
        commit(types.SET_VIDEO_ANALYTICS_FETCHING, false);
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
        commit(types.SET_VIDEO_ANALYTICS_FETCHING, false);
      });
  }),

  setVideoUuid: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID, data);
    resolve(true);
  }),


  setVideoUuidNext: ({ commit }) => new Promise((resolve) => {
    commit(types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT);
    resolve(true);
  }),

  setVideoUuidPrev: ({ commit }) => new Promise((resolve) => {
    commit(types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_PREV);
    resolve(true);
  }),


};


// ====================================================


const mutations = {

  [types.SET_VIDEO_ANALYTICS_FETCHING](state, data) {
    state.statsFetching = data;
  },

  [types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID](state, data) {
    state.selectedVideoUuid = data;
  },

  [types.SET_VIDEO_ANALYTICS_STATS](state, data) {
    const analytics = data.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.analytics) {
          a.push(...currentValue.analytics);
        }

        return a;
      },
      [],
    );
    state.analytics = analytics;

    // unique view sessions (page views until reload or playback completion)
    //
    const sessions = state.analytics.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.sessionUuid) === -1
        ) {
          a.push(currentValue.sessionUuid);
        }
        return a;
      },
      [],
    );

    // group all analytics records by session
    //
    state.sessionGroups = sessions.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        a.push(state.analytics.filter(item => currentValue === item.sessionUuid));
        return a;
      },
      [],
    );


    // set selected video uuid
    //
    if (data && data.length) {
      state.selectedVideoUuid = data[0].video;
    }

    state.stats = data;
  },


  [types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT](state) {
    const foundIndex = state.selectedVideoUuid ? (
      state.stats.findIndex(s => s.video === state.selectedVideoUuid) || 0) : 0;
    if (foundIndex < state.stats.length) {
      state.selectedVideoUuid = state.stats[foundIndex + 1].video;
    }
  },

  [types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_PREV](state) {
    const foundIndex = state.selectedVideoUuid ? (
      state.stats.findIndex(s => s.video === state.selectedVideoUuid) || 0) : 0;
    if (foundIndex > 0) {
      state.selectedVideoUuid = state.stats[foundIndex - 1].video;
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
