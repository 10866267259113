<template>
  <div class="navbar">
    <div class="cursor-pointer" @click="$router.push({ name: 'membership_dashboard' })">
      <icon-logo-full v-if="!isAgencyDomain"
                      class="navbar-logo"  />
      <img v-else :src="agencyLogo" class="navbar-logo__img">
    </div>
    <v-spacer />
    <div v-if="userDataAtSync" class="navbar-profile" style="width: 150px">
      <div class="text-loading text-loading__full" />
    </div>
    <v-menu
      v-else
      v-model="activeStatus"
      transition="slide-y-transition"
      content-class="custom-menu membership__box-menu"
      offset-y bottom left
    >
      <template v-slot:activator="{ on }">
        <div v-if="whoIam" class="navbar-profile" :class="{active: activeStatus}" v-on="on">
          <div v-if="whoIam.avatar" class="navbar-profile__circle" :style="avatarBg" />
          <initial-avatar
            v-else
            :name="username"
          />
          <p class="primary-text lg">
            {{ username }}
          </p>
          <icon-arrow-down class="ml-10 icon-default" />
        </div>
      </template>
      <div class="custom-action-dropdown">
        <div class="navbar-profile__dropdown">
          <div class="layout align-center">
            <div v-if="whoIam.avatar" class="navbar-profile__circle" :style="avatarBg" />
            <initial-avatar
              v-else
              :name="username"
            />
            <div>
              <p class="primary-text">Welcome,</p>
              <p class="primary-text xl semi-bold">
                {{ username }}
              </p>
            </div>
          </div>
          <v-btn class="custom-btn-secondary outline mt-20" @click="goToAccountSetting">
            Account Settings
          </v-btn>
        </div>
        <button
          v-if="whoIam.haveFeatureNew('CLIENT_MANAGEMENT')"
          class="dropdown-btn"
          @click="$router.push({ name: 'dashboard.client-management' })"
        >
          <icon-client class="icon-default" />
          Client Management
        </button>
        <button
          v-if="whoIam.haveFeatureNew('PARTNERSHIP')"
          class="dropdown-btn"
          @click="$router.push({ name: 'dashboard.partnership-management' })"
        >
          <icon-partnership class="icon-default" />
          Partnership Contracts
        </button>
        <button
          v-if="whoIam.haveFeatureNew('VIDEO_DATA_STORAGE')"
          class="dropdown-btn"
          @click="$router.push({ name: 'dashboard.storage-statistics' })"
        >
          <icon-folders class="icon-default icon-folders" />
          Storage Statistics
        </button>
        <button
          v-if="isConnectPartnership"
          class="dropdown-btn"
          @click="$router.push({ name: 'dashboard.partnership-contracts' })"
        >
          <icon-partnership class="icon-default" />
          My Contracts
        </button>
        <button
          v-if="whoIam.isTeamMember"
          class="dropdown-btn"
          @click="$router.push({ name: 'team.membership_dashboard' })"
        >
          <icon-users class="icon-default" />
          Team Campaigns
        </button>
        <button
          class="dropdown-btn"
          @click="goToSupport"
        >
          <icon-question-info class="icon-default" />
          Support
        </button>
        <button class="dropdown-btn delete" @click="logout">
          <icon-logout class="icon-default" />
          <span>Logout</span>
        </button>
      </div>
    </v-menu>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import IconArrowDown from '@/assets/icons/arrow-down.svg';
  import IconPartnership from '@/assets/icons/partnership/partnership.svg';
  import IconFolders from '@/assets/icons/storage/folders.svg';

  export default {
    components: {
      IconArrowDown,
      InitialAvatar,
      IconPartnership,
      IconFolders,
    },
    mixins: [linkHelper],
    data() {
      return {
        activeStatus: false,
      };
    },
    computed: {
      ...mapGetters(['whoIam', 'userDataAtSync', 'isConnectPartnership']),
      ...mapGetters('clientAgency', ['isAgencyDomain', 'agencyProfile']),
      avatarBg() {
        return { 'background-image': `url("${this.imageUrl(this.whoIam.avatar)}")` };
      },
      agencyLogo() {
        if (!this.agencyProfile || !this.agencyProfile.id) {
          return null;
        }
        return this.agencyProfile.logo;
      },
      username() {
        if (!this.isAgencyDomain && !this.whoIam.isPartner) {
          return `${this.whoIam.firstName} ${this.whoIam.lastName}`;
        }
        return this.whoIam.name;
      },
    },
    methods: {
      ...mapActions(['iAmDone']),
      logout() {
        this.iAmDone().then(
          () => {
            this.$router.push({ name: 'login' });
          },
        );
      },
      goToSupport() {
        window.open('https://support.sambakker.com', '_blank');
      },
      goToAccountSetting() {
        if (this.isAgencyDomain) {
          this.$router.push({ name: 'dashboard.account-setting.profile' });
        } else if (this.whoIam.isPartner) {
          this.$router.push({ name: 'dashboard.partnership-account' });
        } else {
          this.$router.push({ name: 'dashboard.account-setting.profile' });
        }
      },
    },
  };
</script>
