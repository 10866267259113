<template>
  <div v-if="contractDetail && contractDetail.id" class="partnership">
    <div class="card partnership-contract">
      <div v-if="contractDetail.membership.user" class="flex-start">
        <div class="avatar-image mr-15" :style="getImage(contractDetail.membership.user.avatar)">
          <initial-avatar
            v-if="!contractDetail.membership.user.avatar"
            :name="senderName"
          />
        </div>
        <div>
          <div class="primary-text xl semi-bold cursor-pointer head-admin-color">
            {{ senderName }} sent you request to partnership
          </div>
        </div>
      </div>
      <div class="mt-20">
        <div class="primary-text xl semi-bold">Campaign:</div>
        <div
          class="flex-start flex-1 course-item mt-10"
        >
          <div class="course-cover mr-2" :style="getImage(contractDetail.membership.media.logo)"/>
          <div class="primary-text lg semi-bold">{{ contractDetail.membership.title }}</div>
        </div>
      </div>
      <div class="mt-20">
        <div class="primary-text xl semi-bold mb-10">Contract Term:</div>
        <div class="flex-start">
          <div class="percentage-box flex-center mr-2">
            <icon-percentage />
          </div>
          <div class="primary-text lg semi-bold">
            Percentage of net sales revenue - {{ contractDetail.amount }}%
          </div>
        </div>
      </div>
      <div class="flex-end mt-30">
        <v-btn
          class="custom-btn__danger sm mr-20"
          :loading="loading"
          @click="onAction('decline')"
        >
          Decline Contract
        </v-btn>
        <v-btn
          class="custom-btn custom-btn-secondary sm"
          :loading="loading"
          @click="onAction('active')"
        >
          Accept Contract
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import IconPercentage from '@/assets/icons/partnership/percentage.svg';
  import PartnershipApi from '@/api/partnership';

  export default {
    components: {
      InitialAvatar,
      IconPercentage,
    },
    mixins: [linkHelper],
    data() {
      return {
        loading: false,
        contractDetail: null,
      };
    },
    computed: {
      senderName() {
        return `${this.contractDetail.membership.user.firstName} ${this.contractDetail.membership.user.lastName}`;
      },
    },
    async created() {
      this.contractDetail = await PartnershipApi.getContractInfoByCode(this.$route.params.code);
      if (!this.contractDetail || !this.contractDetail.id) {
        this.$router.push({ name: 'login' });
      }
    },
    methods: {
      ...mapActions(['syncMe']),
      ...mapActions('partnership', ['activePartnership']),
      getImage(img) {
        return img ? { 'background-image': `url("${this.imageUrl(img)}")` } : {};
      },
      onAction(type) {
        this.loading = true;
        this.activePartnership({
          code: this.$route.params.code,
          status: type,
        }).then((jwt) => {
          this.loading = false;
          window.http
            .defaults
            .headers
            .Authorization = `Bearer ${jwt.token}`;
          localStorage.setItem('jwtToken', jwt.token);
          this.syncMe();
          this.$router.push({ name: 'dashboard.partnership-contracts' });
        });
      },
    },
  };
</script>
