import * as types from '@/store/mutation-types';
import AgencyApi from '@/api/agency';

const state = {
  clients: [],
  invoices: [],
  client: {},
  clientProfiles: [],
  clientProfile: null,
};

const getters = {
  clientList: state => state.clients,
  clientDetail: state => state.client,
  invoiceList: state => state.invoices,
  clientProfileList: state => state.clientProfiles,
  clientProfileDetail: state => state.clientProfile,
};

const actions = {
  syncClientList: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    AgencyApi.getClientList()
      .then(
        (data) => {
          commit(types.SET_AGENCY_CLIENT_LIST, data);
          resolve(data);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  sendActiveEmail({ commit, state, dispatch }, clientId) {
    AgencyApi.sendActiveEmail(clientId)
      .then(() => {
        const tmp = state.client;
        let message = 'A registration email has been sent to your client.';
        if (tmp.isSentInvite) {
          message = 'A registration email has been resent to your client.';
        }
        tmp.isSentInvite = true;
        dispatch('showAlert', message, { root: true });
        commit(types.SET_AGENCY_CLIENT_DETAIL, tmp);
      })
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: error }, { root: true });
        },
      );
  },
  setClientDetail({ commit }, client) {
    commit(types.SET_AGENCY_CLIENT_DETAIL, client);
  },
  updateClient: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    AgencyApi.updateClient(data)
      .then(
        (res) => {
          commit(types.SET_AGENCY_CLIENT_DETAIL, res);
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: error }, { root: true });
          reject(error);
        },
      );
  }),
  deleteClient({ commit, dispatch }, clientId) {
    AgencyApi.deleteClient(clientId)
      .then(
        () => {
          commit(types.DELETE_CLIENT_FROM_LIST, clientId);
          dispatch('showAlert', 'Client has been deleted', { root: true });
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
        },
      );
  },
  fetchClientDetail({ commit, dispatch }, clientId) {
    AgencyApi.getClientDetail(clientId)
      .then(
        (data) => {
          commit(types.SET_AGENCY_CLIENT_DETAIL, data);
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
        },
      );
  },
  addClient: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    AgencyApi.addNewClient(data)
      .then(
        (res) => {
          commit(types.ADD_CLIENT_INTO_LIST, res);
          dispatch('showAlert', 'New client has been added', { root: true });
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: error }, { root: true });
          reject(error);
        },
      );
  }),
  fetchClientInvoices({ commit, dispatch }, data) {
    AgencyApi.getClientInvoices(data)
      .then(
        (res) => {
          commit(types.SET_CLIENT_INVOICE_LIST, res);
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
        },
      );
  },
  createNewInvoice({ commit, dispatch }, formData) {
    AgencyApi.createInvoice(formData)
      .then(
        (res) => {
          commit(types.ADD_INVOICE_INTO_LIST, res);
          dispatch('showAlert', 'Invoice has been added and sent to client email', { root: true });
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
        },
      );
  },
  updateClientProfile: ({ commit, dispatch }, profile) => new Promise((resolve, reject) => {
    AgencyApi.updateClientProfile(profile)
      .then(
        (res) => {
          commit(types.UPDATE_CLIENT_PROFILE, profile);
          dispatch('showAlert', 'Client profile is updated', { root: true });
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  deleteClientProfile: ({ commit, dispatch }, profile) => new Promise((resolve, reject) => {
    AgencyApi.deleteProfile(profile.id)
      .then(
        (res) => {
          commit(types.DELETE_CLIENT_PROFILE, profile);
          dispatch('showAlert', 'Client profile is deleted', { root: true });
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  duplicateClientProfile: ({ commit, dispatch }, profileId) => new Promise((resolve, reject) => {
    AgencyApi.duplicateProfile(profileId)
      .then(
        (res) => {
          commit(types.ADD_CLIENT_PROFILE, res);
          dispatch('showAlert', 'Client profile is duplicated', { root: true });
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  createClientProfile: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    AgencyApi.createClientProfile(data)
      .then(
        (res) => {
          commit(types.ADD_CLIENT_PROFILE, res);
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  getClientProfiles: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    AgencyApi.getAllClientProfiles()
      .then(
        (data) => {
          commit(types.SET_CLIENT_PROFILES, data);
          resolve(data);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' }, { root: true });
          reject(error);
        },
      );
  }),
  getClientProfileDetail: ({ commit }, profileId) => new Promise((resolve, reject) => {
    commit(types.SET_CLIENT_PROFILE, null);
    AgencyApi.getClientProfileDetail(profileId)
      .then((data) => {
        commit(types.SET_CLIENT_PROFILE, data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  setClientProfileDetail: ({ commit, state }, data) => {
    const tmp = Object.assign(state.clientProfile, data);
    commit(types.SET_CLIENT_PROFILE, tmp);
  },
};

const mutations = {
  [types.UPDATE_CLIENT_INVOICE_IN_LIST](state, payload) {
    const index = state.invoices.findIndex(c => c.id === payload.id);
    state.invoices[index] = payload;
  },
  [types.ADD_INVOICE_INTO_LIST](state, payload) {
    state.invoices.push(payload);
  },
  [types.ADD_CLIENT_INTO_LIST](state, payload) {
    state.clients.push(payload);
  },
  [types.DELETE_CLIENT_FROM_LIST](state, id) {
    const index = state.clients.findIndex(c => c.id === id);
    state.clients.splice(index, 1);
  },
  [types.SET_AGENCY_CLIENT_LIST](state, payload) {
    state.clients = payload;
  },
  [types.SET_AGENCY_CLIENT_DETAIL](state, payload) {
    state.client = payload;
  },
  [types.SET_CLIENT_INVOICE_LIST](state, payload) {
    state.invoices = payload;
  },
  [types.SET_CLIENT_PROFILES](state, payload) {
    state.clientProfiles = payload;
  },
  [types.SET_CLIENT_PROFILE](state, payload) {
    state.clientProfile = payload;
  },
  [types.ADD_CLIENT_PROFILE](state, payload) {
    state.clientProfiles.push(payload);
  },
  [types.UPDATE_CLIENT_PROFILE](state, payload) {
    const index = state.clientProfiles.findIndex(c => c.id === payload.id);
    state.clientProfiles[index] = payload;
  },
  [types.DELETE_CLIENT_PROFILE](state, payload) {
    const index = state.clientProfiles.findIndex(c => c.id === payload.id);
    state.clientProfiles.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
