import * as types from '@/store/mutation-types';

import Integration from '@/api/integration';
import ActionIntegrationsApi from '@/api/actionIntegrations';

const state = {
  list: [],
  atSync: false,
  actionIntegrations: [],
  clientProfileId: null,
  profileIntegrationType: null,
};

const actions = {
  getList: ({ commit }, data) => new Promise((resolve, reject) => {
    if (state.atSync) {
      return;
    }
    let param = {};
    if (typeof data === 'string' || data instanceof String) {
      param.type = data;
    } else {
      param = data;
    }
    commit(types.SET_LIST_BUSY, true);
    commit(types.SET_LIST, []);
    Integration.getList(param).then(
      (list) => {
        if (list.error) {
          commit(types.SET_ALERT_TYPE, 'danger');
          commit(types.SET_ALERT_STATE, true);
          commit(types.SET_ALERT_MESSAGE, list.error);
        } else {
          commit(types.SET_LIST, list);
        }
        commit(types.SET_LIST_BUSY, false);
        resolve();
      },
      (error) => {
        reject(error);
      },
    );
  }),
  updateIntegrations: ({
    commit, state, dispatch, rootGetters,
  }, data) => new Promise((resolve, reject) => {
    let tmp = {};
    if (state.clientProfileId) {
      tmp.clientProfileId = state.clientProfileId;
      tmp.integrations = rootGetters['agency/clientProfileDetail'].integrations;
      tmp.integrations[state.profileIntegrationType] = data.integrations[state.profileIntegrationType];
    } else {
      tmp = data;
    }
    Integration.update(tmp).then(
      (res) => {
        resolve();
        if (state.clientProfileId) {
          dispatch('agency/setClientProfileDetail', {
            integrations: res.integrations,
          });
        } else {
          dispatch('updateLocalMe', {
            integrations: res.integrations,
          });
        }
      },
      (error) => {
        reject(error);
      },
    );
  }),
  loadActionIntegrations: ({ commit }, productId) => new Promise((resolve, reject) => {
    ActionIntegrationsApi.loadList(productId)
      .then((res) => {
        commit(types.SET_ACTION_INTEGRATIONS_LIST, res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  connectPayPal: ({ commit }, data) => new Promise((resolve, reject) => {
    window.http.post('/paypal/save-paypal-app', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));

    // Integration.connectPayPal(data)
    //   .then((res) => {
    //     resolve(res);
    //   })
    //   .catch((err) => {
    //     reject(err);
    //   });
  }),
  unlinkPaypalApp: () => new Promise((resolve, reject) => {
    window.http.post('/paypal/unlink-paypal-app')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  addActionIntegration: ({ commit }, payload) => {
    commit(types.ADD_ACTION_INTEGRATION, payload);
  },
  updateActionIntegration: ({ commit }, payload) => {
    commit(types.UPDATE_ACTION_INTEGRATION, { id: payload.id, all: payload });
  },
  activateActionIntegration: ({ commit }, id) => new Promise((resolve, reject) => {
    ActionIntegrationsApi.activate(id)
      .then(() => {
        commit(types.UPDATE_ACTION_INTEGRATION, {
          id,
          key: 'active',
          value: true,
        });
        resolve();
      })
      .catch(() => reject());
  }),
  deactivateActionIntegration: ({ commit }, id) => new Promise((resolve, reject) => {
    ActionIntegrationsApi.deactivate(id)
      .then(() => {
        commit(types.UPDATE_ACTION_INTEGRATION, {
          id,
          key: 'active',
          value: false,
        });
        resolve();
      })
      .catch(() => reject());
  }),
  removeActionIntegration: ({ commit }, id) => {
    commit(types.REMOVE_ACTION_INTEGRATION, id);
  },
  setProfileIdIntegration: ({ commit }, profileId) => {
    commit(types.SET_PROFILE_ID_INTEGRATION, profileId);
  },
  setProfileIntegrationType: ({ commit }, type) => {
    commit(types.SET_PROFILE_INTEGRATION_TYPE, type);
  },
};

const getters = {
  listAutoresponders: state => state.list,
  listAtSync: state => state.atSync,
  actionIntegrations: state => state.actionIntegrations,
};

const mutations = {
  [types.SET_PROFILE_ID_INTEGRATION](state, payload) {
    state.clientProfileId = payload;
  },
  [types.SET_PROFILE_INTEGRATION_TYPE](state, payload) {
    state.profileIntegrationType = payload;
  },
  [types.SET_LIST](state, payload) {
    state.list = payload;
  },
  [types.SET_LIST_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.SET_ACTION_INTEGRATIONS_LIST](state, payload) {
    state.actionIntegrations = payload;
  },
  [types.ADD_ACTION_INTEGRATION](state, payload) {
    state.actionIntegrations.push(payload);
  },
  [types.UPDATE_ACTION_INTEGRATION](state, {
    id, key, value, all,
  }) {
    const index = state.actionIntegrations.findIndex(i => i.id === id);
    if (index !== -1) {
      if (all) {
        state.actionIntegrations.splice(index, 1, all);
      } else {
        const item = { ...state.actionIntegrations[index] };

        item[key] = value;

        state.actionIntegrations.splice(index, 1, item);
      }
    }
  },
  [types.REMOVE_ACTION_INTEGRATION](state, id) {
    const index = state.actionIntegrations.findIndex(i => i.id === id);

    if (index !== -1) {
      state.actionIntegrations.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
