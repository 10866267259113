<template>
  <div class="partnership-commission">
    <div v-if="!detail" class="partnership-admin">
      <statistic-admin-comp />
      <commission-admin-comp class="mt-30" @selected="onSelected" />
    </div>
    <div v-else>
      <commission-detail-admin-comp :detail="detail" @back="detail = undefined" />
    </div>
  </div>
</template>
<script>
  import StatisticAdminComp from './Statistic';
  import CommissionAdminComp from './Commission';
  import CommissionDetailAdminComp from './CommissionDetail';

  export default {
    components: {
      StatisticAdminComp,
      CommissionAdminComp,
      CommissionDetailAdminComp,
    },
    data() {
      return {
        detail: undefined,
      };
    },
    methods: {
      onSelected(item) {
        this.detail = item;
      },
    },
  };
</script>
