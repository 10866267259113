import io from 'socket.io-client';
import { UAParser } from 'ua-parser-js';

const onConnection = socket => () => socket.id;

const onLogout = () => {
  localStorage.removeItem('jwtToken');
  window.location.reload();
};


export default function createConnection(token, fingerprint, loginInfo, userId) {
  if (!token || token === 'null' || !fingerprint) {
    return null;
  }

  let tmp = loginInfo;
  if (!tmp.ip) {
    tmp = {};
  }
  const parser = new UAParser();
  tmp.browser = parser.getBrowser();
  tmp.os = parser.getOS();


  const socket = io(process.env.VUE_APP_SOCKET_URL, {
    transports: ['polling'],
    query: {
      auth: token,
      fingerprint,
      loginInfo: JSON.stringify(tmp),
    },
  });

  socket.on('logout-user-fingerprint', (data) => {
    if (data && data.fingerprint === fingerprint) {
      onLogout();
    }
  });

  socket.on('logout-user', (data) => {
    if (data && data.userId === userId) {
      onLogout();
    }
  });

  socket.on('connect', onConnection(socket));

  return socket;
}
