<template>
  <v-menu
    v-model="activeStatus"
    class="multi-select"
    offset-y
    transition="slide-y-transition"
    :left="true"
    :disabled="disabled"
    :close-on-content-click="false"
    :content-class="`multi-select-content search-select ${customContentClass}`">
    <template v-slot:activator="{ on }">
      <div class="multi-select-text search-select solo"
           :class="selectClass"
           v-on="on">
        <template v-if="customHolder">
          <slot/>
        </template>
        <template v-else-if="searchable && activeStatus">
          <input ref="searchInput" v-model="keyword" type="text" class="search-input">
          <icon-search class="search"/>
        </template>
        <template v-else>
          <template v-if="selectedItem && selectedItem.icon">
            <component :is="`icon-${selectedItem.icon}`" class="prepend-icon" />
          </template>
          <p>
            {{ selectedItem[itemValue] !== undefined ? selectedItem[itemTitle] : placeholder }}
          </p>
          <fa-icon
            v-if="clearable && value !== ''"
            :icon="['fal', 'times']"
            class="clear-value"
            @click.stop="clear"/>
          <icon-dropdown class="dropdown" />
        </template>
      </div>
      <p v-if="errorMessages" class="multi-select-error">{{ errorMessages[0] }}</p>
    </template>
    <div :class="customListClass" class="multi-select-list">
      <div v-if="items.length === 0" class="multi-select-no-item">
        No data available
      </div>
      <div v-if="enableLabelGroup">
        <template v-for="(group, gIndex) in items">
          <div :key="`${gIndex}`" class="multi-select-group-label">
            {{ group.label }}
          </div>
          <div v-for="(item, index) in group.items"
               :key="`${gIndex}-${index}`"
               class="multi-select-list-item"
               :class="{ 'selected': item[itemValue] === selectedItem[itemValue]}"
               @click="select(item)">
            {{ item[itemTitle] }}
          </div>
        </template>
      </div>
      <div v-else>
        <div v-for="(item, index) in searchItems"
             :key="index"
             class="multi-select-list-item"
             :class="{'coming-soon': item.comingSoon}"
             @click="select(item)">
          <component :is="`icon-${item.icon}`" v-if="item.icon"/>
          {{ item[itemTitle] }} <span v-if="item.comingSoon" class="coming-soon">Coming Soon</span>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
  export default {
    props: [
      'items', 'value', 'placeholder',
      'enableLabelGroup', 'itemValue', 'itemTitle',
      'customClass', 'errorMessages', 'clearable',
      'searchable', 'customHolder', 'clickAction',
      'emitItemChange', 'customListClass', 'disabled',
      'customContentClass',
    ],
    data() {
      return {
        keyword: '',
        activeStatus: false,
        selectedItem: {},
      };
    },
    computed: {
      selectClass() {
        return `${this.value ? 'selected' : ''} ${this.disabled ? 'disabled' : ''} ${this.customClass ? this.customClass : ''} ${this.activeStatus ? 'active' : ''}${this.errorMessages && this.errorMessages.length ? 'has-error' : ''}`;
      },
      searchItems() {
        if (!this.searchable || !this.keyword) {
          return this.items;
        }
        const result = [];
        this.items.forEach((item) => {
          if (item[this.itemTitle].includes(this.keyword)) {
            result.push(item);
          }
        });
        return result;
      },
    },
    watch: {
      value: {
        handler(value) {
          if (value || value === false) {
            this.init();
          } else {
            this.selectedItem = {};
          }
        },
        deep: true,
      },
      items: {
        handler(value) {
          if (value) {
            this.init();
          } else {
            this.selectedItem = {};
          }
        },
        deep: true,
      },
      activeStatus(val) {
        if (val && this.searchable) {
          this.keyword = '';
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
        }
      },
    },
    mounted() {
      this.$nextTick(() => {
        if (this.value) {
          this.init();
        }
      });
    },
    methods: {
      clear() {
        this.selectedItem = {};
        this.$emit('input', '');
        this.$emit('change');
      },
      init() {
        if (this.enableLabelGroup) {
          this.items.forEach((group) => {
            group.items.forEach((data) => {
              if (this.value === data[this.itemValue]) {
                this.selectedItem = data;
              }
            });
          });
        } else {
          this.items.forEach((item) => {
            if (this.value === item[this.itemValue]) {
              this.selectedItem = item;
            }
          });
        }
      },
      select(item) {
        if (item.comingSoon) {
          return;
        }
        if (this.clickAction) {
          this.$emit('clickAction', item);
          return;
        }
        this.selectedItem = item;
        this.activeStatus = false;
        this.$emit('input', item[this.itemValue]);
        if (this.emitItemChange) {
          this.$emit('change', item);
        } else {
          this.$emit('change');
        }
      },
    },
  };
</script>
