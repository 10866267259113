import * as types from '@/store/mutation-types';
import LessonApi from '@/api/lesson';

const state = {
  currentLesson: {},
  atSync: false,
};

const actions = {
  readLesson: ({ commit }, lessonId) => new Promise((resolve, reject) => {
    commit(types.SET_LESSON_AT_SYNC, true);
    LessonApi.read(lessonId).then(
      (res) => {
        commit(types.SET_LESSON_AT_SYNC, false);
        commit(types.SET_LESSON, res);
        resolve(res);
      },
      (error) => {
        commit(types.SET_LESSON_AT_SYNC, false);
        reject(error);
      },
    );
  }),
  updateLessonComponent: ({ dispatch }, data) => new Promise(
    (resolve, reject) => {
      LessonApi.updateLessonComponent(data).then(
        (res) => {
          dispatch('showAlert', { message: 'Your lesson is updated!!', type: 'success' });
          resolve(res);
        },
        (error) => {
          dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
          reject(error);
        },
      );
    },
  ),
};

const getters = {
  currentLesson: state => state.currentLesson,
  lessonAtSync: state => state.atSync,
};

const mutations = {
  [types.SET_LESSON](state, payload) {
    state.currentLesson = payload;
  },
  [types.SET_LESSON_AT_SYNC](state, payload) {
    state.atSync = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
