import * as types from '@/store/mutation-types';
import ClientAgencyApi from '@/api/clientAgency';

const state = {
  clientInfo: {},
  agencyProfile: {},
  isAgencyDomain: false,
  invoices: [],
};

const getters = {
  clientInfo: state => state.clientInfo,
  agencyProfile: state => state.agencyProfile,
  isAgencyDomain: state => state.isAgencyDomain,
  invoiceList: state => state.invoices,
};

const actions = {
  fetchAgencyProfile: ({ commit, dispatch }, subDomain) => new Promise((resolve, reject) => {
    ClientAgencyApi.getAgencyInfoBySubdomain(subDomain)
      .then(
        (data) => {
          commit(types.SET_CLIENT_AGENCY_PROFILE, data);
          resolve(data);
        },
      )
      .catch(
        (message) => {
          dispatch('showAlert', { type: 'danger', message }, { root: true });
          reject(message);
        },
      );
  }),
  setIsAgencyDomain({ commit }, payload) {
    commit(types.SET_IS_AGENCY_DOMAIN, payload);
  },
  loginClient: ({ dispatch }, credential) => new Promise((resolve, reject) => {
    ClientAgencyApi.loginClient(credential).then(
      (res) => {
        window.http
          .defaults
          .headers
          .Authorization = `Bearer ${res.jwt.token}`;
        localStorage.setItem('jwtToken', res.jwt.token);
        dispatch('syncMe', {}, { root: true });
        resolve(res);
      },
    ).catch(
      (error) => {
        reject(error);
      },
    );
  }),
  registerClient: ({ dispatch }, formData) => new Promise((resolve, reject) => {
    ClientAgencyApi.registerClient(formData).then(
      (res) => {
        dispatch('loginClient', { email: formData.email, password: formData.password });
        resolve(res);
      },
    ).catch(
      (error) => {
        reject(error);
      },
    );
  }),
  fetchOwnInvoice: ({ commit }) => new Promise((resolve, reject) => {
    ClientAgencyApi.getOwnInvoices().then(
      (res) => {
        commit(types.SET_CLIENT_OWN_INVOICE_LIST, res);
        resolve(res);
      },
    ).catch(
      (error) => {
        reject(error);
      },
    );
  }),
};

const mutations = {
  [types.SET_CLIENT_AGENCY_PROFILE](state, payload) {
    state.agencyProfile = payload;
  },
  [types.SET_IS_AGENCY_DOMAIN](state, payload) {
    state.isAgencyDomain = payload;
  },
  [types.SET_CLIENT_OWN_INVOICE_LIST](state, payload) {
    state.invoices = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
