<template>
  <div class="switch-group-btn">
    <span :style="switchBtnStyle" class="switch-btn-slider" />
    <div
      v-for="(item, index) in items"
      :key="index"
      :ref="`switch-group-${item.id}`"
      :class="{active: selectedItem.id === item.id}"
      class="switch-btn"
      @click="click(item)"
    >
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['items', 'value', 'initFirstValue'],
    data() {
      return {
        switchBtnStyle: '',
        selectedItem: '',
      };
    },
    mounted() {
      if (this.initFirstValue && this.value !== undefined) {
        this.selectedItem = this.items.find(i => i.id === parseInt(this.value, 10));
      } else {
        // eslint-disable-next-line
        this.selectedItem = this.items[0];
      }
      this.$nextTick(() => {
        this.changePositionSlide();
      });
    },
    methods: {
      click(selectedItem) {
        this.selectedItem = selectedItem;
        this.$emit('input', this.selectedItem.id);
        this.$emit('change', this.selectedItem.id);
        this.changePositionSlide();
      },
      changePositionSlide() {
        let leftPos = 0;
        // eslint-disable-next-line
        for (const item of this.items) {
          if (item.id === this.selectedItem.id) {
            break;
          }
          leftPos += this.$refs[`switch-group-${item.id}`][0] ? this.$refs[`switch-group-${item.id}`][0].clientWidth : 0;
        }
        this.switchBtnStyle = `left: ${leftPos + 5}px; width: ${this.$refs[`switch-group-${this.selectedItem.id}`][0] ? this.$refs[`switch-group-${this.selectedItem.id}`][0].clientWidth : 0}px !important`;
      },
    },
  };
</script>
