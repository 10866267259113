<template>
  <v-snackbar
    v-model="show"
    :timeout="alertTimeout"
    :class="`${alertType}-alert`"
    top
    right>
    <icon-check-circle v-if="isSuccess" class="alert-icon" />
    <icon-warning-x v-else-if="isDanger || isWarning" :class="alertType" class="alert-icon"/>
    <icon-info-x v-if="isInfo" class="alert-icon main-info-icon"/>
    <div class="ml-10">
      <p class="alert-title">{{ title }}</p>
      <p class="alert-message">{{ alertMessage }}</p>
    </div>
    <v-btn icon @click="closeAlert">
      <fa-icon :icon="['fal', 'times']" />
    </v-btn>
  </v-snackbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        titleByType: {
          success: 'Success!',
          danger: 'Oh No!',
          warning: 'Regular Notice',
          info: 'New Thing',
        },
      };
    },
    computed: {
      ...mapGetters([
        'alertState',
        'alertType',
        'alertTitle',
        'alertMessage',
        'alertTimeout',
      ]),
      show: {
        get() {
          return this.alertState;
        },
        set(val) {
          if (!val) {
            this.closeAlert();
          }
        },
      },
      title() {
        if (this.alertTitle !== '') {
          return this.alertTitle;
        }
        return this.titleByType[this.alertType];
      },
      isSuccess() {
        return this.alertType === 'success';
      },
      isWarning() {
        return this.alertType === 'warning';
      },
      isDanger() {
        return this.alertType === 'danger';
      },
      isInfo() {
        return this.alertType === 'info';
      },
    },
    methods: {
      ...mapActions([
        'closeAlert',
      ]),
    },
  };
</script>
