import axios from 'axios';
import router from '@/router';

const config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // responseType: "json",
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('jwtToken')}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const authRedirect = () => {
  localStorage.removeItem('jwtToken');
  if (router.history.current.name !== 'login') {
    router.push({ name: 'login' });
  }
};

const Http = axios.create(config);

// Http.interceptors.request.use(
//   async (replaceConfig) => {
//     const token = localStorage.getItem('jwtToken');
//     // eslint-disable-next-line
//     replaceConfig.headers.Authorization = `Bearer ${token}`;
//     return replaceConfig;
//   },
//   error => Promise.reject(error),
// );

Http.interceptors.response.use((response) => {
  if (
    !localStorage.getItem('jwtToken')
    && !(response
      && response.data
      && response.data.jwt
      && response.data.jwt.token)
  ) {
    if (
      router.history.current.name !== 'login'
      && router.history.current.name !== 'reset-password'
      && router.history.current.name !== 'user-create-password'
      && router.history.current.name !== 'affiliate.auth.apply_promote'
      && router.history.current.name !== 'client-register'
      && router.history.current.name !== 'client-invoice'
      && router.history.current.name !== 'partnership-invite'
      && router.history.current.name !== 'partnership-register'
      && router.history.current.path.indexOf('/public') !== 0
    ) {
      router.push({ name: 'login' });
    }
  }


  return response;
}, (error) => {
  if (error && error.response) {
    switch (error.response.status) {
      case 401: authRedirect();
        break;
      case 403: authRedirect();
        break;
      case 404: authRedirect();
        break;
      default: break;
    }
  }

  return Promise.reject(error);
});

export default Http;
