import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  theme: {
    primary: '#fb804d',
    secondary: '#14c8b1',
    accent: '#403ac3',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    gray: '#7b90a1',
  },
  customProperties: true,
  iconfont: 'md',
});
