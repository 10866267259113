<template>
  <thead>
    <tr>
      <th v-for="(header,index) in headers" :key="index" :style="headerStyle(header)">
        <div class="my-datatable-header__content" :style="headerInnerStyle(header)">
          <template v-if="header.customElements">
            <slot />
          </template>
          <template v-else>{{ header.label }}</template>
          <div v-if="header.sort" class="my-datatable-header__sort"
               @click="sort(header.field)">
            <fa-icon
              :icon="['fas', 'caret-up']"
              :class="{active: header.field === currentSort.field && currentSort.order === 'ASC'}"
            />
            <fa-icon
              :icon="['fas', 'caret-down']"
              :class="{active: header.field === currentSort.field && currentSort.order === 'DESC'}"
            />
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    props: ['headers', 'sortable'],
    data() {
      return {
        currentSort: {},
      };
    },
    methods: {
      headerStyle(header) {
        return {
          width: header.width ? header.width : null,
        };
      },
      headerInnerStyle(header) {
        return {
          'justify-content': header.textAlign ? header.textAlign : null,
        };
      },
      sort(field) {
        let order = 'ASC';
        if (field === this.currentSort.field && this.currentSort.order === 'ASC') {
          order = 'DESC';
        }
        this.currentSort = {
          field,
          order,
        };
        this.$emit('sort', this.currentSort);
      },
    },
  };
</script>
