<template>
  <div class="account-setting">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'dashboard.partnership-contracts' })">
        Contracts
      </a>
      <fa-icon :icon="['fal', 'chevron-right']"/>
      <a class="primary-text active">
        Profile Settings
      </a>
    </div>
    <div class="account-setting__container alternative">
      <v-tabs v-model="tab" class="account-setting__tabs">
        <v-tab class="account-setting__tab" href="#profile">Profile</v-tab>
        <v-tab class="account-setting__tab" href="#payments">Payment Method</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="account-setting__tab-item">
        <v-tab-item value="profile"
                    :transition="false"
                    :reverse-transition="false">
          <profile-setting />
        </v-tab-item>
        <v-tab-item value="payments"
                    :transition="false"
                    :reverse-transition="false">
          <payment-method-setup />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import PaymentMethodSetup from './components/PaymentMethodSetup';
  import ProfileSetting from './components/ProfileSetting';

  export default {
    components: {
      PaymentMethodSetup,
      ProfileSetting,
    },
    data() {
      return {
        tab: 'profile',
      };
    },
    mounted() {
      if (this.$route.query && this.$route.query.tab) {
        this.tab = this.$route.query.tab;
      }
    },
  };
</script>
