import Vue from 'vue';

import * as types from '@/store/mutation-types';

import ProductApi from '@/api/product';

import roles from '../../config/roles';

import ProductModel from '../../models/product';

const state = {
  wizardNew: false,
  currentIndex: 0,
  steps: [
    {
      name: 'settings',
      labelCreate: 'Bundle Settings',
      labelEdit: 'Bundle Settings',
      firstTab: 'bundle',
      routeName: ['dashboard.bundle.settings'],
      role: [roles.STAFF_ADMIN],
    },
    {
      name: 'checkout',
      labelCreate: 'Checkout',
      labelEdit: 'Checkout',
      firstTab: 'payment',
      routeName: ['dashboard.bundle.checkout'],
      role: [roles.STAFF_ADMIN],
    },
    {
      name: 'action-integration',
      labelCreate: 'Integration',
      labelEdit: 'Integration',
      firstTab: 'integrations',
      routeName: ['dashboard.bundle.action-integration'],
      role: [roles.STAFF_ADMIN],
    },
  ],
  wizardSaleSettingTab: '',
  wizardCheckoutTab: '',
  wizardInsideTab: {
    settings: ['bundle', 'courses'],
    checkout: ['payment', 'affiliates'],
  },
  currentProduct: new ProductModel(),
};

const getters = {
  currentWizardStep: (state, getters) => getters.wizardSteps[state.currentIndex],
  currentWizardIndex: state => state.currentIndex,
  wizardSteps: (state, getters, rootState, rootGetters) => {
    const newStep = [];
    state.steps.forEach((step) => {
      const push = rootGetters.whoIam && (
        !rootGetters.whoIam.role || (step.role && step.role.includes(rootGetters.whoIam.role))
      );

      if (push) {
        newStep.push(step);
      }
    });
    return newStep;
  },
  wizardSaleSettingTab: state => state.wizardSaleSettingTab,
  wizardCheckoutTab: state => state.wizardCheckoutTab,
  isWizardNew: state => state.wizardNew,
  currentProduct: state => state.currentProduct,
};

const actions = {
  createProduct: ({ state, commit }) => new Promise((resolve, reject) => {
    ProductApi.create(state.currentProduct).then((res) => {
      commit(types.SET_CURRENT_PRODUCT_DATA, res);
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  saveProduct: ({ state, commit }) => new Promise((resolve, reject) => {
    ProductApi.update(state.currentProduct.id, state.currentProduct).then((res) => {
      commit(types.SET_CURRENT_PRODUCT_DATA, res);
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  uploadProductCover: ({ commit, rootState }, { id, cover }) => new Promise((resolve, reject) => {
    ProductApi.uploadCover(id, cover).then((res) => {
      const product = rootState.membership.memberships
        .find(m => m.products.find((p => p.id === id)));

      if (product) {
        Vue.set(product, 'cover', res.path);
        commit(types.UPDATE_CURRENT_PRODUCT_DATA, { key: 'cover', value: res.path });
      }

      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  updateProduct: ({ rootState }, productData) => new Promise((resolve, reject) => {
    ProductApi.update(productData.id, productData).then((res) => {
      Vue.set(rootState.membership.wizardCourse, 'product', Object.assign({}, productData, res));
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  removeProductCover: ({ commit, rootState }, id) => new Promise((resolve, reject) => {
    ProductApi.removeCover(id).then((res) => {
      const product = rootState.membership.memberships
        .find(m => m.products.find((p => p.id === id)));

      if (product) {
        Vue.set(product, 'cover', null);
        commit(types.UPDATE_CURRENT_PRODUCT_DATA, { key: 'cover', value: null });
      }

      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  deleteProduct: ({ rootState }, id) => new Promise((resolve, reject) => {
    ProductApi.delete(id).then((res) => {
      const membership = rootState.membership.memberships
        .find(m => m.products.findIndex((p => p.id === id)) !== -1);

      if (membership) {
        Vue.delete(membership.products, membership.products.findIndex((p => p.id === id)));
      }
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
  setCurrentProductData: ({ commit }, payload) => {
    const data = Object.assign(new ProductModel(), payload);
    commit(types.SET_CURRENT_PRODUCT_DATA, data);
  },
  updateCurrentProductData: ({ commit }, payload) => {
    commit(types.UPDATE_CURRENT_PRODUCT_DATA, payload);
  },
  wizardNextStep: ({ commit, getters }) => {
    commit(types.WIZARD_NEXT_STEP, getters.wizardSteps);
    return Promise.resolve(true);
  },
  wizardPrevStep: ({ commit }) => {
    commit(types.WIZARD_PREV_STEP);
    return Promise.resolve(true);
  },
  setWizardStep: ({ commit, getters }, idx) => {
    commit(types.WIZARD_STEP, { idx, wizardSteps: getters.wizardSteps });
    return Promise.resolve(true);
  },
  resetWizardSteps: ({ commit }) => {
    commit(types.RESET_WIZARD_STEPS);
    return Promise.resolve(true);
  },
  setWizardNew: ({ commit }, val) => {
    commit(types.SET_WIZARD_NEW, val);
    return Promise.resolve(true);
  },
  setWizardTab: ({ commit }, data) => new Promise((resolve) => {
    switch (data.tabType) {
      case 'settings':
        commit(types.SET_WIZARD_SALE_TAB, data.tab);
        break;
      case 'checkout':
        commit(types.SET_WIZARD_CHECKOUT_TAB, data.tab);
        break;
      default:
        break;
    }
    resolve(true);
  }),
  switchInsideTab: ({ state, getters, dispatch }) => {
    if (!getters.isWizardNew) {
      return false;
    }
    if (['settings', 'checkout'].indexOf(getters.currentWizardStep.name) === -1) {
      return false;
    }
    let currentTab = '';
    switch (getters.currentWizardStep.name) {
      case 'settings':
        currentTab = getters.wizardSaleSettingTab;
        break;
      case 'checkout':
        currentTab = getters.wizardCheckoutTab;
        break;
      default:
        break;
    }

    if (!currentTab) {
      return false;
    }

    const tabs = state.wizardInsideTab[getters.currentWizardStep.name];
    const currentIndex = tabs.findIndex(item => item === currentTab);

    if (currentIndex + 1 === tabs.length) {
      return false;
    }

    dispatch('setWizardTab', {
      tab: tabs[currentIndex + 1],
      tabType: getters.currentWizardStep.name,
    });

    return true;
  },
  getProductById: ({ commit }, id) => new Promise((resolve, reject) => {
    ProductApi.getProduct(id).then((res) => {
      commit(types.SET_CURRENT_PRODUCT_DATA, res);
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  }),
};

const mutations = {
  [types.SET_CURRENT_PRODUCT_DATA](state, data) {
    Vue.set(state, 'currentProduct', data);
  },
  [types.UPDATE_CURRENT_PRODUCT_DATA](state, { key, value }) {
    const data = { ...state.currentProduct };
    data[key] = value;
    state.currentProduct = data;
  },
  [types.WIZARD_NEXT_STEP](state, wizardSteps) {
    if (state.currentIndex < wizardSteps.length) {
      state.currentIndex += 1;
    }
    if (state.currentIndex > state.lastVisited) {
      state.lastVisited = state.currentIndex;
    }
  },
  [types.WIZARD_PREV_STEP](state) {
    if (state.currentIndex > 0) {
      state.currentIndex -= 1;
    }
  },
  [types.WIZARD_STEP](state, { idx, wizardSteps }) {
    if (idx >= 0 && idx < wizardSteps.length) {
      state.currentIndex = idx;
    }
  },
  [types.RESET_WIZARD_STEPS](state) {
    state.currentIndex = 0;
    state.lastVisited = 0;
    state.new = false;
  },
  [types.SET_WIZARD_IDS](state, params) {
    if (params) {
      // if (params.courseId) {
      state.courseId = params.courseId || 0;
      // }
      // if (params.membershipId) {
      state.membershipId = params.membershipId || 0;
      // }
    }
  },
  [types.SET_WIZARD_STEP_BY_ROUTE_NAME](state, { routeName, getters }) {
    const findStep = () => {
      const steps = getters.wizardSteps.map(w => w.routeName);

      return steps.findIndex(a => a.includes(routeName));
    };

    const idx = findStep(routeName);

    if (idx !== -1) {
      state.currentIndex = idx;
      if (state.currentIndex > state.lastVisited) {
        state.lastVisited = state.currentIndex;
      }
    }
  },
  [types.SET_WIZARD_NEW](state, val) {
    state.wizardNew = !!val;
  },
  [types.SET_WIZARD_SALE_TAB](state, payload) {
    state.wizardSaleSettingTab = payload;
  },
  [types.SET_WIZARD_CHECKOUT_TAB](state, payload) {
    state.wizardCheckoutTab = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
