import * as types from '@/store/mutation-types';

const state = {
  websocket: null,
  data: [],
  loading: false,
  lastUpdated: false,
  try: 0,
};

const actions = {
  closeVideosConnection: ({ commit }) => {
    if (state.websocket && state.websocket.readyState < 2) {
      state.websocket.close();
      commit(types.SET_VIDEOS_CONNECTION, null);
    }
  },
  setupVideosConnection: ({ state, commit, dispatch }, reset) => new Promise((resolve, reject) => {
    commit(types.SET_VIDEOS_DATA_LOADING, true);
    if (reset) {
      commit(types.SET_VIDEOS_TRY, 0);
    } else {
      commit(types.SET_VIDEOS_TRY, state.try + 1);
    }

    // We can try to connect to websocket 5 times
    if (state.try >= 5) {
      commit(types.SET_VIDEOS_DATA_LOADING, false);
      reject(new Error('Too many attempts to connect to videos server'));
      return;
    }

    // If socket exists when we try to establish connection
    dispatch('closeVideosConnection');

    const newSocket = new WebSocket(process.env.VUE_APP_VIDEOS_WEBSOCKET);
    newSocket.onerror = (err) => {
      // eslint-disable-next-line
      console.error(err);

      // First try - 1s, Second try - 2s...
      setTimeout(() => {
        dispatch('setupVideosConnection', false);
      }, state.try * 1000);
    };

    newSocket.onopen = () => {
      commit(types.SET_VIDEOS_DATA_LOADING, false);
      commit(types.SET_VIDEOS_CONNECTION, newSocket);
      newSocket.send(`Bearer ${localStorage.getItem('jwtToken')}`);
      newSocket.send('videos');
      resolve();
    };

    newSocket.onmessage = (msg) => {
      const { data } = msg;

      try {
        const res = JSON.parse(data);

        if (res.status === 'videos') {
          commit(types.SET_VIDEOS_DATA, res.videos);
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }

      setTimeout(() => {
        newSocket.send('videos');
      }, 5000);
    };
  }),
};

const getters = {
  videoData: state => state.data,
  videoDataLoading: state => state.loading,
  videoDataLastUpdated: state => state.lastUpdated,
};

const mutations = {
  [types.SET_VIDEOS_TRY](state, val) {
    state.try = val;
  },
  [types.SET_VIDEOS_DATA_LOADING](state, val) {
    state.loading = val;
  },
  [types.SET_VIDEOS_CONNECTION](state, val) {
    state.websocket = val;
  },
  [types.SET_VIDEOS_DATA](state, videos) {
    state.data = videos;
    state.videoDataLastUpdated = Date.now();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
