<template>
  <div class="partnership__payment-methods">
    <template v-if="!showForm">
      <div class="layout justify-space-between">
        <p class="primary-text lg semi-bold">Payment Methods</p>
        <v-btn class="custom-btn-secondary sm" @click="addMethod">Add Method</v-btn>
      </div>
      <div class="my-datatable__wrapper mt-30">
        <div class="my-datatable__scroller">
          <table class="my-datatable mt-30 last-column-right client-management__table">
            <table-header :headers="headers" />
            <tbody>
              <tr v-for="(paymentMethod, index) in paymentMethods" :key="index">
                <td>
                  <div class="layout align-center">
                    <template v-if="paymentMethod.type === type.PAYPAL">
                      <paypal-logo-lg />
                      <span class="primary-text ml-2">PayPal: </span>
                      <span class="primary-text semi-bold ml-2">
                        ({{ paymentMethod.setting.email }})
                      </span>
                    </template>
                    <template v-if="paymentMethod.type === type.BANK">
                      <icon-bank/>
                      <span class="primary-text ml-2">Bank</span>
                      <span class="primary-text semi-bold ml-2">
                        Bank {{ paymentMethod.setting.bankName }} -
                        {{ paymentMethod.setting.bankAccount }}
                        (Direct to U.S. Bank)</span>
                    </template>
                  </div>
                </td>
                <td>
                  <span class="badge badge-success">Verified</span>
                </td>
                <td>
                  <v-menu
                    transition="slide-y-transition"
                    content-class="custom-menu membership__box-menu partnership-table__action"
                    offset-y bottom left>
                    <template v-slot:activator="{ on }">
                      <button
                        class="custom-action-dropdown__btn mr-2"
                        v-on="on"
                      >
                        <icon-more class="icon-more" />
                      </button>
                    </template>
                    <div class="custom-action-dropdown">
                      <button
                        class="dropdown-btn flex-start"
                        @click="edit(paymentMethod)"
                      >
                        <icon-edit class="icon-default" />
                        <span>Edit</span>
                      </button>
                      <button class="dropdown-btn delete"
                              @click="deleteMethod(paymentMethod)">
                        <icon-trash class="icon-default" />
                        <span>Delete</span>
                      </button>
                    </div>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <payment-method-form v-else :edit-data="editData" @goBack="showForm = false" />
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import TableHeader from '@/components/table/TableHeader';
  import affiliatePayment from '@/config/affiliatePayment';
  import PaymentMethodForm from './PaymentMethodForm';
  import PaypalLogoLg from '@/assets/tplogos/paypal_lg.svg';
  import ConfirmDialog from '@/components/ConfirmDialog';

  export default {
    components: {
      ConfirmDialog,
      PaypalLogoLg,
      PaymentMethodForm,
      TableHeader,
    },
    data() {
      return {
        editData: null,
        type: affiliatePayment.type,
        showForm: false,
        headers: [
          {
            label: 'system',
            width: '70%',
          },
          {
            label: 'status',
            width: '28%',
          },
          {
            label: 'action',
            textAlign: 'flex-end',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['whoIam', 'hasCountryToken']),
      ...mapGetters('partnership', ['paymentMethods']),
    },
    created() {
      if (!this.hasCountryToken) {
        this.fetchCountryToken();
      } else {
        this.getCountryListData();
      }
      this.getPaymentMethods();
    },
    methods: {
      ...mapActions('partnership', ['getPaymentMethods', 'deletePaymentMethod']),
      ...mapActions(['fetchCountryToken', 'getCountryListData', 'showAlert']),
      addMethod() {
        this.editData = null;
        this.showForm = true;
      },
      edit(paymentMethod) {
        this.showForm = true;
        this.editData = paymentMethod;
      },
      deleteMethod(paymentMethod) {
        this.$refs.confirm.open('Are you sure you want to delete this payment method ?', {}).then((res) => {
          if (res) {
            this.deletePaymentMethod(paymentMethod.id).then(() => {
              this.showAlert('Payment method is deleted');
            });
          }
        });
      },
    },
  };
</script>
