import _cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';

import * as types from '@/store/mutation-types';
import Domain from '@/api/domain';

const state = {
  domains: [],
  atSync: false,
};

const getters = {
  userDomains: state => state.domains,
  userCustomDomains: state => state.domains.filter(d => d.isCustom),
  userAvailableDomains: state => state.domains.filter(d => d.status === 'connected'),
  userSystemDomains: state => state.domains.filter(d => !d.isCustom && d.status === 'connected'),
  userDomainsAtSync: state => state.atSync,
  domainHasMembership:
    state => domain => (
      domain ? state.domains.find(d => d.id === domain.id && d.membership) : false
    ),
};

const actions = {
  syncDomains({ commit }) {
    commit(types.SET_USER_DOMAINS_BUSY, true);
    Domain.getAll().then(
      (domains) => {
        commit(types.SET_USER_DOMAINS, domains);
        commit(types.SET_USER_DOMAINS_BUSY, false);
      },
    );
  },
  addDomain: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DOMAINS_BUSY, true);
    Domain.create(data).then(
      (newDomain) => {
        commit(types.SET_USER_DOMAINS_BUSY, false);
        commit(types.ADD_USER_DOMAIN, newDomain);
        resolve(newDomain);
      },
      (error) => {
        commit(types.SET_USER_DOMAINS_BUSY, false);
        reject(error);
      },
    );
  }),
  recheckDomain: ({ commit }, domain) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DOMAINS_BUSY, true);
    const upd = _cloneDeep(domain);
    upd.status = 'in_progress';
    Domain.recheck(domain.id).then(
      (d) => {
        commit(types.UPDATE_USER_DOMAIN, { domain, upd: d });
        commit(types.SET_USER_DOMAINS_BUSY, false);
        resolve(d);
      },
      (error) => { reject(error); },
    );
  }),
  requestSslForDomain: ({ commit }, domain) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DOMAINS_BUSY, true);
    Domain.requestSsl(domain.id).then(
      (d) => {
        commit(types.UPDATE_USER_DOMAIN, { domain, upd: d });
        commit(types.SET_USER_DOMAINS_BUSY, false);
        resolve();
      },
      (error) => {
        commit(types.SET_USER_DOMAINS_BUSY, false);
        reject(error);
      },
    );
  }),
  deleteDomain: ({ commit }, domain) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DOMAINS_BUSY, true);
    Domain.delete(domain.id).then(
      (res) => {
        commit(types.DELETE_USER_DOMAIN, domain);
        commit(types.SET_USER_DOMAINS_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_USER_DOMAINS_BUSY, false);
        reject(error);
      },
    );
  }),
};

const mutations = {
  [types.SET_USER_DOMAINS](state, payload) {
    state.domains = payload;
  },
  [types.SET_USER_DOMAINS_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.ADD_USER_DOMAIN](state, payload) {
    state.domains.push(payload);
  },
  [types.UPDATE_USER_DOMAIN](state, payload) {
    const { domain, upd } = payload;
    const idx = state.domains.indexOf(domain);
    Vue.set(state.domains, idx, upd);
  },
  [types.DELETE_USER_DOMAIN](state, payload) {
    const idx = state.domains.indexOf(payload);
    state.domains.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
