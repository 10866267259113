export default {
  getAffiliateData: date => new Promise((resolve, reject) => {
    if (!date || !date.start || !date.end) {
      resolve([]);
    }

    window.http.post('affiliate/get-analytic', { date })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getPaymentSettings: () => new Promise((resolve, reject) => {
    window.http.get('affiliate/payment-settings')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  addPaymentSetting: data => new Promise((resolve, reject) => {
    window.http.post('affiliate/payment-setting', { data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updatePaymentSeting: (id, data) => new Promise((resolve, reject) => {
    window.http.put(`affiliate/payment-setting/${id}`, { setting: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deletePaymentSetting: id => new Promise((resolve, reject) => {
    window.http.delete(`affiliate/payment-setting/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  withdrawBalance: data => new Promise((resolve, reject) => {
    window.http.post('affiliate/withdraw', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
