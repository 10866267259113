<template>
  <div v-show="displayFooter" class="footer">
    <icon-logo-full v-if="!agencyLogo" />
    <img v-else :src="agencyLogo" alt="" class="footer-img">
    <a class="primary-text" :href="supportUrl" target="_blank">Support</a>
    <a v-if="!isAgencyDomain" class="primary-text">Privacy Policy</a>
    <a v-if="!isAgencyDomain" class="primary-text">Terms & Conditions</a>
    <v-spacer/>
    <p class="primary-text gray-text">
      Copyright © 2024 {{ companyName }}
    </p>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters('clientAgency', ['isAgencyDomain', 'agencyProfile']),
      displayFooter() {
        return this.$route.name !== 'dashboard.account-setting';
      },
      agencyLogo() {
        if (!this.isAgencyDomain || !this.agencyProfile.id) {
          return null;
        }
        return this.agencyProfile.logo;
      },
      supportUrl() {
        if (!this.isAgencyDomain || !this.agencyProfile.id) {
          return 'https://support.sambakker.com';
        }
        return `mailto: ${this.agencyProfile.user.email}`;
      },
      companyName() {
        if (!this.isAgencyDomain || !this.agencyProfile.id) {
          return 'Member.us.';
        }
        return `${this.agencyProfile.name}.`;
      },
    },
  };
</script>
