<template>
  <div class="client-detail__container">
    <p class="primary-text lg semi-bold">Permissions</p>
    <div class="layout mt-20">
      <div class="flex xs6">
        <p class="label-text">Select Campaign</p>
        <custom-select
          v-model="membershipId"
          :items="membershipSelect"
          item-title="title"
          item-value="id"
          @change="updateMembershipIds"
        />
      </div>
      <div class="flex xs6 ml-15">
        <p class="label-text">Select Courses</p>
        <multi-select
          v-model="formData.courseIds"
          :items="courseList"
          :enable-select-all="true"
          :show-selected="true"
          item-name="course"
          placeholder="Select Courses"
        />
      </div>
    </div>
    <div class="mt-30 permission-select alternative">
      <div class="layout permission-select__header align-center">
        <p class="flex-auto primary-text semi-bold dark-text">Feature</p>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-view class="icon-default-sm" />
          Read & Write
        </div>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-edit-x class="icon-default-sm"/>
          Read
        </div>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-lock class="icon-default-sm" />
          No Access
        </div>
      </div>
      <div class="permission-select__dropdown-rows">
        <div class="layout align-center">
          <p class="primary-text lg flex-auto">Select All</p>
          <div
            class="flex xs3 layout justify-center"
          >
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="formData.permissions = [...allEditPermissions]"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : allEditPermissions
                  .every(i => formData.permissions.includes(i)) }"
              />
            </div>
          </div>
          <div class="flex xs3 layout justify-center">
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="formData.permissions = [...allViewPermissions]"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : allViewPermissions
                  .every(i => formData.permissions.includes(i)) }"
              />
            </div>
          </div>
          <div class="flex xs3 layout justify-center">
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="formData.permissions = []"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : formData.permissions.length === 0 }"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(group, index) in permissionList"
        :key="index"
        class="permission-select__dropdown"
      >
        <p class="dropdown-title gray-text">
          {{ group.label }}
        </p>
        <div class="permission-select__dropdown-rows">
          <div
            v-for="(permission, pIndex) in group.items"
            :key="`p-${pIndex}`"
            class="layout align-center"
          >
            <p class="primary-text lg flex-auto">{{ permission.title }}</p>
            <div
              class="flex xs3 layout justify-center"
            >
              <div
                class="custom-checkbox circle alternative violet-color"
                @click="selectPermission(permission.permissions[0], permission.id)"
              >
                <div
                  class="member-custom-cb"
                  :class="{ checked : formData.permissions.includes(permission.permissions[0]) }"
                />
              </div>
            </div>
            <div class="flex xs3 layout justify-center">
              <div
                class="custom-checkbox circle alternative violet-color"
                @click="selectPermission(permission.permissions[1], permission.id)"
              >
                <div
                  class="member-custom-cb"
                  :class="{ checked : formData.permissions.includes(permission.permissions[1]) }"
                />
              </div>
            </div>
            <div class="flex xs3 layout justify-center">
              <div
                class="custom-checkbox circle alternative violet-color"
                @click="removePermission(permission.id)"
              >
                <div
                  class="member-custom-cb"
                  :class="{ checked : !formData.permissions
                    .some(r=> permission.permissions.includes(r)) }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout justify-end mt-30">
      <v-btn class="custom-btn-normal fixed-width" @click="saveAndExit">Save & Exit</v-btn>
      <v-btn class="ml-15 custom-btn-secondary fixed-width" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomSelect from '@/components/select/CustomSelect';
  import MultiSelect from '@/components/select/MultiSelect';
  import roleHelpers from '@/mixins/roleHelpers';
  import {
    clientPermissionList,
    clientPermissionListFe,
    groupPermission,
    allViewPermissions,
    allEditPermissions,
  } from '@/config/clientPermission';

  export default {
    components: {
      CustomSelect,
      MultiSelect,
    },
    mixins: [roleHelpers],
    data() {
      return {
        allEditPermissions,
        allViewPermissions,
        membershipId: '',
        formData: {
          courseIds: [],
          membershipIds: [],
          permissions: [],
        },
      };
    },
    computed: {
      ...mapGetters(['membershipList', 'whoIam', 'userCourses', 'userCoursesAtSync']),
      ...mapGetters('agency', ['clientDetail']),
      membershipSelect() {
        return this.membershipList.filter(m => m.courses.length > 0
          && (!m.clientMemberships.length
          || m.clientMemberships[0].clientId === this.clientDetail.id))
          .map(m => ({ id: m.id, title: m.title }));
      },
      courseList() {
        const row = [];
        if (!this.formData.membershipIds.length) {
          return row;
        }
        this.userCourses.forEach((course) => {
          if (this.formData.membershipIds.includes(course.membershipId)) {
            row.push({
              id: course.id,
              title: course.title,
            });
          }
        });
        return row;
      },
      permissionList() {
        if (this.isOTO1Role) {
          return clientPermissionList;
        }
        return clientPermissionListFe;
      },
    },
    watch: {
      clientDetail() {
        this.initData();
      },
    },
    created() {
      this.initData();
    },
    methods: {
      ...mapActions('agency', ['updateClient']),
      ...mapActions(['showAlert']),
      initData() {
        if (!this.clientDetail.id) {
          return;
        }
        this.formData.id = this.clientDetail.id;
        this.formData.membershipIds = this.clientDetail.clientMemberships.map(c => c.membershipId);
        [this.membershipId] = this.formData.membershipIds;
        this.formData.courseIds = this.clientDetail.clientCourses.map(c => c.courseId);
        this.formData.permissions = this.clientDetail.clientPermissions.map(c => c.permission);
      },
      async save() {
        await this.updateClient(this.formData);
        this.showAlert('Client permissions has been saved');
      },
      saveAndExit() {
        this.save();
        this.$router.push({ name: 'dashboard.client-management' });
      },
      updateMembershipIds() {
        this.formData.membershipIds = [this.membershipId];
        this.reselectCourseIds();
      },
      reselectCourseIds() {
        this.formData.courseIds = [];
        this.userCourses.forEach((course) => {
          if (this.formData.membershipIds.includes(course.membershipId)) {
            this.formData.courseIds.push(course.id);
          }
        });
      },
      removePermission(groupPermissionId) {
        const tmp = groupPermission[groupPermissionId];
        if (tmp.length) {
          tmp.forEach((permission) => {
            const tmpIndex = this.formData.permissions.findIndex(c => c === permission);
            if (tmpIndex > -1) {
              this.formData.permissions.splice(tmpIndex, 1);
            }
          });
          this.$emit('input', this.formData.permissions);
        }
      },
      selectPermission(permissionId, groupPermissionId) {
        this.removePermission(groupPermissionId);
        this.formData.permissions.push(permissionId);
        this.$emit('input', this.formData.permissions);
      },
    },
  };
</script>
