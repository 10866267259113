<template>
  <div class="sender-email">
    <div class="flex-between">
      <div class="primary-text lg bold">Sender Emails</div>
      <v-btn class="custom-btn-primary" @click="resetEmailSender">Reset Emails</v-btn>
    </div>

    <div class="mt-20">
      <table class="my-datatable last-column-right client-management__table">
        <table-header :headers="headers" />
        <tbody>
          <tr v-for="(sender, index) in senderList" :key="index">
            <td>
              <div>{{ sender.mail }}</div>
            </td>
            <td>
              <div>{{ sender.name ? sender.name : '-' }}</div>
            </td>
            <td>
              <div class="service-integration__status">
                <div
                  class="badge"
                  :class="[sender.status === 'not_confirmed' ? 'badge-warning' : 'badge-success']"
                >
                  {{ sender.status === 'not_confirmed' ? 'Pending' : 'Confirmed' }}
                </div>
              </div>
            </td>
            <td>
              <v-menu
                transition="slide-y-transition"
                content-class="client-integration custom-menu membership__box-menu"
                offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <button class="custom-action-dropdown__btn" v-on="on">
                    <icon-more/>
                  </button>
                </template>
                <div class="custom-action-dropdown">
                  <button
                    class="dropdown-btn flex-start"
                    @click="editProfile"
                  >
                    <icon-edit class="icon-default" />
                    <span>Edit</span>
                  </button>
                </div>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import agencyApi from '@/api/agency';
  import TableHeader from '@/components/table/TableHeader';

  export default {
    components: {
      TableHeader,
    },
    data() {
      return {
        headers: [
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'status',
            field: 'status',
          },
          {
            label: 'Action',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('agency', ['clientProfileDetail']),
      senderList() {
        if (!this.clientProfileDetail || !this.clientProfileDetail.emailSenders.length) {
          return [];
        }
        return this.clientProfileDetail.emailSenders;
      },
    },
    methods: {
      ...mapActions('agency', ['getClientProfileDetail']),
      editProfile() {
        this.$router.push(
          {
            name: 'dashboard.account-setting.agency-setting',
            query: {
              profileId: this.clientProfileDetail.id,
              tab: 'email',
            },
          },
        );
      },
      async resetEmailSender() {
        await agencyApi.resetProfileEmailSender(this.clientProfileDetail.id);
        this.getClientProfileDetail(this.clientProfileDetail.id);
      },
    },
  };
</script>
