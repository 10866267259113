export default {
  getAvailableSpace: () => new Promise((resolve) => {
    resolve(500000000000); // byte ~ 10Gb
  }),
  getMembershipStorage: () => new Promise((resolve, reject) => {
    window.http.get('/media/analytic/membership-storage').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getFileTypeAnalytic: formData => new Promise((resolve, reject) => {
    window.http.post('/media/analytic/file-type-storage', formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getListLargestFile: formData => new Promise((resolve, reject) => {
    window.http.post('/media/analytic/largest-files', formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
