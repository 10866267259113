import * as types from '@/store/mutation-types';
import api from '@/api/affiliateManagement';
import productApi from '@/api/product';

const state = {
  products: [],
  commissions: [],
  affiliateUserDetail: {},
  affiliateProducts: [],
  affiliateCommissions: [],
  commissionSelected: [],
  paymentMethodSelected: '',
  affiliateSetting: {},
  paymentMethodsAvailable: [],
  affiliateUserAnalytic: {},
  statusColor: {
    pending: '#FF9900',
    approved: '#14C8B1',
    reject: '#EB5757',
  },
  productList: [],
};

const getters = {
  productList: state => state.products,
  commissionList: state => state.commissions,
  affiliateProducts: state => state.affiliateProducts,
  affiliateCommissions: state => state.affiliateCommissions,
  affiliateUserDetail: state => state.affiliateUserDetail,
  commissionSelected: state => state.commissionSelected,
  paymentMethodSelected: state => state.paymentMethodSelected,
  affiliateSetting: state => state.affiliateSetting,
  paymentMethodsAvailable: state => state.paymentMethodsAvailable,
  affiliateUserAnalytic: state => state.affiliateUserAnalytic,
  membershipProductList: state => state.productList,
};

const actions = {
  fetchAffiliateUserProducts: ({ commit }, id) => new Promise((resolve, reject) => {
    api.getAffiliateProductsByAffiliateUserId(id)
      .then((res) => {
        commit(types.SET_AFFILIATE_USER_PRODUCTS, res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  fetchAffiliateUserCommissions: ({ commit }, data) => new Promise((resolve, reject) => {
    api.getAffiliateCommissionsByAffiliateUserId(data)
      .then((res) => {
        res.forEach((commission) => {
          // eslint-disable-next-line
          commission.statusColor = data.status === 'Overdue' ? '#EB5757' : '#222A3C';
        });
        commit(types.SET_AFFILIATE_USER_COMMISSIONS, res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  fetchAffiliateUserDetail: ({ commit }, id) => new Promise((resolve, reject) => {
    api.getAffiliateUserById(id)
      .then((res) => {
        commit(types.SET_AFFILIATE_USER_DETAIL, res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  fetchAllAffiliateCommissions: ({ commit }) => new Promise((resolve, reject) => {
    api.getAllCommissions()
      .then(
        (res) => {
          res.forEach((data) => {
            // eslint-disable-next-line
            data.statusColor = data.status === 'Overdue' ? '#EB5757' : '#222A3C';
          });
          commit(types.SET_AFFILIATE_ALL_COMMISSIONS, res);
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  fetchProductPromoteRequest: ({ commit, state }, filter) => new Promise((resolve, reject) => {
    api.getAllProductRequest(filter)
      .then((res) => {
        res.forEach((data) => {
          // eslint-disable-next-line
          data.statusColor = state.statusColor[data.status];
          if (data.productRequest.comment && data.status === 'pending') {
            // eslint-disable-next-line
            data.statusIcon = 'plus-mail';
          }
        });
        commit(types.SET_AFFILIATE_ALL_PRODUCTS, res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  confirmAffiliateProductRequest: ({ commit, dispatch, state }, data) => new Promise(
    (resolve, reject) => {
      api.confirmPromoteRequest(data)
        .then((res) => {
          const tmp = res;
          tmp.statusColor = state.statusColor[res.status];
          tmp.statusIcon = null;
          commit(types.UPDATE_PRODUCT_REQUEST_IN_LIST, tmp);
          dispatch('showAlert', 'Confirm affiliate request success', { root: true });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    },
  ),
  approveAllProductRequest: ({ dispatch }, data) => new Promise(
    (resolve, reject) => {
      api.approveAllProduct(data)
        .then((res) => {
          dispatch('showAlert', 'Approve all affiliate success.', { root: true });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    },
  ),
  fetchOneAffiliateCommission: ({ commit }, id) => new Promise((resolve, reject) => {
    api.getCommission(id)
      .then((res) => {
        commit(types.SET_AFFILIATE_ALL_COMMISSIONS, res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  setCommissionSelected: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_COMMISSION_SELECTED, data);
    resolve(true);
  }),
  setPaymentMethodSelected: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_COMMISSION_PAYMENT_METHOD_SELECTED, data);
    resolve(true);
  }),
  setPaymentMethodsAvailable: ({ commit }, paymentMethodType) => new Promise(
    (resolve) => {
      commit(types.SET_PAYMENT_METHODS_AVAILABLE, paymentMethodType);
      resolve(paymentMethodType);
    },
  ),
  processCommission: ({ getters }) => new Promise(
    (resolve, reject) => {
      const data = {
        commissionIds: getters.commissionSelected,
        paidMethod: getters.paymentMethodSelected,
      };
      api.updateCommissions(data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    },
  ),
  getAnalyticStats: ({ commit }, data) => new Promise(
    (resolve, reject) => {
      api.getAnalytic(data)
        .then((res) => {
          commit(types.SET_AFFILIATE_USER_ANALYTIC, res);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    },
  ),
  fetchMembershipProductList: ({ commit }) => new Promise(
    (resolve, reject) => {
      productApi.getOwnProducts()
        .then((res) => {
          commit(types.SET_PRODUCT_LIST, res);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    },
  ),
};

const mutations = {
  [types.SET_AFFILIATE_USER_DETAIL](state, payload) {
    state.affiliateUserDetail = payload;
  },
  [types.SET_AFFILIATE_ALL_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [types.SET_AFFILIATE_ALL_COMMISSIONS](state, payload) {
    state.commissions = payload;
  },
  [types.UPDATE_PRODUCT_REQUEST_IN_LIST](state, payload) {
    const index = state.products.findIndex(product => product.productRequest.id === payload.id);
    state.products[index].status = payload.status;
    state.products[index].statusColor = payload.statusColor;
    state.products[index].statusIcon = payload.statusIcon;
  },
  [types.SET_AFFILIATE_USER_PRODUCTS](state, payload) {
    state.affiliateProducts = payload;
  },
  [types.SET_AFFILIATE_USER_COMMISSIONS](state, payload) {
    state.affiliateCommissions = payload;
  },
  [types.SET_COMMISSION_SELECTED](state, payload) {
    state.commissionSelected = payload;
  },
  [types.SET_COMMISSION_PAYMENT_METHOD_SELECTED](state, payload) {
    state.paymentMethodSelected = payload;
  },
  [types.SET_WIZARD_AFFILIATE_SETTING](state, payload) {
    state.affiliateSetting = payload;
  },
  [types.SET_PAYMENT_METHODS_AVAILABLE](state, payload) {
    if (!state.paymentMethodsAvailable.includes(payload)) {
      state.paymentMethodsAvailable.push(payload);
    }
  },
  [types.SET_AFFILIATE_USER_ANALYTIC](state, payload) {
    state.affiliateUserAnalytic = payload;
  },
  [types.SET_PRODUCT_LIST](state, payload) {
    state.productList = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
