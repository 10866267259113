import { mapGetters } from 'vuex';
import { PLAN_FE, PLAN_OTO_1, PLAN_OTO_2 } from '@/config/permissions';

export default {
  computed: {
    ...mapGetters(['whoIam', 'userHavePlusPlan']),
    isFERole() {
      const tmp = this.whoIam.roles
        ? this.whoIam.roles.find(({ role }) => role === PLAN_FE)
        : undefined;
      return !!tmp;
    },
    isOTO1Role() {
      const tmp = this.whoIam.roles
        ? this.whoIam.roles.find(({ role }) => role === PLAN_OTO_1)
        : undefined;
      return !!tmp || this.userHavePlusPlan;
    },
    isOTO2Role() {
      const tmp = this.whoIam.roles
        ? this.whoIam.roles.find(({ role }) => role === PLAN_OTO_2)
        : undefined;
      return !!tmp;
    },
  },
};
