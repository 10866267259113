import { mapGetters } from 'vuex';
import Hashids from 'hashids';

// import CourseAPI from '@/api/course';

const linkHelper = {
  computed: {
    ...mapGetters([
      'whoIam',
    ]),
  },
  methods: {
    imageUrl(url) {
      if (!url) {
        return null;
      }
      if (url.toString().indexOf('http') === 0) {
        return url;
      }
      return `${process.env.VUE_APP_API_URL}/${url}`;
    },
    protocol(membership) {
      if (membership && membership.domain) {
        if (!membership.domain.isCustom) {
          return 'https';
        }
        if (membership.domain.isCustom && membership.domain.sslDate) {
          return 'https';
        }
        if (membership.domain.isProxy) {
          return 'https';
        }
      }
      return 'http';
    },
    loginLink(course) {
      if (this.courseDomain(course)) {
        return `${this.protocol(course)}://${this.courseDomain(course)}/login`;
      }
      return '';
    },
    membershipUrl(membership) {
      if (membership && membership.id && this.courseDomain(membership)) {
        return `${this.protocol(membership)}://${this.courseDomain(membership)}`;
      }
      return '';
    },
    checkoutLink(course, membership, cartType) {
      if (course && course.productId && this.courseDomain(membership)) {
        return `${this.protocol(membership)}://${this.courseDomain(membership)}/checkout/${cartType}/${course.productId}`;
      }
      return '';
    },
    checkoutLinkProduct(product, membership, cartType) {
      if (product && this.courseDomain(membership)) {
        return `${this.protocol(membership)}://${this.courseDomain(membership)}/checkout/${cartType}/${product.id}`;
      }
      return '';
    },
    paypalIpnLink(course, membership) {
      // https://api.memberapp.io/api/ipn/paypal/HL93NHK0z

      // looks like there's no domian in the membership object
      //
      // console.log(' ', course, membership, this.courseDomain(membership));

      if (this.courseDomain(membership) && course.hashId) {
        return `${process.env.VUE_APP_API_BASE_URL}/ipn/paypal/${course.hashId}`;
      }
      return '';
    },
    registrationLink(type, data) {
      const domain = this.membershipDomain(data.membership);
      if (domain) {
        const secretKey = type === 'product' ? 'bsecret' : 'secret';

        const hashids = new Hashids('', 10);
        const courseSecretId = hashids.encode(data.id);
        return `${this.protocol(data.membership)}://${domain}/registration?${secretKey}=${courseSecretId}`;
      }
      return '';
    },

    courseLink(domain, course) {
      if (!domain || !domain.name) {
        return '';
      }
      const domainName = `${this.protocol({ domain })}://${domain.name}`;
      if (course && course.slug) {
        return `${domainName}/${course.slug}`;
      }


      return domainName;
    },

    baseLink(course) {
      const d = this.courseDomain(course);
      if (d) {
        return `${this.protocol(course)}://${d}`;
      }
      return '';
    },

    courseDomain(course) {
      if (course && course.domain && course.domain.name) {
        if (course.domain.isCustom && !course.domain.isProxy && !course.domain.sslDate) {
          return `${this.whoIam.username}.${process.env.VUE_APP_COURSE_DOMAIN_NAME}`;
        }

        return course.domain.name;
      }
      return '';
    },

    membershipDomain(membership) {
      if (
        membership
        && membership.domain
        && membership.domain.name
      ) {
        return membership.domain.name;
      }
      return '';
    },
    // preview$(course, membership) {
    //   const m = membership.slug;
    //   const u = this.whoIam.username;
    //   const token = localStorage.getItem('jwtToken');
    //   const url =
    // `${process.env.VUE_APP_PREVIEW_URL}/${u}/${m}?jwt=${token}&courseId=${course.id}`;
    //   window.open(url, '_blank');
    // },
    isGravatar(url) {
      if (!url) {
        return false;
      }
      return url.includes('gravatar.com');
    },
  },
};

export default linkHelper;
