import { mapActions, mapGetters } from 'vuex';
import affiliatePayment from '@/config/affiliatePayment';

const partnerPaymentMethod = {
  props: ['paymentData'],
  data() {
    return {
      firstInit: false,
      loading: false,
      isEdit: false,
      type: affiliatePayment.type,
    };
  },
  computed: {
    ...mapGetters([
      'countryList', 'stateList', 'cityList', 'hasCountryToken',
    ]),
  },
  watch: {
    // eslint-disable-next-line
    'formData.country': function (val) {
      if (!this.firstInit) {
        this.formData.state = '';
      }
      this.getStateListData(val);
    },
    // eslint-disable-next-line
    'formData.state': function (val) {
      if (!this.firstInit) {
        this.formData.city = '';
      }
      if (val) {
        this.getCityListData(val);
      }
      this.firstInit = false;
    },
  },
  methods: {
    ...mapActions([
      'fetchCountryToken', 'getCountryListData', 'getStateListData', 'getCityListData', 'showAlert',
    ]),
    ...mapActions('partnership', ['addPaymentMethod', 'updatePaymentMethod']),
    submit(type) {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.loading = true;
            if (this.isEdit) {
              this.updatePaymentMethod({
                id: this.paymentData.id,
                setting: this.formData,
                verified: true,
              }).then(() => {
                this.loading = false;
                this.$emit('goBack');
              });
            } else {
              this.addPaymentMethod({
                setting: this.formData,
                verified: true,
                type,
              }).then(() => {
                this.loading = false;
                this.$emit('goBack');
              });
            }
          }
        },
      );
    },
  },
};

export default partnerPaymentMethod;
