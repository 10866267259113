<template>
  <div class="layout column align-center campaign-limit__container">
    <icon-warning />
    <p class="primary-text">Upgrade to add more campaigns</p>
    <p class="normal-text">
      You have reached your maximum amount of campaigns.
      Please upgrade to unlock 25 campaigns for your account.
    </p>
    <div class="layout">
      <v-btn class="custom-btn-normal sm mr-15" @click="closeModal">Back</v-btn>
      <v-btn class="custom-btn-secondary" @click="unlockPackage">
        Unlock
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        loading: false,
        membershipName: '',
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps']),
    },
    methods: {
      ...mapActions(['closeModal']),
      unlockPackage() {
        window.open(this.modalComponentProps.canAddCampaign.upgradeUrl, '_blank');
      },
    },
  };
</script>
