<template>

  <button
    :class="{
      [color]: color,
      'only-icon': !label,
    }"
    class="custom-button"
    @click="$emit('click', $event)"
  >
    <fa-icon
      v-if="isFaIcon(icon)"
      :icon="icon"
      :class="{ 'icon-prepend': label }"
    />
    <custom-icon
      v-if="isCustomIcon(icon)"
      :class="{ 'icon-prepend': label }"
      :icon="icon" />
    {{ label }}
    <fa-icon
      v-if="isFaIcon(iconAppend)"
      :class="{ 'icon-append': label }"
      :icon="iconAppend"
    />
    <custom-icon
      v-if="isCustomIcon(iconAppend)"
      :class="{ 'icon-append': label }"
      :icon="iconAppend" />
  </button>

</template>


<script>
  import CustomIcon from '@/components/shared/CustomIcon';

  export default {
    components: {
      CustomIcon,
    },
    props: {
      color: {
        default: 'primary',
        type: String,
      },
      icon: {
        default: '',
        type: [String, Array],
      },
      iconAppend: {
        default: '',
        type: [String, Array],
      },
      label: {
        default: '',
        type: String,
      },
    },
    data: () => ({

    }),
    methods: {
      isFaIcon(icon) {
        if (typeof icon === 'undefined' || !icon) {
          return false;
        }
        if (icon.constructor === Array) {
          return true;
        }
        return false;
      },
      isCustomIcon(icon) {
        if (typeof icon === 'undefined' || !icon) {
          return false;
        }
        if (icon.constructor === String) {
          return true;
        }
        return false;
      },
    },
  };
</script>
