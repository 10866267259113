import * as types from '@/store/mutation-types';
import EmailSender from '@/api/emailSender';

const state = {
  emailSenders: [],
  atSync: false,
};

const getters = {
  userEmailConfirmed: state => state.emailSenders.filter(e => e.status === 'confirmed'),
  userEmailSenders: state => state.emailSenders,
  userEmailSendersAtSync: state => state.atSync,
};

const actions = {
  requestConfirmEmail: ({ commit }, emailSender) => new Promise((resolve, reject) => {
    commit(types.SET_USER_EMAIL_SENDERS_BUSY, true);
    EmailSender.requestConfirm(emailSender.id).then(
      (response) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        resolve(response);
      },
      (error) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        reject(error);
      },
    );
  }),
  syncEmailSenders: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_USER_EMAIL_SENDERS_BUSY, true);
    EmailSender.getAll().then(
      (emailSenders) => {
        commit(types.SET_USER_EMAIL_SENDERS, emailSenders);
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        resolve(emailSenders);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  addEmailSender: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_EMAIL_SENDERS_BUSY, true);
    EmailSender.create(data).then(
      (newEmail) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        commit(types.ADD_USER_EMAIL_SENDER, newEmail);
        resolve(newEmail);
      },
      (error) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        reject(error);
      },
    );
  }),
  updateEmailSender: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_EMAIL_SENDERS_BUSY, true);
    EmailSender.update(data).then(
      (newEmail) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        commit(types.UPDATE_USER_EMAIL_SENDER, newEmail);
        resolve(newEmail);
      },
      (error) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        reject(error);
      },
    );
  }),
  deleteEmailSender: ({ commit }, emailSender) => new Promise((resolve, reject) => {
    commit(types.SET_USER_EMAIL_SENDERS_BUSY, true);
    EmailSender.delete(emailSender.id).then(
      () => {
        commit(types.DELETE_USER_EMAIL_SENDER, emailSender);
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        resolve();
      },
      (error) => {
        commit(types.SET_USER_EMAIL_SENDERS_BUSY, false);
        reject(error);
      },
    );
  }),
  setEmailSenders: ({ commit }, data) => {
    commit(types.SET_USER_EMAIL_SENDERS, data);
  },
};

const mutations = {
  [types.SET_USER_EMAIL_SENDERS](state, payload) {
    state.emailSenders = payload;
  },
  [types.UPDATE_USER_EMAIL_SENDER](state, payload) {
    const index = state.emailSenders.findIndex(e => e.id === payload.id);
    state.emailSenders[index] = payload;
  },
  [types.SET_USER_EMAIL_SENDERS_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.ADD_USER_EMAIL_SENDER](state, payload) {
    state.emailSenders.push(payload);
  },
  [types.DELETE_USER_EMAIL_SENDER](state, payload) {
    const idx = state.emailSenders.indexOf(payload);
    state.emailSenders.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
