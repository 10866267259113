<template>
  <div class="invoice-layout">
    <div class="invoice-layout-inner">
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {};
</script>
