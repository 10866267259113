export default {
  quizMenuList: () => new Promise((resolve, reject) => {
    window.http.get('/quiz/menu-list')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  readQuiz: id => new Promise((resolve, reject) => {
    window.http.get(`/quiz/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createQuiz: data => new Promise((resolve, reject) => {
    window.http.post('/quiz', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateQuiz: data => new Promise((resolve, reject) => {
    window.http.put(`/quiz/${data.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deleteQuiz: id => new Promise((resolve, reject) => {
    window.http.delete(`/quiz/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  quizResultsAnalytics: data => new Promise((resolve, reject) => {
    window.http.post('/quiz/results-analytics', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  quizAnswersAnalytics: data => new Promise((resolve, reject) => {
    window.http.post('/quiz/answers-analytics', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  quizResult: id => new Promise((resolve, reject) => {
    window.http.get(`/quiz/result/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  reviewResult: (id, answers) => new Promise((resolve, reject) => {
    window.http.put(`/quiz/result/${id}`, answers)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadMedia: file => new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('file', file);
    window.http.post('/quiz/upload-media', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
