<template>
  <div class="storage-statistics__file-type">
    <div class="file-type">
      <div class="flex-between">
        <div class="primary-text lg semi-bold">Storage Statistics</div>
        <div v-if="!reRender">
          <multi-select
            v-model="membershipIdsInit"
            :items="listCourse"
            :enableSelectAll="true"
            :show-selected="true"
            :itemName="'Campaign'"
            item-title="title"
            item-value="id"
            :title="''"
            @input="onChanged"
          />
        </div>
      </div>
      <div v-if="totalSize > 0" class="flex-start mt-20">
        <div class="mr-25">
          <div class="file-type__chart">
            <pie-chart
              ref="pieChart"
              :data="chartData"
              :cutoutPercentage="40"
              class="flex-50"
              :tooltipOption="tooltipOption"
              :tooltipCallback="tooltipCallback"
            />
          </div>
        </div>
        <div class="flex-1">
          <div class="w-50">
            <div
              v-for="(item, idx) in fileTypeStorage"
              :key="idx"
              :class="[idx !== 0 ? 'mt-10' : '']"
              class="flex-start"
            >
              <div
                class="mr-3 rounded"
                :style="{
                  'width': '10px',
                  'height': '10px',
                  'background-color': listColor[idx],
                }"
              />
              <div class="flex-1 primary-text lg">{{ item.type }}</div>
              <div class="ml-3 flex-end">
                <div>
                  <span class="primary-text lg semi-bold mr-1">
                    {{ Math.round(((item.size * 100) / totalSize) * 100) / 100 }}%
                  </span>
                  <span class="primary-text lg text-dark">({{ convertByte(item.size) }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import MultiSelect from '@/components/select/MultiSelect';
  import storageHelper from '@/mixins/storage';
  import PieChart from '@/components/charts/PieChart.vue';

  export default {
    components: {
      MultiSelect,
      PieChart,
    },
    mixins: [storageHelper],
    data() {
      return {
        reRender: false,
        membershipIdsInit: [],
        listColor: [],
        idChartTooltip: `chartjs-avg-${Math.floor(Math.random() * 999999999)}`,
        idArrowTooltip: `arrow-tooltip-avg-${Math.floor(Math.random() * 999999999)}`,
        tooltipCallback: {
          label: tooltipItem => this.chartData[tooltipItem.index].label,
        },
        tooltipOption: {
          external: (tooltipModel) => {
            const vue = this.$refs.pieChart;
            // const tooltipModel = context.tooltip;
            const chartElm = vue.$refs.pieChart;
            const tooltipBoxEl = vue.$refs.tooltipBox;
            const position = chartElm.getBoundingClientRect();
            // Tooltip Element
            let tooltipEl = document.getElementById(this.idChartTooltip);
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.classList.add('doughnut-chart__tooltip');
              tooltipEl.id = this.idChartTooltip;
              tooltipEl.innerHTML = `<div class="tooltip-container layout column ${vue.customClass}"></div>`;
              tooltipBoxEl.appendChild(tooltipEl);
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '';
              innerHtml += `<div id="${this.idArrowTooltip}" class="arrow-tooltip"></div>`;
              bodyLines.forEach((body, i) => {
                const text = body[0].split(':');
                const colors = tooltipModel.labelColors[i];
                const idx = tooltipModel.dataPoints[0].index;
                let style = `background:${colors.backgroundColor.replace('0.65', '1')}`;
                style += `; border-color:${colors.backgroundColor}`;
                style += '; border-width: 2px';
                const span = `<span class="tooltip-key" style="${style}"></span>`;
                innerHtml += `<div><div class="el-row--flex align-center flex-between">
                      ${span}
                      <span class="primary-text lg semi-bold text-white flex-1">${text[0]}</span>
                      <span class="primary-text text-white semi-bold lg ml-20">${this.convertByte(this.chartData[idx].value)}</span>
                      <span class="primary-text text-white lg ml-2">(${Math.round(((this.chartData[idx].value * 100) / this.totalSize) * 100) / 100}%)</span>
                </div>`;
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('div');
              tableRoot.innerHTML = innerHtml;

              const arrowTooltip = document.getElementById(this.idArrowTooltip);

              // Display, position, and set styles for font
              tooltipEl.style.display = 'block';
              tooltipEl.style.position = 'fixed';
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = `${position.left + tooltipModel.caretX}px`;
              tooltipEl.style.top = `${position.top + tooltipModel.caretY - tooltipEl.clientHeight}px`;
              tooltipEl.style.marginTop = `${-20}px`;
              tooltipEl.style.marginLeft = `${-(tooltipEl.offsetWidth / 2)}px`;

              arrowTooltip.style.display = 'block';
              arrowTooltip.style.transform = 'rotate(180deg)';
              arrowTooltip.style.marginLeft = `${(tooltipEl.offsetWidth / 2) - 19}px`;
              arrowTooltip.style.marginTop = `${tooltipEl.clientHeight - 10}px`; // 8 for active point
            }
          },
        },
      };
    },
    computed: {
      ...mapGetters([
        'membershipList',
      ]),
      ...mapGetters('storage', [
        'fileTypeStorage',
        'membershipIds',
      ]),
      listCourse() {
        return [...this.membershipList].slice(1, this.membershipList.length);
      },
      chartData() {
        const result = [];
        const srcData = this.fileTypeStorage;
        srcData.forEach((row, index) => {
          result.push({
            color: this.listColor[index],
            value: row.size,
            label: row.type,
          });
        });
        return result;
      },
      totalSize() {
        let tmp = 0;
        this.fileTypeStorage.forEach((el) => {
          tmp += el.size;
        });
        return tmp;
      },
    },
    watch: {
      fileTypeStorage(val) {
        this.listColor = [];
        val.forEach(() => {
          this.listColor.push(this.getRandomColor());
        });
      },
      membershipIds(val) {
        this.getFileTypeAnalytic({
          membershipIds: val,
        });
      },
    },
    mounted() {
      this.getFileTypeAnalytic({
        membershipIds: this.membershipIds,
      });
      this.membershipIdsInit = this.membershipIds;
      this.reRender = true;
      this.$nextTick(() => {
        this.reRender = false;
      });
    },
    methods: {
      ...mapActions('storage', [
        'getFileTypeAnalytic',
        'setMembershipIds',
      ]),
      onChanged(value) {
        if (value.length > 0) {
          this.setMembershipIds(value);
        }
      },
    },
  };
</script>
