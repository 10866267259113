<template>
  <layout>
    <template v-slot:title>
      <h2 class="auth__box-title">Enter your new password</h2>
    </template>
    <p class="label-text">Password</p>
    <v-text-field
      ref="password"
      v-model="formData.password"
      v-validate="'required|min:6'"
      class="auth__password"
      :error-messages="errors.collect('Password')"
      data-vv-name="Password"
      solo
      type="password"
      placeholder="Enter the Password"
      @keyup.enter="submit"/>
    <p class="label-text">Confirm password</p>
    <v-text-field
      v-model="formData.confirmPassword"
      v-validate="'required|confirmed:password'"
      class="auth__password"
      :error-messages="errors.collect('confirm')"
      data-vv-as="Confirm Password"
      data-vv-name="confirm"
      solo
      type="password"
      placeholder="Please re-enter your password"
      @keyup.enter="submit"/>
    <v-btn
      block
      color="secondary"
      class="mb-0 mt-0 auth__button"
      :loading="loading"
      @click="submit">
      Save
    </v-btn>
  </layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import validationHelpers from '@/mixins/validationHelpers';
  import Layout from '@/layout/Auth';
  import User from '@/api/user';
  import ClientAgency from '@/api/clientAgency';

  export default {
    components: {
      Layout,
    },
    mixins: [validationHelpers],
    data() {
      return {
        loading: false,
        passwordType: 'password',
        formData: {
          password: '',
          confirmPassword: '',
          token: '',
        },
      };
    },
    computed: {
      ...mapGetters(['isLoggedIn']),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
    },
    mounted() {
      if (this.isAgencyDomain) {
        ClientAgency.checkForgotPassToken(this.$route.query.token)
          .then((res) => {
            const { id } = res;
            if (!id) {
              this.$router.push({ name: 'login' });
            }
            this.formData.token = this.$route.query.token;
          })
          .catch(() => {
            this.$router.push({ name: 'login' });
          });
      } else {
        User.checkForgotPassToken(this.$route.query.token)
          .then((res) => {
            const { user } = res;
            if (!user) {
              this.$router.push({ name: 'login' });
            }
            this.formData.token = this.$route.query.token;
          })
          .catch(() => {
            this.$router.push({ name: 'login' });
          });
      }
    },
    methods: {
      ...mapActions([
        'createUserPassword', 'tryToLogin', 'showAlert',
      ]),
      submit() {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              this.loading = true;
              if (this.isAgencyDomain) {
                ClientAgency.createNewPassword(this.formData)
                  .then(() => {
                    this.showAlert('Create new password success, you can use your new password for login now');
                    this.$router.push({ name: 'login' });
                  });
              } else {
                this.createUserPassword(this.formData)
                  .then((res) => {
                    if (this.isLoggedIn) {
                      localStorage.removeItem('jwtToken');
                    }
                    this.tryToLogin({
                      email: res.user.email,
                      password: this.formData.password,
                    }).then(
                      (response) => {
                        this.loading = false;
                        if (response.user.onboardingStep === 0) {
                          localStorage.setItem('welcomePage', true);
                          this.$router.push({ name: 'welcome.create-membership' });
                        }
                        this.$router.push({ name: 'membership_dashboard' });
                      },
                      () => {
                        this.loading = false;
                      },
                    );
                  });
              }
            }
          },
        );
      },
    },
  };
</script>
