<template>
  <layout>
    <template v-slot:title>
      <h2 class="auth__box-title">
        {{ submitted ? 'Check your email' : 'Reset your password' }}</h2>
    </template>
    <div v-if="submitted" class="layout align-center column">
      <div class="normal-text text-center">
        We’ve sent password reset instructions to: <br>
        <b class="big-bold">{{ email }}</b>
      </div>
      <div class="normal-text text-center mt-20">
        If it doesn’t arrive soon, check your spam folder <br>
        or <a class="link-violet" @click="resendResetPassword">send the email again.</a>
      </div>
      <div class="layout auth__reset-footer normal-text">
        <div class="text-center">
          <a class="link-violet" @click="$router.push({ name: 'login' })">Back to sign-in</a>
        </div>
        <div class="text-center">
          <span>Need help? <a class="link-violet" @click="goToSupport">Contact support</a></span>
        </div>
      </div>
    </div>
    <template v-else>
      <p class="label-text">Your email addresss</p>
      <v-text-field
        v-model="email"
        v-validate.disable="'required|email'"
        :error-messages="errors.collect('email')"
        data-vv-name="email"
        solo placeholder="Enter your Email"
        @keyup.enter="submit"/>
      <v-btn
        :loading="submitting"
        block color="secondary"
        class="mt-0 mb-0 auth__button"
        @click="submit">
        Reset Password
      </v-btn>
    </template>
  </layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import api from '@/api/user';
  import clientAgencyApi from '@/api/clientAgency';
  import quotes from '@/config/quotes';
  import validationHelpers from '@/mixins/validationHelpers';
  import Layout from '@/layout/Auth';

  export default {
    $_veeValidate: { validator: 'login' },
    components: {
      Layout,
    },
    mixins: [validationHelpers],
    data: () => ({
      email: '',
      submitting: false,
      submitted: false,
    }),
    computed: {
      ...mapGetters('clientAgency', ['isAgencyDomain']),
      quote() {
        return quotes[Math.floor(Math.random() * quotes.length)];
      },
    },
    methods: {
      ...mapActions([
        'showAlert',
      ]),
      goToSupport() {
        window.open('http://support.sambakker.com/', '_blank');
      },
      resendResetPassword() {
        if (this.isAgencyDomain) {
          clientAgencyApi.resetPassword({ email: this.email, sendAgain: true }).then(
            (r) => {
              if (r.error) {
                this.showAlert({ type: 'danger', message: r.error });
              }
              this.showAlert({ type: 'success', message: 'Another confirmation message has been sent to your email' });
            },
            (e) => {
              this.showAlert({ type: 'danger', message: e });
            },
          );
        } else {
          api.forgetPassword({ email: this.email, sendAgain: true }).then(
            (r) => {
              if (r.error) {
                this.showAlert({ type: 'danger', message: r.error });
              }
              this.showAlert({ type: 'success', message: 'Another confirmation message has been sent to your email' });
            },
            (e) => {
              this.showAlert({ type: 'danger', message: e });
            },
          );
        }
      },
      submit() {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              this.submitting = true;
              if (this.isAgencyDomain) {
                clientAgencyApi.resetPassword({ email: this.email }).then(
                  (r) => {
                    this.submitting = false;
                    if (r.error) {
                      this.putErrorToField('email', r.error);
                    } else {
                      this.submitted = true;
                    }
                  },
                  (e) => {
                    this.submitting = false;
                    this.submitted = false;
                    this.putErrorToField('email', e);
                  },
                );
              } else {
                api.forgetPassword({ email: this.email }).then(
                  (r) => {
                    this.submitting = false;
                    if (r.error) {
                      this.putErrorToField('email', r.error);
                    } else {
                      this.submitted = true;
                    }
                  },
                  (e) => {
                    this.submitting = false;
                    this.submitted = false;
                    this.putErrorToField('email', e);
                  },
                );
              }
            }
          },
        );
      },
    },
  };
</script>
