export default {
  getOauthUrl: (type, currentUser) => new Promise((resolve, reject) => {
    let url = `/integration/request-auth?type=${type}&redirectUrl=${window.location.href}`;
    if (currentUser.clientProfile) {
      url = `${url}&clientProfileId=${currentUser.clientProfile.id}`;
    }
    window.http.get(url).then(
      (response) => {
        resolve(response.data);
      },
    ).catch(
      (error) => {
        reject(error.response.data);
      },
    );
  }),
  getList: param => new Promise((resolve, reject) => {
    let url = `/integration/get-list?type=${param.type}`;

    if (param.page) {
      url = `/integration/get-list?type=${param.type}&page=${param.page}`;
    }

    if (param.tags) {
      url = `/integration/get-list?type=${param.type}&tags=true`;
    }

    window.http.get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  update: data => new Promise((resolve, reject) => {
    window.http.post('/integration', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  unlinkPaypalApp: () => new Promise((resolve, reject) => {
    window.http.post('/integration/paypal/unlink-paypal-app')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  connectPaypal: data => new Promise((resolve, reject) => {
    window.http.post('/integration/paypal/save-paypal-app', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
