<template>
  <div class="service-integration">
    <div class="flex-between">
      <div class="primary-text lg bold">Service Integrations</div>
      <v-btn class="custom-btn-primary" @click="resetIntegration">
        Reset Integrations
      </v-btn>
    </div>
    <div class="mt-20">
      <table class="w-100">
        <tbody>
          <tr
            v-for="(item, index) in listIntegration"
            :key="index"
          >
            <td>
              <component
                :is="`icon-${item.type.replace('_', '-')}`"
                class="integration-icon"
              />
            </td>
            <td class="flex-1">
              <div class="primary-text lg ml-3 semi-bold">{{ nameMap[item.type] }}</div>
            </td>
            <td>
              <div class="service-integration__status">
                <div class="badge badge-success">Connected</div>
              </div>
            </td>
            <td>
              <v-menu
                transition="slide-y-transition"
                content-class="client-integration custom-menu membership__box-menu"
                offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <button class="custom-action-dropdown__btn" v-on="on">
                    <icon-more/>
                  </button>
                </template>
                <div class="custom-action-dropdown">
                  <button
                    class="dropdown-btn flex-start"
                    @click="editProfile"
                  >
                    <icon-edit class="icon-default" />
                    <span>Edit</span>
                  </button>
                </div>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import _cloneDeep from 'lodash.clonedeep';
  import InitialAvatar from '@/components/InitialAvatar';
  import IconAweber from '@/assets/tplogos/aweber_sm.svg';
  import IconActiveCampaign from '@/assets/tplogos/activecampaign_sm.svg';
  import IconMailchimp from '@/assets/tplogos/mailchimp_sm.svg';
  import IconMarkethero from '@/assets/tplogos/markethero_sm.svg';
  import IconGetresponse from '@/assets/tplogos/getresponse_sm.svg';
  import IconOntraport from '@/assets/tplogos/ontraport_sm.svg';
  import IconEverwebinar from '@/assets/tplogos/everwebinar.svg';
  import IconZoomus from '@/assets/tplogos/zoomus.svg';
  import IconSendlane from '@/assets/tplogos/sendlane_sm.svg';
  import IconWebinarjam from '@/assets/tplogos/webinarjam.svg';
  import IconGotowebinar from '@/assets/tplogos/gotowebinar.svg';
  import IconDemio from '@/assets/tplogos/demio.svg';
  import IconInfusionsoft from '@/assets/tplogos/infusionsoft.svg';
  import IconMailerlite from '@/assets/tplogos/mailerlite.svg';
  import { nameMap } from '@/config/integrations';
  import agencyApi from '@/api/agency';

  export default {
    components: {
      InitialAvatar,
      IconActiveCampaign,
      IconEverwebinar,
      IconAweber,
      IconGetresponse,
      IconMarkethero,
      IconOntraport,
      IconSendlane,
      IconMailchimp,
      IconWebinarjam,
      IconZoomus,
      IconInfusionsoft,
      IconGotowebinar,
      IconDemio,
      IconMailerlite,
    },
    data() {
      return {
        nameMap,
      };
    },
    computed: {
      ...mapGetters('agency', ['clientProfileDetail']),
      listIntegration() {
        const integrations = [];
        if (!this.clientProfileDetail || !this.clientProfileDetail.integrations) {
          return integrations;
        }
        Object.keys(this.clientProfileDetail.integrations).forEach((key) => {
          const tmp = _cloneDeep(this.clientProfileDetail.integrations[key]);
          tmp.type = key;
          if (tmp.active && tmp.type !== 'stripe' && tmp.type !== 'paypal') {
            integrations.push(tmp);
          }
        });
        return integrations;
      },
    },
    methods: {
      ...mapActions('agency', ['getClientProfileDetail']),
      editProfile() {
        this.$router.push(
          {
            name: 'dashboard.account-setting.agency-setting',
            query: {
              profileId: this.clientProfileDetail.id,
              tab: 'integration',
            },
          },
        );
      },
      async resetIntegration() {
        await agencyApi.resetProfileIntegration(this.clientProfileDetail.id);
        this.getClientProfileDetail(this.clientProfileDetail.id);
      },
    },
  };
</script>
