import QuizApi from '@/api/quiz';
import * as types from '@/store/mutation-types';

const state = {
  quizAtSync: false,
  quizzes: [],
  quizResult: null,
  quizMenuList: [],
  currentQuiz: {},
};

const getters = {
  getQuiz: state => id => state.quizzes.find(q => q.id === id),
  quizMenuList: state => state.quizMenuList,
  quizResultDetails: state => state.quizResult,
  quizAtSync: state => state.quizAtSync,
  currentQuiz: state => state.currentQuiz,
};

const actions = {
  createQuiz: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (state.quizAtSync) {
      return reject(new Error('Quiz creation is in progress.'));
    }
    commit(types.SET_QUIZ_AT_SYNC, true);
    return QuizApi.createQuiz(data).then(
      (res) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        commit(types.ADD_QUIZ, res);
        resolve(res);
        dispatch('showAlert', 'Your quiz is created');
      },
      (error) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        reject(error);
      },
    );
  }),

  updateQuiz: ({ commit, state, dispatch }, data) => new Promise((resolve, reject) => {
    if (state.quizAtSync) {
      return reject(new Error('Quiz update is in progress.'));
    }
    commit(types.SET_QUIZ_AT_SYNC, true);
    return QuizApi.updateQuiz(data).then(
      (res) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        commit(types.UPDATE_QUIZ, res);
        resolve(res);
        dispatch('showAlert', 'Your quiz is updated');
      },
      (error) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        reject(error);
      },
    );
  }),

  fetchQuizResult: ({ commit }, id) => new Promise((resolve, reject) => QuizApi.quizResult(id).then(
    (res) => {
      commit(types.SET_QUIZ_RESULT, res);
      resolve(res);
    },
    (error) => {
      reject(error);
    },
  )),

  readQuiz: ({ commit }, id) => new Promise((resolve, reject) => {
    commit(types.SET_QUIZ_AT_SYNC, true);
    return QuizApi.readQuiz(id).then(
      (res) => {
        commit(types.SET_CURRENT_QUIZ, res);
        commit(types.SET_QUIZ_AT_SYNC, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        reject(error);
      },
    );
  }),

  deleteQuiz: ({ commit }, id) => new Promise((resolve, reject) => {
    commit(types.SET_QUIZ_AT_SYNC, true);
    return QuizApi.deleteQuiz(id).then(
      (res) => {
        commit(types.DELETE_QUIZ, id);
        commit(types.SET_QUIZ_AT_SYNC, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        reject(error);
      },
    );
  }),

  fetchQuizMenuList: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_QUIZ_AT_SYNC, true);
    return QuizApi.quizMenuList().then(
      (res) => {
        commit(types.SET_QUIZ_MENU_LIST, res);
        commit(types.SET_QUIZ_AT_SYNC, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_QUIZ_AT_SYNC, false);
        reject(error);
      },
    );
  }),

};

const mutations = {
  [types.SET_CURRENT_QUIZ](state, payload) {
    state.currentQuiz = payload;
  },
  [types.SET_QUIZ_MENU_LIST](state, payload) {
    state.quizMenuList = payload;
  },
  [types.SET_QUIZ_RESULT](state, payload) {
    state.quizResult = payload;
  },
  [types.SET_QUIZ_AT_SYNC](state, payload) {
    state.quizAtSync = payload;
  },
  [types.ADD_QUIZ](state, payload) {
    state.quizzes.push(payload);
  },
  [types.UPDATE_QUIZ](state, payload) {
    state.currentQuiz = payload;
  },
  [types.DELETE_QUIZ](state, payload) {
    const i = state.quizzes.findIndex(item => item.id === payload.id);
    state.quizzes.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
