<template>
  <div class="client-management">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'membership_dashboard' })">
        Campaigns
      </a>
      <icon-arrow-right class="chevron-mg icon-default" />
      <a class="primary-text active">
        Client Management
      </a>
    </div>
    <div class="client-management__box mt-20">
      <div class="layout justify-space-between">
        <p class="client-management__title">Client Account</p>
        <v-btn class="custom-btn-secondary" @click="addClient">
          <icon-plus-x class="icon-list mr-12" />
          Add Client
        </v-btn>
      </div>
      <p v-if="!loadingList && !clientList.length" class="primary-text semi-bold dark-text">
        Click 'Add Client' to add your first client.
      </p>
      <div v-else class="my-datatable__wrapper">
        <div class="my-datatable__scroller">
          <table class="my-datatable mt-30 last-column-right client-management__table">
            <table-header :headers="headers" @sort="sort" />
            <tbody>
              <tr v-for="(client, index) in rows" :key="index">
                <td class="my-datatable__avatar-row">
                  <div v-if="client.avatar" class="my-datatable__avatar"
                       :style="getBgImage(client.avatar)" />
                  <div v-else class="my-datatable__avatar shortcut-name">
                    {{ getNameShortCut(client.name) }}
                  </div>
                  <p class="primary-text bold">{{ client.name }}</p>
                </td>
                <td>{{ getMembershipName(client.memberships) }}</td>
                <td>
                  <active-status :client="client" />
                </td>
                <td>
                  <span class="badge-btn badge-btn__normal" @click="editClient(client)">
                    <icon-course-user />
                    Profile
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import AddClientWizard from './client/wizard/AddClientWizard';
  import ActiveStatus from './client/ActiveStatus';
  import TableHeader from '@/components/table/TableHeader';
  import linkHelper from '@/mixins/linkHelper';
  import ConfirmDialog from '@/components/ConfirmDialog';
  import IconArrowRight from '@/assets/icons/arrow-right.svg';

  export default {
    components: {
      IconArrowRight,
      ConfirmDialog,
      ActiveStatus,
      TableHeader,
    },
    mixins: [linkHelper],
    data() {
      return {
        loadingList: false,
        sortFunction: null,
        headers: [
          {
            label: 'client name',
            field: 'name',
            sort: true,
            width: '35%',
          },
          {
            label: 'campaign',
            width: '40%',
          },
          {
            label: 'status',
            field: 'status',
            sort: true,
            width: '15%',
          },
          {
            label: 'settings',
            textAlign: 'flex-end',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['membershipList']),
      ...mapGetters('agency', ['clientList']),
      rows() {
        const row = [];
        this.clientList.forEach((client) => {
          const clone = client;
          clone.memberships = client.clientMemberships.map(c => c.membership.title);
          clone.memberships = clone.memberships.join(',');
          clone.statusColor = '#14C8B1';
          if (clone.status === 'pending') {
            clone.statusColor = '#FF9900';
          }
          row.push(clone);
        });
        if (this.sortFunction) {
          row.sort(this.sortFunction);
        }
        return row;
      },
    },
    created() {
      this.loadingList = true;
      if (!this.membershipList.length) {
        this.fetchOwnMemberships();
      }
      this.syncCourses();
      this.syncClientList().then(() => {
        this.loadingList = false;
      });
    },
    methods: {
      ...mapActions(['openCustomModal', 'fetchOwnMemberships', 'syncCourses']),
      ...mapActions('agency', ['syncClientList', 'setClientDetail', 'deleteClient']),
      sort(sort) {
        if (sort.order === 'DESC') {
          this.sortFunction = (a, b) => ((a[sort.field] > b[sort.field]) ? 1 : -1);
        } else {
          this.sortFunction = (a, b) => ((b[sort.field] > a[sort.field]) ? 1 : -1);
        }
      },
      addClient() {
        if (!this.whoIam.agencyProfile
          || (!this.whoIam.agencyProfile.domain && !this.whoIam.agencyProfile.customDomain)) {
          const options = {
            buttonTrueText: 'Yes, setup now',
            buttonFalseText: 'No, i will do it later',
          };
          this.$refs.confirm.open('You need to set up a domain for agency first, Do you want to setup now ?', options).then((res) => {
            if (res) {
              this.$router.push({ name: 'dashboard.account-setting.agency-setting' });
            }
          });
        } else {
          this.openCustomModal({
            title: 'Add New Client',
            component: AddClientWizard,
            maxWidth: '600px',
            width: '600px',
            modalClass: 'membership-form no-overflow',
          });
        }
      },
      editClient(client) {
        this.setClientDetail(client);
        this.$router.push({ name: 'dashboard.client-management.detail', params: { id: client.id } });
      },
      getMembershipName(name) {
        if (name.length < 30) {
          return name;
        }
        return `${name.slice(0, 30)},...`;
      },
      getNameShortCut(name) {
        if (!name) {
          return ' ';
        }
        const matches = name.match(/\b(\w)/g);
        return matches.join('').substring(0, 2);
      },
      getBgImage(url) {
        return { 'background-image': `url("${this.imageUrl(url)}")` };
      },
    },
  };
</script>
