export default {
  getAPIKey: data => new Promise((resolve, reject) => {
    window.http.post('/v1/get-api-key', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  generateNewAPIKey: data => new Promise((resolve, reject) => {
    window.http.post('/v1/generate-api-key', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getListDomain: () => new Promise((resolve, reject) => {
    window.http.get('/domains')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  reCheckDomain: id => new Promise((resolve, reject) => {
    window.http.get(`/domains/${id}/recheck`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
