<template>
  <div class="partnership-student__content__sale-statistic border-card">
    <div class="flex-between">
      <div class="primary-text xl semi-bold">Sale Statistic</div>
      <date-range-picker
        v-model="dateRangeStatistic"
        :customClass="'range'"
        :changeDateOption="dateOptionStatistic"
        :right="true"
        @change="onDateStatisticChanged"
      />
    </div>

    <div
      class="partnership-admin__content__sale-statistic__box flex-between mt-20"
    >
      <div v-for="(item, index) in listStattisticOption" :key="index" class="sale-statistic__item">
        <div class="text-uppercase primary-text sm dark mb-1">{{ item.label }}</div>
        <div class="primary-text xl bold">
          <span>{{ item.prefix }}</span>
          <span>{{ partnerContractStatistic && partnerContractStatistic[item.value]
            ? partnerContractStatistic[item.value] : 0 }}</span>
          <span>{{ item.suffix }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import _debounce from 'lodash.debounce';
  import { mapGetters, mapActions } from 'vuex';
  import DateRangePicker from '@/components/select/DateRangePicker';

  export default {
    components: {
      DateRangePicker,
    },
    data() {
      return {
        page: 1,
        dateOptionStatistic: 'Year to date',
        dateRangeStatistic: {
          start: '',
          end: '',
        },
        debounce_filter_statistic: _debounce(() => {
          this.getStatistic();
        }, 500),
        listStattisticOption: [{
          label: 'net commissions',
          value: 'net_commission',
          prefix: '$',
          suffix: '',
        }, {
          label: 'clicks',
          value: 'click',
          prefix: '',
          suffix: '',
        }, {
          label: 'transactions',
          value: 'transactions',
          prefix: '',
          suffix: '',
        }, {
          label: 'refunds',
          value: 'refunds',
          prefix: '',
          suffix: '%',
        }, {
          label: 'refund amount',
          value: 'refund_amount',
          prefix: '$',
          suffix: '',
        }, {
          label: 'gross sales',
          value: 'gross_sales',
          prefix: '$',
          suffix: '',
        }],
      };
    },
    computed: {
      ...mapGetters('partnership', [
        'partnerContractStatistic',
      ]),
    },
    methods: {
      ...mapActions('partnership', [
        'getPartnerContractStatisticById',
      ]),
      onDateStatisticChanged(date) {
        this.dateOptionStatistic = date.option;
        this.debounce_filter_statistic();
      },
      getStatistic() {
        this.getPartnerContractStatisticById({
          start: moment(this.dateRangeStatistic.start).format('YYYY-MM-DD'),
          end: moment(this.dateRangeStatistic.end).format('YYYY-MM-DD'),
          contractId: this.$route.params.id,
        });
      },
    },
  };
</script>
