import Vue from 'vue';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import * as types from '@/store/mutation-types';
import roles from '@/config/roles';
import User from '@/api/user';
import commonHelper from '@/helper/commonHelper';
import permissions, {
  displayNames,
  FEATURE_CHALLENGE,
  FEATURE_CHALLENGE__NEW,
} from '@/config/permissions';

const state = {
  isLoggedIn: !!localStorage.getItem('jwtToken'),
  whoIam: null,
  atSync: false,
  stripeAccount: {},
  fingerprint: null,
  socket: null,
};

const getters = {
  fingerprint: state => state.fingerprint,
  isLoggedIn: state => state.isLoggedIn,
  whoIam: state => ({
    ...state.whoIam,
    haveFeature(feature) {
      if (
        !state.whoIam
        || state.whoIam.clientPermissions
        || state.whoIam.isPartner
      ) {
        return false;
      }

      if (!state.whoIam.isPlanNew) {
        if (feature === FEATURE_CHALLENGE) {
          return state.whoIam.challengeActivatedAt !== null;
        }

        return false;
      }

      const planPermissions = permissions[state.whoIam.plan];

      if (!planPermissions) {
        return false;
      }

      return planPermissions.includes(feature);
    },
    haveFeatureNew(feature) {
      if (
        !state.whoIam
        || state.whoIam.clientPermissions
        || state.whoIam.isPartner
      ) {
        return false;
      }
      if (
        Object.hasOwnProperty.call(FEATURE_CHALLENGE__NEW, feature)
        && state.whoIam
      ) {
        // check permission of plan have this feature
        let tmp = false;
        if (state.whoIam.plusPlanActivatedAt !== null) {
          if (permissions.plusPlan.includes(FEATURE_CHALLENGE__NEW[feature])) {
            return true;
          }
        }
        // eslint-disable-next-line
        for (const roleData of state.whoIam.roles) {
          const planPermissions = permissions[roleData.role];
          if (!planPermissions) {
            tmp = false;
            break;
          }
          tmp = planPermissions.includes(FEATURE_CHALLENGE__NEW[feature]);
          if (tmp) {
            break;
          }
        }
        return tmp;
      }
      return false;
    },
  }),
  userDataAtSync: state => state.atSync,
  agencyMembers: state => state.agencyMembers,
  clientInfo: state => state.clientInfo,
  isStaffEditor: state => state.whoIam
    && state.whoIam.role
    && state.whoIam.role === roles.STAFF_EDITOR,
  isStaffSupport: state => state.whoIam
    && state.whoIam.role
    && state.whoIam.role === roles.STAFF_SUPPORT,
  isStaffAdmin: state => state.whoIam
    && state.whoIam.role
    && state.whoIam.role === roles.STAFF_ADMIN,
  isStaff: (state, getters) => getters.isStaffAdmin || getters.isStaffSupport || getters.isStaffEditor,
  stripeAccount: state => state.stripeAccount,
  isPlanNew: state => state.whoIam && state.whoIam.isPlanNew,
  planDisplayName: (state) => {
    if (state.whoIam && state.whoIam.isPlanNew) {
      const name = displayNames[state.whoIam.plan];
      if (!name) {
        if (state.whoIam.plusPlanActivatedAt !== null) {
          return 'Plus';
        }

        if (state.whoIam.proActivatedAt !== null) {
          return 'Pro';
        }
      } else {
        return name;
      }
    }

    return '';
  },
  isRegularUser: (s, getters) => !getters.userHavePlusPlan && !getters.isPro && !getters.isPlanNew,
  userHavePlusPlan: state => state.whoIam
    && state.whoIam.plusPlanActivatedAt !== null
    && !state.whoIam.isPlanNew,
  isPro: state => state.whoIam
    && state.whoIam.proActivatedAt !== null
    && !state.whoIam.isPlanNew,
  // just agency
  isAgency: state => !!(
    typeof state.whoIam !== 'undefined'
      && state.whoIam
      && typeof state.whoIam.agencyActivatedAt !== 'undefined'
      && state.whoIam.agencyActivatedAt !== null
  ),
  isAffiliateUser: state => state.whoIam && state.whoIam.isAffiliateUser,
  isEmailVerified: (state, getters) => getters.isAffiliateUser
    || getters.isStaff
    || !state.isLoggedIn
    || state.atSync
    || (state.whoIam && state.whoIam.emailVerified),
  isConnectPartnership: state => state.whoIam
    && state.whoIam.partnership !== null
    && state.whoIam.partnership !== undefined,
};

const mutations = {
  [types.SET_USER_DATA](state, data) {
    state.whoIam = data;
  },
  [types.UPDATE_USER_DATA](state, data) {
    if (!state.whoIam) {
      state.whoIam = {};
    }

    Object.keys(data).forEach((prop) => {
      state.whoIam[prop] = data[prop];
      Vue.set(state.whoIam, prop, data[prop]);
    });
  },
  [types.SET_USER_DATA_BUSY](state, data) {
    state.atSync = data;
  },
  [types.USERS_STRIPE_ACCOUNT](state, data) {
    state.stripeAccount = data;
  },
  [types.USERS_UPLOAD_AVATAR](state, data) {
    state.whoIam.avatar = data;
  },
  [types.SET_FINGERPRINT](state, data) {
    state.fingerprint = data;
  },
  [types.SET_USER_SOCKET](state, data) {
    state.socket = data;
  },
};

const actions = {
  revokeAllUser: ({ state }) => {
    state.socket.emit('revoke-all-user');
  },
  revokeOneUser: ({ state }, fingerprint) => {
    state.socket.emit('revoke-user', { fingerprint });
  },
  setUserSocket: ({ commit }, socket) => {
    commit(types.SET_USER_SOCKET, socket);
  },
  setFingerprint: ({ commit }) => new Promise((resolve) => {
    commit(types.SET_USER_DATA_BUSY, true);

    const fpPromise = FingerprintJS.load();
    fpPromise
      .then(fp => fp.get())
      .then((result) => {
        commit(types.SET_FINGERPRINT, result.visitorId);
        commit(types.SET_USER_DATA_BUSY, false);
        resolve(result.visitorId);
      });
  }),
  syncMe: ({ state, commit, dispatch }) => new Promise((resolve) => {
    commit(types.SET_USER_DATA_BUSY, true);
    User.getInfo(state.fingerprint).then(
      (data) => {
        commit(types.SET_USER_DATA, data);
        commit(types.SET_USER_DATA_BUSY, false);
        resolve();
      },
      () => {
        dispatch('iAmDone');
        commit(types.SET_USER_DATA_BUSY, false);
      },
    );
  }),
  setUserClientProfile: ({ commit, state }, data) => {
    const tmp = state.whoIam;
    tmp.name = data.name;
    tmp.avatar = data.avatar;
    commit(types.SET_USER_DATA, tmp);
  },
  iAmDone: ({ state, commit, dispatch }) => new Promise((resolve) => {
    if (state.socket) {
      state.socket.disconnect();
    }
    User.logout(state.fingerprint);
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('memberJwtToken');
    localStorage.removeItem('onboarding');
    commit(types.SET_USER_DATA, null);
    dispatch('closeVideosConnection');
    resolve();
  }),
  tryToLogin: ({ commit, dispatch }, credentials) => new Promise((resolve, reject) => {
    User.login(credentials)
      .then((res) => {
        window.http.defaults.headers.Authorization = `Bearer ${res.jwt.token}`;
        localStorage.setItem('jwtToken', res.jwt.token);
        localStorage.setItem('memberJwtToken', res.memberToken);
        dispatch('syncMe');
        resolve(res);
      })
      .catch((error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        reject(error);
      });
  }),
  updateLocalMe: ({ commit }, data) => {
    console.log(data);
    commit(types.UPDATE_USER_DATA, data);
  },
  updateMe: ({ commit }, data) => new Promise((resolve, reject) => {
    User.updateUser(data.id, data)
      .then((res) => {
        commit(types.UPDATE_USER_DATA, res);
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  getStripeAccount: ({ commit }) => new Promise((resolve, reject) => {
    User.getStripeAccount()
      .then((res) => {
        commit(types.USERS_STRIPE_ACCOUNT, res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  registration: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    User.registration(data)
      .then((res) => {
        if (res.parentId) {
          commit(types.SET_USER_DATA_BUSY, false);
          dispatch('showAlert', 'A new account has been created.');
          commit(types.USERS_CLIENT_CREATE, res);
        } else {
          commit(types.SET_USER_DATA_BUSY, false);
          dispatch('showAlert', 'Account info has been updated.');
          commit(types.USERS_REGISTRATION, res);
        }

        resolve(res);
      })
      .catch((error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', error);
        reject(error);
      });
  }),
  updateAgencyProfile: ({ commit, dispatch, state }, data) => new Promise((resolve, reject) => {
    User.updateAgencyProfile(data)
      .then((res) => {
        const user = state.whoIam;
        user.agencyProfile = {
          domain: data.domain,
          logo: data.logo,
          id: res.id,
          name: data.name,
        };
        dispatch('showAlert', 'Your agency profile has been updated.');
        commit(types.UPDATE_USER_DATA, user);
        resolve(res);
      })
      .catch((error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'Failed to update your agency profile.');
        reject(error);
      });
  }),
  getClientInfo: ({ commit }, userId) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    User.getClientInfo(userId)
      .then(
        (res) => {
          commit(types.SET_USER_DATA_BUSY, false);
          // dispatch('showAlert', 'Your account has an Agency status now.');
          commit(types.USERS_CLIENT_INFO, res);
          resolve(res);
        },
        (err) => {
          // console.log('getClientInfo reject err', err);
          commit(types.SET_USER_DATA_BUSY, false);
          reject(err);
        },
      )
      .catch((error) => {
        // console.log('getClientInfo catch err', error);
        commit(types.SET_USER_DATA_BUSY, false);
        // dispatch('showAlert', 'Failed to set an agency status to your account.');
        reject(error);
      });
  }),
  uploadAvatar: ({ commit, dispatch }, file) => new Promise((resolve, reject) => {
    User.uploadAvatar(file)
      .then((res) => {
        dispatch('showAlert', 'Your avatar has been uploaded.');
        commit(types.USERS_UPLOAD_AVATAR, res);
        resolve(res);
      })
      .catch((error) => {
        dispatch('showAlert', 'Failed to upload avatar.');
        reject(error);
      });
  }),
  removeAvatar: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    User.removeAvatar()
      .then((res) => {
        dispatch('showAlert', 'Your avatar has been removed.');
        commit(types.USERS_UPLOAD_AVATAR, '');
        resolve(res);
      })
      .catch((error) => {
        dispatch('showAlert', {
          message: 'Failed to remove avatar.',
          type: 'danger',
        });
        reject(error);
      });
  }),
  usernameChangeCheck: (state, data) => new Promise((resolve, reject) => {
    // commit(types.SET_USER_DATA_BUSY, true);
    User.usernameChangeCheck(data)
      .then((res) => {
        // commit(types.SET_USER_DATA_BUSY, false);
        resolve(res);
      })
      .catch((err) => {
        // commit(types.SET_USER_DATA_BUSY, false);
        reject(err);
      });
  }),
  switchAccount: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    const tmp = data.account;
    tmp.accounts = data.currentUser.accounts;
    commit(types.SET_USER_DATA, tmp);
    window.http.defaults.headers.Authorization = `Bearer ${tmp.jwtToken.token}`;
    localStorage.setItem('jwtToken', tmp.jwtToken.token);
    User.getInfo().then(
      (res) => {
        const user = res;
        user.integrations = commonHelper.reorderIntegrations(
          res.integrations,
          res,
        );
        commit(types.SET_USER_DATA, user);
        dispatch('setupVideosConnection', true);
        resolve(true);
      },
      (error) => {
        dispatch('iAmDone');
        reject(error);
      },
    );
  }),
  createUserPassword: ({ dispatch }, data) => new Promise((resolve, reject) => {
    User.createPassword(data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', {
          message: 'Error. Something went wrong.',
          type: 'danger',
        });
        reject(error);
      },
    );
  }),
  resendConfirmMail: ({ dispatch }) => new Promise((resolve, reject) => {
    User.resendConfirmEmail().then(
      (res) => {
        dispatch(
          'showAlert',
          'The confirmation email has been sent. Please check you email address inbox',
        );
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', { message: error, type: 'danger' });
        reject(error);
      },
    );
  }),
  setOnboardingStep: ({ state, commit, dispatch }, step) => new Promise((resolve, reject) => {
    User.updateOnboardingStep({ step }).then(
      (res) => {
        const user = state.whoIam;
        user.onboardingStep = step;
        commit(types.SET_USER_DATA, user);
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', { message: error, type: 'danger' });
        reject(error);
      },
    );
  }),
  addBonusMembershipForUser: ({ dispatch }) => new Promise((resolve, reject) => {
    User.addBonusContent()
      .then((res) => {
        dispatch('showAlert', {
          message: 'You has received a bonus membership and courses',
          timeout: 5000,
          type: 'success',
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  setAvatar: ({ commit }, payload) => new Promise((resolve) => {
    commit(types.USERS_UPLOAD_AVATAR, payload);
    resolve(true);
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
