<template>
  <div>
    <p class="primary-text lg semi-bold head-admin-color">
      Use your Zapier API Key to connect to
      <a class="link-violet" :href="zapierUrl" target="_blank">Member App</a>.
    </p>
    <p class="mt-10 primary-text dark-text">Use this API key:</p>
    <p class="primary-text main-green">{{ apiKey }}</p>
    <p class="primary-text dark-text">
      To connect to the Member App integration within Zapier.
    </p>
    <div class="layout align-center mt-20">
      <v-spacer />
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        zapierUrl: process.env.VUE_APP_ZAPIER_APP_URL,
      };
    },
    computed: {
      ...mapGetters([
        'whoIam',
      ]),
      apiKey() {
        if (this.whoIam.integrationApiKey) {
          return this.whoIam.integrationApiKey;
        }
        return this.whoIam.apiKey;
      },
    },
    methods: {
      ...mapActions([
        'closeModal',
      ]),
    },
  };
</script>
