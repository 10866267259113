import moment from 'moment';
import * as types from '@/store/mutation-types';
import CountryApi from '@/api/country';

const state = {
  hasCountryToken: !!localStorage.getItem('countryApiToken') && Number(localStorage.getItem('countryApiTokenExpire') > moment().unix()),
  countryList: [],
  stateList: [],
  cityList: [],
  atSync: false,
  expireCountryToken: Number(localStorage.getItem('countryApiTokenExpire') > moment().unix()),
};

const getters = {
  hasCountryToken: state => state.hasCountryToken,
  countryList: state => state.countryList,
  stateList: state => state.stateList,
  cityList: state => state.cityList,
};

const actions = {
  fetchCountryToken({ dispatch }) {
    CountryApi.getToken()
      .then(
        (res) => {
          localStorage.setItem('countryApiToken', res.auth_token);
          localStorage.setItem('countryApiTokenExpire', moment().add(20, 'hours').unix().toString());
          dispatch('getCountryListData');
        },
      );
  },
  getCountryListData: ({ commit }) => new Promise((resolve, reject) => {
    CountryApi.getCountries(localStorage.getItem('countryApiToken'))
      .then(
        (res) => {
          const result = [];
          res.forEach((country) => {
            result.push({
              title: country.country_name,
              value: country.country_name,
            });
          });
          commit(types.SET_COUNTRY_LIST, result);
          resolve(true);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  getStateListData({ commit }, country) {
    CountryApi.getStates(localStorage.getItem('countryApiToken'), country)
      .then(
        (res) => {
          const result = [];
          res.forEach((state) => {
            result.push({
              title: state.state_name,
              value: state.state_name,
            });
          });
          commit(types.SET_STATE_LIST, result);
        },
      );
  },
  getCityListData({ commit }, state) {
    CountryApi.getCities(localStorage.getItem('countryApiToken'), state)
      .then(
        (res) => {
          const result = [];
          res.forEach((city) => {
            result.push({
              title: city.city_name,
              value: city.city_name,
            });
          });
          commit(types.SET_CITY_LIST, result);
        },
      );
  },
};

const mutations = {
  [types.SET_COUNTRY_LIST](state, payload) {
    state.countryList = payload;
  },
  [types.SET_STATE_LIST](state, payload) {
    state.stateList = payload;
  },
  [types.SET_CITY_LIST](state, payload) {
    state.cityList = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
