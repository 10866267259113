import moment from 'moment';
import * as types from '@/store/mutation-types';
import api from '@/api/quiz';

const state = {
  quizResults: [],
  quizAnswers: [],
  selectedStudent: null,
};

const getters = {
  quizResults: state => state.quizResults,
  quizAnswers: state => state.quizAnswers,
  selectedStudent: state => state.selectedStudent,

  // --------------------------------------------------------------------------------------

  totalStudents: (state) => {
    const students = state.quizResults.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.memberId) === -1) {
          a.push(currentValue.memberId);
        }
        return a;
      },
      [],
    );
    return students.length;
  },

  // --------------------------------------------------------------------------------------

  totaFinishedTests: (state) => {
    const uniqueQuizResults = state.quizResults.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.id) === -1) {
          a.push(currentValue.id);
        }
        return a;
      },
      [],
    );
    return uniqueQuizResults.length;
  },

  // --------------------------------------------------------------------------------------

  successfulTests: (state) => {
    const filteredQuizResults = state.quizResults.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.id) === -1 && currentValue.passed) {
          a.push(currentValue.id);
        }
        return a;
      },
      [],
    );
    return filteredQuizResults.length;
  },

  // --------------------------------------------------------------------------------------

  failedTests: (state) => {
    const filteredQuizResults = state.quizResults.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (a.indexOf(currentValue.id) === -1 && !currentValue.passed) {
          a.push(currentValue.id);
        }
        return a;
      },
      [],
    );
    return filteredQuizResults.length;
  },

  // --------------------------------------------------------------------------------------

  avarageGrade: (state) => {
    const sum = state.quizResults.reduce((acc, c) => acc + (c.grade || 0), 0);

    if (!sum) {
      return 0;
    }
    return (sum / state.quizResults.length).toFixed(0);
  },


  // --------------------------------------------------------------------------------------

  gradeDistribution: (state) => {
    const barData = [];
    let i = 1;
    while (i < 10) {
      let color = '#EB5757';
      if (i > 4) {
        color = '#14C8B1';
      }
      const min = i * 10;
      const max = (i + 1) * 10;
      barData.push({
        label: `${min}-${max}%`,
        data: (state.quizResults.filter(r => r.grade > min && r.grade <= max)).length,
        color,
      });

      i += 1;
    }

    return barData;
  },

  // --------------------------------------------------------------------------------------

  studentResults: (state) => {
    const list = state.quizResults.map((item, index) => (
      {
        no: index + 1,
        id: item.id,
        name: item.name,
        course: item.courseTitle,
        module: item.moduleTitle,
        date: moment(item.createdAt).format('DD MMM YYYY'),
        review: item.review,
        test_result: item.review ? '<span class="review">review</span>' : `${item.grade || 0}%`,
      }));


    return list;
  },

  // --------------------------------------------------------------------------------------

  difficultQuestions: state => state.quizAnswers,

  // --------------------------------------------------------------------------------------


  // totalHoursVideoViews: (state) => {
  //   // playback time within each group (longest duration value)
  //   // duration is a difference between the lastStoppedTime and the currect player position
  //   // the last stopped time is reset on plyr 'ended' event and on page reload

  //   const totalPlaybackTimeInSeconds = state.sessionGroups.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       let longestDuration = 0;
  //       currentValue.forEach((val) => {
  //         longestDuration = (longestDuration < Number(val.duration))
  //           ? Number(val.duration) : longestDuration;
  //       });
  //       return a + longestDuration;
  //     },
  //     0,
  //   );

  //   return totalPlaybackTimeInSeconds;
  // },


  // // --------------------------------------------------------------------------------------

  // averageTimeViews: (state) => {
  //   if (!state.sessionGroups && !state.sessionGroups.length) {
  //     return 0;
  //   }

  //   const totalPlaybackTimeInSeconds = state.sessionGroups.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       let longestDuration = 0;
  //       currentValue.forEach((val) => {
  //         longestDuration = (longestDuration < Number(val.duration))
  //           ? Number(val.duration) : longestDuration;
  //       });
  //       return a + longestDuration;
  //     },
  //     0,
  //   );

  //   return totalPlaybackTimeInSeconds / state.sessionGroups.length;
  // },

  // // --------------------------------------------------------------------------------------


  // detailedVideoViewingStatisticsItems: (state) => {
  //   const list = state.stats.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       const found = a.find(item => item.courseId === currentValue.courseId);
  //       if (!found) {
  //         a.push({
  //           courseId: currentValue.courseId,
  //           label: currentValue.courseTitle,
  //           items: [],
  //         });
  //       }
  //       return a;
  //     },
  //     [],
  //   );

  //   list.forEach((course, index) => {
  //     list[index].items = (state.stats.filter(s => s.courseId === course.courseId)).map(p => ({
  //       title: p.title,
  //       id: p.video,
  //       // id: p.lessonIndex,

  //     }));
  //   });

  //   return list;
  // },

  // // --------------------------------------------------------------------------------------

  // selectedVideoData: state => state.stats.find(s => s.video === state.selectedVideoUuid) || {},

  // // --------------------------------------------------------------------------------------

  // detailedVideoViewingStatistics: (state) => {
  //   // find longest durations for each session
  //   // get payback position for such records
  //   //
  //   const playbackPositions = state.sessionGroups.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       const itemWithLongestDuration = currentValue.reduce(
  //         (prev, current) =>
  // ((Number(prev.duration) > Number(current.duration)) ? prev : current),
  //       );

  //       if (itemWithLongestDuration.uuid === state.selectedVideoUuid) {
  //         a.push(Number.parseInt(itemWithLongestDuration.playbackPosition, 10));
  //       }
  //       return a;
  //     },
  //     [],
  //   );


  //   // count occurences of each payback position
  //   //
  //   const list = playbackPositions.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       const index = a.findIndex(item => item.date === currentValue);

  //       if (index === -1) {
  //         a.push({
  //           date: currentValue,
  //           value: 1,
  //         });
  //       } else {
  //         a[index].value += 1;
  //       }
  //       return a;
  //     },
  //     [],
  //   );

  //   list.sort((a, b) => ((a.date > b.date) ? 1 : -1));

  //   // format dates
  //   // and return the list
  //   //

  //   return list.map(item => ({
  //     date: moment(item.date * 1000).format('mm:ss'),
  //     value: item.value / playbackPositions.length * 100,
  //   }));
  // },

  // // --------------------------------------------------------------------------------------


};


// ====================================================


const actions = {

  quizResultsAnalytics: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data.courseIds
      || !data.courseIds.length
      || !data.quizIds
      || !data.quizIds.length
      || (!data.startDate && !data.endDate)
    ) {
      commit(types.SET_QUIZ_RESULTS, []);
      return resolve([]);
    }

    return api.quizResultsAnalytics(data)
      .then((res) => {
        commit(types.SET_QUIZ_RESULTS, res);
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),

  quizAnswersAnalytics: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data.courseIds
      || !data.courseIds.length
      || !data.quizIds
      || !data.quizIds.length
      || (!data.startDate && !data.endDate)) {
      commit(types.SET_QUIZ_ANSWERS, []);
      return resolve([]);
    }

    return api.quizAnswersAnalytics(data)
      .then((res) => {
        commit(types.SET_QUIZ_ANSWERS, res);
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  }),


  // setVideoUuidNext: ({ commit }) => new Promise((resolve) => {
  //   commit(types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT);
  //   resolve(true);
  // }),

  setSelectedStudent: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_SELECTED_STUDENT, data);
    resolve(true);
  }),


};


// ====================================================


const mutations = {

  [types.SET_SELECTED_STUDENT](state, data) {
    state.selectedStudent = data;
  },

  [types.SET_QUIZ_RESULTS](state, data) {
    state.quizResults = data;
  },

  [types.SET_QUIZ_ANSWERS](state, data) {
    state.quizAnswers = data;
  },

  // [types.SET_VIDEO_ANALYTICS_STATS](state, data) {
  //   const analytics = data.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       if (currentValue.analytics) {
  //         a.push(...currentValue.analytics);
  //       }

  //       return a;
  //     },
  //     [],
  //   );
  //   state.analytics = analytics;

  //   // unique view sessions (page views until reload or playback completion)
  //   //
  //   const sessions = state.analytics.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       if (a.indexOf(currentValue.sessionUuid) === -1
  //       ) {
  //         a.push(currentValue.sessionUuid);
  //       }
  //       return a;
  //     },
  //     [],
  //   );

  //   // group all analytics records by session
  //   //
  //   state.sessionGroups = sessions.reduce(
  //     (accumulator, currentValue) => {
  //       const a = accumulator;
  //       a.push(state.analytics.filter(item => currentValue === item.sessionUuid));
  //       return a;
  //     },
  //     [],
  //   );


  //   // set selected video uuid
  //   //
  //   if (data && data.length) {
  //     state.selectedVideoUuid = data[0].video;
  //   }

  //   state.stats = data;
  // },


  // [types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT](state) {
  //   const foundIndex = state.selectedVideoUuid ? (
  //     state.stats.findIndex(s => s.video === state.selectedVideoUuid) || 0) : 0;
  //   if (foundIndex < state.stats.length) {
  //     state.selectedVideoUuid = state.stats[foundIndex + 1].video;
  //   }
  // },

  // [types.SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_PREV](state) {
  //   const foundIndex = state.selectedVideoUuid ? (
  //     state.stats.findIndex(s => s.video === state.selectedVideoUuid) || 0) : 0;
  //   if (foundIndex > 0) {
  //     state.selectedVideoUuid = state.stats[foundIndex - 1].video;
  //   }
  // },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
