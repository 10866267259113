<template>
  <div class="partnership-modal__content client-management__wizard">
    <div class="client-management__wizard-tabs mt-10">
      <div class="client-management__wizard-navigation">
        <div
          ref="firstPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 0}"
        >
          <div v-if="step <= 0" class="circle"><span class="circle-active" /></div>
          <icon-check-circle v-else />
          Campaign
        </div>
        <div
          class="client-management__wizard-line"
          :style="styleFirstLine"
          :class="{ active: step >= 1}"
        />
        <div
          ref="secondPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 1}"
        >
          <div v-if="step <= 1" class="circle"><span class="circle-active" /></div>
          <icon-check-circle v-else />
          Terms
        </div>
        <div
          class="client-management__wizard-line"
          :style="styleSecondLine"
          :class="{ active: step >= 2}"
        />
        <div
          ref="thirdPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 2}"
        >
          <div class="circle"><span class="circle-active" /></div>
          Partner
        </div>
      </div>
    </div>
    <div class="mt-20 mb-30">
      <campaign-selector v-if="step === 0" v-model="partnershipContract.membershipId" />
      <term-selector v-if="step === 1" v-model="partnershipContract.termData" />
      <partner-selector v-if="step === 2" v-model="partnershipContract.partner" />
    </div>
    <div class="flex-between">
      <v-btn class="custom-btn-normal fixed-width" :class="{hidden: step === 0}" @click="onBack">
        <fa-icon :icon="['fal', 'chevron-left']" class="mr-15" />
        Back
      </v-btn>
      <v-btn
        :loading="loading"
        :disabled="disableNext"
        class="custom-btn-secondary fixed-width"
        @click="onNext"
      >
        {{ step === 2 ? 'Complete' : 'Next' }}
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import CampaignSelector from './CampaignSelector';
  import TermSelector from './Term';
  import PartnerSelector from './PartnerSelector';

  export default {
    components: {
      CampaignSelector,
      TermSelector,
      PartnerSelector,
    },
    data() {
      return {
        loading: false,
        step: 0,
        firstLine: {
          top: 0,
          width: 0,
          left: 0,
        },
        secondLine: {
          top: 0,
          width: 0,
          left: 0,
        },
        partnershipContract: {
          membershipId: null,
          termData: {
            paymentTerm: 'percentage_of_sale',
            amount: undefined,
            paymentType: 'manual',
            duePeriod: 30,
          },
          partner: { email: '', name: '', partnershipId: '' },
        },
      };
    },
    computed: {
      styleFirstLine() {
        return `width: ${this.firstLine.width}px; left: ${this.firstLine.left}px; top: ${this.firstLine.top}px`;
      },
      styleSecondLine() {
        return `width: ${this.secondLine.width}px; left: ${this.secondLine.left}px; top: ${this.secondLine.top}px`;
      },
      disableNext() {
        if (this.step === 0) {
          return !this.partnershipContract.membershipId;
        }
        if (this.step === 1) {
          if (!this.partnershipContract.termData
            || !Object.keys(this.partnershipContract.termData).length) {
            return true;
          }
          let tmp = false;
          Object.keys(this.partnershipContract.termData).forEach((key) => {
            if (!this.partnershipContract.termData[key]) {
              tmp = true;
            }
          });
          return tmp;
        }
        if (this.step === 2) {
          return !this.partnershipContract.partner;
        }
        return false;
      },
    },
    mounted() {
      this.getPartnerList();
      const first = this.$refs.firstPoint;
      const second = this.$refs.secondPoint;
      const third = this.$refs.thirdPoint;
      const top = this.$refs.firstPoint.offsetTop;
      this.firstLine = {
        top: top + 9,
        left: first.offsetLeft + (first.clientWidth / 2) + 4, // 4: haft of circle width
        width: (second.offsetLeft + (second.clientWidth / 2))
          - (first.offsetLeft + (first.clientWidth / 2))
          - 8,
      };
      this.secondLine = {
        top: top + 9,
        left: second.offsetLeft + (second.clientWidth / 2) + 4,
        width: (third.offsetLeft + (third.clientWidth / 2))
          - (second.offsetLeft + (second.clientWidth / 2))
          - 8,
      };
    },
    methods: {
      ...mapActions(['closeModal']),
      ...mapActions('partnership', ['createPartnershipContract', 'getPartnerList']),
      onBack() {
        if (this.step === 0) {
          this.closeModal();
        } else {
          this.step = this.step - 1;
        }
      },
      async onNext() {
        if (this.step === 2) {
          this.loading = true;
          const params = {
            membershipId: this.partnershipContract.membershipId,
            paymentTerm: this.partnershipContract.termData.paymentTerm,
            paymentType: this.partnershipContract.termData.paymentType,
            amount: this.partnershipContract.termData.amount,
            duePeriod: this.partnershipContract.termData.duePeriod,
          };
          if (this.partnershipContract.partner.partnershipId) {
            params.partnershipId = this.partnershipContract.partner.partnershipId;
          } else if (this.partnershipContract.partner.email) {
            params.email = this.partnershipContract.partner.email;
            params.name = this.partnershipContract.partner.name;
          }
          this.createPartnershipContract(params).then(() => {
            this.loading = false;
            this.closeModal();
          })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.step = this.step + 1;
        }
      },
    },
  };
</script>
