export default {
  list: () => new Promise((resolve, reject) => {
    window.http.get('team-members').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  create: formData => new Promise((resolve, reject) => {
    window.http.post('team-member', formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  update: teamMember => new Promise((resolve, reject) => {
    window.http.put(`team-member/${teamMember.id}`, teamMember).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  deleteTeamMember: teamMemberId => new Promise((resolve, reject) => {
    window.http.delete(`team-member/${teamMemberId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
