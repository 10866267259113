export const SET_USER_COURSES = 'SET_USER_COURSES';
export const SET_COURSE_AT_WORKBENCH = 'SET_COURSE_AT_WORKBENCH';
export const SET_USER_COURSES_BUSY = 'SET_USER_COURSES_BUSY';

export const SET_USER_DOMAINS = 'SET_USER_DOMAINS';
export const SET_USER_DOMAINS_BUSY = 'SET_USER_DOMAINS_BUSY';
export const DELETE_USER_DOMAIN = 'DELETE_USER_DOMAIN';
export const UPDATE_USER_DOMAIN = 'UPDATE_USER_DOMAIN';
export const ADD_USER_DOMAIN = 'ADD_USER_DOMAIN';

export const SET_USER_EMAIL_SENDERS = 'SET_USER_EMAIL_SENDERS';
export const SET_USER_EMAIL_SENDERS_BUSY = 'SET_USER_EMAIL_SENDERS_BUSY';
export const ADD_USER_EMAIL_SENDER = 'ADD_USER_EMAIL_SENDER';
export const DELETE_USER_EMAIL_SENDER = 'DELETE_USER_EMAIL_SENDER';
export const UPDATE_USER_EMAIL_SENDER = 'UPDATE_USER_EMAIL_SENDER';

export const SET_RATES = 'SET_RATES';

export const SET_FINGERPRINT = 'SET_FINGERPRINT';

export const USERS_REGISTRATION = 'USERS_REGISTRATION';
export const USERS_CLIENT_CREATE = 'USERS_CLIENT_CREATE';
export const USERS_STRIPE_ACCOUNT = 'USERS_STRIPE_ACCOUNT';

export const USERS_UPLOAD_AVATAR = 'USERS_UPLOAD_AVATAR';

export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const SET_WIZARD_MODE = 'SET_WIZARD_MODE';
export const SET_ALERT_STATE = 'SET_ALERT_STATE';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_ALERT_TITLE = 'SET_ALERT_TITLE';
export const SET_ALERT_TYPE = 'SET_ALERT_TYPE';
export const SET_ALERT_TIMEOUT = 'SET_ALERT_TIMEOUT';

export const SET_FOOTER_MENU = 'SET_FOOTER_MENU';
export const SET_CHANGE_ACCOUNT_MENU = 'SET_CHANGE_ACCOUNT_MENU';

export const SET_MEMBERS_LIST = 'SET_MEMBERS_LIST';
export const PUSH_MEMBER_TO_CURRENT_LIST = 'PUSH_MEMBER_TO_CURRENT_LIST';
export const SET_MEMBERS_LIST_BUSY = 'SET_MEMBERS_LIST_BUSY';
export const SET_MEMBERS_DETAIL_BUSY = 'SET_MEMBERS_DETAIL_BUSY';
export const DELETE_MEMBER_FROM_LIST = 'DELETE_MEMBER_FROM_LIST';
export const DELETE_COURSE_IN_CURRENT_MEMBER = 'DELETE_COURSE_IN_CURRENT_MEMBER';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_BUSY = 'SET_USER_DATA_BUSY';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';


export const SET_LIST = 'SET_LIST';
export const SET_LIST_BUSY = 'SET_LIST_BUSY';

export const STRIPE_PAYMENT_INTENT_CREATE = 'STRIPE_PAYMENT_INTENT_CREATE';
export const STRIPE_PAYMENT_INTENT_CODE = 'STRIPE_PAYMENT_INTENT_CODE';
export const STRIPE_FETCH_INVOICES = 'STRIPE_FETCH_INVOICES';
export const STRIPE_LOAD = 'STRIPE_LOAD';
export const STRIPE_DEAUTHORIZE = 'STRIPE_DEAUTHORIZE';

export const USERS_CLIENT_INFO = 'USERS_CLIENT_INFO';

// Analytics
export const SET_ANALYTIC = 'SET_ANALYTIC';
export const SET_ANALYTIC_BUSY = 'SET_ANALYTIC_BUSY';
export const SET_SELECTED_WIDGET = 'SET_SELECTED_WIDGET';
export const SET_USER_ANALYTIC_SELECTED_WIDGET = 'SET_USER_ANALYTIC_SELECTED_WIDGET';
export const SET_VIDEO_ANALYTIC_SELECTED_WIDGET = 'SET_VIDEO_ANALYTIC_SELECTED_WIDGET';
export const SET_TEST_ANALYTIC_SELECTED_WIDGET = 'SET_TEST_ANALYTIC_SELECTED_WIDGET';
export const SET_COMMENT_ANALYTIC_SELECTED_WIDGET = 'SET_COMMENT_ANALYTIC_SELECTED_WIDGET';
export const SET_CURRENT_TAB_IS_COURSES = 'SET_CURRENT_TAB_IS_COURSES';
export const SET_USER_ANALYTICS_STATS = 'SET_USER_ANALYTICS_STATS';
export const SET_COMMENTS_ANALYTICS_STATS = 'SET_COMMENTS_ANALYTICS_STATS';
export const SET_ANALYTICS_FILTER_DATES = 'SET_ANALYTICS_FILTER_DATES';
export const SET_TOTAL_NEW_ANALYTIC = 'SET_TOTAL_NEW_ANALYTIC';

export const SET_SELECTED_ANALYTIC_COURSE_IDS = 'SET_SELECTED_ANALYTIC_COURSE_IDS';
export const SET_SELECTED_ANALYTIC_MEMBERSHIP_IDS = 'SET_SELECTED_ANALYTIC_MEMBERSHIP_IDS';

export const SET_STAFF_LIST = 'SET_STAFF_LIST';
export const PUSH_STAFF_TO_CURRENT_LIST = 'PUSH_STAFF_TO_CURRENT_LIST';
export const DELETE_STAFF_FROM_LIST = 'DELETE_STAFF_FROM_LIST';
export const SET_STAFF_LIST_BUSY = 'SET_STAFF_LIST_BUSY';

export const PREVIEW_LOGIN_JWT = 'PREVIEW_LOGIN_JWT';

export const SET_MEMBERSHIPS = 'SET_MEMBERSHIPS';
export const SET_MEMBERSHIP_ARCHIVES = 'SET_MEMBERSHIP_ARCHIVES';
export const SET_MEMBERSHIP_AT_SYNC = 'SET_MEMBERSHIP_AT_SYNC';
export const ADD_MEMBERSHIP = 'ADD_MEMBERSHIP';
export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP';
export const DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP';
export const UPDATE_MEMBERSHIP_COURSE = 'UPDATE_MEMBERSHIP_COURSE';
export const DELETE_MEMBERSHIP_COURSE = 'DELETE_MEMBERSHIP_COURSE';
export const ADD_MEMBERSHIP_COURSE = 'ADD_MEMBERSHIP_COURSE';
export const UPDATE_MEMBERSHIP_MEDIA = 'UPDATE_MEMBERSHIP_MEDIA';

export const WIZARD_NEXT_STEP = 'WIZARD_NEXT_STEP';
export const WIZARD_PREV_STEP = 'WIZARD_PREV_STEP';
export const SET_WIZARD_IDS = 'SET_WIZARD_IDS';
export const WIZARD_STEP = 'WIZARD_STEP';
export const RESET_WIZARD_STEPS = 'RESET_WIZARD_STEPS';

export const SET_MEMBERSHIP_USERS = 'SET_MEMBERSHIP_USERS';
export const ADD_MEMBER_TO_MEMBERSHIP = 'ADD_MEMBER_TO_MEMBERSHIP';
export const REMOVE_MEMBER_FROM_MEMBERSHIP = 'REMOVE_MEMBER_FROM_MEMBERSHIP';
export const UPDATE_MEMBER_IN_MEMBERSHIP = 'UPDATE_MEMBER_IN_MEMBERSHIP';

export const UPDATE_MEMBER_IN_CURRENT_LIST = 'UPDATE_MEMBER_IN_CURRENT_LIST';
export const SET_WIZARD_MEMBERSHIP = 'SET_WIZARD_MEMBERSHIP';
export const SET_WIZARD_COURSE = 'SET_WIZARD_COURSE';
export const SET_WIZARD_COURSE_ON_CREATE = 'SET_WIZARD_COURSE_ON_CREATE';

export const SET_WIZARD_STEP_BY_ROUTE_NAME = 'SET_WIZARD_STEP_BY_ROUTE_NAME';
export const UPDATE_ALL_MEMBERSHIP_COURSES = 'UPDATE_ALL_MEMBERSHIP_COURSES';

export const SET_WIZARD_NEW = 'SET_WIZARD_NEW';
export const SET_IS_COURSE_WIZARD = 'SET_IS_COURSE_WIZARD';
export const SET_IS_COURSE_WIZARD_FLOW = 'SET_IS_COURSE_WIZARD_FLOW';

export const MEMBERSHIP_UNPUBLISH_ALL_COURSES = 'MEMBERSHIP_UNPUBLISH_ALL_COURSES';
export const SET_DASHBOARD_STATS = 'SET_DASHBOARD_STATS';
export const SET_DASHBOARD_STATS_FETCHING = 'SET_DASHBOARD_STATS_FETCHING';
export const SET_DASHBOARD_COMPARE_STATS = 'SET_DASHBOARD_COMPARE_STATS';
export const SET_DASHBOARD_ENABLE_COMPARE = 'SET_DASHBOARD_ENABLE_COMPARE';
export const SET_FILTER_COMPARE_DATES = 'SET_FILTER_COMPARE_DATES';

export const RESET_SELECTED_COURSE_IDS = 'RESET_SELECTED_COURSE_IDS';
export const RESET_SELECTED_MEMBERSHIP_IDS = 'RESET_SELECTED_MEMBERSHIP_IDS';

export const SET_SELECTED_COURSE_IDS = 'SET_SELECTED_COURSE_IDS';
export const SET_SELECTED_MEMBERSHIP_IDS = 'SET_SELECTED_MEMBERSHIP_IDS';


export const SET_FILTER_DATES = 'SET_FILTER_DATES';
export const SET_DASHBOARD_COURSE_LIST = 'SET_DASHBOARD_COURSE_LIST';
export const SET_DROPDOWN_SHOWS_COURSE_LIST = 'SET_DROPDOWN_SHOWS_COURSE_LIST';

export const ADD_PRODUCT_TO_MEMBERSHIP = 'ADD_PRODUCT_TO_MEMBERSHIP';

// Videos
export const SET_VIDEOS_DATA = 'SET_VIDEOS_DATA';
export const SET_VIDEOS_DATA_LOADING = 'SET_VIDEOS_DATA_LOADING';
export const SET_VIDEOS_CONNECTION = 'SET_VIDEOS_CONNECTION';
export const SET_VIDEOS_TRY = 'SET_VIDEOS_TRY';

export const ADD_MEDIA_TO_UPLOAD = 'ADD_MEDIA_TO_UPLOAD';
export const UPDATE_MEDIA_UPLOAD_INFO = 'UPDATE_MEDIA_UPLOAD_INFO';
export const REMOVE_MEDIA_UPLOAD = 'REMOVE_MEDIA_UPLOAD';

// Video Analytics
export const SET_VIDEO_ANALYTICS_STATS = 'SET_VIDEO_ANALYTICS_STATS';
export const SET_VIDEO_ANALYTICS_FETCHING = 'SET_VIDEO_ANALYTICS_FETCHING';
export const SET_VIDEO_ANALYTICS = 'SET_VIDEO_ANALYTICS';
export const SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID = 'SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID';
export const SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT = 'SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_NEXT';
export const SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_PREV = 'SET_VIDEO_ANALYTICS_VIDEO_SELECTED_UUID_PREV';

export const SET_QUIZ_AT_SYNC = 'SET_QUIZ_AT_SYNC';
export const ADD_QUIZ = 'ADD_QUIZ';
export const SET_CURRENT_QUIZ = 'SET_CURRENT_QUIZ';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';

export const SET_SELECTED_STUDENT = 'SET_SELECTED_STUDENT';
export const SET_QUIZ_RESULTS = 'SET_QUIZ_RESULTS';
export const SET_QUIZ_ANSWERS = 'SET_QUIZ_ANSWERS';
export const SET_QUIZ_RESULT = 'SET_QUIZ_RESULT';

export const SET_QUIZ_MENU_LIST = 'SET_QUIZ_MENU_LIST';
export const SET_ANALYTICS_QUIZ_IDS = 'SET_ANALYTICS_QUIZ_IDS';

// Affiliate
export const SET_AFFILIATE_ANALYTIC_DATA = 'SET_AFFILIATE_ANALYTIC_DATA';
export const SET_AFFILIATE_ANALYTIC_FETCHING = 'SET_AFFILIATE_ANALYTIC_FETCHING';
export const SET_AFFILIATE_PAYMENT_SETTINGS = 'SET_AFFILIATE_PAYMENT_SETTINGS';
export const ADD_AFFILIATE_PAYMENT_SETTING = 'ADD_AFFILIATE_PAYMENT_SETTING';
export const DELETE_AFFILIATE_PAYMENT_SETTING = 'DELETE_AFFILIATE_PAYMENT_SETTING';
export const UPDATE_AFFILIATE_PAYMENT_SETTING = 'UPDATE_AFFILIATE_PAYMENT_SETTING';

// Country
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_STATE_LIST = 'SET_STATE_LIST';
export const SET_CITY_LIST = 'SET_CITY_LIST';

// Wizard tab
export const SET_WIZARD_MEMBERSHIP_TAB = 'SET_WIZARD_MEMBERSHIP_TAB';
export const SET_WIZARD_COURSE_TAB = 'SET_WIZARD_COURSE_TAB';
export const SET_WIZARD_CHECKOUT_TAB = 'SET_WIZARD_CHECKOUT_TAB';
export const SET_WIZARD_INTEGRATION_TAB = 'SET_WIZARD_INTEGRATION_TAB';
export const SET_WIZARD_SALE_TAB = 'SET_WIZARD_SALE_TAB';
export const SET_WIZARD_FORCE_EXIT = 'SET_WIZARD_FORCE_EXIT';
export const SET_CAN_NEXT_TAB = 'SET_CAN_NEXT_TAB';

// Lecturer
export const SET_LECTURER_LIST = 'SET_LECTURER_LIST';
export const SET_LECTURER_LIST_BUSY = 'SET_LECTURER_LIST_BUSY';
export const SET_LECTURER_DETAIL_BUSY = 'SET_LECTURER_DETAIL_BUSY';
export const PUSH_LECTURER_TO_CURRENT_LIST = 'PUSH_LECTURER_TO_CURRENT_LIST';
export const DELETE_LECTURER_FROM_CURRENT_LIST = 'DELETE_LECTURER_FROM_CURRENT_LIST';
export const UPDATE_LECTURER_IN_CURRENT_LIST = 'UPDATE_LECTURER_IN_CURRENT_LIST';

// Legal document
export const SET_LEGAL_DOCUMENTS = 'SET_LEGAL_DOCUMENTS';
export const SET_LEGAL_DOCUMENTS_BUSY = 'SET_LEGAL_DOCUMENTS_BUSY';

// Coupon
export const SET_COUPON_LIST = 'SET_COUPON_LIST';
export const SET_COUPON_LIST_BUSY = 'SET_COUPON_LIST_BUSY';
export const ADD_COUPON_INTO_LIST = 'ADD_COUPON_INTO_LIST';
export const DELETE_COUPON_FROM_LIST = 'DELETE_COUPON_FROM_LIST';
export const UPDATE_COUPON_IN_CURRENT_LIST = 'UPDATE_COUPON_IN_CURRENT_LIST';

// Action Integrations
export const SET_ACTION_INTEGRATIONS_LIST = 'SET_ACTION_INTEGRATIONS_LIST';
export const ADD_ACTION_INTEGRATION = 'ADD_ACTION_INTEGRATION';
export const UPDATE_ACTION_INTEGRATION = 'UPDATE_ACTION_INTEGRATION';
export const REMOVE_ACTION_INTEGRATION = 'REMOVE_ACTION_INTEGRATION';

export const SET_CURRENT_PRODUCT_DATA = 'SET_CURRENT_PRODUCT_DATA';
export const UPDATE_CURRENT_PRODUCT_DATA = 'UPDATE_CURRENT_PRODUCT_DATA';

// Affiliate User
export const SET_AFFILIATE_USER_PRODUCTS = 'SET_AFFILIATE_USER_PRODUCTS';
export const SET_AFFILIATE_USER_COMMISSIONS = 'SET_AFFILIATE_USER_COMMISSIONS';
export const SET_AFFILIATE_USER_PAYMENTS = 'SET_AFFILIATE_USER_PAYMENTS';
export const ADD_AFFILIATE_USER_PAYMENT = 'ADD_AFFILIATE_USER_PAYMENT';
export const DELETE_AFFILIATE_USER_PAYMENT = 'DELETE_AFFILIATE_USER_PAYMENT';
export const UPDATE_AFFILIATE_USER_PAYMENT = 'UPDATE_AFFILIATE_USER_PAYMENT';
export const SET_PROMOTE_COURSE = 'SET_PROMOTE_COURSE';
export const SET_AFFILIATE_USER_DETAIL_ANALYTIC = 'SET_AFFILIATE_USER_DETAIL_ANALYTIC';
export const SET_AFFILIATE_FILTER_DATE = 'SET_AFFILIATE_FILTER_DATE';

// Affiliate Management
export const SET_AFFILIATE_ALL_PRODUCTS = 'SET_AFFILIATE_ALL_PRODUCTS';
export const SET_AFFILIATE_ALL_COMMISSIONS = 'SET_AFFILIATE_ALL_COMMISSIONS';
export const UPDATE_PRODUCT_REQUEST_IN_LIST = 'UPDATE_PRODUCT_REQUEST_IN_LIST';
export const SET_AFFILIATE_USER_DETAIL = 'SET_AFFILIATE_USER_DETAIL';
export const SET_COMMISSION_SELECTED = 'SET_COMMISSION_SELECTED';
export const SET_COMMISSION_PAYMENT_METHOD_SELECTED = 'SET_COMMISSION_PAYMENT_METHOD_SELECTED';
export const SET_WIZARD_AFFILIATE_SETTING = 'SET_WIZARD_AFFILIATE_SETTING';
export const SET_PAYMENT_METHODS_AVAILABLE = 'SET_PAYMENT_METHODS_AVAILABLE';
export const SET_AFFILIATE_USER_ANALYTIC = 'SET_AFFILIATE_USER_ANALYTIC';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';

export const SET_LESSON = 'SET_LESSON';
export const SET_LESSON_AT_SYNC = 'SET_LESSON_AT_SYNC';

// settings
export const SET_API_KEY = 'SET_API_KEY';
export const SET_LIST_DOMAIN = 'SET_LIST_DOMAIN';

// Agency
export const SET_AGENCY_CLIENT_LIST = 'SET_AGENCY_CLIENT_LIST';
export const ADD_CLIENT_INTO_LIST = 'ADD_CLIENT_INTO_LIST';
export const DELETE_CLIENT_FROM_LIST = 'DELETE_CLIENT_FROM_LIST';
export const SET_AGENCY_CLIENT_DETAIL = 'SET_AGENCY_CLIENT_DETAIL';
export const SET_CLIENT_INVOICE_LIST = 'SET_CLIENT_INVOICE_LIST';
export const ADD_INVOICE_INTO_LIST = 'ADD_INVOICE_INTO_LIST';
export const UPDATE_CLIENT_INVOICE_IN_LIST = 'UPDATE_CLIENT_INVOICE_IN_LIST';
export const SET_CLIENT_AGENCY_PROFILE = 'SET_CLIENT_AGENCY_INFO';
export const SET_IS_AGENCY_DOMAIN = 'SET_IS_AGENCY_DOMAIN';
export const SET_CLIENT_OWN_INVOICE_LIST = 'SET_CLIENT_OWN_INVOICE_LIST';
export const SET_CLIENT_PROFILES = 'SET_CLIENT_PROFILES';
export const ADD_CLIENT_PROFILE = 'ADD_CLIENT_PROFILE';
export const DELETE_CLIENT_PROFILE = 'DELETE_CLIENT_PROFILE';
export const UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE';
export const SET_CLIENT_PROFILE = 'SET_CLIENT_PROFILE';
export const SET_PROFILE_ID_INTEGRATION = 'SET_PROFILE_ID_INTEGRATION';
export const SET_PROFILE_INTEGRATION_TYPE = 'SET_PROFILE_INTEGRATION_TYPE';

// partnership
export const SET_PARTNERSHIP_CONTRACT_LIST = 'SET_PARTNERSHIP_CONTRACT_LIST';
export const UPDATE_PARTNERSHIP_CONTRACT_IN_LIST = 'UPDATE_PARTNERSHIP_CONTRACT_IN_LIST';
export const SET_PARTNERSHIP_CONTRACT = 'SET_PARTNERSHIP_CONTRACT';
export const SET_ADMIN_CONTRACT_STATISTIC = 'SET_ADMIN_CONTRACT_STATISTIC';
export const SET_ADMIN_COMMISSION_DATA = 'SET_ADMIN_COMMISSION_DATA';
export const DELETE_PARTNERSHIP_CONTRACT_FROM_LIST = 'DELETE_PARTNERSHIP_CONTRACT_FROM_LIST';
export const ADD_PARTNERSHIP_CONTRACT_INTO_LIST = 'ADD_PARTNERSHIP_CONTRACT_INTO_LIST';
export const SET_OWN_PARTNER_LIST = 'SET_OWN_PARTNER_LIST';
export const SET_OWN_CONTRACT_LIST = 'SET_OWN_CONTRACT_LIST';

export const SET_PARTNERSHIP_CONTRACT_BY_ID = 'SET_PARTNERSHIP_CONTRACT_BY_ID';
export const SET_PARTNERSHIP_CONTRACT_STATISTIC_BY_ID = 'SET_PARTNERSHIP_CONTRACT_STATISTIC_BY_ID';
export const SET_PARTNERSHIP_CONTRACT_TRANSACTION_BY_ID = 'SET_PARTNERSHIP_CONTRACT_TRANSACTION_BY_ID';
export const SET_PARTNERSHIP_CONTRACT_COMMISSION_BY_ID = 'SET_PARTNERSHIP_CONTRACT_COMMISSION_BY_ID';

export const SET_PARTNERSHIP_PAYMENT_METHODS = 'SET_PARTNERSHIP_PAYMENT_METHODS';
export const ADD_PARTNERSHIP_PAYMENT_METHOD = 'ADD_PARTNERSHIP_PAYMENT_METHOD';
export const UPDATE_PARTNERSHIP_PAYMENT_METHOD = 'UPDATE_PARTNERSHIP_PAYMENT_METHOD';
export const DELETE_PARTNERSHIP_PAYMENT_METHOD = 'DELETE_PARTNERSHIP_PAYMENT_METHOD';

// storage statistics
export const SET_MAX_SPACE = 'SET_MAX_SPACE';
export const SET_MEMBERSHIP_STORAGE = 'SET_MEMBERSHIP_STORAGE';
export const SET_FILE_TYPE_STORAGE = 'SET_FILE_TYPE_STORAGE';
export const SET_LIST_LARGEST_FILE = 'SET_LIST_LARGEST_FILE';
export const SET_MEMBERSHIP_IDS = 'SET_MEMBERSHIP_IDS';

export const SET_TEAM_MEMBER_LIST = 'SET_TEAM_MEMBER_LIST';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';

export const SET_USER_SOCKET = 'SET_USER_SOCKET';
