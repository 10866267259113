<template>
  <div class="membership-dashboard">
    <div class="layout justify-space-between align-center">
      <h1>Campaigns ( Team )</h1>
    </div>
    <div class="membership-dashboard__row">
      <template v-if="membershipAtSync">
        <div class="membership-dashboard__item">
          <div class="membership-dashboard__box">
            <div class="membership-dashboard__box-container">
              <div class="box-image">
                <div class="text-loading text-loading-box margin-auto" />
              </div>
              <div class="box-content">
                <div class="text-loading text-loading_sm2" />
                <div class="text-loading text-loading_sm2 mt-10"/>
                <div class="text-loading text-loading__text-full mt-10"/>
              </div>
            </div>
          </div>
        </div>
        <div class="membership-dashboard__item">
          <div class="membership-dashboard__box">
            <div class="membership-dashboard__box-container">
              <div class="box-image">
                <div class="text-loading text-loading-box margin-auto" />
              </div>
              <div class="box-content">
                <div class="text-loading text-loading_sm2" />
                <div class="text-loading text-loading_sm2 mt-10"/>
                <div class="text-loading text-loading__text-full mt-10"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(membership, index) in membershipList"
          :key="index"
          class="membership-dashboard__item"
        >
          <div class="membership-dashboard__box">
            <div class="membership-dashboard__box-container">
              <div
                class="box-image" :style="getMembershipLogoBg(membership)"
                :class="{'system-logo': isSystemLogo(membership)}"
                @click="manageMembership(membership)"
              >
                <div class="box-image__overlay" />
                <v-btn class="custom-btn-secondary btn-padding">
                  Manage Campaign
                </v-btn>
              </div>
              <div class="box-content">
                <p class="primary-text lg semi-bold">
                  {{ membership.title }}
                </p>
                <div class="layout align-center">
                  <p class="flex-text primary-text semi-bold mr-3">
                    <icon-course class="mr-2" />
                    {{ membership.courses ? membership.courses.length : 0 }}
                    courses
                  </p>
                  <p class="flex-text primary-text semi-bold">
                    <icon-user-side class="mr-2" />
                    {{ membership.members ? membership.members.length : 0 }} students
                  </p>
                </div>
                <div class="layout align-center">
                  <span v-if="membership.published" class="badge badge-success">
                    Published
                  </span>
                  <span v-else class="badge badge-warning">
                    Unpublished
                  </span>
                  <v-menu
                    v-if="membership.domain"
                    transition="slide-y-transition"
                    content-class="custom-menu membership__box-menu"
                    open-on-hover
                    offset-y top right>
                    <template v-slot:activator="{ on }">
                      <p
                        class="primary-text semi-bold
                          gray-text-btn
                          membership-dashboard__domain-name"
                        v-on="on"
                      >
                        {{ membership.domain.name }}
                      </p>
                    </template>
                    <div class="custom-action-dropdown membership-dashboard__domain-name__tooltip">
                      <div class="primary-text semi-bold gray-text-btn">
                        {{ membership.domain.name }}
                      </div>
                    </div>
                  </v-menu>
                  <v-spacer />
                  <v-menu
                    transition="slide-y-transition"
                    content-class="custom-menu membership__box-menu"
                    offset-y bottom left>
                    <template v-slot:activator="{ on }">
                      <button
                        class="custom-action-dropdown__btn ml-10 mw-25px"
                        v-on="on"
                      >
                        <icon-more/>
                      </button>
                    </template>
                    <div class="custom-action-dropdown">
                      <button class="dropdown-btn" @click="manageMembership(membership)">
                        <icon-edit class="icon-default" />
                        <span>Manage</span>
                      </button>
                      <button class="dropdown-btn" @click="goToMembershipSetting(membership)">
                        <icon-widget class="icon-default svg-black" />
                        <span>Settings</span>
                      </button>
                      <button class="dropdown-btn" @click="triggerPublish(membership)">
                        <icon-publish v-if="!membership.published" class="icon-default" />
                        <icon-unpublished v-else class="icon-default" />
                        <span>{{ membership.published ? 'Unpublish' : 'Publish'}}</span>
                      </button>
                    </div>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import linkHelper from '@/mixins/linkHelper';
  import membershipApi from '@/api/membership';
  import IconCourse from '@/assets/icons/onboarding/course.svg';
  import ConfirmDialog from '@/components/ConfirmDialog';
  import roleHelpers from '@/mixins/roleHelpers';

  export default {
    components: {
      ConfirmDialog,
      IconCourse,
    },
    mixins: [linkHelper, roleHelpers],
    data() {
      return {
        searchKeyword: '',
        canAddCampaign: {},
        loading: true,
        listBrandingLogo: [
          'platform/membership-logo/logo_1.png',
          'platform/membership-logo/logo_2.png',
          'platform/membership-logo/logo_3.png',
          'platform/membership-logo/logo_4.png',
          'platform/membership-logo/logo_5.png',
          'platform/membership-logo/logo_6.png',
          'platform/membership-logo/logo_7.png',
          'platform/membership-logo/logo_8.png',
          'platform/membership-logo/logo_9.png',
          'platform/membership-logo/logo_10.png',
          'platform/membership-logo/logo_11.png',
          'platform/membership-logo/logo_12.png',
        ],
      };
    },
    computed: {
      ...mapGetters(['membershipList', 'membershipAtSync', 'whoIam']),
    },
    watch: {
      whoIam() {
        this.checkRedirect();
      },
    },
    created() {
      this.fetchTeamMemberMemberships();
    },
    methods: {
      ...mapActions([
        'fetchTeamMemberMemberships',
        'previewLoginJWT',
        'showAlert',
        'openCustomModal',
        'updateMembership',
      ]),
      manageMembership(membership) {
        if (!membership.domain) {
          this.$refs.confirm.open('This membership do not have any domain attached. Do you want to generate a system domain for this membership', {}).then((res) => {
            if (res) {
              membershipApi.generateSystemDomain({
                membershipId: membership.id,
                subDomain: this.generateSubDomain(`${moment().unix()}-${membership.title.trim()}`),
              }).then((domain) => {
                if (domain.id) {
                  const tmp = membership;
                  tmp.domain = domain;
                  this.previewLogin(tmp);
                }
              });
            }
          });
        } else {
          this.previewLogin(membership);
        }
      },
      previewLogin(membership, target = '') {
        this.previewLoginJWT(membership).then((res) => {
          if (res.status === 'success') {
            const membershipUrl = this.membershipUrl(membership);
            let url = `${membershipUrl}/login?jwt=${res.token}`;
            if (target) {
              url = `${url}&target=${target}`;
            }
            window.location.href = url;
          } else if (res.message) {
            this.showAlert(res.message);
          }
        }).catch((err) => {
          this.showAlert(err);
        });
      },
      goToMembershipSetting(membership) {
        this.previewLogin(membership, 'setting');
      },
      triggerPublish(membership) {
        const published = !membership.published;
        this.updateMembership({ id: membership.id, data: { published } })
          .then(() => {
            this.showAlert('Campaign is updated');
          });
      },
      generateSubDomain(val) {
        return val.toLowerCase().replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
      },
      async copyMembership(membership) {
        await this.duplicateMembership(membership.id);
        this.showAlert('Campaign is copied');
      },
      getMembershipLogoBg(membership) {
        let img = '/img/membership_logo_default.png';
        if (membership.media && membership.media.logo) {
          img = this.imageUrl(membership.media.logo);
        }
        return { 'background-image': `url("${img}")` };
      },
      isSystemLogo(membership) {
        if (!membership.media || !membership.media.logo) {
          return false;
        }
        let result = false;
        this.listBrandingLogo.forEach((logo) => {
          if (membership.media.logo.includes(logo)) {
            result = true;
          }
        });
        return result;
      },
    },
  };
</script>
