<template>
  <div class="partnership-main">
    <div class="card">
      <div class="flex-between">
        <div class="primary-text xl semi-bold">Partnership Contracts</div>
        <div>
          <v-btn class="custom-btn-secondary" @click="onCreate">
            Create
          </v-btn>
        </div>
      </div>
      <div class="partnership-content">
        <table class="my-datatable mt-30 last-column-right partnership-table">
          <table-header :headers="headers" @sort="sort" />
          <tbody v-if="dataTable.length">
            <tr v-for="(contract, index) in dataTable" :key="index">
              <td class="my-datatable__avatar-row">
                <div class="flex-start">
                  <div class="avatar-image mr-15" :style="getAvatar(contract.partnership)">
                    <initial-avatar
                      v-if="!contract.partnership.avatar"
                      :name="contract.partnership.name"
                    />
                  </div>
                  <div>
                    <div class="primary-text lg semi-bold">
                      {{ contract.partnership.name }}
                    </div>
                    <div
                      class="primary-text text-dark text-ellipsis"
                    >
                      {{ contract.membership.title }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="primary-text lg text-black">
                {{ moment(contract.createdAt).format('DD MMM, YYYY') }}
              </td>
              <td><active-status :client="contract" /></td>
              <td>
                <v-menu
                  transition="slide-y-transition"
                  content-class="custom-menu membership__box-menu partnership-table__action"
                  offset-y bottom left>
                  <template v-slot:activator="{ on }">
                    <button
                      class="custom-action-dropdown__btn mr-2"
                      v-on="on"
                    >
                      <icon-more class="icon-more" />
                    </button>
                  </template>
                  <div class="custom-action-dropdown">
                    <button
                      class="dropdown-btn flex-start"
                      @click="onView(contract)"
                    >
                      <icon-file class="icon-view-contract" />
                      <span>View Contract</span>
                    </button>
                    <button
                      class="dropdown-btn flex-start"
                      @click="onEdit(contract)"
                    >
                      <icon-edit class="icon-edit" />
                      <span>Edit Contract</span>
                    </button>
                    <button class="dropdown-btn delete" @click="onDelete(contract)">
                      <icon-trash class="icon-default" />
                      <span>Delete</span>
                    </button>
                  </div>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>
<script>
  import moment from 'moment';
  import { mapGetters, mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import CreatePartnership from './components/CreatePartnershipLayout';
  import EditPartnershipContract from './components/EditPartnershipContact';
  import TableHeader from '@/components/table/TableHeader';
  import IconEdit from '@/assets/icons/partnership/edit.svg';
  import ActiveStatus from '@/page/client/ActiveStatus';
  import IconFile from '@/assets/icons/partnership/file.svg';
  import ConfirmDialog from '@/components/ConfirmDialog';

  export default {
    components: {
      ConfirmDialog,
      InitialAvatar,
      TableHeader,
      IconEdit,
      ActiveStatus,
      IconFile,
    },
    mixins: [linkHelper],
    data() {
      return {
        moment,
        prop: 'amount',
        order: 'ASC',
        sortFunction: null,
        headers: [
          {
            label: 'partner',
            field: 'member.name',
            sort: true,
            width: '50%',
          },
          {
            label: 'date created',
            field: 'createdAt',
            sort: true,
            width: '25%',
          },
          {
            label: 'status',
            field: 'status',
            sort: true,
            width: '15%',
          },
          {
            label: 'action',
            textAlign: 'flex-center',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['membershipList']),
      ...mapGetters('partnership', ['partnershipContractList']),
      dataTable() {
        const row = this.partnershipContractList ? [...this.partnershipContractList] : [];
        if (this.sortFunction) {
          row.sort(this.sortFunction);
        }
        return row;
      },
    },
    mounted() {
      this.getPartnershipContracts();
      if (!this.membershipList || this.membershipList.length === 0) {
        this.fetchOwnMemberships();
      }
    },
    methods: {
      ...mapActions('partnership', [
        'getPartnershipContracts',
        'deletePartnershipContract',
      ]),
      ...mapActions([
        'openCustomModal', 'fetchOwnMemberships',
      ]),
      sort(sort) {
        if (sort.order === 'DESC') {
          this.sortFunction = (a, b) => ((a[sort.field] > b[sort.field]) ? 1 : -1);
        } else {
          this.sortFunction = (a, b) => ((b[sort.field] > a[sort.field]) ? 1 : -1);
        }
      },
      getAvatar(member) {
        return member.avatar ? {
          'background-image': `url("${this.imageUrl(member.avatar)}")`,
        } : {};
      },
      onCreate() {
        this.openCustomModal({
          title: 'Create Partnership Contract',
          component: CreatePartnership,
          width: '600px',
          modalClass: ['partnership-modal'],
        });
      },
      onEdit(item) {
        this.openCustomModal({
          title: 'Edit Partnership Contract',
          component: EditPartnershipContract,
          width: '600px',
          modalClass: ['partnership-modal'],
          data: {
            contract: item,
          },
        });
      },
      onView(item) {
        this.$emit('view', item);
      },
      onDelete(item) {
        this.$refs.confirm.open('Are you sure you want to delete this partnership contract?', {}).then((res) => {
          if (res) {
            this.deletePartnershipContract(item.id);
          }
        });
      },
    },
  };
</script>
