<template>
  <div class="domain-setting account-setting__form">
    <div class="domain-setting__title primary-text xl semi-bold">Your Domains</div>
    <div class="domain-setting__description primary-text">
      All domains used by this account among all campaigns.
    </div>
    <div class="domain-setting__table w-100">
      <table class="custom-table w-100">
        <thead>
          <tr>
            <th class="primary-text semi-bold">DOMAIN</th>
            <th class="primary-text semi-bold">
              <div class="status">STATUS</div>
            </th>
            <th class="primary-text semi-bold sm">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listDomain" :key="index">
            <td>
              <div class="primary-text semi-bold label">
                {{ item.membership ? item.membership.title : '-' }}
              </div>
              <div class="primary-text domain text-ellipsis">{{ item.name }}</div>
            </td>
            <td>
              <div class="status">
                <div
                  v-if="item.status === 'connected'"
                  class="status-box primary-text semi-bold verify"
                >Verified</div>
                <div v-else class="status-box primary-text semi-bold un-verify">Unverified</div>
              </div>
            </td>
            <td>
              <v-menu
                transition="slide-y-transition"
                content-class="custom-menu membership__box-menu"
                offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <button
                    class="custom-action-dropdown__btn ml-10"
                    v-on="on"
                  >
                    <icon-option class="icon-option" />
                  </button>
                </template>
                <div class="custom-action-dropdown">
                  <button
                    v-if="item.status !== 'connected'"
                    class="dropdown-btn"
                    @click="onReCheck(item)"
                  >
                    <span>Recheck Domain</span>
                  </button>
                  <button
                    class="dropdown-btn"
                    @click="goToMembershipSetting(item)"
                  >
                    <span class="primary-text">Campaign Settings</span>
                  </button>
                </div>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import ClientAgencyApi from '@/api/clientAgency';
  import linkHelper from '@/mixins/linkHelper';

  export default {
    mixins: [linkHelper],
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters([
        'listDomain',
      ]),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
    },
    async mounted() {
      await this.getListDomain();
    },
    methods: {
      ...mapActions(['getListDomain', 'reCheckDomain', 'showAlert', 'previewLoginJWT']),
      onReCheck(item) {
        this.reCheckDomain(item);
      },
      previewLogin(membership, target = '') {
        if (this.isAgencyDomain) {
          ClientAgencyApi.previewLoginJwt(membership.id).then((res) => {
            if (res.status === 'success') {
              const membershipUrl = this.membershipUrl(membership);
              let url = `${membershipUrl}/login?jwt=${res.token}`;
              if (target) {
                url = `${url}&target=${target}`;
              }
              window.location.href = url;
            } else if (res.message) {
              this.showAlert(res.message);
            }
          }).catch((err) => {
            this.showAlert(err);
          });
        } else {
          this.previewLoginJWT(membership).then((res) => {
            if (res.status === 'success') {
              const membershipUrl = this.membershipUrl(membership);
              let url = `${membershipUrl}/login?jwt=${res.token}`;
              if (target) {
                url = `${url}&target=${target}`;
              }
              window.location.href = url;
            } else if (res.message) {
              this.showAlert(res.message);
            }
          }).catch((err) => {
            this.showAlert(err);
          });
        }
      },
      goToMembershipSetting(domain) {
        this.previewLogin(Object.assign({}, domain.membership, {
          domain,
        }), 'setting');
      },
    },
  };
</script>
