<template>
  <div class="storage-statistics">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'membership_dashboard' })">
        Campaigns
      </a>
      <fa-icon :icon="['fal', 'chevron-right']"/>
      <a class="primary-text active">
        Your Data Usage
      </a>
    </div>

    <div class="storage-statistics__label">
      Storage - {{ parseAvailableSpace }} Available
    </div>

    <div v-if="!isLoading" class="storage-statistics__membership-storage card">
      <membership-storage />
    </div>

    <div v-if="!isLoading" class="storage-statistics__content card">
      <file-type />
      <largest-file />
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import storageHelper from '@/mixins/storage';
  import MembershipStorage from '@/page/storage-statistics/components/MembershipStorage';
  import FileType from '@/page/storage-statistics/components/FileType';
  import LargestFile from '@/page/storage-statistics/components/LargestFile';

  export default {
    components: {
      MembershipStorage,
      FileType,
      LargestFile,
    },
    mixins: [storageHelper],
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters('storage', [
        'availableSpace',
      ]),
      ...mapGetters(['whoIam']),
      parseAvailableSpace() {
        return this.convertByte(this.availableSpace);
      },
    },
    watch: {
      whoIam() {
        this.getAvailableSpace();
      },
    },
    mounted() {
      this.fetchOwnMemberships().then((response) => {
        this.isLoading = false;
        const membershipIds = [];
        response.forEach((el) => {
          membershipIds.push(el.id);
        });
        this.setMembershipIds(membershipIds);
        this.getAvailableSpace();
      });
    },
    methods: {
      ...mapActions([
        'fetchOwnMemberships',
      ]),
      ...mapActions('storage', [
        'getAvailableSpace',
        'setMembershipIds',
      ]),
    },
  };
</script>
