<template>
  <v-container fill-height fluid class="auth">
    <div class="auth__main fill-height">
      <div class="welcome welcome__questions">
        <icon-main-logo class="main-logo"/>
        <h1 v-if="whoIam">Hi {{ whoIam.firstName }}</h1>
        <p class="normal-text">
          Please, answer a few questions to help us get know you better.
        </p>
        <p class="label-text">Are you business owner?</p>
        <custom-select
          v-model="isBusinessOwner"
          :items="yesNoOptions"
          item-title="title"
          item-value="value"
        />
        <template v-if="isBusinessOwner === 'Yes'">
          <p class="label-text">
            Add your business name
          </p>
          <v-text-field
            v-model="businessName"
            v-validate="'required'"
            data-vv-name="name"
            data-vv-as="Business name"
            :error-messages="errors.collect('name')"
            solo
            placeholder="Enter your business name"
            class="default no-error"
          />
        </template>
        <p class="label-text">Where are you located?</p>
        <search-box
          v-model="country"
          :items="countryList"
          item-title="title"
          item-value="value"
          placeholder="Search a country"/>
        <p class="label-text">Are you an agency?</p>
        <custom-select
          v-model="isAgency"
          :items="yesNoOptions"
          item-title="title"
          item-value="value"
        />
        <p class="label-text">Briefly describe your business,
          who do you serve, what do you sell, what’s the price point?</p>
        <v-text-field v-model="businessDescribe" class="default no-error" solo />
        <p class="label-text">How much money are you currently
          making in your business per month?</p>
        <custom-select
          v-model="moneyMake"
          :items="moneyRanges"
          item-title="title"
          item-value="value"
        />
        <p class="label-text">Why did you choose Member App?</p>
        <v-text-field v-model="reasonChoose" class="default no-error" solo />
        <p class="label-text">What other membership software have you used in the past?</p>
        <v-text-field v-model="otherMembershipHasUsed" class="default no-error" solo />
        <v-btn
          class="custom-btn-secondary full-width"
          :loading="loading"
          @click="submit"
        >Enter Member.us Account</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomSelect from '@/components/select/CustomSelect';
  import SearchBox from '@/components/select/SearchBox';
  import userApi from '@/api/user';

  export default {
    components: {
      CustomSelect,
      SearchBox,
    },
    data() {
      return {
        loading: false,
        businessName: '',
        isAgency: '',
        moneyRanges: [
          {
            title: '$100 - $1000',
            value: '$100 - $1000',
          },
          {
            title: '$1000 - $5000',
            value: '$1000 - $5000',
          },
          {
            title: '$5000 - $20,000',
            value: '$5000 - $20,000',
          },
          {
            title: '$20,000+',
            value: '$20,000+',
          },
        ],
        yesNoOptions: [
          {
            title: 'Yes',
            value: 'Yes',
          },
          {
            title: 'No, just exploring',
            value: 'No',
          },
        ],
        identifyOptions: [
          {
            title: 'Content creator',
            value: 'Content creator',
          },
          {
            title: 'Teacher',
            value: 'Teacher',
          },
          {
            title: 'Marketeers',
            value: 'Marketeers',
          },
        ],
        topicOptions: [
          {
            title: 'UX/UI Design',
            value: 'UX/UI Design',
          },
          {
            title: 'Education',
            value: 'Education',
          },
        ],
        revenueStageOptions: [
          {
            title: 'Prefer not to answer',
            value: 'Prefer not to answer',
          },
          {
            title: 'Funding',
            value: 'Funding',
          },
        ],
        moneyMake: '',
        businessDescribe: '',
        isBusinessOwner: '',
        country: '',
        otherMembershipHasUsed: '',
        reasonChoose: '',
      };
    },
    computed: {
      ...mapGetters([
        'whoIam', 'hasCountryToken', 'countryList',
      ]),
    },
    created() {
      if (!this.hasCountryToken) {
        this.fetchCountryToken();
      } else {
        this.getCountryListData();
      }
    },
    methods: {
      ...mapActions([
        'fetchCountryToken', 'getCountryListData',
      ]),
      submit() {
        this.loading = true;
        userApi.submitWelcomeQuestion({
          businessName: this.businessName,
          businessDescribe: this.businessDescribe,
          country: this.country,
          isBusinessOwner: this.isBusinessOwner,
          isAgency: this.isAgency,
          moneyMake: this.moneyMake,
          reasonChoose: this.reasonChoose,
          otherMembershipHasUsed: this.otherMembershipHasUsed,
        }).then(() => {
          this.loading = false;
          localStorage.removeItem('welcomePage');
          this.$router.push({ name: 'membership_dashboard' });
        });
      },
    },
  };
</script>
