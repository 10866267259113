const subscriptionLevels = {
  ANNUAL: 1,
  FREE: 2,
  MONTHLY: 3,
  ONETIME: 4,
  CHOOSE: 5,
  SPLIT: 6,
};

export default subscriptionLevels;
