export default {
  updateById: (memberId, data) => new Promise((resolve, reject) => {
    window.http.put(`/members/${memberId}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  fetchById: memberId => new Promise((resolve, reject) => {
    window.http.get(`/members/${memberId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  delete: memberId => new Promise((resolve, reject) => {
    window.http.delete(`/members/${memberId}`).then(
      () => { resolve(); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getMemberList: filter => new Promise((resolve, reject) => {
    window.http.get('/members/list', { params: filter }).then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  getTransactions: memberId => new Promise((resolve, reject) => {
    window.http.get(`/members/${memberId}/transactions`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  revokeCourseAccess: params => new Promise((resolve, reject) => {
    window.http.post('/members/revoke-course-access', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  createMultipleMember: params => new Promise((resolve, reject) => {
    window.http.post('/members/create-multi', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updateMultiple: params => new Promise((resolve, reject) => {
    window.http.post('/members/update-multi', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  createAndUpdateMultiple: params => new Promise((resolve, reject) => {
    window.http.post('/members/create-and-update-multi', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  deleteMultiple: memberIds => new Promise((resolve, reject) => {
    window.http.post('/members/delete-multi', { memberIds }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  markNewStudentSeen: () => new Promise((resolve, reject) => {
    window.http.post('members/mark-seen-new-student/')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
