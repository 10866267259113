<template>
  <!--
  <span class="custom-icon">
    <span class="custom-icon-container">
    -->
  <span/>
  <!--
    </span>
  </span>
-->
</template>


<script>
  export default {
    props: {
      icon: {
        default: '',
        type: String,
      },
      color: {
        default: '',
        type: String,
      },
      customClass: {
        default: '',
        type: String,
      },
    },
    data: () => ({
      isSVGReady: false,
      inlinedSVG: null,
      existingClasses: [],

    }),
    computed: {
      filepath() {
        return `/img/icons/${this.icon}.svg`;
      },
    },
    watch: {
      customClass(newValue) {
        const [inlinedSVG] = this.$el.getElementsByTagName('svg');
        // const [inlinedSVG] = this.$el.querySelectorAll('svg');
        const newArr = newValue.split(' ');
        const classArr = (newArr.concat(this.existingClasses)).filter(el => el);
        const removeArr = [];

        inlinedSVG.classList.forEach((cl) => {
          if (classArr.indexOf(cl) === -1) {
            removeArr.push(cl);
          }
        });

        if (removeArr.length) {
          inlinedSVG.classList.remove(...removeArr);
        }

        if (classArr.length) {
          inlinedSVG.classList.add(...classArr);
        }
      },
    },
    mounted() {
      this.generateInlineSVG();
    },
    methods: {


      /* remove fill and stroke style declarations
     in the each path to enable color control */
      removeFillStrokeStyles(inlinedSVG) {
        const elements = Array.from(inlinedSVG.getElementsByTagName('*'));
        elements.forEach((element, i) => {
          // for (let i = 0; i < elements.length; i++) {
          if (elements[i].style) {
            const { fill } = elements[i].style;
            if (fill && fill !== 'none') {
              elements[i].style.fill = '';
            }
            const { stroke } = elements[i].style;
            if (stroke && stroke !== 'none') {
              elements[i].style.stroke = '';
            }
          }
          // }
        });
      },

      /* load a svg image with xml http request to get
    // an inlined svg and append it to this component */
      generateInlineSVG() {
        const context = this;

        // reset first
        this.isSVGReady = false;
        // let svgElement = this.$el.getElementsByTagName('svg')[0]
        // if (svgElement) {
        //   this.$el.removeChild(svgElement)
        // }

        // Get the contents of the SVG
        const request = new XMLHttpRequest();
        request.open('GET', this.filepath, true);
        request.onload = () => {
          if (request.status >= 200 && request.status < 400) {
            // Setup a dom parser to convert the response to text/xml in order for it
            // to be manipulated and changed
            const domParser = new DOMParser();
            const result = domParser.parseFromString(request.responseText, 'text/xml');
            const inlinedSVG = result.getElementsByTagName('svg')[0];

            if (!inlinedSVG) {
              // console.error('No svg element found. Did you pass a valid .svg file?')
              return;
            }
            // const styleElement = inlinedSVG.getElementsByTagName('style')[0];


            // remove fill and stroke style declarations
            // in the each path to enable color control
            context.removeFillStrokeStyles(inlinedSVG);

            // Remove some of the attributes that aren't needed
            inlinedSVG.removeAttribute('xmlns:a');
            inlinedSVG.removeAttribute('width');
            inlinedSVG.removeAttribute('height');
            inlinedSVG.removeAttribute('x');
            inlinedSVG.removeAttribute('y');
            inlinedSVG.removeAttribute('enable-background');
            inlinedSVG.removeAttribute('xmlns:xlink');
            inlinedSVG.removeAttribute('xml:space');
            inlinedSVG.removeAttribute('version');

            if (context.id) inlinedSVG.id = context.id;
            inlinedSVG.style.width = context.width;
            inlinedSVG.style.height = context.height;
            inlinedSVG.style.fill = context.fill;
            inlinedSVG.style.stroke = context.stroke;
            inlinedSVG.classList.add('custom-icon-image'); // add an additional class
            this.existingClasses.push('custom-icon-image');
            // context.$el.appendChild(inlinedSVG)
            // context.$el.classList.forEach(
            //   (cls) => {
            //     inlinedSVG.classList.add(cls);
            //   },
            // );
            // context.$el.replaceWith(inlinedSVG);
            context.$el.appendChild(inlinedSVG);
            this.inlinedSVG = inlinedSVG;
            // now the svg is ready to show
            this.isSVGReady = true;
            context.$emit('ready');
          } else {
          // console.error('There was an error retrieving the source of the SVG.')
          }
        };

        request.onerror = () => {
        // console.error('There was an error connecting to the origin server.')
        };

        request.send();
      },


    },
  };
</script>
