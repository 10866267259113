<template>
  <div class="date-range-picker__container">
    <div v-if="activeStatus && needCustomDate"
         class="date-range-picker__overlay" @click="this.updateData"/>
    <custom-select
      v-model="currentDateOption"
      :custom-holder="needCustomDate"
      :items="availableOptions"
      :disabled="disabled"
      item-title="title"
      item-value="value"
    >
      <span>{{ formatDate(startDate) }} - {{ formatDate(endDate) }}</span>
      <icon-dropdown class="dropdown" />
    </custom-select>
    <v-slide-y-transition>
      <div
        v-if="activeStatus && needCustomDate"
        :class="`date-range-picker__calendar ${customClass} ${right ? 'right-position' : ''}`"
      >
        <div class="date-picker">
          <v-date-picker
            v-model="startDate"
            :weekday-format="getDay"
            class="date-range-picker" color="light-blue"
            event-color="light-blue lighten-4 date-in-range"
            :events="selectedRange"
            :show-current="false"
            no-title/>
        </div>
        <div class="date-picker">
          <v-date-picker
            v-model="endDate"
            :weekday-format="getDay"
            class="date-range-picker" color="light-blue"
            event-color="light-blue lighten-4 date-in-range"
            :events="selectedRange"
            :show-current="false"
            no-title/>
        </div>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
  import moment from 'moment';
  import CustomSelect from './CustomSelect';
  import restrictRange from '@/config/restrictRange';

  export default {
    components: {
      CustomSelect,
    },
    props: {
      restrictRange: {
        type: String,
        default: '',
      },
      value: {
        type: Object,
        default: () => {},
      },
      right: {
        type: Boolean,
        default: false,
      },
      customClass: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      changeDateOption: {
        type: String,
        default: '',
      },
      noOneDaySelect: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        val: [],
        currentDateOption: 'This week',
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week'),
        activeStatus: false,
        options: [
          {
            title: 'Today',
            value: 'Today',
          },
          {
            title: 'Yesterday',
            value: 'Yesterday',
          },
          {
            title: 'This week',
            value: 'This week',
          },
          {
            title: 'Last week',
            value: 'Last week',
          },
          {
            title: 'This month',
            value: 'This month',
          },
          {
            title: 'Last month',
            value: 'Last month',
          },
          {
            title: 'Last 3 months',
            value: 'Last 3 months',
          },
          {
            title: 'Last 6 months',
            value: 'Last 6 months',
          },
          {
            title: 'Year to date',
            value: 'Year to date',
          },
          {
            title: 'All time',
            value: 'All time',
          },
          {
            title: 'Custom',
            value: 'Custom',
          },
        ],
      };
    },
    computed: {
      needCustomDate() {
        return this.currentDateOption === 'Custom';
      },
      availableOptions() {
        if (!this.restrictRange) {
          return this.options;
        }

        return restrictRange[this.restrictRange];
      },
    },
    watch: {
      activeStatus: {
        handler(value) {
          if (!value) {
            this.initDate();
          }
        },
        immediate: true,
      },
      currentDateOption(val) {
        if (!val) {
          return;
        }

        this.updateDateOption(val);
      },
      changeDateOption(val) {
        if (val) {
          this.currentDateOption = val;
        }
      },
    },
    created() {
      this.initDate();
      this.updateData();
    },
    methods: {
      updateDateOption(val) {
        switch (val) {
        case 'Today':
          if (this.noOneDaySelect) {
            this.startDate = moment().subtract(6, 'days');
            this.endDate = moment();
          } else {
            this.startDate = moment();
            this.endDate = moment();
          }
          break;
        case 'Yesterday':
          if (this.noOneDaySelect) {
            this.startDate = moment().subtract(6, 'days');
            this.endDate = moment();
          } else {
            this.startDate = moment().subtract(1, 'days');
            this.endDate = moment().subtract(1, 'days');
          }
          break;
        case 'This week':
          this.startDate = moment().startOf('week');
          this.endDate = moment().endOf('week');
          break;
        case 'Last week':
          this.startDate = moment().subtract(1, 'weeks').startOf('week');
          this.endDate = moment().subtract(1, 'weeks').endOf('week');
          break;
        case 'This month':
          this.startDate = moment().startOf('month');
          this.endDate = moment().endOf('month');
          break;
        case 'Last month':
          this.startDate = moment().subtract(1, 'months').startOf('month');
          this.endDate = moment().subtract(1, 'months').endOf('month');
          break;
        case 'Last 3 months':
          this.startDate = moment().subtract(2, 'months').startOf('month');
          this.endDate = moment().endOf('month');
          break;
        case 'Last 6 months':
          this.startDate = moment().subtract(5, 'months').startOf('month');
          this.endDate = moment().endOf('month');
          break;
        case 'Year to date':
          this.startDate = moment().startOf('year');
          this.endDate = moment();
          break;
        case 'This year':
          this.startDate = moment().startOf('year');
          this.endDate = moment().endOf('year');
          break;
        case 'All time':
          this.startDate = '';
          this.endDate = '';
          break;
        case 'Custom':
          this.startDate = this.startDate.format('YYYY-MM-DD');
          this.endDate = this.endDate.format('YYYY-MM-DD');
          this.activeStatus = true;
          break;
        default:
          this.startDate = moment();
          this.endDate = moment();
        }
        if (val !== 'Custom') {
          this.updateData();
        }
      },
      reset() {
        this.currentDateOption = '';
      },
      getDay(date) {
        const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        const i = new Date(date).getDay(date);
        return daysOfWeek[i];
      },
      selectedRange(date) {
        if (this.startDate && this.endDate) {
          return moment(date).isBetween(...[this.startDate, this.endDate]);
        }
        return '';
      },
      initDate() {
        if (this.value && this.value.start && this.value.end) {
          this.startDate = moment(this.value.start).format('YYYY-MM-DD');
          this.endDate = moment(this.value.end).format('YYYY-MM-DD');
        } else if (this.noOneDaySelect) {
          this.currentDateOption = 'Today';
        } else {
          this.currentDateOption = this.changeDateOption;
        }
        this.updateDateOption(this.currentDateOption);
      },
      formatDate(date) {
        if (date === '') {
          return '';
        }
        return moment(date).format('Do MMM');
      },
      updateData() {
        let start = '';
        let end = '';
        if (this.startDate && this.endDate) {
          start = moment.utc(this.startDate).startOf('day');
          end = moment.utc(this.endDate).endOf('day');
        }
        this.$emit('input', { start, end });
        this.$emit('change', { option: this.currentDateOption });
        this.activeStatus = false;
      },
    },
  };
</script>
