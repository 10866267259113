<template>
  <div class="partnership__custom-table commission">
    <div
      v-if="partnerContractCommission"
      class="mt-20 mb-20 partnership-admin__content__sale-statistic__box flex-between mt-20">
      <div
        v-for="(item, index) in listOverviewOption"
        :key="index"
        class="sale-statistic__item start">
        <div>
          <div class="text-uppercase primary-text sm dark mb-1">{{ item.label }}</div>
          <div class="primary-text xl bold">
            <span>{{ item.prefix }}</span>
            <span>{{ partnerContractCommission.sumary[item.value]
              ? partnerContractCommission.sumary[item.value] : 0 }}</span>
            <span>{{ item.suffix }}</span>
          </div>
        </div>
      </div>
    </div>

    <table class="my-datatable custom last-column-right partnership-table mt-3">
      <table-header :headers="headers" @sort="sortData" />
      <tbody>
        <tr v-for="(item, index) in dataTable" :key="index">
          <td class="primary-text lg semi-bold">{{ item.courses[0].title }}</td>
          <td>
            <div class="flex-start">
              <div
                class="status-box primary-text semi-bold"
                :class="[item.isPaid ? 'active' : 'pending']">
                {{ item.isPaid ? 'Paid' : 'Due' }}
              </div>
            </div>
          </td>
          <td class="primary-text lg dark">
            {{ moment(item.createdAt).format('DD MMM, YYYY') }}
          </td>
          <td>
            <div>
              <div class="primary-text xl semi-bold">${{ item.amountUsd }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import moment from 'moment';
  import _cloneDeep from 'lodash.clonedeep';
  import { mapGetters } from 'vuex';
  import TableHeader from '@/components/table/TableHeader';

  export default {
    components: {
      TableHeader,
    },
    data() {
      return {
        moment,
        listOverviewOption: [
          {
            label: 'Due',
            value: 'due',
            prefix: '$',
            suffix: '',
          },
          {
            label: 'Upcoming',
            value: 'upcoming',
            prefix: '$',
            suffix: '',
          },
        ],
        prop: 'createdAt',
        order: 'ASC',
        headers: [{
          label: 'course',
          sort: true,
          field: 'course',
          width: '40%',
        }, {
          label: 'status',
          sort: true,
          field: 'status',
        }, {
          label: 'date',
          sort: true,
          field: 'createdAt',
        }, {
          label: 'amount',
          sort: true,
          field: 'amount',
        }],
      };
    },
    computed: {
      ...mapGetters('partnership', [
        'partnerContractCommission',
      ]),
      dataTable() {
        const data = this.partnerContractCommission
          ? _cloneDeep(this.partnerContractCommission.commissions) : [];
        return data && data.length > 0 ? data
          .sort((a, b) => {
            const tmp1 = this.order === 'ASC' ? _cloneDeep(a) : _cloneDeep(b);
            const tmp2 = this.order === 'ASC' ? _cloneDeep(b) : _cloneDeep(a);
            if (this.prop === 'course') {
              return tmp1.courses[0].title.toString()
                .localeCompare(tmp2.courses[0].title.toString());
            }
            if (this.prop === 'status') {
              return tmp1[this.prop].toString().localeCompare(tmp2[this.prop].toString());
            }
            if (this.prop === 'createdAt') {
              return new Date(tmp1[this.prop]).getTime() - new Date(tmp2[this.prop]).getTime();
            }
            return tmp1[this.prop] - tmp2[this.prop];
          }) : [];
      },
    },
    methods: {
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
    },
  };
</script>
