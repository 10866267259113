<template>
  <div class="account-setting__form">
    <div class="layout align-center justify-space-between">
      <p class="primary-text xl semi-bold">Payment Methods</p>
    </div>
    <div class="layout mt-20 align-center">
      <icon-stripe-normal class="mr-3" />
      <p v-if="stripeAccount" class="primary-text lg">
        Connected account:
        <span class="primary-text dark-text">{{ stripeAccount.stripeUserId }}</span>
        <a class="primary-text violet-text ml-2" @click="disconnectStripe">Disconnect</a>
      </p>
      <v-btn v-else class="custom-btn-primary" @click="connectStripe">Connect</v-btn>
    </div>
    <div class="layout mt-20 align-center">
      <icon-paypal-text class="mr-3" />
      <p v-if="paypalConnected" class="primary-text lg">
        <a class="primary-text violet-text ml-2" @click="disconnectPaypal">Disconnect</a>
      </p>
      <v-btn v-else class="custom-btn-primary" @click="connectPaypal">Connect</v-btn>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import _cloneDeep from 'lodash.clonedeep';
  import PaymentModal from './PaymentModal';
  import PaypalModal from './PaypalModal';
  import ConfirmDialog from '@/components/ConfirmDialog';

  export default {
    components: {
      ConfirmDialog,
    },
    props: ['clientProfile'],
    computed: {
      ...mapGetters(['userDataAtSync', 'whoIam']),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
      stripeAccount() {
        if (this.clientProfile) {
          return this.clientProfile.stripeAccount ? this.clientProfile.stripeAccount : null;
        }
        return this.whoIam.stripeAccount;
      },
      paypalConnected() {
        return this.whoIam.integrations ? !!this.whoIam.integrations.paypal.client_id : false;
      },
    },
    methods: {
      ...mapActions(['deauthorizeStripe', 'openCustomModal', 'closeModal', 'unlinkPaypalApp', 'updateIntegrations']),
      addPaymentMethod() {
        this.openCustomModal({
          title: 'Add Payment Method',
          component: PaymentModal,
          maxWidth: '600px',
          width: '600px',
          modalClass: 'membership-form',
        });
      },
      connectStripe() {
        if (this.whoIam) {
          let url = `${process.env.VUE_APP_API_BASE_URL}/stripe/authorize/${this.whoIam.id}`;
          if (this.clientProfile) {
            url = `${url}?clientProfileId=${this.clientProfile.id}`;
          } else if (this.isAgencyDomain) {
            url = `${url}?clientProfileId=${this.whoIam.clientProfile.id}`;
          }
          window.location.href = url;
        }

        return null;
      },
      disconnectStripe() {
        this.deauthorizeStripe(this.stripeAccount.stripeUserId).then(() => {
          window.location.reload();
        });
      },
      connectPaypal() {
        const user = _cloneDeep(this.whoIam);
        this.openCustomModal({
          title: 'Connect Paypal',
          component: PaypalModal,
          maxWidth: '430px',
          width: '430px',
          data: {
            integration: user.integrations.paypal,
            icon: '/img/tplogos/paypal_lg.svg',
            onSuccessCallback: () => {
              this.closeModal();
            },
          },
          modalClass: 'account-setting__integration-modal',
        });
      },
      disconnectPaypal() {
        this.$refs.confirm.open('Are you sure you want to disconnect Pay Pal integration?', {}).then(async (res) => {
          if (res) {
            await this.unlinkPaypalApp();
            const { integrations } = this.whoIam;
            const data = integrations.paypal;

            data.active = false;
            data.api_key = '';
            data.api_url = '';

            integrations.paypal = data;
            const params = {
              integrations,
              key: 'paypal',
              data,
            };
            this.updateIntegrations({ integrations: params.integrations });
          }
        });
      },
    },
  };
</script>
