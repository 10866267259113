<template>
  <div class="campaign-selector">
    <p class="label-text">Select Campaign</p>
    <custom-select
      v-model="campaignSelected"
      :items="membershipSelect"
      item-title="title"
      item-value="id"
      @change="onChanged"
    />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import CustomSelect from '@/components/select/CustomSelect';

  export default {
    components: {
      CustomSelect,
    },
    props: ['value'],
    data() {
      return {
        campaignSelected: undefined,
      };
    },
    computed: {
      ...mapGetters(['membershipList']),
      membershipSelect() {
        return this.membershipList.filter(m => !m.partnershipMembership)
          .map(m => ({ id: m.id, title: m.title }));
      },
    },
    mounted() {
      this.campaignSelected = this.value;
    },
    methods: {
      onChanged() {
        this.$emit('input', this.campaignSelected);
      },
    },
  };
</script>
