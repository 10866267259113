import * as types from '@/store/mutation-types';

const state = {
  modal: {
    open: false,
    customOpen: false, // for custom modal to prevent bug with v-dialog
    component: null,
    title: 'Some modal',
    timeout: 3000,
    componentProps: {},
    maxWidth: '30%',
    width: '100%',
    transition: 'fade-transition',
    class: '',
  },
  wizard: {
    mode: 'new', // 'new', 'edit'
  },
  alert: false,
  alertContent: '',
  alertType: 'success',
  alertTitle: '',
  alertTimeout: 3000,
  showFooterMenu: false,
  showChangeAccountMenu: false,
  // cached rates, but we update this cache everytime
  rates: {
    AED: 3.67,
    ARS: 70.49,
    AUD: 1.44,
    BGN: 1.73,
    BRL: 5.31,
    BSD: 1,
    CAD: 1.36,
    CHF: 0.944,
    CLP: 812.22,
    CNY: 7.07,
    COP: 3710.41,
    CZK: 23.59,
    DKK: 6.6,
    DOP: 58.26,
    EGP: 16.1,
    EUR: 0.888,
    FJD: 2.18,
    GBP: 0.8,
    GTQ: 7.7,
    HKD: 7.75,
    HRK: 6.7,
    HUF: 312,
    IDR: 14255.54,
    ILS: 3.45,
    INR: 74.88,
    ISK: 138.26,
    JPY: 107.45,
    KRW: 1199.44,
    KZT: 406.11,
    MXN: 22.59,
    MYR: 4.29,
    NOK: 9.49,
    NZD: 1.54,
    PAB: 1,
    PEN: 3.52,
    PHP: 49.71,
    PKR: 166.25,
    PLN: 3.96,
    PYG: 6802.42,
    RON: 4.28,
    RUB: 70.48,
    SAR: 3.75,
    SEK: 9.29,
    SGD: 1.39,
    THB: 31.05,
    TRY: 6.85,
    TWD: 29.47,
    UAH: 26.98,
    USD: 1,
    UYU: 42.08,
    ZAR: 16.96,
  },
};

const getters = {
  rates: state => state.rates,
  modalTimeout: state => state.modal.timeout,
  modalOpen: state => state.modal.open,
  modalCustomOpen: state => state.modal.customOpen,
  modalTitle: state => state.modal.title,
  modalComponent: state => state.modal.component,
  modalComponentProps: state => state.modal.componentProps,
  modalMaxWidth: state => state.modal.maxWidth,
  modalWidth: state => state.modal.width,
  modalClass: state => state.modal.class,
  modalTransition: state => state.modal.transition,
  wizardMode: state => state.wizard.mode,
  alertState: state => state.alert,
  alertType: state => state.alertType,
  alertTitle: state => state.alertTitle,
  alertMessage: state => state.alertContent,
  isProd: () => process.env.VUE_APP_NODE_ENV === 'production',
  alertTimeout: state => state.alertTimeout,
  showFooterMenu: state => state.showFooterMenu,
  showChangeAccountMenu: state => state.showChangeAccountMenu,
};

const actions = {
  setRates({ commit }, payload) {
    commit(types.SET_RATES, payload);
  },
  openModal({ commit }, {
    component, title, data, maxWidth, width, transition, modalClass,
  }) {
    commit(types.SET_MODAL_STATE, {
      open: true,
      title,
      component,
      componentProps: data,
      maxWidth: maxWidth || window.mobilecheck() ? '100%' : '38%',
      width: width || '100%',
      transition: transition || 'fade-transition',
      class: modalClass || '',
    });
  },
  openCustomModal({ commit }, {
    component, title, data, maxWidth, width, transition, modalClass,
  }) {
    commit(types.SET_MODAL_STATE, {
      customOpen: true,
      title,
      component,
      componentProps: data,
      maxWidth: maxWidth || window.mobilecheck() ? '100%' : '38%',
      width: width || '100%',
      transition: transition || 'dialog-transition',
      class: modalClass || '',
    });
  },
  closeModal({ commit }) {
    commit(types.SET_MODAL_STATE, {
      open: false,
      component: null,
      componentProps: {},
    });
  },
  setWizardMode({ commit }, payload) {
    commit(types.SET_WIZARD_MODE, payload);
  },
  showAlert({ commit }, payload) {
    commit(types.SET_ALERT_TIMEOUT, payload && payload.timeout ? payload.timeout : 3000);
    commit(types.SET_ALERT_TITLE, payload && payload.title ? payload.title : '');
    commit(types.SET_ALERT_TYPE, payload && payload.type ? payload.type : 'success');
    commit(types.SET_ALERT_STATE, true);

    if (typeof payload === 'object') {
      commit(types.SET_ALERT_MESSAGE, payload.message);
    } else {
      commit(types.SET_ALERT_MESSAGE, payload);
    }
  },
  closeAlert({ commit }) {
    commit(types.SET_ALERT_STATE, false);
  },
  toggleFooterMenu({ commit, state }) {
    commit(types.SET_FOOTER_MENU, !state.showFooterMenu);
  },
  toggleChangeAccountMenu({ commit, state }) {
    commit(types.SET_CHANGE_ACCOUNT_MENU, !state.showChangeAccountMenu);
  },
};

const mutations = {
  [types.SET_RATES](state, payload) {
    state.rates = payload;
  },
  [types.SET_MODAL_STATE](state, payload) {
    state.modal = payload;
  },
  [types.SET_WIZARD_MODE](state, payload) {
    state.wizard.mode = payload;
  },
  [types.SET_ALERT_STATE](state, payload) {
    state.alert = payload;
  },
  [types.SET_ALERT_TYPE](state, payload) {
    state.alertType = payload;
  },
  [types.SET_ALERT_TITLE](state, payload) {
    state.alertTitle = payload;
  },
  [types.SET_ALERT_MESSAGE](state, payload) {
    state.alertContent = payload;
  },
  [types.SET_ALERT_TIMEOUT](state, payload) {
    state.alertTimeout = payload;
  },
  [types.SET_FOOTER_MENU](state, payload) {
    state.showFooterMenu = payload;
  },
  [types.SET_CHANGE_ACCOUNT_MENU](state, payload) {
    state.showChangeAccountMenu = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
