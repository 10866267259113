import * as types from '@/store/mutation-types';
import Stripe from '@/api/stripe';

const state = {
  paymentIntents: [],
  paymentIntent: {},
  stripe: null,
  elements: null,
};

const getters = {
  paymentIntents: state => state.paymentIntents,
  paymentIntent: state => state.paymentIntent,
  stripe: state => state.stripe,
  elements: state => state.elements,
};

const mutations = {
  [types.STRIPE_PAYMENT_INTENT_CODE](state, data) {
    state.paymentIntent = data;
  },
  [types.STRIPE_PAYMENT_INTENT_CREATE]() {

  },
  [types.STRIPE_FETCH_INVOICES](state, data) {
    state.paymentIntents = data;
  },
  [types.STRIPE_LOAD](state, data) {
    state.stripe = data;
    state.elements = data.elements();
  },

};

const actions = {

  paymentIntentCode: ({ commit }, code) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    Stripe.paymentIntentCode(code).then(
      (res) => {
        commit(types.SET_USER_DATA_BUSY, false);
        commit(types.STRIPE_PAYMENT_INTENT_CODE, res);
        resolve(res);
      },
    ).catch(
      (error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        reject(error);
      },
    );
  }),

  paymentIntentCreate: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    Stripe.paymentIntentCreate(data).then(
      (res) => {
        commit(types.SET_USER_DATA_BUSY, false);
        // commit(types.STRIPE_PAYMENT_INTENT_CREATE, res);
        dispatch('showAlert', 'An invoice was created.');
        resolve(res);
      },
    ).catch(
      (error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'Failed to create invoice.');
        reject(error);
      },
    );
  }),


  fetchInvoices: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    Stripe.fetchInvoices().then(
      (res) => {
        commit(types.SET_USER_DATA_BUSY, false);
        commit(types.STRIPE_FETCH_INVOICES, res);
        dispatch('showAlert', 'The invoice list was fetched.');
        resolve(res);
      },
    ).catch(
      (error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'Failed to fetch the invoice list.');
        reject(error);
      },
    );
  }),


  sendInvoice: ({ commit, dispatch }, paymentIntentId) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    Stripe.sendInvoice(paymentIntentId).then(
      (res) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'An invoice email was sent to the client.');
        resolve(res);
      },
    ).catch(
      (error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'Failed to send an email invoice to the client.');
        reject(error);
      },
    );
  }),


  deauthorizeStripe: ({ commit, dispatch }, stripeUserId) => new Promise((resolve, reject) => {
    commit(types.SET_USER_DATA_BUSY, true);
    Stripe.deauthorize(stripeUserId).then(
      (res) => {
        dispatch('showAlert', 'You have successfully disconnected your stripe account.');
        dispatch('syncMe');
        resolve(res);
      },
    ).catch(
      (error) => {
        commit(types.SET_USER_DATA_BUSY, false);
        dispatch('showAlert', 'Failed to disconnect your stripe account.');
        reject(error);
      },
    );
  }),


  loadStripe: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    try {
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        setTimeout(() => {
          const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
          commit(types.STRIPE_LOAD, stripe);
          dispatch('showAlert', 'StripeJS was successfully loaded.');
          resolve(stripe);
        }, 0);
      };
      stripeJs.onerror = (err) => {
        reject(err);
      };
      if (
        typeof document === 'undefined'
        || !document
        || typeof document.body === 'undefined'
        || !document.body
      ) {
        reject(new Error('document is not defined'));
      } else {
        document.body.appendChild(stripeJs);
      }
    } catch (error) {
      reject(error);
    }
  }),

};

export default {
  state,
  getters,
  mutations,
  actions,
};
