<template>
  <layout>
    <template v-slot:title>
      <h2 class="auth__box-title">Login to your account</h2>
    </template>
    <p class="label-text">Your email address</p>
    <v-text-field
      v-model="formData.email"
      v-validate.disable="'required|email'"
      :error-messages="errors.collect('Email')"
      data-vv-name="Email"
      solo
      placeholder="Enter your Email or Username"
      @keyup.enter="submit"/>
    <div class="d-flex">
      <p class="label-text">Your password</p>
      <v-spacer/>
      <router-link :to="{ name: 'reset-password' }" class="auth__forgot-password">
        Forgot Password ?
      </router-link>
    </div>
    <v-text-field
      v-model="formData.password"
      v-validate.disable="'required'"
      class="auth__password"
      :error-messages="errors.collect('Password')"
      data-vv-name="Password"
      solo
      :type="passwordType"
      placeholder="Enter the Password"
      @keyup.enter="submit">
      <template v-slot:append>
        <div v-if="passwordType === 'password'"
             class="auth__icon" @click="passwordType = 'text'">
          <icon-preview/>
        </div>
        <div v-if="passwordType === 'text'"
             class="auth__icon" @click="passwordType = 'password'">
          <icon-hide/>
        </div>
      </template>
    </v-text-field>
    <custom-checkbox v-model="rememberMe" custom-class="auth__checkbox">
      Remember me
    </custom-checkbox>
    <v-btn
      block
      color="secondary"
      class="mt-0 mb-0 auth__button"
      :loading="loading"
      @click="submit">
      Log in
    </v-btn>
  </layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import quotes from '@/config/quotes';
  import validationHelpers from '@/mixins/validationHelpers';
  import CustomCheckbox from '@/components/shared/CustomCheckBox';
  import Layout from '@/layout/Auth';
  import UserApi from '@/api/user';
  import createConnection from '@/socket';

  export default {
    $_veeValidate: { validator: 'login' },
    components: {
      Layout,
      CustomCheckbox,
    },
    mixins: [validationHelpers],
    data() {
      return {
        passwordType: 'password',
        rememberMe: false,
        loading: false,
        staffActiveToken: '',
        mainAccount: '',
        formData: {
          email: '',
          password: '',
        },
      };
    },

    computed: {
      ...mapGetters(['userDataAtSync', 'fingerprint']),
      ...mapGetters('clientAgency', ['isAgencyDomain', 'agencyProfile']),
      email() {
        return this.formData.email;
      },
      quote() {
        return quotes[Math.floor(Math.random() * quotes.length)];
      },
    },
    watch: {
      email() {
        const email = this.formData.email.replace(/\s{1,}/g, '');
        if (email !== this.formData.email) {
          this.formData.email = email;
        }
      },
    },
    created() {
      if (this.$route.query.token) {
        this.staffActiveToken = this.$route.query.token;
      }
      if (this.$route.query.main) {
        this.mainAccount = this.$route.query.main;
      }
      if (this.$route.params.email) {
        this.formData.email = this.$route.params.email;
      }
    },
    methods: {
      ...mapActions(['tryToLogin', 'showAlert', 'setUserSocket']),
      ...mapActions('clientAgency', ['loginClient']),
      initSocketService(user) {
        UserApi.getCountryInfoByIp().then(
          (res) => {
            this.socket = createConnection(
              localStorage.getItem('jwtToken'),
              this.fingerprint,
              res,
              user.id,
            );
            this.setUserSocket(this.socket);
          },
        );
      },
      submit() {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              this.loading = true;
              this.formData.email = this.formData.email.replace(/\s{1,}/g, '');
              if (this.isAgencyDomain) {
                this.loginClient(this.formData).then(
                  () => {
                    this.loading = false;
                    this.$router.push({ name: 'membership_dashboard' });
                  },
                  (error) => {
                    this.loading = false;
                    if (error === 'Invalid credentials') {
                      this.putErrorToField('Email', 'These credentials do not match our records');
                    } else {
                      this.showAlert({ message: error, type: 'danger' });
                    }
                  },
                );
              } else {
                this.tryToLogin(this.formData).then(
                  (res) => {
                    this.initSocketService(res.user);
                    this.loading = false;
                    this.$router.push({ name: 'membership_dashboard' });
                  },
                  (error) => {
                    this.loading = false;
                    if (error === 'Invalid credentials') {
                      this.putErrorToField('Email', 'These credentials do not match our records');
                    } else {
                      this.showAlert({ message: error, type: 'danger' });
                    }
                  },
                );
              }
            }
          },
        );
      },
    },
  };
</script>
