<template>
  <div class="membership-storage">
    <div class="flex-between">
      <div class="flex-start">
        <div class="membership-storage__used text-black">{{ usedSize }}%</div>
        <div class="ml-1 primary-text xl text-dark">used</div>
      </div>
      <div>
        <v-btn
          v-if="canShowUpgrade"
          class="custom-btn-secondary"
          @click="onUpdatePlan"
        >
          <fa-icon :icon="['fal', 'chevron-right']" class="icon-plan" />
          Upgrade Plan
        </v-btn>
      </div>
    </div>

    <div class="mt-20 membership-storage__progress">
      <div class="w-100 membership-storage__progress-line flex-start">
        <v-tooltip
          v-for="(item, idx) in membershipStorageConverter"
          :key="idx"
          content-class="storage-statistics__tooltip" top>
          <template v-slot:activator="{ on }">
            <div
              :style="{
                'width': `${Number(item.size) * 100 / availableSpace}%`,
                'background-color': listColor[idx],
              }"
              class="membership-storage__progress-line__item"
              v-on="on"
            />
          </template>
          <div
            class="membership-storage__list__item flex-start w-100"
          >
            <div
              class="mr-3 rounded"
              :style="{
                'width': '10px',
                'height': '10px',
                'background-color': listColor[idx],
              }"
            />
            <div class="w-75 primary-text lg text-white">{{ item.name }}</div>
            <div class="ml-3 flex-end flex-1">
              <div class="whitespace-nowrap">
                <span class="primary-text lg semi-bold mr-1 text-white">
                  {{ (Number(item.size) * 100 / availableSpace).toFixed(2) }}%
                </span>
                <span class="primary-text lg text-white">({{ convertByte(item.size) }})</span>
              </div>
            </div>
          </div>
        </v-tooltip>
        <div
          :style="{
            'width': `${100 - usedSize}%`,
            'background-color': '#EAEAEF',
          }"
          class="membership-storage__progress-line__item"
        />
      </div>
      <div class="flex-between mt-10">
        <div class="primary-text xl semi-bold text-dark">{{ totalSizeText }}</div>
        <div class="primary-text xl semi-bold text-dark">{{ parseAvailableSpace }}</div>
      </div>
    </div>

    <div class="mt-20 w-50">
      <div
        v-for="(item, idx) in membershipStorageConverter"
        :key="idx"
        class="membership-storage__list__item flex-start w-100"
        :class="[idx !== 0 ? 'mt-10' : '']"
      >
        <div
          class="mr-3 rounded"
          :style="{
            'width': '10px',
            'height': '10px',
            'background-color': listColor[idx],
          }"
        />
        <div class="w-75 primary-text lg">{{ item.name }}</div>
        <div class="ml-3 flex-end flex-1">
          <div class="whitespace-nowrap">
            <span class="primary-text lg semi-bold mr-1">
              {{ Math.round((Number(item.size) / totalSize) * 100) }}%
            </span>
            <span class="primary-text lg text-dark">({{ convertByte(item.size) }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import storageHelper from '@/mixins/storage';
  import UpdatePlan from './UpdatePlan.vue';

  export default {
    mixins: [storageHelper],
    data() {
      return {
        listColor: [],
      };
    },
    computed: {
      ...mapGetters('storage', [
        'availableSpace',
        'membershipStorage',
      ]),
      parseAvailableSpace() {
        return this.convertByte(this.availableSpace);
      },
      totalSize() {
        let tmp = 0;
        this.membershipStorage.forEach((el) => {
          tmp += Number(el.size);
        });
        return tmp;
      },
      totalSizeText() {
        return this.convertByte(this.totalSize);
      },
      usedSize() {
        return Math.round(this.totalSize * 100 / this.availableSpace);
      },
      membershipStorageConverter() {
        const tmp = [];
        const otherItem = {
          id: 'other',
          name: 'Other',
          size: 0,
        };
        this.membershipStorage.forEach((el) => {
          if (Math.round((Number(el.size) / this.totalSize) * 100) > 0) {
            tmp.push(el);
          } else {
            otherItem.size += Number(el.size);
          }
        });
        if (otherItem.size > 0) {
          tmp.push(otherItem);
        }
        return tmp;
      },
      canShowUpgrade() {
        return this.availableSpace < 5000 * (1000 ** 3);
      },
    },
    watch: {
      membershipStorageConverter(val) {
        this.listColor = [];
        val.forEach(() => {
          this.listColor.push(this.getRandomColor());
        });
      },
    },
    mounted() {
      this.getMembershipStorage();
    },
    methods: {
      ...mapActions([
        'openCustomModal',
      ]),
      ...mapActions('storage', [
        'getMembershipStorage',
      ]),
      onUpdatePlan() {
        this.openCustomModal({
          title: 'Upgrade Plan',
          component: UpdatePlan,
          maxWidth: '440px',
          width: '440px',
          modalClass: 'membership-storage',
        });
      },
    },
  };
</script>
