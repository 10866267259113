<template>
  <div class="payment-method">
    <div class="flex-between">
      <div class="primary-text lg bold">Client’s Payment Methods</div>
      <v-btn class="custom-btn-primary" @click="resetPayment">Reset Payment Methods</v-btn>
    </div>
    <div class="mt-20 layout align-center">
      <icon-stripe-normal class="mr-3" />
      <p v-if="stripeAccount" class="primary-text semi-bold lg">
        Connected account:
        <span class="primary-text dark-text">{{ stripeAccount.stripeUserId }}</span>
      </p>
      <p v-else class="primary-text semi-bold lg">
        Not connected
      </p>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import agencyApi from '@/api/agency';

  export default {
    computed: {
      ...mapGetters('agency', ['clientProfileDetail']),
      stripeAccount() {
        if (!this.clientProfileDetail || !this.clientProfileDetail.stripeAccount) {
          return null;
        }
        return this.clientProfileDetail.stripeAccount;
      },
    },
    methods: {
      ...mapActions('agency', ['getClientProfileDetail']),
      async resetPayment() {
        await agencyApi.resetProfilePayment(this.clientProfileDetail.id);
        this.getClientProfileDetail();
      },
    },
  };
</script>
