import moment from 'moment';

import { affiliateRewardTypes } from '@/config/affiliateSetting';
import subscriptionLevels from '@/config/subscriptionLevels';
import permissions from '@/config/permissions';
import { FEATURE_BUNDLES, FEATURE_COURSE_CHECKOUT, FEATURE_ZAPIER } from '../config/permissions';

export default {
  prepareCurrentPercentage(newData, oldData) {
    function prepareCurrentPercentage(data, last, courseData) {
      // Get number of lesson
      let lessonCount = 0;
      const modules = courseData.content ? courseData.content : courseData.modules;
      const lessonIds = newData.completedLessonIds;

      if (modules) {
        modules.forEach((module) => {
          const steps = module.lessons ? module.lessons : module.steps;
          steps.forEach(() => {
            lessonCount += 1;
          });
        });
      }

      const tmp = courseData;
      tmp.last_activity = (last && last[courseData.id]) ? last[courseData.id] : null;

      if (!lessonIds || !lessonIds[courseData.id]) {
        tmp.progress = 0;
      } else {
        const lessonCompleted = lessonIds[courseData.id].length;
        tmp.progress = Math.round(lessonCompleted / lessonCount * 100);
      }
      data.push(tmp);
    }

    const tmpData = oldData;
    if (newData.productMember && newData.productMember.length > 0) {
      const data = [];
      newData.productMember.forEach((pm) => {
        const last = newData.lastActivities ? newData.lastActivities : tmpData.lastAcitivites;

        if (pm.product.type === 'course') {
          prepareCurrentPercentage(data, last, pm.product.course);
        }

        if (pm.product.type === 'bundle') {
          pm.product.courses.forEach((c) => {
            prepareCurrentPercentage(data, last, c);
          });
        }
      });
      tmpData.productMember = data;
    }
    return tmpData;
  },
  userHaveFeature(user, feature) {
    const planPermissions = permissions[user.plan];

    if (!planPermissions) {
      return false;
    }

    return planPermissions.includes(feature);
  },
  reorderIntegrations(integrations, whoIam) {
    const isProd = process.env.VUE_APP_NODE_ENV === 'production';

    if (!integrations) return {};

    const isPro = whoIam.proActivatedAt !== null;
    // const isChallenge = whoIam.challengeActivatedAt !== null;
    const isPlus = whoIam.plusPlanActivatedAt !== null;
    const isNew = whoIam.isPlanNew;

    const result = {
      aweber: integrations.aweber ? integrations.aweber : {},
      active_campaign: integrations.active_campaign ? integrations.active_campaign : {},
      mailchimp: integrations.mailchimp ? integrations.mailchimp : {},
      getresponse: integrations.getresponse ? integrations.getresponse : {},
      sendlane: integrations.sendlane ? integrations.sendlane : {},
      markethero: integrations.markethero ? integrations.markethero : {},
      ontraport: integrations.ontraport ? integrations.ontraport : {},
      gotowebinar: integrations.gotowebinar ? integrations.gotowebinar : {},
      zoomus: (integrations.zoomus && whoIam.email === 'zoom@memberapp.io') ? integrations.zoomus : {},
      demio: integrations.demio ? integrations.demio : {},
      everwebinar: integrations.everwebinar ? integrations.everwebinar : {},
      webinarjam: integrations.webinarjam ? integrations.webinarjam : {},
      infusionsoft: integrations.infusionsoft ? integrations.infusionsoft : {},
      mailerlite: integrations.mailerlite ? integrations.mailerlite : {},
    };

    // add bundle
    const addCheckoutData = isNew
      ? (this.userHaveFeature(whoIam, FEATURE_COURSE_CHECKOUT)
        || this.userHaveFeature(whoIam, FEATURE_BUNDLES))
      : (isPlus || isPro);

    if (addCheckoutData) {
      result.stripe = integrations.stripe ? integrations.stripe : {};
      result.paypal = integrations.paypal ? integrations.paypal : {};
    }

    const addZapier = isNew
      ? this.userHaveFeature(whoIam, FEATURE_ZAPIER)
      : isPro;

    if (addZapier) {
      result.zapier = integrations.zapier ? integrations.zapier : {};
    }

    if (isProd && result.paypal) {
      delete result.paypal;
    }

    return result;
  },
  getCourseOrder(courses) {
    let currentOrder = 1;
    courses.forEach((course) => {
      if (course.order && course.order > currentOrder) {
        currentOrder = course.order;
      }
    });
    return currentOrder + 1;
  },
  getCourseUsefulData(course) {
    if (course && course.content && course.content.map) {
      return {
        title: course.title,
        content: course.content.map(module => ({
          id: module.id,
          dayToPublish: module.dayToPublish,
          title: module.title,
          lessons: module.lessons.map(lesson => ({
            description: lesson.description,
            file: lesson.file,
            files: lesson.files,
            id: lesson.id,
            title: lesson.title,
            video: lesson.video,
            audio: lesson.audio,
            videoThumbnail: lesson.videoThumbnail,
          })),
        })),
      };
    }

    return {};
  },
  prepareLabelDateForAnalytic(startDate, endDate, dataset) {
    const dateArr = [];
    const result = [];

    if (!startDate && !endDate) {
      return result;
    }

    const diff = endDate.diff(startDate, 'days');
    let start = new Date(startDate);
    const end = new Date(endDate);

    while (start < end) {
      dateArr.push(moment.utc(start).format('YYYY-MM-DD'));
      const newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);
    }

    // normal display
    if (diff < 20) {
      dateArr.forEach((date) => {
        const key = moment(date).format('MMM DD');
        let pushObj = { date: key };
        const check = dataset.find(obj => obj.date === date);
        if (check) {
          pushObj = check;
          pushObj.date = key;
        }
        result.push(pushObj);
      });
    } else if (diff < 32) { // one month display
      dateArr.forEach((date) => {
        const key = moment(date).format('Do');
        let pushObj = { date: key };
        const check = dataset.find(obj => obj.date === date);
        if (check) {
          pushObj = check;
          pushObj.date = key;
        }
        result.push(pushObj);
      });
    } else if (diff < 90) { // two month display
      const keys = [];
      dateArr.forEach((date, index) => {
        // create first key
        let key = `${moment(date).startOf('week').format('MM/DD')} - ${moment(date).endOf('week').format('MM/DD')}`;

        if (index === 0) {
          key = `${moment(date).format('MM/DD')} - ${moment(date).endOf('week').format('MM/DD')}`;
          keys.push(key);
          result.push({ date: key });
        } else if (!keys.includes(key)) {
          // create a key of current week and check if it already have
          keys.push(key);
          result.push({ date: key });
        }
        const check = dataset.find(obj => obj.date === date);
        const currentResult = result.find(obj => obj.date === key);
        if (check) {
          Object.keys(check).forEach((keyInObject) => {
            if (keyInObject !== 'date') {
              if (keyInObject in currentResult) {
                currentResult[keyInObject] += check[keyInObject];
              } else {
                currentResult[keyInObject] = check[keyInObject];
              }
            }
          });
        }
      });
    } else { // more than that will show by month
      const keys = [];
      dateArr.forEach((date, index) => {
        const endOfMonthCheck = moment(date).endOf('month').format('YYYY-MM-DD');
        if (!(index === 0 && endOfMonthCheck === date)) {
          // create first key
          const key = moment(date).format('MMM');
          if (!keys.includes(key)) {
            keys.push(key);
            result.push({ date: key });
          }
          const check = dataset.find(obj => obj.date === date);
          const currentResult = result.find(obj => obj.date === key);
          if (check) {
            Object.keys(check).forEach((keyInObject) => {
              if (keyInObject !== 'date') {
                if (keyInObject in currentResult) {
                  currentResult[keyInObject] += check[keyInObject];
                } else {
                  currentResult[keyInObject] = check[keyInObject];
                }
              }
            });
          }
        }
      });
    }

    return result;
  },
  splitOneMonthDay(labels) {
    const newLabels = [];
    labels.forEach((label, index) => {
      let tmp = label;
      if (!(index % 2)) {
        tmp = '';
      }
      newLabels.push(tmp);
    });
    return newLabels;
  },
  parseSecond(second) {
    const secNum = parseInt(second, 10); // don't forget the second param
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - (hours * 3600)) / 60);
    let seconds = secNum - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = `0${hours}`; }
    if (minutes < 10) { minutes = `0${minutes}`; }
    if (seconds < 10) { seconds = `0${seconds}`; }
    return `${hours}:${minutes}:${seconds}`;
  },
  formatMoneyByCurrency(currency, money) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
    return formatter.format(money);
  },
  getTermText(affiliateSetting, subscriptionLevel) {
    if (affiliateSetting.rewardType === affiliateRewardTypes.PERCENTAGE) {
      if (subscriptionLevel === subscriptionLevels.ONETIME) {
        return `${affiliateSetting.rewardAmount}%`;
      }

      return `${affiliateSetting.firstAmount}% of first payment,
                and ${affiliateSetting.subscriptionAmount}% of each recurring payment after`;
    }

    if (affiliateSetting.rewardType === affiliateRewardTypes.AMOUNT_DOLLAR) {
      if (subscriptionLevel === subscriptionLevels.ONETIME) {
        return `$${affiliateSetting.firstAmount}`;
      }

      return `$${affiliateSetting.firstAmount} for first payment,
              and $${affiliateSetting.subscriptionAmount} for each recurring payment after`;
    }

    return '';
  },
  getTermTextAdditionalText(affiliateSetting, subscriptionLevel) {
    if (affiliateSetting.rewardType === affiliateRewardTypes.PERCENTAGE) {
      if (subscriptionLevel === subscriptionLevels.ONETIME) {
        return `${affiliateSetting.rewardAmount}% of sales`;
      }

      return `${affiliateSetting.firstAmount}% of first payment,
                and ${affiliateSetting.subscriptionAmount}% of each recurring payment after`;
    }

    if (affiliateSetting.rewardType === affiliateRewardTypes.AMOUNT_DOLLAR) {
      if (subscriptionLevel === subscriptionLevels.ONETIME) {
        return `$${affiliateSetting.firstAmount} for each sale`;
      }

      return `$${affiliateSetting.firstAmount} for first payment,
              and $${affiliateSetting.subscriptionAmount} for each recurring payment after`;
    }

    return '';
  },
  getInvoiceCode(invoiceId) {
    let invoiceCode = invoiceId.toString();
    while (invoiceCode.length < 5) {
      invoiceCode = `0${invoiceCode}`;
    }
    invoiceCode = `IN-${invoiceCode}`;
    return invoiceCode;
  },
};
