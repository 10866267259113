<template>
  <v-menu
    v-model="activeStatus"
    class="multi-select"
    offset-y
    transition="slide-y-transition"
    :left="true"
    :close-on-content-click="false"
    content-class="multi-select-content search-select search-box">
    <template v-slot:activator="{ on }">
      <div class="multi-select__search-box">
        <v-text-field
          v-model="searchKey"
          class="custom-input__text"
          :disabled="disabled"
          :placeholder="placeholder"
        />
        <fa-icon v-if="disabled" :icon="['fal', 'times']" @click="clear" />
      </div>
    </template>
    <div class="multi-select-list">
      <div v-if="searchItems.length > 0 || Object.keys(searchItems).length > 0">
        <template v-if="enableLabelGroup">
          <template v-for="(group, gIndex) in searchItems">
            <div :key="`${gIndex}`" class="multi-select-group-label">
              {{ gIndex }}
            </div>
            <div v-for="(item, index) in group"
                 :key="`${gIndex}-${index}`"
                 class="multi-select-list-item"
                 @click="select(item)" v-html="textWithHighLight(item[itemTitle])" />
          </template>
        </template>
        <template v-else>
          <div v-for="(item, index) in searchItems"
               :key="index"
               class="multi-select-list-item"
               @click="select(item)"
               v-html="textWithHighLight(item[itemTitle])" />
        </template>
      </div>
      <div v-else>No result</div>
    </div>
  </v-menu>
</template>

<script>
  import textHelper from '@/mixins/textHelper';

  export default {
    mixins: [textHelper],
    props: ['items', 'value', 'placeholder', 'itemValue', 'itemTitle', 'enableLabelGroup'],
    data() {
      return {
        searchKey: '',
        activeStatus: false,
        selectedItem: {},
        searchItems: [],
      };
    },
    computed: {
      disabled() {
        return Object.keys(this.selectedItem).length !== 0;
      },
    },
    watch: {
      searchKey(val) {
        if (val !== '' && !this.disabled) {
          this.triggerSearch();
        } else {
          this.searchItems = [];
        }
      },
      value(val) {
        this.items.forEach((item) => {
          if (val === item[this.itemValue]) {
            this.select(item);
          }
        });
      },
    },
    methods: {
      clear() {
        this.searchKey = '';
        this.selectedItem = {};
        this.$emit('input', '');
      },
      triggerSearch() {
        this.activeStatus = true;
        if (this.enableLabelGroup) {
          this.searchItems = {};
          this.items.forEach((group) => {
            group.items.forEach((data) => {
              if (data[this.itemTitle].toLowerCase().includes(this.searchKey.toLowerCase())) {
                if (this.searchItems[group.label] === undefined) {
                  this.searchItems[group.label] = [];
                }
                this.searchItems[group.label].push(data);
              }
            });
          });
        } else {
          this.searchItems = [];
          this.items.forEach((item) => {
            if (item[this.itemTitle].toLowerCase().includes(this.searchKey.toLowerCase())) {
              this.searchItems.push(item);
            }
          });
        }
      },
      textWithHighLight(text) {
        return text.replace(this.searchKey, `<mark>${this.searchKey}</mark>`);
      },
      select(item) {
        this.selectedItem = item;
        this.searchKey = item[this.itemTitle];
        this.activeStatus = false;
        this.$emit('input', item[this.itemValue]);
      },
    },
  };
</script>
