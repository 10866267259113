

import DashboardAgencyPaymentForm from '@/views/dashboard/agency/PaymentForm';

// import ContentLayout from '@/layout/Content';
// import DashboardContentLayout from '@/layout/DashboardContent';
import InvoiceLayout from '@/layout/Invoice';

const routes = {
  path: 'invoice',
  component: InvoiceLayout,
  children: [
    {
      path: ':code',
      component: DashboardAgencyPaymentForm,
      name: 'dashboard.agency.payment-form',
      meta: {
        title: 'Invoice',
      },
    },
  ],

};

export default routes;
