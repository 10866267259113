export default {
  read: lessonId => new Promise((resolve, reject) => {
    window.http.get(`/courses/lesson/${lessonId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updateLessonComponent: data => new Promise((resolve, reject) => {
    window.http.put(`/courses/update-lesson-component/${data.id}`, data.params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
