export default {
  create: data => new Promise((resolve, reject) => {
    window.http.put('/products', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  delete: id => new Promise((resolve, reject) => {
    window.http.delete(`/products/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadCover: (id, cover) => new Promise((resolve, reject) => {
    window.http.post(`/products/${id}/cover`, { cover })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  removeCover: id => new Promise((resolve, reject) => {
    window.http.delete(`/products/${id}/cover`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  update: (id, data) => new Promise((resolve, reject) => {
    window.http.post(`/products/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getOwnProducts: () => new Promise((resolve, reject) => {
    window.http.get('/products')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getProduct: id => new Promise((resolve, reject) => {
    window.http.get(`/product/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
