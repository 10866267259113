const affiliatePayment = {
  type: {
    BANK: 1,
    PAYPAL: 2,
    PAYONEER: 3,
    CHEQUE: 4,
  },
  typeName: {
    1: 'Bank',
    2: 'Paypal',
    3: 'Payoneer',
    4: 'Cheque',
  },
};

export default affiliatePayment;
