<template>
  <div class="pie-chart-container">
    <canvas ref="pieChart" class="pie-chart" />
    <div ref="tooltipBox" />
  </div>
</template>

<script>
  import Chart from 'chart.js';

  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      tooltipOption: {
        type: Object,
        default: () => {},
      },
      tooltipCallback: {
        type: Object,
        default: () => {},
      },
      opacityColor: {
        type: Number,
        default: 1,
      },
      cutoutPercentage: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
    }),
    watch: {
      data() {
        this.updateChartData();
      },
    },
    mounted() {
      const el = this.$el.querySelector('.pie-chart');
      const ctx = el.getContext('2d');
      this.options.cutoutPercentage = this.cutoutPercentage;
      if (this.tooltipOption) {
        this.options.tooltips = {
          enabled: false,
        };
        this.options.tooltips.custom = this.tooltipOption.external;
      }
      if (this.tooltipCallback) {
        this.options.tooltips.callbacks = this.tooltipCallback;
      }

      this.pieChart = new Chart(ctx, {
        type: 'pie',
        options: this.options,
        data: {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          }],
        },
      });
    },
    beforeDestroy() {
      if (this.pieChart) {
        this.pieChart.destroy();
      }
    },
    methods: {
      updateChartData() {
        this.pieChart.data.labels = [];
        this.pieChart.data.datasets[0].data = [];
        this.pieChart.data.datasets[0].backgroundColor = [];
        this.data.forEach((row) => {
          this.pieChart.data.labels.push(row.label);
          this.pieChart.data.datasets[0].backgroundColor.push(this.hexToRGBA(row.color, 1));
          this.pieChart.data.datasets[0].data.push(row.value);
        });
        this.pieChart.update();
      },
      hexToRGBA(hex, opacity) {
        const val = hex.replace('#', '');
        const r = parseInt(val.substring(0, 2), 16);
        const g = parseInt(val.substring(2, 4), 16);
        const b = parseInt(val.substring(4, 6), 16);

        return `rgba(${r},${g},${b},${opacity})`;
      },
    },
  };
</script>
