<template>
  <div class="partnership-admin-modal__content confirm-payment">
    <div class="flex-center">
      <icon-confirmation />
    </div>
    <div class="primary-text lg semi-bold mt-20 mb-10 w-100 text-center">
      Have you processed all commissions?
    </div>
    <div class="primary-text w-100 text-center">
      Only confirm if you’ve processed all commissions listed here.
      When you confirm an email will be sent
      to the affiliate to inform them that their commissions have been paid.
    </div>
    <div class="flex-center w-100 mt-20">
      <v-btn
        class="custom-btn-normal"
        @click="onConfirm(false)"
      >
        No
      </v-btn>
      <v-btn
        class="custom-btn-secondary"
        @click="onConfirm(true)"
      >
        Yes
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import IconConfirmation from '@/assets/icons/partnership/confirm.svg';

  export default {
    components: {
      IconConfirmation,
    },
    computed: {
      ...mapGetters([
        'modalComponentProps',
      ]),
    },
    methods: {
      ...mapActions([
        'closeModal',
      ]),
      async onConfirm(type) {
        await this.modalComponentProps.onSubmitCallback(type);
        this.closeModal();
      },
    },
  };
</script>
