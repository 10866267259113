const constant = {
  SORT_DESC: 'DESC',
  SORT_ASC: 'ASC',
  currencies: [
    { title: 'USD', id: 1 }, // Unites States Dollar (USD)
    { title: 'RUB', id: 2 }, // Russian Ruble (RUB)
    { title: 'EUR', id: 3 }, // Euro (EUR)
    { title: 'JPY', id: 4 }, // Japanese yen (JPY)
    { title: 'GBP', id: 5 }, // Pound sterling (GBP)
    { title: 'AUD', id: 6 }, // Australian dollar (AUD)
    { title: 'CAD', id: 7 }, // Canadian dollar (CAD)
    { title: 'CHF', id: 8 }, // Swiss franc (CHF)
    { title: 'NZD', id: 9 }, // New Zealand dollar (NZD)
    { title: 'DKK', id: 10 }, // New Zealand dollar (NZD)
    { title: 'INR', id: 11 }, // New Zealand dollar (NZD)
  ],
  clientSubscriptionLevels: [
    { title: 'One Time', id: 'one-time' },
    { title: 'Subscriptions', id: 'subscription' },
  ],
  clientPaymentTypes: [
    {
      label: 'Internal',
      value: 'internal',
    },
    {
      label: 'External',
      value: 'external',
    },
    {
      label: 'Free',
      value: 'free',
    },
  ],
};

export default constant;
