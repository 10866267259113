import * as types from '@/store/mutation-types';
import Membership from '@/api/membership';

const state = {
  memberships: [],
  membershipArchives: [],
  membershipUsers: [],
  membershipAtSync: false,
};

const getters = {
  membershipAtSync: state => state.membershipAtSync,
  membershipArchives: state => state.membershipArchives,
  membershipList: state => state.memberships.sort((a, b) => (b.published - a.published)),
};

const actions = {
  fetchOwnMemberships: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_MEMBERSHIP_AT_SYNC, true);
    Membership.own().then(
      (res) => {
        commit(types.SET_MEMBERSHIPS, {});
        commit(types.SET_MEMBERSHIP_AT_SYNC, false);
        commit(types.SET_MEMBERSHIPS, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  fetchTeamMemberMemberships: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_MEMBERSHIP_AT_SYNC, true);
    Membership.getTeamMemberMembership().then(
      (res) => {
        commit(types.SET_MEMBERSHIPS, {});
        commit(types.SET_MEMBERSHIP_AT_SYNC, false);
        commit(types.SET_MEMBERSHIPS, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  fetchOwnMembershipArchives: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_MEMBERSHIP_ARCHIVES, {});
    Membership.ownArchive().then(
      (res) => {
        commit(types.SET_MEMBERSHIP_ARCHIVES, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  restoreArchiveMembership: ({ dispatch }, data) => new Promise((resolve, reject) => {
    Membership.restoreArchive(data).then(
      (res) => {
        dispatch('showAlert', 'Your memberships and courses has been restore');
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  fetchMembership: ({ commit }, id) => new Promise((resolve, reject) => {
    Membership.getOne(id).then(
      (res) => {
        commit(types.ADD_MEMBERSHIP, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  createMembership: ({ commit }, data) => new Promise((resolve, reject) => {
    Membership.create(data).then(
      (res) => {
        commit(types.ADD_MEMBERSHIP, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  updateMembership: ({ commit }, { id, data }) => new Promise((resolve, reject) => {
    Membership.update(id, data).then(
      (res) => {
        commit(types.UPDATE_MEMBERSHIP, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  deleteMembership: ({ commit, dispatch }, item) => new Promise((resolve, reject) => {
    Membership.delete(item.id).then(
      (res) => {
        commit(types.DELETE_MEMBERSHIP, item);
        dispatch('showAlert', 'Your membership has been deleted.');
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  archiveMembership: ({ commit, dispatch }, item) => new Promise((resolve, reject) => {
    Membership.archive(item.id).then(
      (res) => {
        commit(types.DELETE_MEMBERSHIP, item);
        dispatch('showAlert', 'Your membership has been archived.');
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  duplicateMembership: ({ commit, dispatch }, membershipId) => new Promise((resolve, reject) => {
    commit(types.SET_MEMBERSHIP_AT_SYNC, true);
    Membership.duplicateMembership(membershipId).then(
      (res) => {
        dispatch('fetchOwnMemberships');
        resolve(res);
      },
      (error) => {
        commit(types.SET_MEMBERSHIP_AT_SYNC, false);
        reject(error);
      },
    );
  }),
};

const mutations = {
  [types.SET_MEMBERSHIP_AT_SYNC](state, payload) {
    state.membershipAtSync = payload;
  },
  [types.SET_MEMBERSHIPS](state, payload) {
    state.memberships = payload;
  },
  [types.SET_MEMBERSHIP_ARCHIVES](state, payload) {
    state.membershipArchives = payload;
  },
  [types.ADD_MEMBERSHIP](state, payload) {
    const idx = state.memberships.findIndex(membership => payload.id === membership.id);
    if (idx !== -1) {
      state.memberships.splice(idx, 1, payload);
    } else {
      state.memberships.push(payload);
    }
  },
  [types.UPDATE_MEMBERSHIP](state, payload) {
    const idx = state.memberships.findIndex(membership => payload.id === membership.id);
    Object.assign(state.memberships[idx], payload);
  },
  [types.ADD_MEMBERSHIP_COURSE](state, course) {
    state.memberships.forEach((m) => {
      if (m.id === course.membershipId) {
        m.courses.push(course);
      }
    });
  },
  [types.DELETE_MEMBERSHIP](state, payload) {
    const idx = state.memberships.findIndex(membership => payload.id === membership.id);
    state.memberships.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
