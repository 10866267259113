<template>
  <div class="account-setting__form">
    <div class="account-setting__avatar-section">
      <img v-if="avatar" :src="avatar" alt="" class="account-setting__avatar">
      <template v-else>
        <div v-if="whoIam.avatar" class="account-setting__avatar" :style="avatarBg"/>
        <initial-avatar v-else :name="`${whoIam.name}`" />
      </template>
      <p
        class="flex-text primary-text semi-bold mb-15 violet-text cursor-pointer"
        @click="$refs.avatarInput.click()"
      >
        <icon-upload class="mr-2" />
        Upload New Avatar
      </p>
      <input v-show="false" ref="avatarInput" type="file" @change="onChangeAvatar">
    </div>
    <p class="label-text">Name</p>
    <v-text-field
      v-model="formData.name"
      solo/>
    <p class="label-text">Email</p>
    <v-text-field
      v-model="formData.email"
      :readonly="true"
      class="hide-detail mb-20"
      solo/>
    <p
      v-if="!showPasswordForm"
      class="flex-text primary-text semi-bold violet-btn mb-25"
      @click="showPasswordForm = true"
    >
      <icon-password class="mr-2"/>
      Edit password
    </p>
    <template v-if="showPasswordForm">
      <p class="label-text">Old Password</p>
      <v-text-field
        v-model="formData.oldPassword"
        type="password"
        solo/>
      <p class="label-text">New Password</p>
      <v-text-field
        v-model="formData.newPassword"
        type="password"
        solo/>
    </template>
    <div class="layout mt-30">
      <v-spacer />
      <v-btn class="custom-btn-secondary md" :loading="submitting" @click="save">
        Save Changes
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import linkHelper from '@/mixins/linkHelper';
  import InitialAvatar from '@/components/InitialAvatar';
  import PartnershipApi from '@/api/partnership';

  export default {
    components: {
      InitialAvatar,
    },
    mixins: [linkHelper],
    data() {
      return {
        avatar: '',
        showPasswordForm: false,
        submitting: false,
        formData: {
          name: '',
          lastName: '',
          email: '',
          uploadAvatar: '',
        },
      };
    },
    computed: {
      ...mapGetters([
        'whoIam',
      ]),
      avatarBg() {
        return { 'background-image': `url("${this.imageUrl(this.whoIam.avatar)}")` };
      },
    },
    watch: {
      whoIam() {
        this.init();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(['showAlert', 'uploadAvatar', 'resendConfirmMail']),
      init() {
        this.formData = {
          name: this.whoIam ? this.whoIam.name : '',
          email: this.whoIam ? this.whoIam.email : '',
        };
      },
      onChangeAvatar() {
        if (!this.$refs.avatarInput.files[0]) {
          return;
        }

        const file = this.$refs.avatarInput.files[0];

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.avatar = event.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      save() {
        this.submitting = true;
        if (this.avatar) {
          this.formData.uploadAvatar = this.avatar;
        }
        PartnershipApi.updateSelf(this.formData).then(
          (res) => {
            this.submitting = false;
            if (res.error) {
              this.showAlert({ type: 'danger', message: res.error });
            } else {
              this.showAlert('Account information is updated.');
              this.$nextTick(() => {
                this.$set(this.whoIam, 'email', res.email);
                this.$set(this.whoIam, 'name', res.name);
                this.$set(this.whoIam, 'avatar', res.avatar);
              });
              this.showPasswordForm = false;
              this.formData.oldPassword = '';
              this.formData.newPassword = '';
              this.formData.uploadAvatar = '';
            }
          },
        ).catch(
          (error) => {
            this.submitting = false;
            this.showAlert({ type: 'danger', message: error });
          },
        );
      },
    },
  };
</script>
