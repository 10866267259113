<template>
  <div class="api-setting account-setting__form">
    <div class="api-setting__head">
      <div class="api-setting__head-label primary-text xl semi-bold">Your API Key</div>
      <div>
        <v-btn class="custom-btn-secondary md" :loading="isLoading" @click="onGenerateKey">
          Generate Key
        </v-btn>
      </div>
    </div>
    <div class="api-setting__key">
      <div class="api-setting__key-content primary-text sm">{{ apiKey }}</div>
      <div class="api-setting__key-action" @click="onCopy">
        <icon-copy class="icon-default icon-copy" />
        <div class="api-setting__key-action__label primary-text ">Copy</div>
      </div>
    </div>
    <div class="api-setting__des">
      <span class="api-setting__des-content primary-text">
        API keys provide full access to your Memberapp account, so keep them safe.
        <span
          class="api-setting__des-content__link primary-text semi-bold"
          @click="gotoAPIDocument"
        >
          Read The API Documentation.
        </span>
      </span>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import copy from 'copy-to-clipboard';

  export default {
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters(['apiKey']),
    },
    async mounted() {
      await this.getAPIKey();
      this.isLoading = false;
    },
    methods: {
      ...mapActions([
        'generateNewAPIKey',
        'getAPIKey',
        'showAlert',
      ]),
      async onGenerateKey() {
        this.isLoading = true;
        await this.generateNewAPIKey();
        this.isLoading = false;
      },
      gotoAPIDocument() {
        window.open('https://memberapp.io/api-document/', '_blank');
      },
      onCopy() {
        copy(this.apiKey);
        this.showAlert('API key copied to your clipboard');
      },
    },
  };
</script>
