<template>
  <div class="date-range-picker__container single">
    <div v-if="activeStatus"
         class="date-range-picker__overlay" @click="this.updateData"/>
    <div
      ref="datePicker"
      class="date-range-picker__text"
      :class="{ 'active' : activeStatus }" @click="activeStatus = !activeStatus">
      <span>{{ formatDate(date) }}</span>
      <icon-calendar />
    </div>
    <v-slide-y-transition>
      <div
        v-if="activeStatus"
        :class="`date-range-picker__calendar single ${customClass}
        ${right ? 'right-single' : ''} ${top ? 'top-single': ''}`">
        <v-date-picker
          v-model="date"
          :weekday-format="getDay"
          class="date-range-picker" color="light-blue"
          event-color="light-blue lighten-4 date-in-range"
          no-title
          @input="updateData"/>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
      customClass: {
        type: String,
        default: '',
      },
      right: {
        type: Boolean,
        default: false,
      },
      top: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeStatus: false,
        date: '',
      };
    },
    created() {
      if (this.value !== '') {
        this.date = this.value;
      }
    },
    methods: {
      getDay(date) {
        const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        const i = new Date(date).getDay(date);
        return daysOfWeek[i];
      },
      formatDate(date) {
        if (date === '') {
          return '';
        }
        return moment(date).format('MMM DD, YYYY');
      },
      updateData() {
        this.$emit('input', this.date);
        this.activeStatus = false;
      },
    },
  };
</script>
