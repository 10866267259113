export default {
  loadList: productId => new Promise((resolve, reject) => {
    window.http.get(`/action-integrations/${productId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  create: data => new Promise((resolve, reject) => {
    window.http.post('/action-integration', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  update: (id, data) => new Promise((resolve, reject) => {
    window.http.post(`/action-integration/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  activate: id => new Promise((resolve, reject) => {
    window.http.post(`/action-integration/${id}/activate`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deactivate: id => new Promise((resolve, reject) => {
    window.http.post(`/action-integration/${id}/deactivate`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  delete: id => new Promise((resolve, reject) => {
    window.http.delete(`/action-integration/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  connectPaypal: data => new Promise((resolve, reject) => {
    console.log('api');
    window.http.post('/integration/paypal/save-paypal-app', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
