const integrationService = {
  integrationList: {
    aweber: {
      active: false,
    },
    active_campaign: {
      active: false,
      api_key: '',
      api_url: '',
    },
    mailchimp: {
      active: false,
      api_key: '',
    },
    getresponse: {
      active: false,
      api_key: '',
    },
    sendlane: {
      active: false,
      api: '',
      hash: '',
    },
    markethero: {
      api_key: '',
    },
    ontraport: {
      active: false,
      api_key: '',
      app_id: '',
    },
    mailerlite: {
      active: false,
      api_key: '',
    },
    infusionsoft: {
      active: false,
    },
    stripe: {
      active: true,
      stripe_user_id: '',
    },
    paypal: {
      active: true,
    },
    gohighlevel: {
      active: false,
    },
  },
  webinarList: {
    gotowebinar: {
      active: false,
    },
    zoomus: {
      active: false,
      auth_key: '',
      secret_key: '',
      external_id: '',
    },
    demio: {
      active: false,
    },
    everwebinar: {
      active: false,
      api_key: '',
    },
    webinarjam: {
      active: false,
      api_key: '',
    },
  },
  integrationType: {
    MAIL_CHIMP: 'mailchimp',
    GET_RESPONSE: 'getresponse',
    ACTIVE_CAMPAIGN: 'active_campaign',
    MARKET_HERO: 'markethero',
    WEBINAR_JAM: 'webinarjam',
    EVERWEBINAR: 'everwebinar',
    ONTRAPORT: 'ontraport',
    SENDLANE: 'sendlane',
    GOTOWEBINAR: 'gotowebinar',
    AWEBER: 'aweber',
    ZOOMUS: 'zoomus',
    STRIPE: 'stripe',
    INFUSIONSOFT: 'infusionsoft',
    MAILERLITE: 'mailerlite',
    DEMIO: 'demio',
    ZAPIER: 'zapier',
    PAYPAL: 'paypal',
    GO_HIGH_LEVEL: 'gohighlevel',
  },
};

export default integrationService;

export const nameMap = {
  mailchimp: 'MailChimp',
  getresponse: 'Get Response',
  active_campaign: 'Active Campaign',
  markethero: 'Market Hero',
  webinarjam: 'WebinarJam',
  everwebinar: 'Everwebinar',
  ontraport: 'Ontraport',
  sendlane: 'Sendlane',
  gotowebinar: 'GoToWebinar',
  aweber: 'AWeber',
  zoomus: 'Zoom',
  infusionsoft: 'Infusion Soft',
  mailerlite: 'Mailer Lite',
  demio: 'Demio',
  gohighlevel: 'GoHighLevel',
  clepher: 'Clepher',
};

export const integrationsTags = {
  mailchimp: true,
  getresponse: true,
  active_campaign: true,
  markethero: false,
  webinarjam: false,
  everwebinar: false,
  ontraport: false,
  sendlane: true,
  gotowebinar: false,
  aweber: true,
  zoomus: false,
  infusionsoft: false,
  mailerlite: false,
  demio: false,
  gohighlevel: false,
};
