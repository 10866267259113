import Vue from 'vue';

import Home from '@/assets/icons/home.svg';
import NoPayment from '@/assets/icons/no_payment.svg';
import Users from '@/assets/icons/users.svg';
import Account from '@/assets/icons/account.svg';
import Domains from '@/assets/icons/domain1.svg';
import Copy from '@/assets/icons/copy.svg';
import Study from '@/assets/icons/study.svg';
import ListGrid from '@/assets/icons/list-grid.svg';
import WarningX from '@/assets/icons/warning-x.svg';
import QuestionInfo from '@/assets/icons/question-info.svg';
import Payments from '@/assets/icons/payments.svg';
import Settings from '@/assets/icons/settings.svg';
import Courses from '@/assets/icons/courses.svg';
import UserSidebar from '@/assets/icons/user1.svg';
import CourseSidebar from '@/assets/icons/course1.svg';
import AccountSidebar from '@/assets/icons/account1.svg';
import Training from '@/assets/icons/training1.svg';
import Dashboard from '@/assets/icons/dashboard.svg';
import Avatar from '@/assets/icons/avatar.svg';
import Drag from '@/assets/icons/drag.svg';
import DragMobile from '@/assets/icons/drag-m.svg';
import Edit from '@/assets/icons/edit.svg';
import Paragraph from '@/assets/icons/paragraph.svg';
import Video from '@/assets/icons/video.svg';
import Vidello from '@/assets/icons/vidello.svg';
import Trash from '@/assets/icons/trash.svg';
import Preview from '@/assets/icons/preview.svg';
import CourseUser from '@/assets/icons/course-user.svg';
import Recheck from '@/assets/icons/recheck.svg';
import Certificated from '@/assets/icons/certificated.svg';
import Uncertificated from '@/assets/icons/uncertificated.svg';
import Download from '@/assets/icons/download.svg';
import Plus from '@/assets/icons/plus.svg';
import Password from '@/assets/icons/password.svg';
import Logout from '@/assets/icons/logout.svg';
import Email from '@/assets/icons/email.svg';
import Image from '@/assets/icons/image.svg';
import Star from '@/assets/icons/star.svg';
import Favicon from '@/assets/icons/favicon.svg';
import PlayVideo from '@/assets/icons/play-video.svg';
import AddFile from '@/assets/icons/add-file.svg';
import Calendar from '@/assets/icons/calendar.svg';
import CalendarEmpty from '@/assets/icons/calendar-empty.svg';
import DropFile from '@/assets/icons/dropfile.svg';
import Warning from '@/assets/icons/warning.svg';
import MainLogo from '@/assets/icons/main-logo.svg';
import LogoFull from '@/assets/icons/brand/logo_full.svg';
import LogoMinimize from '@/assets/icons/brand/logo_minimize.svg';
import List from '@/assets/icons/list.svg';
import Grid from '@/assets/icons/grid.svg';
import Level from '@/assets/icons/level.svg';
import RoundedPlus from '@/assets/icons/rounded-plus.svg';
import More from '@/assets/icons/more.svg';
import Unpublished from '@/assets/icons/unpublished.svg';
import EditX from '@/assets/icons/edit1.svg';
import Paint from '@/assets/icons/paint.svg';
import Save from '@/assets/icons/save.svg';
import OpenLink from '@/assets/icons/open_link.svg';
import LessonPlay from '@/assets/icons/lesson-play.svg';
import CheckCircle from '@/assets/icons/check-circle.svg';
import Hide from '@/assets/icons/hide.svg';
import Upload from '@/assets/icons/upload.svg';
import Menu from '@/assets/icons/menu.svg';
import Dropdown from '@/assets/icons/dropdown.svg';
import Publish from '@/assets/icons/publish.svg';
import Uploading from '@/assets/icons/uploading.svg';
import File from '@/assets/icons/file.svg';
import FileDefault from '@/assets/icons/file-default.svg';
import FilePdf from '@/assets/icons/file-pdf.svg';
import FileDocx from '@/assets/icons/file-docx.svg';
import FileDoc from '@/assets/icons/file-doc.svg';
import FileTxt from '@/assets/icons/file-txt.svg';
import FilePng from '@/assets/icons/file-png.svg';
import FilePptx from '@/assets/icons/file-pptx.svg';
import PasswordUnlocked from '@/assets/icons/password_unlock.svg';
import FilePpt from '@/assets/icons/file-ppt.svg';
import Widget from '@/assets/icons/widget.svg';
import MoreX from '@/assets/icons/more-x.svg';
import DownloadX from '@/assets/icons/download-x.svg';
import Search from '@/assets/icons/search.svg';
import CirclePlus from '@/assets/icons/circle-plus.svg';
import Info from '@/assets/icons/info.svg';
import Share from '@/assets/icons/share.svg';
import Facebook from '@/assets/icons/facebook.svg';
import Twitter from '@/assets/icons/twitter.svg';
import Linkedin from '@/assets/icons/linkedin.svg';
import Gmail from '@/assets/icons/gmail.svg';
import Like from '@/assets/icons/like.svg';
import Dislike from '@/assets/icons/dislike.svg';
import Comment from '@/assets/icons/comment.svg';
import Analytics from '@/assets/icons/analytics.svg';
import FileX from '@/assets/icons/file-x.svg';
import Trophy from '@/assets/icons/trophy.svg';
import QuizTest from '@/assets/icons/quiz-test.svg';
import CheckboxActive from '@/assets/icons/check-box-active.svg';
import RadioActive from '@/assets/icons/radio-active.svg';
import SquareCircle from '@/assets/icons/square-circle.svg';
import InfoX from '@/assets/icons/info-x.svg';
import IncorrectTest from '@/assets/icons/incorrect-test.svg';
import HideX from '@/assets/icons/hide-x.svg';
import IconPaypal from '@/assets/icons/paypal.svg';
import IconBank from '@/assets/icons/bank.svg';
import ChangeAccount from '@/assets/icons/change-account.svg';
import ArrowUp from '@/assets/icons/arrow-up.svg';
import MemberAppLogo from '@/assets/icons/member-app-logo.svg';
import ArrowLeft from '@/assets/icons/arrow-left.svg';
import ArrowRight from '@/assets/icons/arrow-right.svg';
import IconPlusX from '@/assets/icons/plus-x.svg';
import Document from '@/assets/icons/document.svg';
import ConfirmWarning from '@/assets/icons/confirm-warning.svg';
import IconClose from '@/assets/icons/close.svg';
import TextareaExpand from '@/assets/icons/textarea-expand.svg';
import IconMoreXs from '@/assets/icons/more-xs.svg';
import IconPercentage from '@/assets/icons/percentage.svg';
import IconDownloadPdf from '@/assets/icons/download-pdf.svg';
import IconPointingArrow from '@/assets/icons/pointing_arrow.svg';
import IconDot from '@/assets/icons/dot.svg';
import IconCopyX from '@/assets/icons/copy-x.svg';
import IconPlay from '@/assets/icons/play.svg';
import IconEmailTemplate from '@/assets/icons/email-template.svg';
import IconInfoHome from '@/assets/icons/info-home.svg';
import IconArchive from '@/assets/icons/archive.svg';
import IconDecline from '@/assets/icons/decline.svg';
import Payoneer from '@/assets/icons/payoneer.svg';
import Cheque from '@/assets/icons/cheque.svg';
import PaypalText from '@/assets/icons/paypal-text.svg';
import Graduate from '@/assets/icons/graduate.svg';
import IconClient from '@/assets/icons/client.svg';
import IconView from '@/assets/icons/view.svg';
import IconLock from '@/assets/icons/lock.svg';
import IconCard from '@/assets/icons/partnership/card.svg';
import IconTool from '@/assets/icons/tool.svg';
import IconIntegration from '@/assets/icons/integration.svg';
import IconComputer from '@/assets/icons/computer.svg';
import IconAgency from '@/assets/icons/agency.svg';
// Onboarding
import OnboardingCustomize from '@/assets/icons/onboarding/customize.svg';
import OnboardingPayment from '@/assets/icons/onboarding/payment.svg';
import OnboardingCourse from '@/assets/icons/onboarding/course.svg';
import OnboardingContent from '@/assets/icons/onboarding/content.svg';
import OnboardingCart from '@/assets/icons/onboarding/cart.svg';
import IconAttachment from '@/assets/icons/attachment.svg';
import IconPlusMail from '@/assets/icons/plus-mail.svg';
// New quiz
import QuestionRadio from '@/assets/icons/quiz/radio.svg';
import QuestionCheckBox from '@/assets/icons/quiz/checkbox.svg';
import QuestionFileUpload from '@/assets/icons/quiz/file-upload.svg';
import QuestionTextAnswer from '@/assets/icons/quiz/text-answer.svg';
import QuestionImageSelection from '@/assets/icons/quiz/image-selection.svg';
import QuestionYesNo from '@/assets/icons/quiz/yes-no.svg';
import ImageMedia from '@/assets/icons/quiz/image-media.svg';
import ImageX from '@/assets/icons/image-x.svg';
// Challenge
import CourseLg from '@/assets/icons/course-lg.svg';
import ChallengeLg from '@/assets/icons/challenge-lg.svg';
import Challenge from '@/assets/icons/challenge.svg';
// Action
import IconActionVideo from '@/assets/icons/action/video.svg';
import IconActionRead from '@/assets/icons/action/read.svg';
import IconActionHomework from '@/assets/icons/action/homework.svg';
import IconActionCustom from '@/assets/icons/action/custom.svg';

// Course preview
import IconWebinar from '@/assets/icons/coursePreview/webinar.svg';
import IconHourGlass from '@/assets/icons/coursePreview/hour-glass.svg';
import IconBook from '@/assets/icons/coursePreview/book.svg';
import IconClock from '@/assets/icons/coursePreview/clock.svg';
import IconLessonTest from '@/assets/icons/coursePreview/lesson-test.svg';
import IconCheckCircleAlt from '@/assets/icons/coursePreview/check-circle-alt.svg';
import IconGraduateAlt from '@/assets/icons/coursePreview/graduate-alt.svg';
import IconFacebookAlt from '@/assets/icons/coursePreview/facebook.svg';
import IconInstagramAlt from '@/assets/icons/coursePreview/instagram.svg';
import IconTwitterAlt from '@/assets/icons/coursePreview/twitter.svg';
import IconLinkedinAlt from '@/assets/icons/coursePreview/linkedin.svg';
import IconOption from '@/assets/icons/option.svg';

import IconChallengeFlag from '@/assets/icons/challenge-flag.svg';
import IconStripe from '@/assets/tplogos/stripe.svg';
import IconCreditCard from '@/assets/tplogos/credit-card.svg';

import FileXLS from '@/assets/icons/file-xls.svg';
import FileCSV from '@/assets/icons/file-csv.svg';
import FileXLSX from '@/assets/icons/file-xlsx.svg';
import FileJPG from '@/assets/icons/file-jpg.svg';
import FileJPEG from '@/assets/icons/file-jpeg.svg';
import FileTIF from '@/assets/icons/file-tif.svg';
import FileMP4 from '@/assets/icons/file-mp4.svg';
import FileMOV from '@/assets/icons/file-mov.svg';
import FileODT from '@/assets/icons/file-odt.svg';
import FileODS from '@/assets/icons/file-ods.svg';
import FileGIF from '@/assets/icons/file-gif.svg';

Vue.component('icon-card', IconCard);
Vue.component('icon-home', Home);
Vue.component('icon-trash', Trash);
Vue.component('icon-users', Users);
Vue.component('icon-account', Account);
Vue.component('icon-domains', Domains);
Vue.component('icon-copy', Copy);
Vue.component('icon-course-side', CourseSidebar);
Vue.component('icon-user-side', UserSidebar);
Vue.component('icon-account-side', AccountSidebar);
Vue.component('icon-payments', Payments);
Vue.component('icon-settings', Settings);
Vue.component('icon-courses', Courses);
Vue.component('icon-dashboard', Dashboard);
Vue.component('icon-drag', Drag);
Vue.component('icon-drag-m', DragMobile);
Vue.component('icon-edit', Edit);
Vue.component('icon-paragraph', Paragraph);
Vue.component('icon-video', Video);
Vue.component('icon-vidello', Vidello);
Vue.component('icon-training-side', Training);
Vue.component('icon-preview', Preview);
Vue.component('icon-course-user', CourseUser);
Vue.component('icon-recheck', Recheck);
Vue.component('icon-certificated', Certificated);
Vue.component('icon-uncertificated', Uncertificated);
Vue.component('icon-download', Download);
Vue.component('icon-plus', Plus);
Vue.component('icon-password', Password);
Vue.component('icon-password-unlocked', PasswordUnlocked);
Vue.component('icon-logout', Logout);
Vue.component('icon-email', Email);
Vue.component('icon-avatar', Avatar);
Vue.component('icon-image', Image);
Vue.component('icon-star', Star);
Vue.component('icon-favicon', Favicon);
Vue.component('icon-play-video', PlayVideo);
Vue.component('icon-calendar', Calendar);
Vue.component('icon-calendar-empty', CalendarEmpty);
Vue.component('icon-file', File);
Vue.component('icon-file-default', FileDefault);
Vue.component('icon-file-pdf', FilePdf);
Vue.component('icon-file-docx', FileDocx);
Vue.component('icon-file-doc', FileDoc);
Vue.component('icon-file-txt', FileTxt);
Vue.component('icon-file-png', FilePng);
Vue.component('icon-file-pptx', FilePptx);
Vue.component('icon-file-ppt', FilePpt);

Vue.component('icon-file-xls', FileXLS);
Vue.component('icon-file-csv', FileCSV);
Vue.component('icon-file-xlsx', FileXLSX);
Vue.component('icon-file-jpg', FileJPG);
Vue.component('icon-file-jpeg', FileJPEG);
Vue.component('icon-file-tif', FileTIF);
Vue.component('icon-file-mp4', FileMP4);
Vue.component('icon-file-mov', FileMOV);
Vue.component('icon-file-odt', FileODT);
Vue.component('icon-file-ods', FileODS);
Vue.component('icon-file-gif', FileGIF);

Vue.component('icon-add-file', AddFile);
Vue.component('icon-drop-file', DropFile);
Vue.component('icon-warning', Warning);
Vue.component('icon-main-logo', MainLogo);
Vue.component('icon-logo-full', LogoFull);
Vue.component('icon-study', Study);
Vue.component('icon-list-grid', ListGrid);
Vue.component('icon-list', List);
Vue.component('icon-grid', Grid);
Vue.component('icon-level', Level);
Vue.component('icon-rounded-plus', RoundedPlus);
Vue.component('icon-more', More);
Vue.component('icon-unpublished', Unpublished);
Vue.component('icon-edit-x', EditX);
Vue.component('icon-paint', Paint);
Vue.component('icon-save', Save);
Vue.component('icon-open-link', OpenLink);
Vue.component('icon-check-circle', CheckCircle);
Vue.component('icon-lesson-play', LessonPlay);
Vue.component('icon-hide', Hide);
Vue.component('icon-upload', Upload);
Vue.component('icon-menu', Menu);
Vue.component('icon-dropdown', Dropdown);
Vue.component('icon-publish', Publish);
Vue.component('icon-widget', Widget);
Vue.component('icon-more-x', MoreX);
Vue.component('icon-download-x', DownloadX);
Vue.component('icon-search', Search);
Vue.component('icon-circle-plus', CirclePlus);
Vue.component('icon-info', Info);
Vue.component('icon-uploading', Uploading);
Vue.component('icon-share', Share);
Vue.component('icon-facebook', Facebook);
Vue.component('icon-twitter', Twitter);
Vue.component('icon-linkedin', Linkedin);
Vue.component('icon-gmail', Gmail);
Vue.component('icon-like', Like);
Vue.component('icon-dislike', Dislike);
Vue.component('icon-comment', Comment);
Vue.component('icon-analytics', Analytics);
Vue.component('icon-file-x', FileX);
Vue.component('icon-complete-trophy', Trophy);
Vue.component('icon-quiz-test', QuizTest);
Vue.component('icon-check-box-active', CheckboxActive);
Vue.component('icon-radio-active', RadioActive);
Vue.component('icon-square-circle', SquareCircle);
Vue.component('icon-info-x', InfoX);
Vue.component('icon-incorrect-test', IncorrectTest);
Vue.component('icon-hide-x', HideX);
Vue.component('icon-warning-x', WarningX);
Vue.component('icon-paypal', IconPaypal);
Vue.component('icon-bank', IconBank);
Vue.component('icon-change-account', ChangeAccount);
Vue.component('icon-arrow-up', ArrowUp);
Vue.component('icon-question-info', QuestionInfo);
Vue.component('member-app-logo', MemberAppLogo);
Vue.component('icon-arrow-left', ArrowLeft);
Vue.component('icon-arrow-right', ArrowRight);
Vue.component('icon-logo-minimize', LogoMinimize);
Vue.component('icon-plus-x', IconPlusX);
Vue.component('icon-document', Document);
Vue.component('icon-confirm-warning', ConfirmWarning);
Vue.component('icon-close', IconClose);
Vue.component('icon-textarea-expand', TextareaExpand);
Vue.component('icon-more-xs', IconMoreXs);
Vue.component('icon-percentage', IconPercentage);
Vue.component('icon-download-pdf', IconDownloadPdf);
Vue.component('icon-pointing-arrow', IconPointingArrow);
Vue.component('icon-dot', IconDot);
Vue.component('icon-copy-x', IconCopyX);
Vue.component('icon-play', IconPlay);
Vue.component('icon-email-template', IconEmailTemplate);
Vue.component('icon-info-home', IconInfoHome);
Vue.component('icon-archive', IconArchive);
Vue.component('icon-onboarding-customize', OnboardingCustomize);
Vue.component('icon-onboarding-payment', OnboardingPayment);
Vue.component('icon-onboarding-course', OnboardingCourse);
Vue.component('icon-onboarding-content', OnboardingContent);
Vue.component('icon-onboarding-cart', OnboardingCart);
Vue.component('icon-attachment', IconAttachment);
Vue.component('icon-decline', IconDecline);
Vue.component('icon-payoneer', Payoneer);
Vue.component('icon-cheque', Cheque);
Vue.component('icon-paypal-text', PaypalText);
Vue.component('icon-question-radio', QuestionRadio);
Vue.component('icon-question-checkbox', QuestionCheckBox);
Vue.component('icon-question-text-answer', QuestionTextAnswer);
Vue.component('icon-question-file-upload', QuestionFileUpload);
Vue.component('icon-question-image-selection', QuestionImageSelection);
Vue.component('icon-question-yes-no', QuestionYesNo);
Vue.component('icon-image-media', ImageMedia);
Vue.component('icon-image-x', ImageX);
Vue.component('icon-graduate', Graduate);
Vue.component('icon-plus-mail', IconPlusMail);
Vue.component('icon-challenge-lg', ChallengeLg);
Vue.component('icon-course-lg', CourseLg);
Vue.component('icon-challenge', Challenge);
Vue.component('icon-challenge-flag', IconChallengeFlag);

Vue.component('icon-action-video', IconActionVideo);
Vue.component('icon-action-read', IconActionRead);
Vue.component('icon-action-homework', IconActionHomework);
Vue.component('icon-action-custom', IconActionCustom);

Vue.component('icon-hour-glass', IconHourGlass);
Vue.component('icon-webinar', IconWebinar);
Vue.component('icon-book', IconBook);
Vue.component('icon-clock', IconClock);
Vue.component('icon-lesson-test', IconLessonTest);
Vue.component('icon-check-circle-alt', IconCheckCircleAlt);
Vue.component('icon-graduate-alt', IconGraduateAlt);
Vue.component('icon-facebook-alt', IconFacebookAlt);
Vue.component('icon-twitter-alt', IconTwitterAlt);
Vue.component('icon-instagram-alt', IconInstagramAlt);
Vue.component('icon-linkedin-alt', IconLinkedinAlt);
Vue.component('icon-option', IconOption);
Vue.component('icon-client', IconClient);
Vue.component('icon-view', IconView);
Vue.component('icon-lock', IconLock);
Vue.component('icon-tool', IconTool);
Vue.component('icon-no-payment', NoPayment);
Vue.component('icon-stripe-normal', IconStripe);
Vue.component('icon-integration', IconIntegration);
Vue.component('icon-agency', IconAgency);
Vue.component('icon-credit-card', IconCreditCard);
Vue.component('icon-computer', IconComputer);
