<template>
  <v-layout column fill-height class="membership-archive">
    <div class="membership-archive__header">
      <div
        class="pointer membership-archive__logo"
        @click="$router.push({ name: 'membership_dashboard' })"
      >
        <icon-logo-full />
      </div>
      <v-text-field
        v-model="keyword"
        solo
        placeholder="Search"
        class="no-error"
        @keyup.enter="fetch">
        <div slot="append" class="text-icon-prepend">
          <icon-search />
        </div>
      </v-text-field>
    </div>
    <div class="membership-archive__body">
      <div class="membership-archive__content">
        <h1 class="membership-archive__title">Archive</h1>
        <div
          v-if="memberships.length || coursesDeleted.length"
          class="layout justify-space-between membership-archive__enable">
          <custom-check-box
            v-model="enableRestore"
            custom-class="horizontal-tick alternative"
          >
            Select to restore
          </custom-check-box>
          <v-btn class="custom-btn-secondary" :loading="loading" @click="restore">
            Restore
          </v-btn>
        </div>
        <div class="membership__container">
          <div
            v-for="(membership, index) in memberships"
            :key="index"
            class="membership__box"
            :class="{'archive': !membershipSelected[membership.id]}"
          >
            <div class="membership__box-header">
              <custom-check-box
                v-if="enableRestore"
                v-model="membershipSelected[membership.id]"
                custom-class="alternative"
                @clickNative="selectMembership(membership.id)"
              />
              <div>
                <p class="box-title">{{ membership.title }}</p>
                <span class="box-date">
                  Created: {{ formatDate(membership.createdAt) }}
                </span>
              </div>
            </div>
            <div class="membership__box-main">
              <div class="membership__box-courses">
                <p class="main-title">Deleted Courses:</p>
                <div
                  v-for="(course, key) in membership.courses"
                  :key="`course-${key}-${index}`"
                  class="membership__box-course"
                  :class="{'archive': !courseOfMembershipSelected[course.id]}"
                >
                  <div class="layout align-center pointer">
                    <custom-check-box
                      v-if="enableRestore"
                      v-model="courseOfMembershipSelected[course.id]"
                    />
                    <div class="box-course__image" :style="getCourseBackground(course)"/>
                    <p class="box-course__title">{{ course.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="coursesDeleted.length" class="membership__box archive">
            <div class="membership__box-header">
              <div>
                <p class="box-title">Not attached to any membership</p>
              </div>
            </div>
            <div class="membership__box-main">
              <div class="membership__box-courses">
                <p class="main-title">Deleted Courses:</p>
                <div
                  v-for="(course, key) in coursesDeleted"
                  :key="key"
                  class="membership__box-course"
                  :class="{'archive': !courseSelected[course.id]}"
                >
                  <div class="layout align-center pointer">
                    <custom-check-box
                      v-if="enableRestore"
                      v-model="courseSelected[course.id]"
                    />
                    <div class="box-course__image" :style="getCourseBackground(course)"/>
                    <p class="box-course__title">{{ course.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import CustomCheckBox from '@/components/shared/CustomCheckBox';
  import linkHelper from '@/mixins/linkHelper';

  export default {
    components: {
      CustomCheckBox,
    },
    mixins: [linkHelper],
    data() {
      return {
        keyword: '',
        enableRestore: false,
        membershipSelected: {},
        courseOfMembershipSelected: {},
        courseSelected: {},
        loading: false,
      };
    },
    computed: {
      ...mapGetters([
        'membershipArchives',
      ]),
      memberships() {
        if (!this.membershipArchives || !this.membershipArchives.memberships) {
          return [];
        }
        const result = this.membershipArchives.memberships.filter(
          m => m.title.includes(this.keyword),
        );

        return result.sort((b, c) => moment(c.createdAt, 'YYYY-MM-DD').toDate() - moment(b.createdAt, 'YYYY-MM-DD').toDate());
      },
      coursesDeleted() {
        if (!this.membershipArchives || !this.membershipArchives.courses) {
          return [];
        }
        return this.membershipArchives.courses.filter(
          c => c.title.toLowerCase().includes(this.keyword.toLowerCase()),
        );
      },
    },
    watch: {
      enableRestore(val) {
        if (!val) {
          this.membershipSelected = {};
          this.courseOfMembershipSelected = {};
          this.courseSelected = {};
        }
      },
    },
    mounted() {
      this.fetchOwnMembershipArchives();
    },
    methods: {
      ...mapActions([
        'fetchOwnMembershipArchives', 'restoreArchiveMembership', 'showAlert',
      ]),
      getCourseBackground(course) {
        const hasCover = course && course.cover;
        const url = hasCover ? this.imageUrl(course.cover) : '/img/default-lesson-list.svg';
        return { 'background-image': `url("${url}")` };
      },
      formatDate(date) {
        return moment(date).format('MMM DD, YYYY');
      },
      restore() {
        const membershipIds = [];
        const courseIds = [];
        const courseIdsOfMembership = [];
        Object.keys(this.membershipSelected).forEach((membershipId) => {
          if (this.membershipSelected[membershipId]) {
            membershipIds.push(parseInt(membershipId, 10));
          }
        });
        Object.keys(this.courseSelected).forEach((courseId) => {
          if (this.courseSelected[courseId]) {
            courseIds.push(parseInt(courseId, 10));
          }
        });
        Object.keys(this.courseOfMembershipSelected).forEach((courseId) => {
          if (this.courseOfMembershipSelected[courseId]) {
            courseIdsOfMembership.push(parseInt(courseId, 10));
          }
        });
        if (!membershipIds.length && !courseIds.length && !courseIdsOfMembership.length) {
          this.showAlert({ type: 'warning', message: 'You must select membership/course to restore' });
          return false;
        }

        this.loading = true;
        this.restoreArchiveMembership({ membershipIds, courseIds, courseIdsOfMembership })
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'membership_dashboard' });
          });
        return true;
      },
      selectMembership(membershipId) {
        let selected = true;
        if (!this.membershipSelected[membershipId]) {
          selected = false;
        }
        const membership = this.membershipArchives.memberships.find(m => m.id === membershipId);
        membership.courses.forEach((course) => {
          this.$set(this.courseOfMembershipSelected, course.id, selected);
        });
      },
    },
  };
</script>
