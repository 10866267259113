<template>
  <div class="account-setting__form">
    <div class="layout justify-space-between client-management__detail-row">
      <p class="primary-text xl semi-bold">Your Account</p>
    </div>
    <div class="layout align-center">
      <div class="layout align-center">
        <div class="client-management__detail-avatar" :style="avatarBg">
          <initial-avatar v-if="!whoIam.avatar" :name="whoIam.name"/>
        </div>
        <div class="ml-10">
          <p class="primary-text xl semi-bold">{{ whoIam.name }}</p>
          <p class="primary-text lg dark-text">{{ whoIam.email }}</p>
        </div>
      </div>
      <v-btn class="custom-btn-primary" @click="editProfile">Edit Profile</v-btn>
    </div>
    <p class="primary-text semi-bold mt-20 lg">Payment History</p>
    <div class="my-datatable__wrapper mt-3">
      <div class="my-datatable__scroller">
        <table class="my-datatable last-column-right client-management__table">
          <table-header :headers="headers" @sort="sort"/>
          <tbody>
            <tr v-for="(invoice, index) in invoiceList" :key="index">
              <td>{{ getInvoiceCode(invoice) }}</td>
              <td>
                <p class="primary-text">{{ getDayInvoice(invoice) }}</p>
                <p class="primary-text dark-text">{{ getTimeInvoice(invoice) }}</p>
              </td>
              <td>
                <span v-if="invoice.status === 'success'" class="badge badge-success">Paid</span>
                <span
                  v-else-if="invoice.status === 'pending' && isExpiredInvoice"
                  class="badge badge-error"
                >
                  Expired
                </span>
                <span v-else class="badge warning">Pending</span>
              </td>
              <td class="text-right">
                <p class="primary-text xl semi-bold red--text">
                  {{ getAmountInvoice(invoice) }}
                </p>
                <stripe-logo class="mt-1"/>
              </td>
              <td class="layout justify-end">
                <span
                  v-if="invoice.status === 'success'"
                  class="badge-btn badge-btn__normal"
                  @click="downloadInvoice(invoice)"
                >
                  <icon-download-pdf/>
                  Download Invoice
                </span>
                <span v-else>--</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import currencies from '@/config/currencies';
  import InitialAvatar from '@/components/InitialAvatar';
  import commonHelper from '@/helper/commonHelper';
  import TableHeader from '@/components/table/TableHeader';
  import StripeLogo from '@/assets/tplogos/stripe.svg';
  import ClientProfile from '@/components/modal/ClientProfile';

  export default {
    components: { InitialAvatar, TableHeader, StripeLogo },
    data() {
      return {
        headers: [
          {
            label: 'Invoice#',
            field: 'id',
            sort: true,
            width: '20%',
          },
          {
            label: 'date',
            field: 'date',
            sort: true,
            width: '20%',
          },
          {
            label: 'status',
            field: 'status',
            sort: true,
            width: '20%',
          },
          {
            label: 'amount',
            field: 'amount',
            sort: true,
            width: '10%',
            textAlign: 'flex-end',
          },
          {
            label: 'action',
            textAlign: 'flex-end',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
      ...mapGetters('clientAgency', ['invoiceList']),
      avatarBg() {
        return { 'background-image': `url("${this.whoIam.avatar}")` };
      },
      isExpiredInvoice() {
        return moment(this.whoIam.paymentDueDate) < moment();
      },
    },
    created() {
      this.fetchOwnInvoice();
    },
    methods: {
      ...mapActions(['openCustomModal']),
      ...mapActions('clientAgency', ['fetchOwnInvoice']),
      sort(sort) {
        if (sort.order === 'DESC') {
          this.sortFunction = (a, b) => ((a[sort.field] > b[sort.field]) ? 1 : -1);
        } else {
          this.sortFunction = (a, b) => ((b[sort.field] > a[sort.field]) ? 1 : -1);
        }
      },
      getAmountInvoice(invoice) {
        const currency = currencies[invoice.currency].title.toUpperCase();
        return `-${commonHelper.formatMoneyByCurrency(currency, invoice.amount)}`;
      },
      getDayInvoice(invoice) {
        return moment(invoice.createdAt).format('MMM DD, YYYY');
      },
      getTimeInvoice(invoice) {
        return moment(invoice.createdAt).format('HH:MM');
      },
      getInvoiceCode(invoice) {
        return `#${commonHelper.getInvoiceCode(invoice.id)}`;
      },
      downloadInvoice(invoice) {
        window.open(`${process.env.VUE_APP_API_URL}/client/invoice/${invoice.uuid}`);
      },
      editProfile() {
        this.openCustomModal({
          component: ClientProfile,
          title: 'Edit Profile',
          maxWidth: '580px',
          width: '580px',
          modalClass: 'membership-form',
        });
      },
    },
  };
</script>
