<template>
  <div class="client-integration">
    <div>
      <service-integration />
    </div>
    <div>
      <sender-email />
    </div>
    <div>
      <payment-method />
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import ServiceIntegration from './ServiceIntegration.vue';
  import SenderEmail from './SenderEmail.vue';
  import PaymentMethod from './PaymentMethod.vue';

  export default {
    components: {
      ServiceIntegration,
      SenderEmail,
      PaymentMethod,
    },
    computed: {
      ...mapGetters('agency', ['clientProfileDetail', 'clientDetail']),
    },
    mounted() {
      this.$nextTick(() => {
        this.getClientProfileDetail(this.clientDetail.profile.id);
      });
    },
    methods: {
      ...mapActions('agency', ['getClientProfileDetail']),
    },
  };
</script>
