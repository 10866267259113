export default {
  getAll: () => new Promise((resolve, reject) => {
    window.http.get('/courses')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getOne: courseId => new Promise((resolve, reject) => {
    window.http.get(`/courses/${courseId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getOneWithContent: id => new Promise((resolve, reject) => {
    window.http.get(`/courses/${id}/content`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  create: data => new Promise((resolve, reject) => {
    window.http.post('/courses', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  archive: id => new Promise((resolve, reject) => {
    window.http.post(`/courses/archive/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadAsset: (courseId, assetType, file) => new Promise((resolve, reject) => {
    if (!courseId) {
      return reject(new Error('No course ID was provided.'));
    }

    if (!assetType) {
      return reject(new Error('No assetType was provided.'));
    }

    const formData = new FormData();
    formData.append('file', file);
    return window.http.put(`/courses/${courseId}/media/${assetType}`, formData)
      .then(response => resolve(response.data.path))
      .catch(error => reject(error.response.data));
  }),
  deleteAsset: (courseId, assetType) => new Promise((resolve, reject) => {
    window.http.delete(`/courses/${courseId}/media/${assetType}`)
      .then(response => resolve(response.data.path))
      .catch(error => reject(error.response.data));
  }),
  update: (courseId, data, updateContent = false) => new Promise((resolve, reject) => {
    const params = data;
    params.updateContent = updateContent;
    window.http.put(`/courses/${courseId}`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateCommentsSettings: (courseId, data) => new Promise((resolve, reject) => {
    window.http.put(`/courses/${courseId}/comments-settings`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  delete: courseId => new Promise((resolve, reject) => {
    window.http.delete(`/courses/${courseId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  previewLoginJWT: membershipId => new Promise((resolve, reject) => {
    window.http.get(`courses/${membershipId}/preview-login-jwt`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  checkForPreview: courseId => new Promise((resolve, reject) => {
    window.http.get(`courses/${courseId}/check-preview-login`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  checkForPreviewAppDomain: courseId => new Promise((resolve, reject) => {
    window.http.get(`courses/${courseId}/check-preview-login-app-domain`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  sendMailTest: (courseId, type) => new Promise((resolve, reject) => {
    window.http.get(`courses/${courseId}/send-example-email?type=${type}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  addPdfFile: (courseId, data, file) => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(data).forEach((property) => {
      formData.append(property, data[property]);
    });
    window.http.put(`/courses/${courseId}/add-pdf-file`, formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  stats: data => new Promise((resolve, reject) => {
    if (!data || !data.courseIds || !data.courseIds.length) {
      resolve([]);
    }
    window.http.post('/courses/stats', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  transactionsList: data => new Promise((resolve, reject) => {
    if (!data || !data.courseIds || !data.courseIds.length) {
      resolve([]);
      return;
    }
    window.http.post('/courses/transactions', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  courseList: () => new Promise((resolve, reject) => {
    window.http.get('/courses/list')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  refund: data => new Promise((resolve, reject) => {
    window.http.post('/courses/refund', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  loadEmbedPreview: embedCode => new Promise((resolve, reject) => {
    window.http.post('/courses/take-shot', { embedCode }, { responseType: 'blob' })
      .then((response) => {
        const r = new FileReader();
        r.readAsDataURL(response.data);
        r.onloadend = () => {
          resolve(r.result);
        };
      })
      .catch(error => reject(error.response ? error.response.data : error));
  }),
  copy: courseId => new Promise((resolve, reject) => {
    if (!courseId) {
      resolve([]);
    }
    window.http.post('/courses/duplicate', { courseId })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  requestIntegration: data => new Promise((resolve, reject) => {
    window.http.post('/courses/request-integration', { data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadImageContent: file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    return window.http.post('/courses/upload-content-image', formData)
      .then(response => resolve(response.data.link))
      .catch(error => reject(error.response.data));
  }),
  addNewLesson: data => new Promise((resolve, reject) => {
    window.http.post('/courses/add-new-lesson', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  addNewModule: data => new Promise((resolve, reject) => {
    window.http.post('/courses/add-new-module', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
