<template>
  <v-app>
    <router-view/>
    <custom-modal/>
    <alert/>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomModal from '@/layout/CustomModal';
  import Alert from '@/layout/Alert';
  import createConnection from '@/socket';
  import UserApi from '@/api/user';

  export default {
    name: 'App',
    components: {
      CustomModal,
      Alert,
    },
    data() {
      return {
        socket: null,
      };
    },
    computed: {
      ...mapGetters(['userDataAtSync', 'fingerprint', 'whoIam']),
      ...mapGetters('clientAgency', ['agencyProfile']),
    },
    created() {
      if (process.env.VUE_APP_MAINTENANCE_MODE !== '1') {
        if (window.location.hostname.includes(process.env.VUE_APP_AGENCY_DOMAIN)
          || window.location.hostname !== process.env.VUE_APP_DOMAIN
        ) {
          let domain = '';
          if (window.location.hostname.includes(process.env.VUE_APP_AGENCY_DOMAIN)) {
            domain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
          } else {
            domain = window.location.hostname;
          }
          if (domain) {
            this.setIsAgencyDomain(true);
            window.document.body.classList.add('agency-domain');
            this.fetchAgencyProfile(domain).then(() => {})
              .catch(() => {
                window.location.href = process.env.VUE_APP_URL;
              });
          }
        }
        this.$store.dispatch('setFingerprint').then(() => {
          if (localStorage.getItem('jwtToken')) {
            this.$store.dispatch('syncMe').then(() => {
              this.initSocketService();
            });
          }
        });
      }
    },
    methods: {
      ...mapActions('clientAgency', ['fetchAgencyProfile', 'setIsAgencyDomain']),
      ...mapActions(['setUserSocket']),
      initSocketService() {
        if (this.whoIam) {
          UserApi.getCountryInfoByIp().then(
            (res) => {
              this.socket = createConnection(
                localStorage.getItem('jwtToken'),
                this.fingerprint,
                res,
                this.whoIam.id,
              );
              this.setUserSocket(this.socket);
            },
          );
        }
      },
    },
  };
</script>
