import _map from 'lodash.map';
import _values from 'lodash.values';

export default {
  methods: {
    mapServerErrorsToFields(errors) {
      if (!this.$validator || !this.$options) {
        return;
      }
      _map(errors, (error) => {
        const field = this.$validator.fields.find({
          name: error.property,
          scope: this.$options.scope,
        });
        if (!field) return;
        this.$validator.errors.add({
          id: field.id,
          field: error.property,
          msg: _values(error.constraints)[0],
          scope: this.$options.scope,
        });
      });
    },
    putErrorToField(fieldName, text) {
      if (!this.$validator || !this.$options) {
        return;
      }
      const field = this.$validator.fields.find({
        name: fieldName,
        scope: this.$options.scope,
      });
      if (!field) return;
      this.$validator.errors.add({
        id: field.id,
        field: fieldName,
        msg: text,
        scope: this.$options.scope,
      });
    },
    validateHTML(html) {
      if (html === '' || html === undefined) {
        return true;
      }
      const doc = document.createElement('div');
      doc.innerHTML = html;
      return (doc.innerHTML === html);
    },
  },
};
