<template>
  <div class="partnership-modal__content client-management__wizard">
    <div class="mt-20 mb-30">
      <term-selector v-model="partnershipContract" />
    </div>
    <div class="layout">
      <v-spacer />
      <v-btn
        :loading="loading"
        class="custom-btn-secondary fixed-width"
        @click="save"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import TermSelector from './Term';

  export default {
    components: {
      TermSelector,
    },
    data() {
      return {
        loading: false,
        partnershipContract: {},
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps']),
    },
    created() {
      this.partnershipContract = this.modalComponentProps.contract;
    },
    methods: {
      ...mapActions(['closeModal']),
      ...mapActions('partnership', ['updatePartnershipContract']),
      async save() {
        this.loading = true;
        const params = {
          id: this.partnershipContract.id,
          paymentTerm: this.partnershipContract.paymentTerm,
          paymentType: this.partnershipContract.paymentType,
          amount: this.partnershipContract.amount,
          duePeriod: this.partnershipContract.duePeriod,
        };
        this.updatePartnershipContract(params).then(() => {
          this.loading = false;
          this.closeModal();
        })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
