<template>
  <div class="client-management">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'membership_dashboard' })">
        Campaigns
      </a>
      <fa-icon :icon="['fal', 'chevron-right']"/>
      <a class="primary-text" @click="$router.push({ name: 'dashboard.client-management' })">
        Client Management
      </a>
      <fa-icon :icon="['fal', 'chevron-right']"/>
      <a class="primary-text active">
        {{ clientDetail.name }}
      </a>
    </div>
    <div class="client-management__box mt-20">
      <div class="layout justify-space-between client-management__detail-row">
        <p class="client-management__title">Client Account</p>
        <v-menu
          transition="slide-y-transition"
          content-class="custom-menu membership__box-menu"
          offset-y bottom left>
          <template v-slot:activator="{ on }">
            <button
              class="custom-action-dropdown__btn ml-10"
              v-on="on"
            >
              <icon-more/>
            </button>
          </template>
          <div class="custom-action-dropdown">
            <button
              v-if="clientDetail.status === 'active'"
              class="dropdown-btn"
              @click="updateClientStatus('pause')"
            >
              <icon-password class="icon-default icon-copy-black" />
              <span>Pause client access</span>
            </button>
            <button
              v-else-if="clientDetail.status === 'pause'"
              class="dropdown-btn"
              @click="updateClientStatus('active')"
            >
              <icon-password-unlocked class="icon-default svg-black" />
              <span>Resume access</span>
            </button>
            <button
              v-else-if="clientDetail.status === 'pending' && clientDetail.paymentType === 'free'"
              class="dropdown-btn"
              @click="sendActiveEmail(clientDetail.id)"
            >
              <icon-recheck class="icon-default svg-black" />
              <span>Resend Email Active</span>
            </button>
            <button class="dropdown-btn delete" @click="deleteSelf">
              <icon-trash class="icon-default" />
              <span>Delete client account</span>
            </button>
          </div>
        </v-menu>
      </div>
      <div class="layout align-center">
        <div class="layout align-center">
          <div class="client-management__detail-avatar" :style="avatarBg" :class="{ uploading }">
            <v-progress-circular v-if="uploading" :size="20" indeterminate color="#5458FB"/>
            <initial-avatar v-else-if="!clientDetail.avatar" :name="clientDetail.name"/>
            <input v-show="false" ref="avatarInput" type="file" @change="onChangeAvatar">
            <span class="client-management__detail-avatar-edit" @click="$refs.avatarInput.click()">
              <icon-edit-x class="icon-default" />
            </span>
          </div>
          <div class="ml-10">
            <p class="primary-text semi-bold lg">{{ clientDetail.name }}</p>
            <p class="primary-text dark-text">{{ clientDetail.email }}</p>
            <p class="primary-text dark-text flex-text mt-5">
              <active-status :client="clientDetail" class="mr-2" />
              {{ membershipTitle }}
            </p>
          </div>
        </div>
      </div>
      <div class="account-setting__container client-management__detail-container alternative">
        <v-tabs v-model="tab" class="account-setting__tabs">
          <v-tab
            v-if="clientDetail.paymentType === 'internal'"
            class="account-setting__tab" href="#payments"
          >
            Payments
          </v-tab>
          <v-tab class="account-setting__tab" href="#settings">Settings</v-tab>
          <v-tab class="account-setting__tab" href="#permissions">Permissions</v-tab>
          <v-tab class="account-setting__tab" href="#integration">Integrations</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="account-setting__tab-item">
          <v-tab-item value="payments"
                      :transition="false"
                      :reverse-transition="false">
            <client-payment />
          </v-tab-item>
          <v-tab-item value="settings"
                      :transition="false"
                      :reverse-transition="false">
            <client-setting />
          </v-tab-item>
          <v-tab-item value="permissions"
                      :transition="false"
                      :reverse-transition="false">
            <client-permission />
          </v-tab-item>
          <v-tab-item value="integration"
                      :transition="false"
                      :reverse-transition="false">
            <client-integration />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import ConfirmDialog from '@/components/ConfirmDialog';
  import ActiveStatus from './client/ActiveStatus';
  import ClientSetting from './client/ClientSetting';
  import ClientPayment from './client/ClientPayment';
  import ClientPermission from './client/ClientPermission';
  import ClientIntegration from './client/integration/ClientIntegration';

  export default {
    components: {
      ClientIntegration,
      ClientPermission,
      ActiveStatus,
      InitialAvatar,
      ConfirmDialog,
      ClientSetting,
      ClientPayment,
    },
    data() {
      return {
        uploading: false,
        tab: 'settings',
      };
    },
    computed: {
      ...mapGetters(['membershipList', 'userCourses']),
      ...mapGetters('agency', ['clientDetail']),
      avatarBg() {
        if (!this.clientDetail) {
          return '';
        }
        return { 'background-image': `url("${this.clientDetail.avatar}")` };
      },
      membershipTitle() {
        if (!this.clientDetail || !this.clientDetail.clientMemberships) {
          return '';
        }
        return this.clientDetail.clientMemberships.map(c => c.membership.title).join(',');
      },
    },
    async created() {
      if (!this.userCourses.length) {
        await this.syncCourses();
      }
      if (!this.membershipList.length) {
        await this.fetchOwnMemberships();
      }
      this.fetchClientDetail(this.$route.params.id);
    },
    methods: {
      ...mapActions('agency', ['fetchClientDetail', 'updateClient', 'deleteClient', 'sendActiveEmail']),
      ...mapActions(['fetchOwnMemberships', 'syncCourses', 'showAlert']),
      updateClientStatus(status) {
        this.updateClient({ id: this.clientDetail.id, status });
      },
      deleteSelf() {
        this.$refs.confirm.open('Are you sure you want to delete this client?', {}).then((res) => {
          if (res) {
            this.deleteClient(this.$route.params.id);
            this.$router.push({ name: 'dashboard.client-management' });
          }
        });
      },
      onChangeAvatar() {
        if (!this.$refs.avatarInput.files[0]) {
          return;
        }
        this.uploading = true;

        const file = this.$refs.avatarInput.files[0];

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.updateClient({ id: this.clientDetail.id, uploadAvatar: event.target.result })
              .then(() => {
                this.uploading = false;
              });
          };
          reader.readAsDataURL(file);
        }
      },
    },
  };
</script>
