<template>
  <div class="partnership-admin__commission-detail">
    <div class="flex-between mb-30">
      <div v-if="detail.contract.partnership" class="flex-start mt-10 pointer">
        <div class="avatar-image mr-15" :style="getAvatar(detail.contract.partnership)">
          <initial-avatar
            v-if="!detail.contract.partnership.avatar"
            :name="detail.contract.partnership.name" />
        </div>
        <div>
          <div class="primary-text xl semi-bold">
            {{ detail.contract.partnership.name }}
          </div>
          <div class="primary-text lg dark mt-5">
            {{ detail.contract.partnership.email }}
          </div>
        </div>
      </div>
      <div class="flex-end">
        <v-btn
          class="custom-btn-normal ml-2 sm"
          @click="$emit('back')"
        >Back</v-btn>
      </div>
    </div>

    <div class="mb-30 border-card">
      <statistic-comp :label="'Partner Statistics'" />
    </div>

    <div class="border-card">
      <div class="flex-between">
        <div class="primary-text xl semi-bold">Commissions</div>
        <div>
          <date-range-picker
            v-model="dateRangeTable"
            :customClass="'range'"
            :changeDateOption="dateOptionTable"
            :right="true"
            @change="onDateDatatableChanged"
          />
        </div>
      </div>
      <div class="partnership__custom-table mt-20">
        <table class="my-datatable custom last-column-right partnership-table">
          <table-header class="table-header" :headers="headers" @sort="sortData" />
          <tbody>
            <tr
              v-for="(item, index) in dataTable"
              :key="index"
              class="partnership__custom-table__row"
            >
              <td>
                <div class="flex-start">
                  <span class="primary-text lg semi-bold">{{ item.courses[0].title }}</span>
                </div>
              </td>
              <td>
                <div class="flex-start">
                  <div
                    class="status-box primary-text semi-bold"
                    :class="[item.isPaid ?
                      'active' : 'pending']"
                  >
                    {{ item.isPaid ? 'Paid' : 'Due' }}
                  </div>
                </div>
              </td>
              <td class="primary-text lg dark">
                {{ moment(item.createdAt).format('DD MMM, YYYY') }}
              </td>
              <td>
                <div>
                  <div class="primary-text xl semi-bold">${{ item.amountUsd }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import _cloneDeep from 'lodash.clonedeep';
  import _debounce from 'lodash.debounce';
  import { mapActions } from 'vuex';
  import PayCommission from './PayCommission';
  import StatisticComp from './Statistic.vue';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import DateRangePicker from '@/components/select/DateRangePicker';
  import TableHeader from '@/components/table/TableHeader';

  export default {
    components: {
      InitialAvatar,
      StatisticComp,
      DateRangePicker,
      TableHeader,
    },
    mixins: [linkHelper],
    props: {
      detail: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        moment,
        listStattisticOption: [{
          label: 'net commissions',
          value: 'net_commissions',
          prefix: '$',
          suffix: '',
        }, {
          label: 'clicks',
          value: 'clicks',
          prefix: '',
          suffix: '',
        }, {
          label: 'transactions',
          value: 'transactions',
          prefix: '',
          suffix: '',
        }, {
          label: 'refunds',
          value: 'refunds',
          prefix: '',
          suffix: '%',
        }, {
          label: 'refund amount',
          value: 'refund_amount',
          prefix: '$',
          suffix: '',
        }, {
          label: 'gross sales',
          value: 'gross_sales',
          prefix: '$',
          suffix: '',
        }],
        dateOptionTable: 'Year to date',
        dateRangeTable: {
          start: '',
          end: '',
        },
        debounce_filter_table: _debounce(() => {
          this.getCommissionAdminData();
        }, 500),
        prop: 'createdAt',
        order: 'ASC',
        headers: [{
          label: 'course',
          sort: true,
          field: 'course',
          width: '40%',
        }, {
          label: 'status',
          sort: true,
          field: 'status',
        }, {
          label: 'date',
          sort: true,
          field: 'createdAt',
        }, {
          label: 'amount',
          sort: true,
          field: 'amount',
        }],
      };
    },
    computed: {
      dataTable() {
        const data = this.detail
          ? [_cloneDeep(this.detail)] : [];
        return data && data.length > 0 ? data
          .sort((a, b) => {
            const tmp1 = this.order === 'ASC' ? _cloneDeep(a) : _cloneDeep(b);
            const tmp2 = this.order === 'ASC' ? _cloneDeep(b) : _cloneDeep(a);
            if (this.prop === 'course') {
              return tmp1.courses[0].title.toString()
                .localeCompare(tmp2.courses[0].title.toString());
            }
            if (this.prop === 'status' || this.prop === 'paymentType') {
              return tmp1[this.prop].toString().localeCompare(tmp2[this.prop].toString());
            }
            if (this.prop === 'createdAt') {
              return new Date(tmp1[this.prop]).getTime() - new Date(tmp2[this.prop]).getTime();
            }
            return tmp1[this.prop] - tmp2[this.prop];
          }) : [];
      },
    },
    methods: {
      ...mapActions('partnership', [
        'getCommissionAdminData',
        'adminPayment',
      ]),
      ...mapActions([
        'openCustomModal',
      ]),
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
      getAvatar(member) {
        return member.avatar ? { 'background-image': `url("${this.imageUrl(member.avatar)}")` } : {};
      },
      onDateDatatableChanged(date) {
        this.dateOptionTable = date.option;
        this.debounce_filter_table();
      },
      onPay(item) {
        this.openCustomModal({
          title: 'Pay Commission',
          component: PayCommission,
          width: '600px',
          modalClass: 'partnership-modal',
          data: {
            detail: Object.assign({}, item, {
              member: this.detail.member,
            }),
            onSubmitCallback: (confirmed) => {
              if (confirmed) {
                this.adminPayment(item).then(() => {
                  this.isManual = false;
                });
              }
            },
          },
        });
      },
      onViewContract() {
        // console.log('onViewContract');
      },
    },
  };
</script>
