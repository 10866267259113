<template>
  <v-container fill-height fluid class="client-invoice">
    <div class="fill-height">
      <div class="client-invoice__wrapper">
        <div v-if="loading" class="text-loading text-loading__logo" />
        <img v-else :src="agencyLogo" alt="" class="client-invoice__logo">
        <div v-if="loading" class="client-management__box mt-30">
          <div class="text-loading margin-auto text-loading_sm3" />
          <div class="text-loading margin-auto text-loading_sm2 mt-10"/>
          <div class="text-loading text-center text-loading__full mt-30"/>
        </div>
        <template v-else>
          <div class="client-management__box mt-30">
            <h2 class="text-center heading-text">INVOICE</h2>
            <p class="primary-text mt-10 dark-text text-center">
              Invoice #{{ invoiceCode }} / {{ invoiceDate }}
            </p>
            <div class="mt-30">
              <p class="link-blue xl primary-text">Total Amount ( {{ invoiceCurrency }} )</p>
              <h2 class="mt-5 heading-text">{{ invoiceAmount }}</h2>
              <p class="primary-text">Payment method - Stripe</p>
            </div>
            <div class="mt-30">
              <p class="client-invoice__text-label primary-text bold xl mb-10">Partner Details</p>
              <div class="layout justify-space-between">
                <div>
                  <p class="label-text">Name</p>
                  <p class="primary-text mt-5">{{ invoiceData.client.name }}</p>
                </div>
                <div>
                  <p class="label-text text-right">Email</p>
                  <p class="primary-text mt-5">{{ invoiceData.client.email }}</p>
                </div>
              </div>
            </div>
            <div class="mt-30">
              <p class="client-invoice__text-label mt-30 primary-text bold xl mb-10">Description</p>
              <div class="layout justify-space-between">
                <div>
                  <p class="label-text">Payment</p>
                  <p class="primary-text mt-5">Client Payment</p>
                  <p class="primary-text dark-text sm">
                    {{ isOneTime ? 'One-time payment' : 'Subscription payment' }}
                  </p>
                </div>
                <div>
                  <p class="label-text text-right">Amount</p>
                  <p class="primary-text mt-5">{{ invoiceAmount }}</p>
                </div>
              </div>
            </div>
            <template v-if="step !== 'pay'">
              <v-btn
                block
                color="secondary"
                class="mt-30 mb-15 auth__button"
                @click="goToPayStep"
              >
                Pay Now
              </v-btn>
              <p class="primary-text">
                Your order was processed by <a class="link-blue" href="https://memberapp.io">Member App.</a>
                Copyright 2024 All rights reserved
              </p>
            </template>
          </div>
          <template v-if="step === 'pay'">
            <div class="client-management__box payment-box mt-20">
              <p class="primary-text lg semi-bold mb-15">Contact Information</p>
              <div class="layout">
                <div class="flex xs6">
                  <p class="label-text">Full name</p>
                  <v-text-field
                    v-model="formData.name"
                    v-validate.disable="'required'"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    data-vv-as="Full name"
                    class="default"
                    solo
                  />
                </div>
                <div class="flex xs6 ml-15">
                  <p class="label-text">Email</p>
                  <v-text-field
                    v-model="formData.email"
                    v-validate.disable="'required|email'"
                    :error-messages="errors.collect('email')"
                    data-vv-name="email"
                    data-vv-as="Email"
                    class="default"
                    solo
                  />
                </div>
              </div>
            </div>
            <div class="client-management__box payment-box mt-20">
              <p class="primary-text lg semi-bold">Payment Information</p>
              <p class="flex-text primary-text dark-text mt-2">
                <icon-secure class="icon-default" />
                This is a secure 128-bit SSL encrypted payment
              </p>
              <div class="layout mt-20 align-center">
                <div class="flex xs6">
                  <p class="label-text">Payment method</p>
                  <custom-select
                    v-model="payMethod"
                    :items="items"
                    item-title="title"
                    item-value="value"
                  />
                </div>
                <div class="flex xs6 ml-15 layout justify-end">
                  <img
                    src="/img/credit_card.png"
                    alt="Credit Card"
                    class="client-invoice__credit-logo"
                  >
                </div>
              </div>
              <div class="layout mt-20">
                <div class="flex xs6">
                  <p class="label-text">Card number</p>
                  <div id="stripe-card-number" class="stripe-input" />
                </div>
                <div class="flex xs4 ml-15">
                  <p class="label-text">Expiration Date</p>
                  <div id="stripe-card-expiry" class="stripe-input" />
                </div>
                <div class="flex xs2 ml-15">
                  <p class="label-text">CVC Code</p>
                  <div id="stripe-card-cvc" class="stripe-input" />
                </div>
              </div>
            </div>
            <v-btn
              block
              color="secondary"
              class="mt-30 mb-15 auth__button"
              :loading="submitting"
              @click="chargePayment"
            >
              {{ isOneTime ? 'Pay Now' : 'Subscribe' }}
            </v-btn>
            <p class="primary-text">
              Your order was processed by <a class="link-blue" href="https://memberapp.io">Member App.</a>
              Copyright 2024 All rights reserved
            </p>
          </template>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import { loadStripe } from '@stripe/stripe-js';
  import currencies from '@/config/currencies';
  import agencyClientApi from '@/api/clientAgency';
  import CustomSelect from '@/components/select/CustomSelect';
  import commonHelper from '@/helper/commonHelper';
  import IconSecure from '@/assets/icons/checkout/secure.svg';

  export default {
    components: {
      IconSecure,
      CustomSelect,
    },
    data() {
      return {
        cardNumber: null,
        payMethod: 'credit',
        items: [
          { title: 'Credit Card', value: 'credit' },
        ],
        step: 'info',
        submitting: false,
        loading: false,
        stripe: null,
        invoiceData: {},
        stripeElementErrors: {},
        formData: {
          name: '',
          email: '',
        },
      };
    },
    computed: {
      ...mapGetters('clientAgency', ['isAgencyDomain', 'agencyProfile']),
      agencyLogo() {
        if (!this.isAgencyDomain || !this.agencyProfile.id) {
          return null;
        }
        return this.agencyProfile.logo;
      },
      invoiceAmount() {
        return commonHelper.formatMoneyByCurrency(this.invoiceCurrency, this.invoiceData.amount);
      },
      invoiceCurrency() {
        return currencies[this.invoiceData.currency].title.toUpperCase();
      },
      invoiceDate() {
        return moment(this.invoiceData.createdAt).format('DD MMM, YYYY');
      },
      invoiceCode() {
        return commonHelper.getInvoiceCode(this.invoiceData.id);
      },
      isOneTime() {
        return this.invoiceData.client.paymentSubscriptionLevel === 'one-time';
      },
      shouldShowAlert() {
        return Object.keys(this.stripeElementErrors).length > 0;
      },
    },
    created() {
      this.loading = true;
      agencyClientApi.getInvoiceDataByUuid(this.$route.params.uuid).then((res) => {
        if (!res.stripeAccountId || res.status === 'success') {
          this.$router.push('/');
          return;
        }
        this.loading = false;
        this.invoiceData = res;
        this.formData.name = this.invoiceData.client.name;
        this.formData.email = this.invoiceData.client.email;
      }).catch(() => {
        this.$router.push('/');
      });
    },
    methods: {
      ...mapActions(['showAlert']),
      goToPayStep() {
        this.step = 'pay';
        this.$nextTick(() => {
          this.initStripe();
        });
      },
      async loadStripe() {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
                                       { stripeAccount: this.invoiceData.stripeAccountId });
      },
      initStripe() {
        this.loadStripe().then(() => {
          this.createElements(this.stripe.elements({
            fonts: [
              {
                cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap',
              },
            ],
            locale: this.lang,
          }));
        });
      },
      createElements(elements) {
        const elementStyles = {
          base: {
            color: '#222A3C',
            fontWeight: 400,
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '14px',

            '::placeholder': {
              color: '#859DA7',
            },
            ':-webkit-autofill': {
              color: '#222A3C',
            },
          },
          invalid: {
            color: '#EB5757',

            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        };

        const elementClasses = {
          focus: 'focus',
          empty: 'empty',
          invalid: 'invalid',
        };

        const cardNumber = elements.create('cardNumber', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardNumber.mount('#stripe-card-number');
        this.cardNumber = cardNumber;

        const cardExpiry = elements.create('cardExpiry', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardExpiry.mount('#stripe-card-expiry');

        const cardCvc = elements.create('cardCvc', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardCvc.mount('#stripe-card-cvc');

        this.registerElements([cardNumber, cardExpiry, cardCvc]);
      },
      registerElements(elements) {
        const vue = this;

        elements.forEach((element, idx) => {
          element.on('change', (event) => {
            if (event.error) {
              vue.$set(vue.stripeElementErrors, idx, event.error.message);
              vue.stripeElementErrors[idx] = event.error.message;
            } else {
              vue.$delete(vue.stripeElementErrors, idx, '');
            }
          });
        });
      },
      async processOneTime(paymentMethod) {
        const paymentResponse = await agencyClientApi.chargeInvoice({
          uuid: this.$route.params.uuid,
          paymentMethodId: paymentMethod.paymentMethod.id,
          clientName: this.formData.name,
          clientEmail: this.formData.email,
        });
        if (paymentResponse.status === 'success') {
          const invoiceStatus = await agencyClientApi.checkInvoiceStatus(this.$route.params.uuid);
          if (invoiceStatus.code) {
            this.submitting = false;
            window.location.href = `/client/invite/${invoiceStatus.code}`;
          } else {
            this.submitting = false;
            this.showAlert({ message: invoiceStatus, type: 'danger' });
          }
        } else {
          this.submitting = false;
          this.showAlert({ message: 'Payment failed', type: 'danger' });
        }
      },
      async processSubscription(paymentMethod) {
        await agencyClientApi.subscribeInvoice({
          uuid: this.$route.params.uuid,
          paymentMethodId: paymentMethod.paymentMethod.id,
          clientName: this.formData.name,
          clientEmail: this.formData.email,
        });
        let count = 0;
        const interval = setInterval(() => {
          agencyClientApi.checkInvoiceStatus(this.$route.params.uuid)
            .then((res) => {
              count += 1;
              if (res.code) {
                this.submitting = false;
                window.location.href = `/client/invite/${res.code}`;
                clearInterval(interval);
              } else if (count === 4) {
                this.submitting = false;
                this.showAlert({ message: 'Payment failed', type: 'danger' });
                clearInterval(interval);
              }
            });
        }, 2000);
      },
      async chargePayment() {
        if (this.shouldShowAlert) {
          return;
        }

        this.submitting = true;

        const response = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardNumber,
          billing_details: {
            name: this.formData.name,
          },
        });
        if (response.error) {
          this.submitting = false;
          this.showAlert({ message: response.error.message, type: 'danger' });
          return;
        }
        try {
          if (this.isOneTime) {
            await this.processOneTime(response);
          } else {
            await this.processSubscription(response);
          }
        } catch (e) {
          this.showAlert({ message: e, type: 'danger' });
          this.submitting = false;
        }
      },
    },
  };
</script>
