<template>
  <div class="home-content">
    <iframe width="100%" height="100%" frameborder="0" :src="`https://memberapp.io?ref=${$route.query.ref ? $route.query.ref : 'briltd'}`" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'HomePage',
    computed: {
      ...mapGetters(['isLoggedIn']),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
    },
    mounted() {
      document.documentElement.classList.add('home-page');
      if (this.isLoggedIn && this.isAgencyDomain) {
        this.$router.push({ name: 'membership_dashboard' });
      }
    },
    beforeDestroy() {
      document.documentElement.classList.remove('home-page');
    },
  };
</script>

<style lang="scss">
.home-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
