<template>
  <div class="partnership-term">
    <div class="partnership-term__rows">
      <div class="partnership-term__title primary-text semi-bold lg">Select a Reward</div>
      <div class="partnership-term__value flex-between">
        <div class="flex-50">
          <p class="label-text">Payment Terms</p>
          <div>
            <custom-select
              v-model="termData.paymentTerm"
              :items="paymentTerms"
              item-title="label"
              item-value="value"
              @change="onUpdated"
            />
          </div>
        </div>
        <div class="flex-50">
          <p class="label-text">Amount</p>
          <div>
            <v-text-field
              v-model="termData.amount"
              type="number"
              suffix="%"
              class="default input-amount"
              @change="onUpdated"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="partnership-term__rows mt-20">
      <p class="partnership-term__title primary-text lg semi-bold">Select Payouts</p>
      <div class="partnership-term__value flex-between">
        <div class="flex-50">
          <p class="label-text">Processing Type:</p>
          <div>
            <custom-select
              v-model="termData.paymentType"
              :items="paymentList"
              item-title="label"
              item-value="value"
              @change="onUpdated"
            />
          </div>
        </div>
        <div class="flex-50">
          <p class="label-text">Processing Due:</p>
          <div>
            <custom-select
              v-model="termData.duePeriod"
              :items="periodList"
              item-title="label"
              item-value="value"
              @change="onUpdated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CustomSelect from '@/components/select/CustomSelect';

  export default {
    components: {
      CustomSelect,
    },
    props: ['value'],
    data() {
      return {
        paymentTerms: [{
          value: 'percentage_of_sale',
          label: 'Percentage of sale',
        }],
        paymentList: [{
          value: 'manual',
          label: 'Manually',
        }],
        periodList: [{
          value: 15,
          label: '15 days',
        }, {
          value: 30,
          label: '30 days',
        }, {
          value: 45,
          label: '45 days',
        }, {
          value: 60,
          label: '60 days',
        }],
        termData: {
          paymentTerm: 'percentage_of_sale',
          amount: undefined,
          paymentType: 'manual',
          duePeriod: undefined,
        },
      };
    },
    mounted() {
      this.termData = this.value;
    },
    methods: {
      onUpdated() {
        this.$emit('input', this.termData);
      },
    },
  };
</script>
