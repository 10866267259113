<template>
  <div class="custom-checkbox" :class="containerClass">
    <div class="member-custom-cb" :class="{'checked': value}" @click="check">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        default: false,
        type: Boolean,
      },
      customClass: {
        default: '',
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      containerClass() {
        let styleClass = '';
        if (this.customClass) {
          styleClass += this.customClass;
        }
        if (this.value) {
          styleClass += ' checked';
        }

        if (this.disabled) {
          styleClass += ' disabled';
        }

        return styleClass;
      },
    },

    methods: {
      check($event) {
        this.$emit('input', !this.value);
        // if ($event.target.classList.contains('checked')) {
        //   this.$emit('input', false);
        // } else {
        //   this.$emit('input', true);
        // }
        this.$emit('clickNative', $event);
      },
    },
  };
</script>
