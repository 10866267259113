export const VIEW_FUNNEL = 1;
export const EDIT_FUNNEL = 2;
export const VIEW_CUSTOMER = 3;
export const EDIT_CUSTOMER = 4;
export const VIEW_MARKETING = 5;
export const EDIT_MARKETING = 6;
export const VIEW_GROUP_CHAT = 7;
export const EDIT_GROUP_CHAT = 8;
export const VIEW_TRANSACTION = 9;
export const EDIT_TRANSACTION = 10;
export const VIEW_TRANSACTION_SUBSCRIPTION = 11;
export const EDIT_TRANSACTION_SUBSCRIPTION = 12;
export const VIEW_TRANSACTION_REFUND = 13;
export const EDIT_TRANSACTION_REFUND = 14;
export const VIEW_AFFILIATE_COMMISSION = 15;
export const EDIT_AFFILIATE_COMMISSION = 16;
export const VIEW_CAMPAIGN_GENERAL = 17;
export const EDIT_CAMPAIGN_GENERAL = 18;
export const VIEW_CAMPAIGN_PAYMENT = 19;
export const EDIT_CAMPAIGN_PAYMENT = 20;
export const VIEW_CAMPAIGN_BUNDLE = 21;
export const EDIT_CAMPAIGN_BUNDLE = 22;
export const VIEW_CAMPAIGN_INTEGRATION = 23;
export const EDIT_CAMPAIGN_INTEGRATION = 24;
export const VIEW_CAMPAIGN_EMAIL = 25;
export const EDIT_CAMPAIGN_EMAIL = 26;
export const VIEW_CAMPAIGN_LEGAL = 27;
export const EDIT_CAMPAIGN_LEGAL = 28;
export const VIEW_PLANNER = 29;
export const EDIT_PLANNER = 30;
export const VIEW_LESSON = 31;
export const EDIT_LESSON = 32;
export const VIEW_QUIZ = 33;
export const EDIT_QUIZ = 34;
export const VIEW_HOMEWORK = 35;
export const EDIT_HOMEWORK = 36;
export const VIEW_FEEDBACK = 37;
export const EDIT_FEEDBACK = 38;
export const VIEW_ASSIGNMENT = 39;
export const EDIT_ASSIGNMENT = 40;
export const VIEW_MEDIA = 41;
export const EDIT_MEDIA = 42;

export const allEditPermissions = [EDIT_CUSTOMER, EDIT_GROUP_CHAT,
  EDIT_TRANSACTION_REFUND, EDIT_TRANSACTION, EDIT_TRANSACTION_SUBSCRIPTION,
  EDIT_AFFILIATE_COMMISSION, EDIT_CAMPAIGN_EMAIL,
  EDIT_CAMPAIGN_GENERAL, EDIT_CAMPAIGN_BUNDLE, EDIT_CAMPAIGN_INTEGRATION, EDIT_CAMPAIGN_LEGAL,
  EDIT_CAMPAIGN_PAYMENT, EDIT_PLANNER, EDIT_LESSON, EDIT_HOMEWORK,
  EDIT_FEEDBACK, EDIT_ASSIGNMENT, EDIT_MEDIA, EDIT_QUIZ,
];
export const allViewPermissions = [VIEW_CUSTOMER, VIEW_GROUP_CHAT,
  VIEW_TRANSACTION_REFUND, VIEW_TRANSACTION, VIEW_TRANSACTION_SUBSCRIPTION,
  VIEW_CAMPAIGN_GENERAL, VIEW_CAMPAIGN_BUNDLE, VIEW_CAMPAIGN_INTEGRATION, VIEW_CAMPAIGN_LEGAL,
  VIEW_CAMPAIGN_PAYMENT, VIEW_CAMPAIGN_EMAIL, VIEW_PLANNER, VIEW_LESSON, VIEW_HOMEWORK,
  VIEW_FEEDBACK, VIEW_ASSIGNMENT, VIEW_MEDIA, VIEW_QUIZ, VIEW_AFFILIATE_COMMISSION,
];

export const groupPermission = {
  student: [EDIT_CUSTOMER, VIEW_CUSTOMER],
  discussion: [EDIT_GROUP_CHAT, VIEW_GROUP_CHAT],
  transaction: [EDIT_TRANSACTION, VIEW_TRANSACTION],
  subscription: [EDIT_TRANSACTION_SUBSCRIPTION, VIEW_TRANSACTION_SUBSCRIPTION],
  refund: [EDIT_TRANSACTION_REFUND, VIEW_TRANSACTION_REFUND],
  commission: [EDIT_AFFILIATE_COMMISSION, VIEW_AFFILIATE_COMMISSION],
  campaignGeneral: [EDIT_CAMPAIGN_GENERAL, VIEW_CAMPAIGN_GENERAL],
  campaignPayment: [EDIT_CAMPAIGN_PAYMENT, VIEW_CAMPAIGN_PAYMENT],
  campaignIntegration: [EDIT_CAMPAIGN_INTEGRATION, VIEW_CAMPAIGN_INTEGRATION],
  campaignBundle: [EDIT_CAMPAIGN_BUNDLE, VIEW_CAMPAIGN_BUNDLE],
  campaignEmail: [EDIT_CAMPAIGN_EMAIL, VIEW_CAMPAIGN_EMAIL],
  campaignLegal: [EDIT_CAMPAIGN_LEGAL, VIEW_CAMPAIGN_LEGAL],
  planner: [EDIT_PLANNER, VIEW_PLANNER],
  lesson: [EDIT_LESSON, VIEW_LESSON],
  quiz: [EDIT_QUIZ, VIEW_QUIZ],
  homework: [EDIT_HOMEWORK, VIEW_HOMEWORK],
  feedback: [EDIT_FEEDBACK, VIEW_FEEDBACK],
  assignment: [EDIT_ASSIGNMENT, VIEW_ASSIGNMENT],
  media: [EDIT_MEDIA, VIEW_MEDIA],
};

export const clientPermissionList = [
  {
    label: 'General',
    id: 'general',
    items: [
      {
        title: 'Students',
        permissions: [EDIT_CUSTOMER, VIEW_CUSTOMER],
        id: 'student',
      },
      {
        title: 'Discussion',
        permissions: [EDIT_GROUP_CHAT, VIEW_GROUP_CHAT],
        id: 'discussion',
      },
    ],
  },
  {
    label: 'Transactions',
    id: 'transactions',
    items: [
      {
        title: 'Transactions',
        permissions: [EDIT_TRANSACTION, VIEW_TRANSACTION],
        id: 'transaction',
      },
      {
        title: 'Subscriptions',
        permissions: [EDIT_TRANSACTION_SUBSCRIPTION, VIEW_TRANSACTION_SUBSCRIPTION],
        id: 'subscription',
      },
      {
        title: 'Refunds',
        permissions: [EDIT_TRANSACTION_REFUND, VIEW_TRANSACTION_REFUND],
        id: 'refund',
      },
    ],
  },
  {
    label: 'Affiliates',
    id: 'affiliate',
    items: [
      {
        title: 'Commissions',
        permissions: [EDIT_AFFILIATE_COMMISSION, VIEW_AFFILIATE_COMMISSION],
        id: 'commission',
      },
    ],
  },
  {
    label: 'Campaign Settings',
    id: 'campaignSetting',
    items: [
      {
        title: 'General',
        permissions: [EDIT_CAMPAIGN_GENERAL, VIEW_CAMPAIGN_GENERAL],
        id: 'campaignGeneral',
      },
      {
        title: 'Payment',
        permissions: [EDIT_CAMPAIGN_PAYMENT, VIEW_CAMPAIGN_PAYMENT],
        id: 'campaignPayment',
      },
      {
        title: 'Bundles',
        permissions: [EDIT_CAMPAIGN_BUNDLE, VIEW_CAMPAIGN_BUNDLE],
        id: 'campaignBundle',
      },
      {
        title: 'Integrations',
        permissions: [EDIT_CAMPAIGN_INTEGRATION, VIEW_CAMPAIGN_INTEGRATION],
        id: 'campaignIntegration',
      },
      {
        title: 'Email',
        permissions: [EDIT_CAMPAIGN_EMAIL, VIEW_CAMPAIGN_EMAIL],
        id: 'campaignEmail',
      },
      {
        title: 'Legal',
        permissions: [EDIT_CAMPAIGN_LEGAL, VIEW_CAMPAIGN_LEGAL],
        id: 'campaignLegal',
      },
    ],
  },
  {
    label: 'Content',
    id: 'content',
    items: [
      {
        title: 'Planner',
        permissions: [EDIT_PLANNER, VIEW_PLANNER],
        id: 'planner',
      },
      {
        title: 'Lessons',
        permissions: [EDIT_LESSON, VIEW_LESSON],
        id: 'lesson',
      },
      {
        title: 'Quizzes',
        permissions: [EDIT_QUIZ, VIEW_QUIZ],
        id: 'quiz',
      },
      {
        title: 'Homework',
        permissions: [EDIT_HOMEWORK, VIEW_HOMEWORK],
        id: 'homework',
      },
      {
        title: 'Feedback',
        permissions: [EDIT_FEEDBACK, VIEW_FEEDBACK],
        id: 'feedback',
      },
      {
        title: 'Assignments',
        permissions: [EDIT_ASSIGNMENT, VIEW_ASSIGNMENT],
        id: 'assignment',
      },
      {
        title: 'Media',
        permissions: [EDIT_MEDIA, VIEW_MEDIA],
        id: 'media',
      },
    ],
  },
];


export const clientPermissionListFe = [
  {
    label: 'General',
    id: 'general',
    items: [
      {
        title: 'Students',
        permissions: [EDIT_CUSTOMER, VIEW_CUSTOMER],
        id: 'student',
      },
    ],
  },
  {
    label: 'Campaign Settings',
    id: 'campaignSetting',
    items: [
      {
        title: 'General',
        permissions: [EDIT_CAMPAIGN_GENERAL, VIEW_CAMPAIGN_GENERAL],
        id: 'campaignGeneral',
      },
      {
        title: 'Payment',
        permissions: [EDIT_CAMPAIGN_PAYMENT, VIEW_CAMPAIGN_PAYMENT],
        id: 'campaignPayment',
      },
      {
        title: 'Integrations',
        permissions: [EDIT_CAMPAIGN_INTEGRATION, VIEW_CAMPAIGN_INTEGRATION],
        id: 'campaignIntegration',
      },
    ],
  },
  {
    label: 'Content',
    id: 'content',
    items: [
      {
        title: 'Planner',
        permissions: [EDIT_PLANNER, VIEW_PLANNER],
        id: 'planner',
      },
      {
        title: 'Lessons',
        permissions: [EDIT_LESSON, VIEW_LESSON],
        id: 'lesson',
      },
      {
        title: 'Quizzes',
        permissions: [EDIT_QUIZ, VIEW_QUIZ],
        id: 'quiz',
      },
      {
        title: 'Homework',
        permissions: [EDIT_HOMEWORK, VIEW_HOMEWORK],
        id: 'homework',
      },
      {
        title: 'Feedback',
        permissions: [EDIT_FEEDBACK, VIEW_FEEDBACK],
        id: 'feedback',
      },
    ],
  },
];
