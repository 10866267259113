<template>
  <div>
    <icon-stripe-normal class="d-block mt-10" />
    <v-btn class="custom-btn-primary mt-20" @click="connectStripe">Connect</v-btn>
    <div class="layout justify-end mt-20">
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width" @click="closeModal">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['whoIam']),
    },
    methods: {
      ...mapActions(['closeModal']),
      connectStripe() {
        if (this.whoIam) {
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/stripe/authorize/${this.whoIam.id}`;
        }

        return null;
      },
    },
  };
</script>
