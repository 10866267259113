<template>
  <layout class="affiliate-auth">
    <template v-slot:title>
      <h2 class="auth__box-title">Select your account to get started:</h2>
    </template>
    <template v-if="whoIam">
      <div
        v-for="(account,index) in whoIam.accounts"
        :key="index"
        class="select-account__item"
        :class="{'active' : checkActive(account)}"
      >
        <div class="layout align-center account-info">
          <initial-avatar v-if="!account.avatar" :name="getName(account)"/>
          <img v-else :src="getAvatarAccount(account)" class="account-avatar">
          <div class="layout column">
            <p>{{ getName(account) }}</p>
            <span>{{ getPosition(account) }}</span>
          </div>
        </div>
        <v-btn class="custom-btn-secondary" @click="triggerChange(account)">
          Go To Account
        </v-btn>
      </div>
    </template>
  </layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Layout from '@/layout/Auth';
  import linkHelper from '@/mixins/linkHelper';
  import InitialAvatar from '@/components/InitialAvatar';

  export default {
    components: {
      Layout,
      InitialAvatar,
    },
    mixins: [linkHelper],
    computed: {
      ...mapGetters([
        'whoIam',
      ]),
    },
    methods: {
      ...mapActions([
        'switchAccount',
      ]),
      checkActive(user) {
        return user.userId === this.whoIam.userId && user.id === this.whoIam.id;
      },
      getName(user) {
        if (!user) {
          return '';
        }

        if (user.isAffiliateUser) {
          if (user.firstName && user.lastName) {
            return `${user.firstName} ${user.lastName}`;
          }
          return user.username;
        }

        if (user.role) {
          const property = '__user__';
          if (user[property] && user[property].businessName) {
            return user[property].businessName;
          }

          return user.name;
        }

        if (this.checkActive(user) || !user.businessName) {
          return `${user.firstName} ${user.lastName}`;
        }

        return user.businessName;
      },
      getPosition(user) {
        if (!user) {
          return '';
        }
        if (user.isAffiliateUser) {
          return 'Affiliate';
        }
        if (!user.role) {
          return 'User';
        }
        return `${user.role} Role`;
      },
      getAvatar(avatar) {
        return avatar ? this.imageUrl(avatar) : '/img/no_avatar_2.png';
      },
      getAvatarAccount(user) {
        if (user.role) {
          const property = '__user__';
          if (user[property] && user[property].avatar) {
            return this.getAvatar(user[property].avatar);
          }
        }

        return this.getAvatar(user.avatar);
      },
      triggerChange(account) {
        this.switchAccount({ account, currentUser: this.whoIam })
          .then(() => {
            if (account.isAffiliateUser) {
              this.$router.push({ name: 'dashboard.affiliate-user.main' });
            } else if (account.role === 'support') {
              this.$router.push({ name: 'dashboard.user-management' });
            } else if (this.$route.name !== 'dashboard.membership.main') {
              this.$router.push({ name: 'dashboard.membership.main' });
            }
          });
      },
    },
  };
</script>
