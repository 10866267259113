<template>
  <layout class="client-auth" :logo="agencyLogo">
    <template v-slot:title>
      <h2 class="auth__box-title">
        Complete your registration to<br> access your account.
      </h2>
      <div class="w-100 step-bar">
        <div class="layout">
          <div
            v-for="(step, idx) in stepConfig"
            :key="idx"
            :class="{ active: currentStep >= step.id }"
            class="step-bar__item mr-4"
          >
            <div class="step-bar__item-label primary-text semi-bold">
              {{ step.id }}. {{ step.label }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div>
      <template v-if="currentStep === 1"><register-form @next="currentStep += 1" /></template>
      <template v-if="currentStep === 2">
        <complete-register @back="currentStep -= 1" />
      </template>
    </div>
  </layout>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Layout from '@/layout/Auth';
  import RegisterForm from '@/page/client/register/Form.vue';
  import CompleteRegister from '@/page/client/register/CompleteRegister.vue';

  export default {
    components: {
      Layout,
      RegisterForm,
      CompleteRegister,
    },
    data() {
      return {
        currentStep: 1,
        stepConfig: [{
          id: 1,
          label: 'Personal Information',
        }, {
          id: 2,
          label: 'Complete Registration',
        }],
      };
    },
    computed: {
      ...mapGetters('clientAgency', ['agencyProfile']),
      avatarDisplay() {
        if (!this.avatar) {
          return '/student-avatar-placeholder.png';
        }
        return this.avatar;
      },
      agencyLogo() {
        if (!this.agencyProfile || !this.agencyProfile.id) {
          return null;
        }
        return this.agencyProfile.logo;
      },
    },
  };
</script>
