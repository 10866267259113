<template>
  <div class="client-detail__container">
    <p class="primary-text semi-bold lg">Payment History</p>
    <div class="layout justify-space-between mt-30">
      <v-text-field
        v-model="searchKeyword"
        solo
        placeholder="Search by invoice number"
        class="client-detail__search-payment default"
      >
        <div slot="prepend-inner" class="text-icon-prepend">
          <icon-search/>
        </div>
      </v-text-field>
      <date-range-picker v-model="dateRange" :right="true" changeDateOption="Year to date" />
    </div>
    <div v-if="invoiceList.length" class="my-datatable__wrapper mt-3">
      <div class="my-datatable__scroller">
        <table class="my-datatable last-column-right client-management__table">
          <table-header :headers="headers" @sort="sort" />
          <tbody>
            <tr v-for="(invoice, index) in rows" :key="index">
              <td>{{ getInvoiceCode(invoice) }}</td>
              <td>
                <p class="primary-text">{{ getDayInvoice(invoice) }}</p>
                <p class="primary-text dark-text">{{ getTimeInvoice(invoice) }}</p>
              </td>
              <td>
                <span v-if="invoice.status === 'success'" class="badge badge-success">Paid</span>
                <span
                  v-else-if="invoice.status === 'pending' && isExpiredInvoice"
                  class="badge badge-error"
                >
                  Expired
                </span>
                <span v-else class="badge badge-warning">Pending</span>
              </td>
              <td class="text-right">
                <p class="primary-text xl semi-bold main-green">
                  {{ getAmountInvoice(invoice) }}
                </p>
                <stripe-logo class="mt-1" />
              </td>
              <td class="layout justify-end">
                <span
                  v-if="invoice.status === 'success'"
                  class="badge-btn badge-btn__normal"
                  @click="downloadInvoice(invoice)"
                >
                  <icon-download-pdf />
                  Download Invoice
                </span>
                <span v-else-if="invoice.status === 'pending' && isExpiredInvoice"
                      class="badge-btn badge-btn__normal" @click="resendInvoice(invoice)">
                  <icon-recheck />
                  Resend Invoice
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="no-data__box">
      <div class="no-data__box-content">
        <icon-no-payment class="mr-20" />
        <div>
          <p class="primary-text semi-bold lg">No Transactions</p>
          <p class="primary-text mt-1">Client haven’t made any transactions yet</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import currencies from '@/config/currencies';
  import commonHelper from '@/helper/commonHelper';
  import TableHeader from '@/components/table/TableHeader';
  import StripeLogo from '@/assets/tplogos/stripe.svg';
  import DateRangePicker from '@/components/select/DateRangePicker';
  import AgencyApi from '@/api/agency';

  export default {
    components: {
      DateRangePicker,
      StripeLogo,
      TableHeader,
    },
    data() {
      return {
        dateRange: {
          start: '',
          end: '',
        },
        searchKeyword: '',
        headers: [
          {
            label: 'Invoice#',
            field: 'id',
            sort: true,
            width: '20%',
          },
          {
            label: 'date',
            field: 'date',
            sort: true,
            width: '20%',
          },
          {
            label: 'status',
            field: 'status',
            sort: true,
            width: '20%',
          },
          {
            label: 'amount',
            field: 'amount',
            sort: true,
            width: '10%',
            textAlign: 'flex-end',
          },
          {
            label: 'action',
            textAlign: 'flex-end',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('agency', ['clientDetail', 'invoiceList']),
      isExpiredInvoice() {
        return moment(this.clientDetail.paymentDueDate) < moment();
      },
      rows() {
        if (!this.searchKeyword) {
          return this.invoiceList;
        }
        return this.invoiceList
          .filter((i) => {
            const invoiceCode = commonHelper.getInvoiceCode(i.id);
            return invoiceCode.toLowerCase().includes(this.searchKeyword.toLowerCase());
          });
      },
    },
    watch: {
      dateRange: {
        handler() {
          this.fetchData();
        },
        deep: true,
      },
    },
    created() {
      this.fetchData();
    },
    methods: {
      ...mapActions(['showAlert', 'openCustomModal']),
      ...mapActions('agency', ['updateClient', 'fetchClientInvoices']),
      fetchData() {
        const params = { clientId: this.$route.params.id };
        if (this.dateRange.start && this.dateRange.end) {
          params.start = this.dateRange.start.format('YYYY-MM-DD HH:mm:ss');
          params.end = this.dateRange.end.format('YYYY-MM-DD HH:mm:ss');
        }
        this.fetchClientInvoices(params);
      },
      sort(sort) {
        if (sort.order === 'DESC') {
          this.sortFunction = (a, b) => ((a[sort.field] > b[sort.field]) ? 1 : -1);
        } else {
          this.sortFunction = (a, b) => ((b[sort.field] > a[sort.field]) ? 1 : -1);
        }
      },
      getAmountInvoice(invoice) {
        const currency = currencies[invoice.currency].title.toUpperCase();
        return `+${commonHelper.formatMoneyByCurrency(currency, invoice.amount)}`;
      },
      getDayInvoice(invoice) {
        return moment(invoice.createdAt).format('MMM DD, YYYY');
      },
      getTimeInvoice(invoice) {
        return moment(invoice.createdAt).format('HH:MM');
      },
      getInvoiceCode(invoice) {
        return `#${commonHelper.getInvoiceCode(invoice.id)}`;
      },
      async resendInvoice(invoice) {
        await AgencyApi.resendInvoice(invoice.clientId);
        this.showAlert('Client invoice has been resent');
        this.fetchData();
      },
      downloadInvoice(invoice) {
        window.open(`${process.env.VUE_APP_API_URL}/client/invoice/${invoice.uuid}`);
      },
    },
  };
</script>
