<template>
  <div class="partnership__custom-table commission">
    <div v-if="!adminCommissionData.commissions.length" class="no-data__box">
      <div class="no-data__box-content">
        <icon-no-payment class="mr-20" />
        <div>
          <p class="primary-text semi-bold lg">No Transactions</p>
          <p class="primary-text mt-1">You haven’t made any transactions yet</p>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="primary-text xl semi-bold mb-20">
        {{ !isManual ? 'Partnership' : 'Select' }} Commissions
      </div>

      <template v-if="!isManual">
        <div
          class="mt-20 mb-20 partnership-admin__content__sale-statistic__box flex-between mt-20"
        >
          <div v-for="(item, index) in listOverviewOption" :key="index"
               class="sale-statistic__item">
            <div>
              <div class="text-uppercase primary-text sm dark mb-1">{{ item.label }}</div>
              <div class="primary-text xl bold">
                <span>{{ item.prefix }}</span>
                <span>
                  {{ adminCommissionData && adminCommissionData.sumary[item.value]
                    ? adminCommissionData.sumary[item.value] : 0 }}
                </span>
                <span>{{ item.suffix }}</span>
              </div>
            </div>
          </div>
          <div>
            <v-btn
              v-if="adminCommissionData && adminCommissionData.commissions.length > 0"
              class="custom-btn-secondary"
              @click="onManual"
            >
              Process Manual Commissions
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex-between">
          <div class="flex-start">
            <icon-info-x class="mr-1 icon-default" />
            <div class="primary-text lg">
              You will process <b>
                ${{ totalPrice }}
              </b> commission for <b>
                {{ selectedCommissionItem }}
              </b> user{{ selectedCommissionItem > 1 ? 's' : '' }}
            </div>
          </div>
          <div class="flex-end">
            <v-btn
              :disabled="selectedCommissionItem === 0
                || (selectedCommissionItem > 0 && !isValidPayment)"
              class="custom-btn-secondary sm mr-1"
              @click="onPay(selectedCommission, true)"
            >
              Process Commissions
            </v-btn>
            <v-btn
              class="custom-btn-normal sm ml-2"
              @click="isManual = false"
            >
              Cancel
            </v-btn>
          </div>
        </div>
      </template>

      <div class="mt-30 mb-20 flex-between partnership-admin__filter">
        <div>
          <custom-select
            v-model="filter.status"
            :items="listStatusConfig"
            :emitItemChange="true"
            item-title="label"
            item-value="id"
            @change="debounce_filter"
          />
        </div>
        <div>
          <multi-select
            v-model="filter.courseIds"
            :items="listCourse"
            :enableSelectAll="true"
            :show-selected="true"
            :itemName="'Campaign'"
            item-title="title"
            item-value="id"
            :title="''"
            @input="debounce_filter"
          />
        </div>
        <div>
          <date-range-picker
            v-model="dateRange"
            :customClass="'range'"
            :changeDateOption="dateOption"
            :right="true"
            class="ml-0"
            @change="debounce_filter"
          />
        </div>
      </div>

      <table class="my-datatable custom mt-20 last-column-right partnership-table">
        <table-header :headers="headersConfig" @sort="sortData">
          <template v-for="(header, index) in headersConfig">
            <div v-if="header.customElements" :key="index">
              <div v-if="header.field === 'selector'" class="select-all-commission">
                <custom-checkbox
                  v-model="selectedCommissionAll"
                  custom-class="commission-checkbox"
                  @clickNative="onSelectAll"
                />
              </div>
            </div>
          </template>
        </table-header>
        <tbody>
          <tr
            v-for="(item, index) in dataTable"
            :key="index"
            class="partnership__custom-table__row"
          >
            <template v-for="(col, idx) in headersConfig">
              <td v-if="col.field === 'member'" :key="idx">
                <div class="flex-start mt-10 pointer" @click="onViewDetail(item)">
                  <div class="avatar-image mr-15" :style="getAvatar(item.contract.partnership)">
                    <initial-avatar
                      v-if="!item.contract.partnership.avatar"
                      :name="item.contract.partnership.name" />
                  </div>
                  <div>
                    <div class="primary-text xl">
                      {{ item.contract.partnership.name }}
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="col.field === 'isPay'" :key="idx">
                <div v-if="!item.isPaid" class="flex-end mr-20">
                  <div class="pay-box flex-start pointer" @click="onPay(item, false)">
                    <icon-card class="mr-1" />
                    <span class="primary-text lg dark">Pay</span>
                  </div>
                </div>
              </td>
              <td v-if="col.field === 'status'" :key="idx">
                <div class="flex-start">
                  <div
                    class="status-box primary-text semi-bold"
                    :class="[item.isPaid ?
                      'active' : 'pending']"
                  >
                    {{ item.isPaid ? 'Paid' : 'Due' }}
                  </div>
                </div>
              </td>
              <td v-if="col.field === 'createdAt'" :key="idx" class="primary-text lg dark">
                {{ moment(item.createdAt).format('DD MMM, YYYY') }}
              </td>
              <td v-if="col.field === 'paymentType'" :key="idx" class="primary-text lg dark">
                {{ paymentTerms[item.contract.paymentTerm] }}
              </td>
              <td v-if="col.field === 'amountUsd'" :key="idx">
                <div>
                  <div class="primary-text xl semi-bold">${{ item.amountUsd }}</div>
                </div>
              </td>
              <td v-if="col.field === 'selector'" :key="idx">
                <custom-checkbox
                  v-model="selectedCommission[index]"
                  custom-class="commission-checkbox"
                  @clickNative="onSelectSingle"
                />
              </td>
              <td v-if="col.field === 'paymentMethod'" :key="idx">
                <div v-if="!reRender" class="mr-20">
                  <custom-select
                    :value="paymentMethodSelector[index]"
                    :items="getPaymentList(item.contract.partnership.partnershipPayments)"
                    :emitItemChange="true"
                    :customHolder="true"
                    item-title="label"
                    item-value="id"
                    class="payment-method-selector"
                    @change="(value) => { onReload(index, value); }"
                  >
                    <div v-if="!paymentMethodSelector[index]" class="primary-text lg dark">
                      Select Payment Vendor
                    </div>
                    <icon-paypal-payment
                      v-else-if="paymentMethodSelector[index].type
                        === partnershipConfig.paymentMethodType.PAYPAL"
                      class="icon-paypal"
                    />
                    <icon-bank
                      v-else-if="paymentMethodSelector[index].type
                        === partnershipConfig.paymentMethodType.BANK"
                      class="icon-bank"
                    />
                  </custom-select>
                  <div v-if="paymentMethodSelector[index]" class="flex-start mt-5">
                    <div class="mr-1 primary-text lg">{{ paymentMethodSelector[index]
                      ? paymentMethodSelector[index].email : '-' }}</div>
                    <div class="pointer" @click="onCopy(paymentMethodSelector[index].email)">
                      <icon-copy
                        class="icon-default icon-copy"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>
<script>
  import moment from 'moment';
  import _debounce from 'lodash.debounce';
  import _cloneDeep from 'lodash.clonedeep';
  import { mapGetters, mapActions } from 'vuex';
  import copy from 'copy-to-clipboard';
  import ConfirmPayment from './ConfirmPayment';
  import PayCommission from './PayCommission';
  import CustomSelect from '@/components/select/CustomSelect.vue';
  import TableHeader from '@/components/table/TableHeader';
  import MultiSelect from '@/components/select/MultiSelect.vue';
  import DateRangePicker from '@/components/select/DateRangePicker';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import IconPaypalPayment from '@/assets/tplogos/paypal.svg';
  import CustomCheckbox from '@/components/shared/CustomCheckBox.vue';
  import partnershipConfig from '@/config/partnershipConfig';

  export default {
    components: {
      CustomSelect,
      TableHeader,
      MultiSelect,
      DateRangePicker,
      InitialAvatar,
      IconPaypalPayment,
      CustomCheckbox,
    },
    mixins: [linkHelper],
    data() {
      return {
        debounce_filter: _debounce(() => {
          this.getCommissionAdminData({
            courseIds: this.filter.courseIds.length === 0 ? undefined : this.filter.courseIds,
            start: moment(this.dateRange.start).format('YYYY-MM-DD'),
            end: moment(this.dateRange.end).format('YYYY-MM-DD'),
            nameSearch: this.filter.keywork ? this.filter.keywork : undefined,
            status: this.filter.status !== 'all' ? this.filter.status : undefined,
            page: 1,
          });
        }, 500),
        partnershipConfig,
        paymentTerms: partnershipConfig.paymentTerms,
        moment,
        reRender: false,
        isManual: false,
        selectedCommission: [],
        selectedCommissionAll: false,
        paymentMethodSelector: [],
        filter: {
          keywork: '',
          status: 'all',
          courseIds: [],
          dateFrom: '',
          dateTo: '',
        },
        dateOption: 'Year to date',
        dateRange: {
          start: '',
          end: '',
        },
        listStatusConfig: [{
          id: 'all',
          label: 'All Statuses',
        }, {
          id: 'paid',
          label: 'Paid',
        }, {
          id: 'due',
          label: 'Due',
        }],
        listOverviewOption: [
          {
            label: 'Due',
            value: 'due',
            prefix: '$',
            suffix: '',
          },
          {
            label: 'Upcoming',
            value: 'upcoming',
            prefix: '$',
            suffix: '',
          },
        ],
        prop: 'createdAt',
        order: 'ASC',
        headersManual: [{
          label: '',
          sort: false,
          field: 'selector',
          customElements: true,
          width: '7%',
        }, {
          label: 'partner',
          sort: true,
          field: 'member',
          width: '50%',
        }, {
          label: 'payment method',
          sort: false,
          field: 'paymentMethod',
          width: '30%',
        }, {
          label: 'amount',
          sort: true,
          field: 'amountUsd',
        }],
        headers: [{
          label: 'partner',
          sort: true,
          field: 'member',
          width: '50%',
        }, {
          label: 'status',
          sort: true,
          field: 'status',
        }, {
          label: 'date',
          sort: true,
          field: 'createdAt',
        }, {
          label: 'amount',
          sort: true,
          field: 'amountUsd',
        }],
      };
    },
    computed: {
      ...mapGetters([
        'membershipList',
      ]),
      ...mapGetters('partnership', [
        'adminCommissionData',
      ]),
      courseListSelect() {
        return this.membershipList;
      },
      listCourse() {
        return [...this.courseListSelect].slice(1, this.courseListSelect.length);
      },
      dataTable() {
        let data = this.adminCommissionData.commissions
          ? _cloneDeep(this.adminCommissionData.commissions) : [];
        if (this.isManual) {
          data = data.filter(el => !el.isPaid);
        }
        return data && data.length > 0 ? data
          .sort((a, b) => {
            const tmp1 = this.order === 'ASC' ? _cloneDeep(a) : _cloneDeep(b);
            const tmp2 = this.order === 'ASC' ? _cloneDeep(b) : _cloneDeep(a);
            if (this.prop === 'member') {
              return tmp1.contract.partnership.name.toString()
                .localeCompare(tmp2.contract.partnership.name.toString());
            }
            if (this.prop === 'status' || this.prop === 'paymentType') {
              return tmp1[this.prop].toString().localeCompare(tmp2[this.prop].toString());
            }
            if (this.prop === 'createdAt') {
              return new Date(tmp1[this.prop]).getTime() - new Date(tmp2[this.prop]).getTime();
            }
            return tmp1[this.prop] - tmp2[this.prop];
          }) : [];
      },
      headersConfig() {
        return this.isManual ? this.headersManual : this.headers;
      },
      totalPrice() {
        let tmp = 0;
        this.dataTable.forEach((item, index) => {
          if (this.selectedCommission[index]) {
            tmp += item.amountUsd;
          }
        });
        return tmp;
      },
      selectedCommissionItem() {
        return this.selectedCommission.filter(el => el).length;
      },
      isValidPayment() {
        const tmp = this.paymentMethodSelector.filter(el => !!el);
        return (this.selectedCommissionItem > 0 && tmp.length === this.selectedCommissionItem);
      },
    },
    methods: {
      ...mapActions([
        'openCustomModal',
        'showAlert',
      ]),
      ...mapActions('partnership', [
        'getCommissionAdminData',
        'adminPayment',
      ]),
      getAvatar(member) {
        return member.avatar ? { 'background-image': `url("${this.imageUrl(member.avatar)}")` } : {};
      },
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
      onManual() {
        this.isManual = true;
        this.selectedCommissionAll = false;
        this.selectedCommission = [];
        this.paymentMethodSelector = [];
        this.dataTable.forEach((el) => {
          if (el.contract.partnership.partnershipPayments.length > 0) {
            this.paymentMethodSelector.push({
              id: el.contract.partnership.partnershipPayments[0].id,
              type: el.contract.partnership.partnershipPayments[0].type,
              email: el.contract.partnership.partnershipPayments[0].setting.email,
              label: `${partnershipConfig.paymentMethodConfig[el.contract.partnership.partnershipPayments[0].type]}
                - ${el.contract.partnership.partnershipPayments[0].setting.email}`,
            });
          }
        });
      },
      onReload(index, value) {
        this.paymentMethodSelector[index] = value;
        this.reRender = true;
        this.$nextTick(() => {
          this.reRender = false;
        });
      },
      onViewDetail(item) {
        this.$emit('selected', item);
      },
      onPay(item, isAll) {
        let params = [];
        this.dataTable.forEach((el, index) => {
          if (this.paymentMethodSelector[index] && this.selectedCommission[index]) {
            params.push({
              commissionId: el.id,
              paymentMethodId: this.paymentMethodSelector[index].id,
            });
          }
        });
        if (params.length > 0) {
          this.openCustomModal({
            title: isAll ? ' ' : 'Pay Commission',
            component: isAll ? ConfirmPayment : PayCommission,
            width: isAll ? '470px' : '600px',
            modalClass: 'partnership-modal',
            data: {
              detail: item,
              onSubmitCallback: (confirmed, data) => {
                if (data) {
                  params = data;
                }
                if (confirmed) {
                  this.adminPayment({
                    data: params,
                  }).then(() => {
                    this.isManual = false;
                    this.selectedCommissionAll = false;
                    this.onSelectAll();
                    this.debounce_filter();
                  });
                }
              },
            },
          });
        }
      },
      onSelectAll() {
        this.selectedCommission = [];
        if (this.selectedCommissionAll) {
          this.dataTable.forEach(() => {
            this.selectedCommission.push(true);
          });
        }
      },
      onSelectSingle() {
        const tmp = this.selectedCommission.filter(item => item);
        if (tmp.length === this.dataTable.length) {
          this.selectedCommissionAll = true;
        } else {
          this.selectedCommissionAll = false;
        }
      },
      getPaymentList(value) {
        const tmp = [];
        value.forEach((el) => {
          tmp.push({
            id: el.id,
            type: el.type,
            email: el.type === partnershipConfig.paymentMethodType.BANK
              ? el.setting.bankNumber : el.setting.email,
            label: `${partnershipConfig.paymentMethodConfig[el.type]}
              - ${el.type === partnershipConfig.paymentMethodType.BANK
            ? el.setting.bankNumber : el.setting.email}`,
          });
        });
        return tmp;
      },
      onCopy(value) {
        this.showAlert('The Payment Info is copied to your clipboard.');
        copy(value);
      },
    },
  };
</script>
