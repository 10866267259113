<template>
  <div>
    <p class="primary-text">
      To connect Demio to Member App add your ‘API Key’ and ‘API Secret’
      found within your Demio account.
    </p>
    <div class="mt-20">
      <p class="label-text">Your API Secret</p>
      <v-text-field v-model="apiSecret"
                    solo
                    class="no-error" placeholder="Enter your Demio apiSecret" />
      <p v-if="showError.apiSecret" class="primary-text sm red-text mt-1">Please input API Url</p>
    </div>
    <div class="mt-20">
      <p class="label-text">Your API Key</p>
      <v-text-field v-model="apiKey"
                    solo
                    class="no-error" placeholder="Enter your Demio ApiKey" />
      <p v-if="showError.apiKey" class="primary-text sm red-text mt-1">Please input API key</p>
    </div>
    <div class="layout mt-20">
      <icon-info-x class="icon-default mr-1"/>
      <p class="primary-text dark-text">
        You can find detailed instructions on how to connect and use this integration
        <a
          class="link-violet"
          target="_blank"
          href="https://support.sambakker.com/hc/en-us/articles/360008033778"
        >
          here
        </a>.
      </p>
    </div>
    <div class="layout align-center mt-20">
      <p
        v-if="modalComponentProps.integration.active"
        class="flex-text primary-text sm cursor-pointer icon-red"
        @click="disconnect"
      >
        <icon-close class="icon-xs"/>
        Disconnect
      </p>
      <v-spacer />
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        apiKey: '',
        apiSecret: '',
        showError: {},
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps', 'whoIam']),
    },
    created() {
      this.apiKey = this.modalComponentProps.integration.api_key;
      this.apiSecret = this.modalComponentProps.integration.api_secret;
    },
    methods: {
      ...mapActions([
        'showAlert', 'closeModal', 'updateIntegrations',
      ]),
      async save() {
        if (this.apiSecret === '') {
          this.$set(this.showError, 'apiSecret', true);
          return;
        }
        if (this.apiKey === '') {
          this.$set(this.showError, 'apiKey', true);
          return;
        }
        const { integrations } = this.whoIam;
        const data = integrations.demio;

        data.active = true;
        data.api_key = this.apiKey;
        data.api_secret = this.apiSecret;

        integrations.demio = data;

        await this.updateIntegrations({ integrations });
        this.modalComponentProps.onSuccessCallback();
        this.closeModal();
      },
      disconnect() {
        const { integrations } = this.whoIam;
        const data = integrations.demio;

        data.active = false;
        data.api_key = '';
        data.api_secret = '';

        integrations.demio = data;
        const params = {
          integrations,
          key: 'demio',
          data,
        };
        this.modalComponentProps.onConfirmDialog('Are you sure you want to disconnect ActiveCampaign integration?', params);
        this.closeModal();
      },
    },
  };
</script>
