import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VuetifyConfirm from 'vuetify-confirm';
import VuePlyr from 'vue-plyr';

import ExchangeRate from '@/api/exchangeRate';

// import Notifications from 'vue-notification';
import '@/plugins/vuetify';
import '@/styles/index.scss';
import '@/bootstrap/icon-library';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/index';
import http from '@/bootstrap/http';
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import 'intro.js/introjs.css';

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(VuetifyConfirm);
// Vue.use(Notifications);
window.http = http;

Vue.prototype.$globalBus = new Vue();

Vue.use(VuePlyr);

window.mobilecheck = () => {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

new Vue({
  router,
  store,
  created() {
    ExchangeRate.getExchangeRateForAll().then((data) => {
      store.dispatch('setRates', data.rates);
    }).catch((err) => {
      // eslint-disable-next-line
      console.error(err);
    });
  },
  render: h => h(App),
}).$mount('#app');
