<template>
  <div class="account-setting__form">
    <div class="layout align-center justify-space-between">
      <p class="primary-text xl semi-bold">Integrations</p>
    </div>
    <div>
      <div v-for="(integration, index) in integrations"
           :key="index" class="account-setting__integration">
        <component
          :is="`icon-${integration.type.replace('_', '-')}`"
          class="integration-icon"
        />
        <div class="ml-10">
          <p class="primary-text semi-bold lg">{{ nameMap[integration.type] }}</p>
          <p class="primary-text">
            Automatically add students into your {{ nameMap[integration.type] }}<br>
            subscriber lists & apply tags.
          </p>
        </div>
        <v-spacer />
        <span
          :class="{connected: integration.active}"
          class="account-setting__integration-btn"
          @click="openIntegration(index)"
        >
          <template v-if="integration.active">
            <icon-check-box-active class="mr-2" />
            Connected
          </template>
          <template v-else>
            Connect
          </template>
        </span>
      </div>
      <div
        v-if="(whoIam.haveFeatureNew('INTEGRATION') || isAgencyDomain) && !clientProfile"
        class="account-setting__integration">
        <icon-zapier class="integration-icon" />
        <div class="ml-10">
          <p class="primary-text semi-bold lg">Zapier</p>
          <p class="primary-text">
            Automatically add students into your Zapier<br>
            subscriber lists & apply tags.
          </p>
        </div>
        <v-spacer />
        <span
          class="account-setting__integration-btn"
          @click="openZapier"
        >
          Connect
        </span>
      </div>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import _cloneDeep from 'lodash.clonedeep';
  import IconAweber from '@/assets/tplogos/aweber_sm.svg';
  import IconActiveCampaign from '@/assets/tplogos/activecampaign_sm.svg';
  import IconMailchimp from '@/assets/tplogos/mailchimp_sm.svg';
  import IconMarkethero from '@/assets/tplogos/markethero_sm.svg';
  import IconGetresponse from '@/assets/tplogos/getresponse_sm.svg';
  import IconOntraport from '@/assets/tplogos/ontraport_sm.svg';
  import IconEverwebinar from '@/assets/tplogos/everwebinar.svg';
  import IconZoomus from '@/assets/tplogos/zoomus.svg';
  import IconSendlane from '@/assets/tplogos/sendlane_sm.svg';
  import IconWebinarjam from '@/assets/tplogos/webinarjam.svg';
  import IconGotowebinar from '@/assets/tplogos/gotowebinar.svg';
  import IconDemio from '@/assets/tplogos/demio.svg';
  import IconInfusionsoft from '@/assets/tplogos/infusionsoft.svg';
  import IconMailerlite from '@/assets/tplogos/mailerlite.svg';
  import IconZapier from '@/assets/tplogos/zapier.svg';
  import IconGohighlevel from '@/assets/tplogos/gohighlevel.svg';
  import IconClepher from '@/assets/tplogos/clepher.svg';

  import Aweber from '@/components/integrations/service/Aweber.vue';
  import Mailchimp from '@/components/integrations/service/Mailchimp.vue';
  import Sendlane from '@/components/integrations/service/Sendlane.vue';
  import Ontraport from '@/components/integrations/service/Ontraport.vue';
  import MarketHero from '@/components/integrations/service/MarketHero.vue';
  import WebinarJam from '@/components/integrations/service/WebinarJam.vue';
  import EverWebinar from '@/components/integrations/service/EverWebinar.vue';
  import ActiveCampaign from '@/components/integrations/service/ActiveCampaign.vue';
  import GetResponse from '@/components/integrations/service/GetResponse.vue';
  import ZoomUs from '@/components/integrations/service/ZoomUs.vue';
  import GotoWebinar from '@/components/integrations/service/GotoWebinar';
  import Demio from '@/components/integrations/service/Demio';
  import Zapier from '@/components/integrations/service/Zapier';
  import Infusionsoft from '@/components/integrations/service/Infusionsoft.vue';
  import Mailerlite from '@/components/integrations/service/Mailerlite.vue';
  import GoHighLevel from '@/components/integrations/service/GoHighLevel.vue';
  import Clepher from '@/components/integrations/service/Clepher.vue';

  import ConfirmDialog from '@/components/ConfirmDialog';
  import { nameMap } from '@/config/integrations';

  export default {
    components: {
      ConfirmDialog,
      IconActiveCampaign,
      IconEverwebinar,
      IconAweber,
      IconClepher,
      IconGetresponse,
      IconMarkethero,
      IconOntraport,
      IconSendlane,
      IconMailchimp,
      IconWebinarjam,
      IconZoomus,
      IconInfusionsoft,
      IconGotowebinar,
      IconDemio,
      IconMailerlite,
      IconZapier,
      IconGohighlevel,
    },
    props: ['clientProfile'],
    data() {
      return {
        nameMap,
        integrations: [],
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
    },
    watch: {
      whoIam() {
        this.assignData();
      },
    },
    mounted() {
      this.setProfileIdIntegration(this.clientProfile ? this.clientProfile.id : null);
      this.assignData();
    },
    methods: {
      ...mapActions(['openCustomModal', 'updateIntegrations', 'setProfileIdIntegration', 'setProfileIntegrationType']),
      openIntegration(index) {
        const item = this.integrations[index];
        let componentName = item.type.charAt(0).toUpperCase() + item.type.slice(1);
        componentName = componentName.replace('_', ' ');
        const component = this.getComponentFromType(item.type);
        if (this.clientProfile && this.clientProfile.id) {
          this.setProfileIntegrationType(item.type);
        }
        this.openCustomModal({
          component,
          data: {
            clientProfile: this.clientProfile,
            integration: item,
            icon: `/img/tplogos/${item.type}.svg`,
            onConfirmDialog: (message, params) => {
              this.$refs.confirm.open(message, {}).then((res) => {
                if (res) {
                  this.updateIntegrations({ integrations: params.integrations }).then(() => {
                    this.assignData();
                  });
                }
              });
            },
            onSuccessCallback: () => {
              this.assignData();
            },
          },
          title: componentName,
          maxWidth: '430px',
          width: '430px',
          modalClass: 'account-setting__integration-modal',
        });
      },
      openZapier() {
        this.openCustomModal({
          component: Zapier,
          data: {
            icon: '/img/tplogos/zapier.svg',
          },
          title: 'Zapier',
          maxWidth: '430px',
          width: '430px',
          modalClass: 'account-setting__integration-modal',
        });
      },
      assignData() {
        this.integrations = [];
        let sourceData = _cloneDeep(this.whoIam);
        if (this.clientProfile) {
          sourceData = _cloneDeep(this.clientProfile);
        }
        if (sourceData !== undefined && sourceData !== null
          && sourceData.integrations !== undefined
          && sourceData.integrations !== null) {
          Object.keys(sourceData.integrations).forEach((key) => {
            if (key !== 'paypal' && key !== 'stripe' && (key !== 'zoomus' || sourceData.email === 'zoom@memberapp.io' || sourceData.email === 'thaianhtu1993@gmail.com')) {
              const tmp = _cloneDeep(sourceData.integrations[key]);
              tmp.type = key;
              this.integrations.push(tmp);
            }
          });
        }
      },
      getComponentFromType(type) {
        switch (type) {
        case 'aweber':
          return Aweber;
        case 'mailchimp':
          return Mailchimp;
        case 'active_campaign':
          return ActiveCampaign;
        case 'everwebinar':
          return EverWebinar;
        case 'webinarjam':
          return WebinarJam;
        case 'markethero':
          return MarketHero;
        case 'ontraport':
          return Ontraport;
        case 'getresponse':
          return GetResponse;
        case 'sendlane':
          return Sendlane;
        case 'zoomus':
          return ZoomUs;
        case 'gohighlevel':
          return GoHighLevel;
        case 'gotowebinar':
          return GotoWebinar;
        case 'demio':
          return Demio;
        case 'infusionsoft':
          return Infusionsoft;
        case 'mailerlite':
          return Mailerlite;
        case 'clepher':
          return Clepher;
        default:
          return Mailchimp;
        }
      },
    },
  };
</script>
