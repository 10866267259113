import subscriptionLevelsConfig from '@/config/subscriptionLevels';

export default {
  data: () => ({
    currencies: [
      { title: 'USD', id: 1 }, // Unites States Dollar (USD)
      { title: 'RUB', id: 2 }, // Russian Ruble (RUB)
      { title: 'EUR', id: 3 }, // Euro (EUR)
      { title: 'JPY', id: 4 }, // Japanese yen (JPY)
      { title: 'GBP', id: 5 }, // Pound sterling (GBP)
      { title: 'AUD', id: 6 }, // Australian dollar (AUD)
      { title: 'CAD', id: 7 }, // Canadian dollar (CAD)
      { title: 'CHF', id: 8 }, // Swiss franc (CHF)
      { title: 'NZD', id: 9 }, // New Zealand dollar (NZD)
    ],
    subscriptionTextMapping: {
      [subscriptionLevelsConfig.ONETIME]: 'One Time',
      [subscriptionLevelsConfig.MONTHLY]: 'Subscription',
      [subscriptionLevelsConfig.SPLIT]: 'Split Pay',
      [subscriptionLevelsConfig.CHOOSE]: 'User decides price',
      [subscriptionLevelsConfig.FREE]: 'Free',
    },
  }),
  computed: {
    subscriptionLevels() {
      return [
        {
          value: subscriptionLevelsConfig.ONETIME,
          title: 'One Time',
        },
        {
          value: subscriptionLevelsConfig.MONTHLY,
          title: 'Subscription',
        },
        {
          value: subscriptionLevelsConfig.SPLIT,
          title: 'Split Pay',
        },
        {
          value: subscriptionLevelsConfig.CHOOSE,
          title: 'User decides price',
        },
        {
          value: subscriptionLevelsConfig.FREE,
          title: 'Free',
        },
      ];
    },
  },
  methods: {
    emitUpdate(course) {
      this.$emit('update:course', course);
    },
  },
};
