import * as types from '@/store/mutation-types';
import TeamMemberApi from '@/api/teamMember';

const state = {
  teamMembers: [],
};

const getters = {
  teamMemberList: state => state.teamMembers,
};

const actions = {
  syncTeamMembers: ({ commit }) => new Promise((resolve, reject) => {
    TeamMemberApi.list().then(
      (res) => {
        commit(types.SET_TEAM_MEMBER_LIST, res);
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  addTeamMember: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    TeamMemberApi.create(data).then(
      (res) => {
        commit(types.ADD_TEAM_MEMBER, res);
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', { message: error, type: 'danger', duration: 2000 }, { root: true });
        reject(error);
      },
    );
  }),
  updateTeamMember: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    TeamMemberApi.update(data).then(
      (res) => {
        commit(types.UPDATE_TEAM_MEMBER, res);
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', { message: error, type: 'danger', duration: 2000 }, { root: true });
        reject(error);
      },
    );
  }),
  deleteTeamMember: ({ commit }, teamMember) => new Promise((resolve, reject) => {
    TeamMemberApi.deleteTeamMember(teamMember.id).then(
      () => {
        commit(types.DELETE_TEAM_MEMBER, teamMember);
        resolve();
      },
      (error) => {
        reject(error);
      },
    );
  }),
};

const mutations = {
  [types.SET_TEAM_MEMBER_LIST](state, payload) {
    state.teamMembers = payload;
  },
  [types.UPDATE_TEAM_MEMBER](state, payload) {
    const index = state.teamMembers.findIndex(e => e.id === payload.id);
    state.teamMembers[index] = payload;
  },
  [types.ADD_TEAM_MEMBER](state, payload) {
    state.teamMembers.push(payload);
  },
  [types.DELETE_TEAM_MEMBER](state, payload) {
    const idx = state.teamMembers.indexOf(payload);
    state.teamMembers.splice(idx, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
