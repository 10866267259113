<template>
  <div class="payment-form">
    <h1>
      Invoice # {{ paymentIntent.id }}
    </h1>
    <p class="subtitle"> payment form </p>

    <div class="mb-2 mt-2">
      <p class="amount"> Amount to be paid:    <span>{{amount}}  </span> </p>
      <p class="d`escription">
        <span>Payment description</span>:
        <br> {{paymentIntent.description}}
      </p>
    </div>


    <div class="mb-3 mt-3">
      <custom-input
        v-model="cardholderName"
        label="Card Holder name"
      />
    </div>

    <div id="card-element" class="mb-4 mt-3">
      <!-- A Stripe Element will be inserted here. -->
    </div>


    <div class="mb-3 mt-4">
      <custom-button
        :color="'primary'"
        :label="'Pay'"
        @click="pay"
      />
    </div>


  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomButton from '@/components/shared/CustomButton';
  import CustomInput from '@/components/shared/CustomInput';

  export default {
    components: {
      CustomButton,
      CustomInput,
    },
    data: () => ({
      cardholderName: 'Payer name',
      cardElement: null,
    }),

    computed: {
      ...mapGetters([
        'whoIam',
        'stripe',
        'elements',
        'paymentIntent',
      ]),

      amount() {
        const amount = Number.parseInt(this.paymentIntent.amount, 10);
        return `${(Number.isNaN(amount) ? 0 : amount / 100).toFixed(2)}USD`;
      },

    },
    watch: {
      paymentIntent: {
        handler() {
          this.cardholderName = this.fullName().toUpperCase();
        },
        deep: true,
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.fetchData();
        this.loadStripe().then(() => {
          this.createForm();
        });
      });
    },


    methods: {
      ...mapActions([
        'paymentIntentCode',
        'loadStripe',
        'showAlert',
      ]),
      fetchData() {
        // console.log(this.$route.params.code);
        this.paymentIntentCode(this.$route.params.code).then(
          () => {
            // console.log(res);
            // this.paymentIntent = res;
          },
          () => {

          },
        );
      },

      createForm() {
        // https://stripe.com/docs/payments/payment-intents/quickstart#automatic-confirmation-flow


        this.cardElement = this.elements.create('card');
        this.cardElement.mount('#card-element');

        // this.cardholderName = document.getElementById('cardholder-name');
        // var cardButton = document.getElementById('card-button');
        // var clientSecret = cardButton.dataset.secret;
      },
      pay() {
        this.stripe.handleCardPayment(
          this.paymentIntent.clientSecret, this.cardElement, {
            payment_method_data: {
              billing_details: { name: this.cardholderName },
            },
          },
        ).then((result) => {
          if (result.error) {
            // Display error.message in your UI.
            this.showAlert(`Error: ${result.error.message}`);
          } else {
            // The payment has succeeded. Display a success message.
            this.showAlert('The payment has succeeded.');
          }
        });
      },


      fullName() {
        if (this.paymentIntent && this.paymentIntent.payer) {
          return `${this.paymentIntent.payer.firstName} ${this.paymentIntent.payer.lastName}`;
        }
        return '';
      },


    },

  };
</script>
