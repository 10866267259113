import * as types from '@/store/mutation-types';
import LegalDocumentApi from '@/api/legalDocument';

const state = {
  list: [],
  atSync: false,
};

const actions = {
  updateLegalDocuments: ({ commit, dispatch }, data) => new Promise(
    (resolve, reject) => {
      commit(types.SET_LEGAL_DOCUMENTS_BUSY, true);
      LegalDocumentApi.update(data).then(
        (res) => {
          dispatch('showAlert', { message: 'Your legal pages have been updated.', type: 'success' });
          commit(types.SET_LEGAL_DOCUMENTS_BUSY, false);
          resolve(res);
        },
        (error) => {
          commit(types.SET_LEGAL_DOCUMENTS_BUSY, false);
          dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
          reject(error);
        },
      );
    },
  ),
  createLegalDocuments: ({ dispatch, commit }, data) => new Promise((resolve, reject) => {
    commit(types.SET_LEGAL_DOCUMENTS_BUSY, true);
    LegalDocumentApi.create(data).then(
      (response) => {
        commit(types.SET_LEGAL_DOCUMENTS_BUSY, false);
        resolve(response);
      },
      (error) => {
        commit(types.SET_LEGAL_DOCUMENTS_BUSY, false);
        dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
        reject(error);
      },
    );
  }),
  getLegalDocuments: ({ commit }, membershipId) => new Promise((resolve, reject) => {
    commit(types.SET_LEGAL_DOCUMENTS_BUSY, true);
    LegalDocumentApi.getList(membershipId).then(
      (res) => {
        commit(types.SET_LEGAL_DOCUMENTS_BUSY, false);
        commit(types.SET_LEGAL_DOCUMENTS, res);
        resolve(res);
      },
      (err) => {
        commit(types.SET_LECTURER_LIST_BUSY, false);
        reject(err);
      },
    );
  }),
};

const getters = {
  legalDocuments: state => state.list,
  legalDocumentsAtSync: state => state.atSync,
};

const mutations = {
  [types.SET_LEGAL_DOCUMENTS](state, payload) {
    state.list = payload;
  },
  [types.SET_LEGAL_DOCUMENTS_BUSY](state, payload) {
    state.atSync = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
