export default {
  getAll: () => new Promise((resolve, reject) => {
    window.http.get('/domains').then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  create: data => new Promise((resolve, reject) => {
    window.http.post('/domains', data).then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  recheck: domainId => new Promise((resolve, reject) => {
    window.http.get(`/domains/${domainId}/recheck`).then(
      domain => resolve(domain.data),
      (error) => { reject(error.response.data); },
    );
  }),
  delete: domainId => new Promise((resolve, reject) => {
    window.http.delete(`/domains/${domainId}`).then(
      () => { resolve(); },
      (error) => { reject(error.response.data); },
    );
  }),
  requestSsl: domainId => new Promise((resolve, reject) => {
    window.http.get(`/domains/${domainId}/requestSsl`).then(
      domain => resolve(domain.data.domain),
      (error) => { reject(error.response.data); },
    );
  }),
};
