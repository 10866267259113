<template>
  <div class="custom-input-wrapper">

    <div v-if="label" class="custom-input-label">
      <div class="custom-input-label-left">
        {{ label }}
      </div>
      <div
        v-if="labelRight"
        class="custom-input-label-right">
        {{ labelRight }}
      </div>
    </div>

    <div class="custom-input-field">
      <input
        ref="inputValue"
        :placeholder="placeholder"
        :value="value"
        :class="{
          'icon-prepended': icon && icon.length,
          'icon-appended': iconAppend && iconAppend.length
        }"
        @input="input" >

      <div v-if="iconAppend && iconAppend.length" class="custom-input-icon-append">
        <fa-icon v-if="isFaIcon(iconAppend)" :icon="iconAppend" />
        <custom-icon v-if="isCustomIcon(iconAppend)" :icon="iconAppend" />
      </div>
    </div>

    <div v-if="hint" class="custom-input-hint">
      {{ hint }}
    </div>

  </div>
</template>


<script>
  import CustomIcon from '@/components/shared/CustomIcon';

  export default {
    components: {
      CustomIcon,
    },
    props: {
      color: {
        default: 'primary',
        type: String,
      },
      value: {
        default: '',
        type: [String, Number],
      },
      label: {
        default: '',
        type: String,
      },
      labelRight: {
        default: '',
        type: String,
      },
      hint: {
        default: '',
        type: String,
      },
      placeholder: {
        default: '',
        type: String,
      },
      icon: {
        default: '',
        type: [String, Array],
      },
      iconAppend: {
        default: '',
        type: [String, Array],
      },

      disabled: {
        default: false,
        type: [Boolean],
      },
      loading: {
        default: false,
        type: [Boolean],
      },
    },
    data: () => ({

    }),

    methods: {
      input() {
        this.$emit('input', this.$refs.inputValue.value);
      },
      isFaIcon(icon) {
        if (typeof icon === 'undefined' || !icon) {
          return false;
        }
        if (icon.constructor === Array) {
          return true;
        }
        return false;
      },
      isCustomIcon(icon) {
        if (typeof icon === 'undefined' || !icon) {
          return false;
        }
        if (icon.constructor === String) {
          return true;
        }
        return false;
      },
    },

  };
</script>
