import Vue from 'vue';
import axios from 'axios';

import * as types from '@/store/mutation-types';
import roles from '../../config/roles';
import { FEATURE_BUNDLES, FEATURE_COURSE_CHECKOUT } from '../../config/permissions';

// const validAudioTypes = [
//   'audio/mp3', // mp3
// ];

// const maxAudioSize = (1024 ** 2) * 1500; // 1500 MB

const state = {
  steps: [
    {
      name: 'membership',
      labelCreate: 'Membership',
      firstTab: 'name',
      labelEdit: 'Membership',
      routeName: ['dashboard.membership.setting'],
      role: [roles.STAFF_ADMIN],
    },
    {
      name: 'course',
      labelCreate: 'Course',
      labelEdit: 'Course',
      firstTab: 'name',
      routeName: ['dashboard.membership.wizard.course'],
      role: [roles.STAFF_EDITOR, roles.STAFF_ADMIN],
    },
    {
      name: 'checkout',
      labelCreate: 'Checkout',
      labelEdit: 'Checkout',
      firstTab: 'payment',
      routeName: ['dashboard.membership.wizard.theme'],
      role: [roles.STAFF_EDITOR, roles.STAFF_ADMIN],
    },
    {
      name: 'payments',
      labelCreate: 'Payments',
      labelEdit: 'Payments',
      firstTab: 'paypal',
      routeName: ['dashboard.membership.wizard.theme'],
      role: [roles.STAFF_EDITOR, roles.STAFF_ADMIN],
    },
    {
      name: 'integration',
      labelCreate: 'Integration',
      labelEdit: 'Integration',
      firstTab: 'integrations',
      routeName: ['dashboard.membership.wizard.email'],
      role: [roles.STAFF_EDITOR, roles.STAFF_ADMIN],
    },
  ],
  currentIndex: 0,
  lastVisited: 0,
  courseId: 0,
  membershipId: 0,
  new: false,
  isCourseWizard: false, // For dertemine current on course wizard to using course api update
  isCourseWizardFlow: false, // For dertemine showing the navigation tab without membership
  mediaUploadingData: {},
};

const actions = {
  wizardNextStep: ({ commit, getters }) => {
    commit(types.WIZARD_NEXT_STEP, getters.wizardSteps);
    return Promise.resolve(true);
  },
  wizardPrevStep: ({ commit }) => {
    commit(types.WIZARD_PREV_STEP);
    return Promise.resolve(true);
  },
  wizardStep: ({ commit, getters }, idx) => {
    commit(types.WIZARD_STEP, { idx, wizardSteps: getters.wizardSteps });
    return Promise.resolve(true);
  },
  resetWizardSteps: ({ commit }) => {
    commit(types.RESET_WIZARD_STEPS);
    return Promise.resolve(true);
  },
  setWizardIds: ({ commit }, params) => {
    commit(types.SET_WIZARD_IDS, params);
    return Promise.resolve(true);
  },
  setWizardStepByRouteName: ({ commit, getters }, routeName) => {
    commit(types.SET_WIZARD_STEP_BY_ROUTE_NAME, { routeName, getters });
    return Promise.resolve(true);
  },
  setWizardNew: ({ commit }, val) => {
    commit(types.SET_WIZARD_NEW, val);
    return Promise.resolve(true);
  },
  setIsCourseWizard: ({ commit }, val) => {
    commit(types.SET_IS_COURSE_WIZARD, val);
    return Promise.resolve(true);
  },
  setIsCourseWizardFlow: ({ commit }, val) => {
    commit(types.SET_IS_COURSE_WIZARD_FLOW, val);
    return Promise.resolve(true);
  },
  cancelAllMediaUpload: ({ dispatch, state }) => {
    Object.keys(state.mediaUploadingData).forEach(v => dispatch('cancelMediaUpload', v));
  },
  cancelMediaUpload: ({ commit, state }, id) => new Promise((resolve) => {
    if (id in state.mediaUploadingData) {
      state.mediaUploadingData[id].token.cancel('Cancel');
    }

    commit(types.REMOVE_MEDIA_UPLOAD, id);

    resolve();
  }),
  uploadAudio: ({ state, commit, dispatch }, { file, lesson }) => {
    const lessonId = lesson.id;

    const cancelToken = axios.CancelToken.source();

    // if (!validAudioTypes.includes(file.type)) {
    //   reject(new Error('Only MP3 files are allowed!'));
    //   return;
    // }

    // // only accept pdf file maximum size is 10 MB
    // if (file.size > maxAudioSize) {
    //   reject(new Error('The file was too big!'));
    //   return;
    // }

    const config = {
      baseURL: process.env.VUE_APP_API_BASE_URL,
      onUploadProgress: (progressEvent) => {
        const percentUpload = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        const d = state.mediaUploadingData[lessonId];

        if (d && d.percent !== percentUpload) {
          commit(types.UPDATE_MEDIA_UPLOAD_INFO, {
            id: lessonId,
            percent: percentUpload,
          });
        }
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwtToken')}`,
      },
      cancelToken: cancelToken.token,
    };

    commit(types.ADD_MEDIA_TO_UPLOAD, { id: lessonId, token: cancelToken });

    let fileSize = file.size;

    if ((fileSize / 1024) < 1024) {
      fileSize = `${(fileSize / 1024).toFixed(2)}KB`;
    } else {
      fileSize = `${(fileSize / (1024 ** 2)).toFixed(2)}MB`;
    }

    const formData = new FormData();
    formData.append('audio', file);

    axios.post('upload-audio', formData, config).then(({ data }) => {
      const audioData = {
        name: file.name,
        size: fileSize,
        duration: data.duration,
        url: data.path,
        mime: data.mime,
      };
      Vue.set(lesson, 'audio', audioData);
      dispatch('updateLessonComponent', {
        id: lesson.id,
        params: {
          components: [{
            type: 'audio',
            content: audioData,
          }],
        },
      }).then(() => {
        window.saved = true;
      });
      commit(types.REMOVE_MEDIA_UPLOAD, lessonId);
      dispatch('showAlert', 'Audio successfully uploaded!');
    }).catch((err) => {
      if (!axios.isCancel(err)) {
        const { message } = err.response.data;
        commit(types.REMOVE_MEDIA_UPLOAD, lessonId);
        dispatch('showAlert', { type: 'danger', message: message || 'Upload failed' });
      }
    });

    // const formData = new FormData();
    // formData.append('audio', file);
    // axios.post('upload-audio', formData, config).then((response) => {
    //   resolve({
    //     name: file.name,
    //     size: fileSize,
    //     url: response.data.path,
    //   });
    // }).catch((error) => {
    //   if (!axios.isCancel(error)) {
    //     reject(new Error(error.message || 'Upload failed'));
    //   }
    // });
  },
  uploadVideo: ({ state, commit, dispatch }, { file, lesson }) => {
    const lessonId = lesson.id;

    const cancelToken = axios.CancelToken.source();

    const config = {
      baseURL: process.env.VUE_APP_VIDEOS_URL,
      onUploadProgress: (progressEvent) => {
        const percentUpload = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        const d = state.mediaUploadingData[lessonId];

        if (d && d.percent !== percentUpload) {
          commit(types.UPDATE_MEDIA_UPLOAD_INFO, {
            id: lessonId,
            percent: percentUpload,
          });
        }
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwtToken')}`,
      },
      cancelToken: cancelToken.token,
    };

    commit(types.ADD_MEDIA_TO_UPLOAD, { id: lessonId, token: cancelToken });

    const formData = new FormData();
    formData.append('video', file);

    axios.post('upload', formData, config).then(({ data }) => {
      const { uuid } = data;
      Vue.set(lesson, 'video', uuid);
      dispatch('updateLessonComponent', {
        id: lesson.id,
        params: {
          components: [{
            type: 'video',
            content: uuid,
          }],
        },
      }).then(() => {
        window.saved = true;
      });
      commit(types.REMOVE_MEDIA_UPLOAD, lessonId);
      dispatch('showAlert', {
        type: 'success',
        title: 'Video Uploaded',
        message: 'Your video is now processing',
      });
    }).catch((err) => {
      if (!axios.isCancel(err)) {
        const { error, message } = err.response.data;
        // eslint-disable-next-line
        console.error(error);
        commit(types.REMOVE_MEDIA_UPLOAD, lessonId);
        dispatch('showAlert', { type: 'danger', message });
      }
    });
  },
};

const getters = {
  currentWizardStep: (state, getters) => getters.wizardSteps[state.currentIndex],
  lastVisitedWizardStep: (state, getters) => getters.wizardSteps[state.lastVisited],
  currentWizardIndex: state => state.currentIndex,
  lastVisitedWizardIndex: state => state.lastVisited,
  getWizardIds: state => ({ courseId: state.courseId, membershipId: state.membershipId }),
  isCourseWizard: state => state.isCourseWizard,
  isCourseWizardFlow: state => state.isCourseWizardFlow,
  isWizardNew: state => state.new,
  wizardSteps: (state, getters) => {
    let newStep = [];
    state.steps.forEach((step) => {
      // payment step only for pro
      let pushPaymentStep = step.name !== 'payments' && step.name !== 'checkout';

      if (getters.whoIam) {
        if (step.name === 'checkout') {
          pushPaymentStep = getters.whoIam.isPlanNew
            ? (getters.whoIam.haveFeature(FEATURE_COURSE_CHECKOUT)
              || getters.whoIam.haveFeature(FEATURE_BUNDLES))
            : getters.userHavePlusPlan;
        }

        if (step.name === 'payments') {
          pushPaymentStep = getters.whoIam.isPlanNew
            ? (!getters.whoIam.haveFeature(FEATURE_COURSE_CHECKOUT)
              && !getters.whoIam.haveFeature(FEATURE_BUNDLES))
            : !getters.userHavePlusPlan;
        }
      }

      const push = getters.whoIam && (
        !getters.whoIam.role || (step.role && step.role.includes(getters.whoIam.role))
      ) && pushPaymentStep;

      // hide membership tab in case course wizard flow:
      const hideMembership = step.name === 'membership' && getters.isCourseWizardFlow;

      if (push && !hideMembership) {
        newStep.push(step);
      }
    });
    // For onboarding membership / course
    if (localStorage.getItem('onboarding')) {
      if (!getters.isCourseWizardFlow) {
        newStep = [state.steps[0]];
      } else {
        newStep = [state.steps[1]];
      }
    }

    return newStep;
  },
  mediaUploadingData: state => state.mediaUploadingData,
};

const mutations = {
  [types.WIZARD_NEXT_STEP](state, wizardSteps) {
    if (state.currentIndex < wizardSteps.length) {
      state.currentIndex += 1;
    }
    if (state.currentIndex > state.lastVisited) {
      state.lastVisited = state.currentIndex;
    }
  },
  [types.WIZARD_PREV_STEP](state) {
    if (state.currentIndex > 0) {
      state.currentIndex -= 1;
    }
  },
  [types.WIZARD_STEP](state, { idx, wizardSteps }) {
    if (idx >= 0 && idx < wizardSteps.length) {
      state.currentIndex = idx;
    }
  },
  [types.RESET_WIZARD_STEPS](state) {
    state.currentIndex = 0;
    state.lastVisited = 0;
    state.new = false;
  },
  [types.SET_WIZARD_IDS](state, params) {
    if (params) {
      // if (params.courseId) {
      state.courseId = params.courseId || 0;
      // }
      // if (params.membershipId) {
      state.membershipId = params.membershipId || 0;
      // }
    }
  },


  [types.SET_WIZARD_STEP_BY_ROUTE_NAME](state, { routeName, getters }) {
    const findStep = () => {
      const steps = getters.wizardSteps.map(w => w.routeName);

      return steps.findIndex(a => a.includes(routeName));
    };

    const idx = findStep(routeName);

    if (idx !== -1) {
      state.currentIndex = idx;
      if (state.currentIndex > state.lastVisited) {
        state.lastVisited = state.currentIndex;
      }
    }
  },
  [types.SET_WIZARD_NEW](state, val) {
    state.new = !!val;
  },
  [types.SET_IS_COURSE_WIZARD](state, val) {
    state.isCourseWizard = !!val;
  },
  [types.SET_IS_COURSE_WIZARD_FLOW](state, val) {
    state.isCourseWizardFlow = !!val;
  },
  [types.ADD_MEDIA_TO_UPLOAD](state, { id, token }) {
    Vue.set(state.mediaUploadingData, id, {
      token,
      percent: 0,
    });
  },
  [types.UPDATE_MEDIA_UPLOAD_INFO](state, { id, percent }) {
    state.mediaUploadingData[id].percent = percent;
  },
  [types.REMOVE_MEDIA_UPLOAD](state, id) {
    Vue.delete(state.mediaUploadingData, id);
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
