const textHelper = {
  methods: {
    trim(text, trimLength) {
      let length = trimLength;
      if (!length) {
        length = 80;
      }
      if (text && text.length > length) {
        return `${text.slice(0, length)}...`;
      }
      return text;
    },
  },
};

export default textHelper;
