<template>
  <layout v-if="!showPaymentMethodForm" class="client-auth">
    <template v-slot:title>
      <h2 class="auth__box-title">
        Complete your registration to<br> access your partnership account.
      </h2>
    </template>
    <div class="d-flex">
      <input v-show="false" ref="avatarInput" type="file" @change="onChangeAvatar">
      <div class="client-auth__avatar cursor-pointer" @click="$refs.avatarInput.click()">
        <img :src="avatarDisplay" alt="Student Placeholder">
        <span class="client-auth__avatar-btn">
          <icon-plus />
        </span>
      </div>
      <div>
        <p class="label-text">Name</p>
        <v-text-field
          v-model="formData.name"
          v-validate.disable="'required'"
          :error-messages="errors.collect('name')"
          data-vv-name="Name"
          solo
          placeholder="Enter your name"
          @keyup.enter="submit"/>
      </div>
    </div>
    <p class="label-text">Email</p>
    <v-text-field
      v-model="formData.email"
      v-validate.disable="'required'"
      :error-messages="errors.collect('email')"
      data-vv-name="Email"
      solo
      placeholder="Enter your email"
      @keyup.enter="submit"/>
    <p class="label-text">Create Password</p>
    <v-text-field
      v-model="formData.password"
      v-validate.disable="'required'"
      class="auth__password"
      :error-messages="errors.collect('Password')"
      data-vv-name="Password"
      solo
      :type="passwordType"
      placeholder="Enter a password"
      @keyup.enter="submit">
      <template v-slot:append>
        <div v-if="passwordType === 'password'"
             class="auth__icon" @click="passwordType = 'text'">
          <icon-preview/>
        </div>
        <div v-if="passwordType === 'text'"
             class="auth__icon" @click="passwordType = 'password'">
          <icon-hide/>
        </div>
      </template>
    </v-text-field>
    <p class="primary-text gray-text mb-10">
      By clicking 'Register' you agree to our
      <a href="" class="link-blue">Terms of Us</a>
      and <a class="link-blue">Privacy Policy</a>
    </p>
    <v-btn
      block
      color="secondary"
      class="mt-0 mb-0 auth__button"
      :loading="loading"
      @click="submit">
      Register
    </v-btn>
  </layout>
  <layout v-else class="client-auth partnership__auth-payment-form">
    <payment-method-form @goBack="nextStep" />
  </layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import validationHelpers from '@/mixins/validationHelpers';
  import Layout from '@/layout/Auth';
  import PaymentMethodForm from './components/PaymentMethodForm';

  export default {
    $_veeValidate: { validator: 'login' },
    components: {
      Layout,
      PaymentMethodForm,
    },
    mixins: [validationHelpers],
    data() {
      return {
        showPaymentMethodForm: false,
        avatar: '',
        passwordType: 'password',
        loading: false,
        formData: {
          email: '',
          id: '',
          name: '',
          password: '',
          uploadAvatar: null,
          code: '',
        },
      };
    },
    computed: {
      ...mapGetters(['whoIam', 'hasCountryToken']),
      avatarDisplay() {
        if (!this.avatar) {
          return '/student-avatar-placeholder.png';
        }
        return this.avatar;
      },
    },
    created() {
      const { code } = this.$route.params;
      try {
        const decode = JSON.parse(atob(code));
        if (decode.id === undefined || decode.name === undefined || decode.email === undefined) {
          this.$router.push('/');
          return;
        }
        this.formData.email = decode.email;
        this.formData.name = decode.name;
        this.formData.code = code;
        this.formData.id = decode.id;
      } catch (error) {
        this.$router.push('/');
      }
    },
    methods: {
      ...mapActions(['showAlert', 'tryToLogin', 'syncMe', 'fetchCountryToken', 'getCountryListData']),
      ...mapActions('partnership', ['activePartnership']),
      submit() {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              this.loading = true;
              if (this.avatar) {
                this.formData.uploadAvatar = this.avatar;
              }
              this.activePartnership(this.formData).then((jwt) => {
                window.http
                  .defaults
                  .headers
                  .Authorization = `Bearer ${jwt.token}`;
                localStorage.setItem('jwtToken', jwt.token);
                this.syncMe();
                this.showPaymentMethodForm = true;
                if (!this.hasCountryToken) {
                  this.fetchCountryToken();
                } else {
                  this.getCountryListData();
                }
                this.loading = false;
              })
                .catch((error) => {
                  this.showAlert({ type: 'danger', message: error });
                  this.loading = false;
                });
            }
          },
        );
      },
      nextStep() {
        this.$router.push({ name: 'partnership-invite', params: { code: this.formData.code } });
      },
      onChangeAvatar() {
        if (!this.$refs.avatarInput.files[0]) {
          return;
        }

        const file = this.$refs.avatarInput.files[0];

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.avatar = event.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
    },
  };
</script>
