<template>
  <div class="partnership pay-commission__content">
    <div class="primary-text lg">
      Confirm if you’ve processed commission.
      When you confirm an email will be sent to
      the affiliate to inform them that their commissions have been paid.
    </div>
    <div class="mt-20">
      <div class="flex-start">
        <div class="avatar-image mr-15" :style="getAvatar(dataModal.contract.partnership)">
          <initial-avatar
            v-if="!dataModal.contract.partnership.avatar"
            :name="dataModal.contract.partnership.name" />
        </div>
        <div>
          <div class="primary-text xl semi-bold">
            {{ dataModal.contract.partnership.name }}
          </div>
          <div class="primary-text lg dark mt-5">
            {{ dataModal.contract.partnership.email }}
          </div>
        </div>
      </div>
    </div>
    <div class="partnership__custom-table mt-20">
      <table class="my-datatable mt-20 last-column-right partnership-table">
        <table-header :headers="headers" @sort="sortData" />
        <tbody>
          <tr class="partnership__custom-table__row">
            <td>
              <div class="flex-start">
                <span class="primary-text lg semi-bold">{{ dataModal.courses[0].title }}</span>
              </div>
            </td>
            <td class="primary-text lg dark">
              {{ paymentTerms[dataModal.contract.paymentTerm] }}
            </td>
            <td>
              <div>
                <div class="primary-text xl semi-bold">${{ dataModal.amount }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-30">
      <div class="primary-text bold">Payment Info</div>
      <div class="flex">
        <div class="flex-50">
          <custom-select
            v-model="paymentMethodSelector"
            :items="getPaymentList(dataModal.contract.partnership.partnershipPayments)"
            :emitItemChange="true"
            :customHolder="true"
            item-title="label"
            item-value="id"
            class="payment-method-selector mt-5"
            @change="onReloadMethod"
          >
            <div v-if="!paymentMethodSelector" class="primary-text lg dark">
              Select Payment Vendor
            </div>
            <icon-paypal v-else-if="paymentMethodSelector.id === 1" class="icon-paypal" />
          </custom-select>
        </div>
      </div>
    </div>
    <div class="mt-20">
      <div class="primary-text bold">Payment Info</div>
      <div class="flex-start mt-5">
        <div class="mr-1 primary-text lg">{{ dataModal.contract.partnership.email }}</div>
        <icon-copy
          class="icon-default icon-copy pointer"
          @click="copy(dataModal.contract.partnership.email)" />
      </div>
    </div>
    <div class="mt-30 flex-end">
      <v-btn
        class="custom-btn-normal"
        @click="closeModal"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!paymentMethodSelector"
        class="custom-btn-secondary ml-2"
        @click="onConfirm(true)"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import copy from 'copy-to-clipboard';
  import CustomSelect from '@/components/select/CustomSelect.vue';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import TableHeader from '@/components/table/TableHeader';
  import partnershipConfig from '@/config/partnershipConfig';

  export default {
    components: {
      CustomSelect,
      InitialAvatar,
      TableHeader,
    },
    mixins: [linkHelper],
    data() {
      return {
        copy,
        paymentTerms: partnershipConfig.paymentTerms,
        paymentMethodSelector: undefined,
        listPaymentVendor: [{
          id: 'paypal',
          label: 'Paypal',
        }],
        prop: 'createdAt',
        order: 'ASC',
        headers: [{
          label: 'campaign',
          sort: false,
          field: 'course',
          width: '60%',
        }, {
          label: 'payment type',
          sort: false,
          field: 'paymentType',
        }, {
          label: 'amount',
          sort: false,
          field: 'amount',
        }],
      };
    },
    computed: {
      ...mapGetters([
        'modalComponentProps',
      ]),
      dataModal() {
        return this.modalComponentProps.detail;
      },
    },
    methods: {
      ...mapActions([
        'closeModal',
      ]),
      onReloadMethod(value) {
        this.paymentMethodSelector = value;
      },
      getAvatar(member) {
        return member.avatar ? { 'background-image': `url("${this.imageUrl(member.avatar)}")` } : {};
      },
      async onConfirm(type) {
        await this.modalComponentProps.onSubmitCallback(type, [{
          commissionId: this.dataModal.id,
          paymentMethodId: this.paymentMethodSelector.id,
        }]);
        this.closeModal();
      },
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
      getPaymentList(value) {
        const tmp = [];
        value.forEach((el) => {
          tmp.push({
            id: el.id,
            label: partnershipConfig.paymentMethodConfig[el.id],
          });
        });
        return tmp;
      },
    },
  };
</script>
