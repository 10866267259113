const widgets = {
  REVENUE: 1,
  REFUNDS: 2,
  NEW_USERS: 3,
  STUDENT_GENERAL: 4,
  LG_DETAILED_REPORT: 5,
  LG_NEW_USERS: 6,
  LG_REVENUE: 7,
  LG_SALES_SUMMARY: 8,
  LG_LESSONS_SUMMARY: 9,
  USER_ACTIVITY: 10,
  POPULAR: 11,
  AGE: 12,
  GENDER: 13,
  OVERVIEW_VIDEO: 14,
  DETAIL_VIDEO: 15,
  OVERVIEW_TEST: 16,
  GRADE_DISTRIBUTION: 17,
  STUDENT_RESULT: 18,
  QUESTION_POPULAR: 19,
  COMMENTS: 20,
  COURSE_STATISTICS: 21,
};

const defaultWidgets = [
  widgets.REVENUE,
  widgets.REFUNDS,
  widgets.NEW_USERS,
  widgets.STUDENT_GENERAL,
  widgets.LG_DETAILED_REPORT,
  widgets.LG_NEW_USERS,
  widgets.LG_REVENUE,
  widgets.LG_SALES_SUMMARY,
];

const userAnalyticDefaultWidgets = [
  widgets.STUDENT_GENERAL,
  widgets.USER_ACTIVITY,
  widgets.COURSE_STATISTICS,
  widgets.POPULAR,
  widgets.AGE,
  widgets.GENDER,
];

const videoAnalyticDefaultWidgets = [
  widgets.OVERVIEW_VIDEO,
  widgets.DETAIL_VIDEO,
];

const testAnalyticDefaultWidgets = [
  widgets.OVERVIEW_TEST,
  widgets.GRADE_DISTRIBUTION,
  widgets.STUDENT_RESULT,
  widgets.QUESTION_POPULAR,
];

const commentAnalyticDefaultWidgets = [
  widgets.COMMENTS,
];

const widgetLabel = [
  {
    label: 'large widgets',
    items: [
      {
        title: 'Report',
        id: widgets.LG_DETAILED_REPORT,
      },
      {
        title: 'New users',
        id: widgets.LG_NEW_USERS,
      },
      {
        title: 'Revenue',
        id: widgets.LG_REVENUE,
      },
      {
        title: 'Sales Summary',
        id: widgets.LG_SALES_SUMMARY,
      },
    ],
  },
];

const userAnalyticWidgetsLabel = [
  {
    label: 'user analytic widgets',
    items: [
      {
        title: 'Report',
        id: widgets.STUDENT_GENERAL,
      },
      {
        title: 'Course statistics',
        id: widgets.COURSE_STATISTICS,
      },
      {
        title: 'User activity',
        id: widgets.USER_ACTIVITY,
      },
      {
        title: 'Popular',
        id: widgets.POPULAR,
      },
      {
        title: 'Age',
        id: widgets.AGE,
      },
      {
        title: 'Gender',
        id: widgets.GENDER,
      },
    ],
  },
];

const videoAnalyticWidgetsLabel = [
  {
    label: 'video analytic widgets',
    items: [
      {
        title: 'Overview',
        id: widgets.OVERVIEW_VIDEO,
      },
      {
        title: 'Detail',
        id: widgets.DETAIL_VIDEO,
      },
    ],
  },
];

const testAnalyticWidgetsLabel = [
  {
    label: 'test analytic widgets',
    items: [
      {
        title: 'Overview',
        id: widgets.OVERVIEW_TEST,
      },
      {
        title: 'Grade Distribution',
        id: widgets.GRADE_DISTRIBUTION,
      },
      {
        title: 'Student Result',
        id: widgets.STUDENT_RESULT,
      },
      {
        title: 'Question Popular',
        id: widgets.QUESTION_POPULAR,
      },
    ],
  },
];

const commentAnalyticWidgetsLabel = [
  {
    label: 'Comment Widgets',
    items: [
      {
        title: 'Comment List',
        id: widgets.COMMENTS,
      },
    ],
  },
];

export default {
  widgets,
  widgetLabel,
  defaultWidgets,
  userAnalyticDefaultWidgets,
  videoAnalyticDefaultWidgets,
  testAnalyticDefaultWidgets,
  commentAnalyticDefaultWidgets,
  userAnalyticWidgetsLabel,
  videoAnalyticWidgetsLabel,
  testAnalyticWidgetsLabel,
  commentAnalyticWidgetsLabel,
};
