<template>
  <div>
    <div class="layout">
      <div class="select-payment mr-20">
        <input
          id="paypal"
          v-model="paymentType" type="radio" :value="type.PAYPAL"
        >
        <label for="paypal" class="select-payment__label">
          <span class="circle no-bg">
            <span class="active-circle"/>
          </span>
          <icon-paypal-text />
        </label>
      </div>
      <div class="select-payment">
        <input
          id="bank"
          v-model="paymentType" type="radio" :value="type.BANK"
        >
        <label for="bank" class="select-payment__label align-center layout">
          <span class="circle no-bg">
            <span class="active-circle"/>
          </span>
          <span class="primary-text semi-bold ml-2">
            Bank Account
          </span>
        </label>
      </div>
    </div>
    <paypal-form v-if="paymentType === type.PAYPAL"
                 :payment-data="editData" @goBack="$emit('goBack')" />
    <bank-form v-if="paymentType === type.BANK"
               :payment-data="editData" @goBack="$emit('goBack')" />
  </div>
</template>

<script>
  import affiliatePayment from '@/config/affiliatePayment';
  import BankForm from './BankForm';
  import PaypalForm from './PaypalForm';

  export default {
    components: { BankForm, PaypalForm },
    props: ['editData'],
    data() {
      return {
        paymentType: affiliatePayment.type.PAYPAL,
        type: affiliatePayment.type,
        paymentData: {},
      };
    },
    mounted() {
      if (this.editData) {
        this.paymentType = this.editData.type;
      }
    },
  };
</script>
