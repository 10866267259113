<template>
  <div class="account-setting__form">
    <div class="layout justify-space-between align-center">
      <div class="domain-setting__title primary-text xl semi-bold">Your Team</div>
      <v-btn class="custom-btn-secondary fixed-width" @click="addTeamMember">
        Add Team Member
      </v-btn>
    </div>
    <div
      v-for="(teamMember, index) in teamMemberList"
      :key="index"
      class="account-setting__integration account-setting__team-member"
    >
      <div class="layout align-center">
        <div
          class="account-setting__team-member__avatar"
          :style="getBackgroundAvatar(teamMember.user)"
        >
          <initial-avatar
            v-if="!teamMember.user.avatar"
            :name="`${teamMember.user.firstName} ${teamMember.user.lastName}`"
          />
        </div>
        <div class="ml-15">
          <div class="layout">
            <p class="primary-text semi-bold">
              {{ `${teamMember.user.firstName} ${teamMember.user.lastName }` }}
            </p>
            <p class="primary-text dark-text ml-10">
              {{ teamMember.user.email }}
            </p>
          </div>
          <div class="layout mt-1">
            <span v-if="teamMember.active" class="badge badge-success">Active</span>
            <span v-else class="badge badge-warning">Pending</span>
            <p
              class="flex-text primary-text cursor-pointer ml-1 account-setting__team-member__drop"
              :class="{ active: activeDropDown[teamMember.id] }"
              @click="triggerDropdown(teamMember.id)"
            >
              <icon-course-side class="mr-1" />
              Assigned Campaigns
              <fa-icon :icon="['fas', 'chevron-down']" class="ml-1 icon-xxs"/>
            </p>
          </div>
        </div>
        <v-spacer />
        <v-menu
          v-if="updateChange"
          transition="slide-y-transition"
          content-class="custom-menu membership__box-menu"
          offset-y bottom left>
          <template v-slot:activator="{ on }">
            <div class="account-setting__team-member-role" v-on="on">
              {{ getRoleName(teamMember.role) }}
              <fa-icon :icon="['fas', 'chevron-down']" />
            </div>
          </template>
          <div class="custom-action-dropdown">
            <button
              v-for="(role, rIndex) in teamMemberRoleItems"
              :key="`role-${rIndex}`"
              class="dropdown-btn"
              @click="updateRole(role.value, teamMember, index)"
            >
              {{ role.title }}
            </button>
          </div>
        </v-menu>
        <v-menu
          transition="slide-y-transition"
          content-class="custom-menu membership__box-menu"
          offset-y bottom left>
          <template v-slot:activator="{ on }">
            <button
              class="custom-action-dropdown__btn ml-10"
              v-on="on"
            >
              <icon-more/>
            </button>
          </template>
          <div class="custom-action-dropdown">
            <button
              class="dropdown-btn"
              @click="editTeamMember(teamMember)"
            >
              <icon-edit-x class="icon-default" />
              Edit
            </button>
            <button class="dropdown-btn delete" @click="deleteRow(teamMember)">
              <icon-trash class="icon-default" />
              <span>Delete</span>
            </button>
          </div>
        </v-menu>
      </div>
      <v-slide-y-transition>
        <div v-if="activeDropDown[teamMember.id]">
          <div
            v-for="(teamMembership, mIndex) in teamMember.teamMemberships"
            :key="`membership-${mIndex}`"
            class="account-setting__team-member__membership"
          >
            <div
              class="team-member__membership-logo"
              :style="getMembershipLogoBg(teamMembership.membership)"
            />
            <div>
              <p class="primary-text semi-bold">
                {{ teamMembership.membership.title }}
              </p>
              <p
                v-for="(course, cIndex) in teamMembership.membership.courses.slice(0, 3)"
                :key="`course-${cIndex}`"
                class="primary-text"
              >
                {{ course.title }}
              </p>
              <p v-if="teamMembership.membership.courses.length > 3" class="primary-text">...</p>
            </div>
          </div>
        </div>
      </v-slide-y-transition>
    </div>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import TeamMemberForm from './TeamMemberForm';
  import ConfirmDialog from '@/components/ConfirmDialog';
  import InitialAvatar from '@/components/InitialAvatar';
  import { teamMemberRoleItems } from '@/config/teamMember';

  export default {
    components: {
      ConfirmDialog,
      InitialAvatar,
    },
    data() {
      return {
        updateChange: true,
        activeDropDown: {},
        teamMemberRoleItems,
      };
    },
    computed: {
      ...mapGetters('teamMember', ['teamMemberList']),
      ...mapGetters(['membershipList']),
    },
    created() {
      this.syncTeamMembers();
      if (!this.membershipList.length) {
        this.fetchOwnMemberships();
      }
    },
    methods: {
      ...mapActions('teamMember', ['syncTeamMembers', 'deleteTeamMember', 'updateTeamMember']),
      ...mapActions(['fetchOwnMemberships', 'openCustomModal', 'showAlert']),
      getBackgroundAvatar(user) {
        if (user.avatar) {
          return { 'background-image': `url("${user.avatar}")` };
        }
        return '';
      },
      triggerDropdown(id) {
        if (this.activeDropDown[id] === undefined) {
          this.$set(this.activeDropDown, id, true);
          return;
        }
        this.$set(this.activeDropDown, id, !this.activeDropDown[id]);
      },
      getMembershipLogoBg(membership) {
        let img = '/img/membership_logo_default.png';
        if (membership.media && membership.media.logo) {
          img = membership.media.logo;
        }
        return { 'background-image': `url("${img}")` };
      },
      editTeamMember(teamMember) {
        this.openCustomModal({
          component: TeamMemberForm,
          maxWidth: '450px',
          width: '450px',
          title: 'Edit Team Member',
          modalClass: 'membership-form',
          data: {
            isEdit: true,
            teamMember,
          },
        });
      },
      deleteRow(teamMember) {
        this.$refs.confirm.open('Do you want to delete this team member', {}).then((res) => {
          if (res) {
            this.deleteTeamMember(teamMember).then(() => {
              this.showAlert('Your team member is deleted');
            });
          }
        });
      },
      async updateRole(role, teamMember, index) {
        const params = {
          id: teamMember.id,
          role,
        };
        await this.updateTeamMember(params);
        this.updateChange = false;
        this.$set(this.teamMemberList[index], 'role', role);
        this.updateChange = true;
      },
      getRoleName(role) {
        const find = teamMemberRoleItems.find(t => t.value === role);
        if (find) {
          return find.title;
        }
        return '';
      },
      addTeamMember() {
        this.openCustomModal({
          component: TeamMemberForm,
          maxWidth: '450px',
          width: '450px',
          title: 'Add Team Member',
          modalClass: 'membership-form',
          data: {
            isEdit: false,
          },
        });
      },
    },
  };
</script>
