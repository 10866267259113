<template>
  <div>
    <template v-if="!isEdit">
      <p class="label-text">Email</p>
      <v-text-field
        v-model="formData.userEmail"
        class="default no-error"
        solo
      />
      <div class="layout mt-20">
        <div class="flex-auto">
          <p class="label-text">First Name</p>
          <v-text-field
            v-model="formData.firstName"
            class="default no-error"
            solo
          />
        </div>
        <div class="ml-10 flex-auto">
          <p class="label-text">Last Name</p>
          <v-text-field
            v-model="formData.lastName"
            class="default no-error"
            solo
          />
        </div>
      </div>
    </template>
    <p class="label-text mt-20">Select Role</p>
    <custom-select
      v-model="formData.role"
      :items="teamMemberRoleItems"
      item-title="title"
      item-value="value"
      placeholder="Select Role"
    />
    <p class="label-text mt-20">Select Campaign</p>
    <multi-select
      v-model="formData.membershipIds"
      :items="membershipSelect"
      :enable-select-all="true"
      :show-selected="true"
      item-name="memberships"
      placeholder="Select Campaigns"
    />
    <div class="layout mt-20">
      <v-spacer />
      <v-btn class="custom-btn-normal sm mr-15" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary" :loading="loading" @click="submit">
        <template v-if="isEdit">Save</template>
        <template v-else>
          Add
        </template>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import MultiSelect from '@/components/select/MultiSelect';
  import CustomSelect from '@/components/select/CustomSelect';
  import { teamMemberRoleItems } from '@/config/teamMember';

  export default {
    components: {
      CustomSelect,
      MultiSelect,
    },
    data() {
      return {
        teamMemberRoleItems,
        loading: false,
        formData: {
          userEmail: '',
          membershipIds: [],
          role: 'editor',
        },
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps', 'membershipList']),
      isEdit() {
        return this.modalComponentProps.isEdit;
      },
      membershipSelect() {
        return this.membershipList.filter(m => m.courses.length > 0)
          .map(m => ({ id: m.id, title: m.title }));
      },
    },
    created() {
      if (this.isEdit) {
        this.formData.membershipIds = this.modalComponentProps.teamMember.teamMemberships
          .map((tm => tm.membershipId));
        this.formData.role = this.modalComponentProps.teamMember.role;
        this.formData.id = this.modalComponentProps.teamMember.id;
        this.formData.userEmail = this.modalComponentProps.teamMember.user.email;
        this.formData.firstName = this.modalComponentProps.teamMember.user.firstName;
        this.formData.lastName = this.modalComponentProps.teamMember.user.lastName;
      }
    },
    methods: {
      ...mapActions(['closeModal', 'showAlert']),
      ...mapActions('teamMember', ['addTeamMember', 'updateTeamMember']),
      async submit() {
        if (!this.formData.userEmail || !this.formData.membershipIds.length) {
          return;
        }
        this.loading = true;
        try {
          if (this.isEdit) {
            await this.updateTeamMember(this.formData);
            this.showAlert('Team Member is updated');
          } else {
            await this.addTeamMember(this.formData);
            this.showAlert('Team Member is created');
          }
        } catch (err) {
          this.loading = false;
          this.closeModal();
        }

        this.loading = false;
        this.closeModal();
      },
    },
  };
</script>
