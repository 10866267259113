<template>
  <div class="client-management__wizard">
    <div class="client-management__wizard-tabs">
      <div class="client-management__wizard-navigation">
        <div
          ref="firstPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 'client'}"
        >
          <div v-if="!clientInfoComplete" class="circle"><span class="circle-active" /></div>
          <icon-check-circle v-else />
          Client
        </div>
        <div
          class="client-management__wizard-line"
          :style="styleFirstLine"
          :class="{ active: step === 'campaign'}"
        />
        <div
          ref="secondPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 'campaign' || permissionInfoComplete}"
        >
          <div v-if="!permissionInfoComplete" class="circle"><span class="circle-active" /></div>
          <icon-check-circle v-else />
          Campaign
        </div>
        <div
          class="client-management__wizard-line"
          :style="styleSecondLine"
          :class="{ active: step === 'permission'}"
        />
        <div
          ref="thirdPoint"
          class="client-management__wizard-navigation-point"
          :class="{ active: step === 'permission'}"
        >
          <div class="circle"><span class="circle-active" /></div>
          Payment
        </div>
      </div>
      <div v-if="step === 'client'" class="client-management__wizard-main">
        <p class="label-text">Client name</p>
        <v-text-field
          v-model="formData.name"
          v-validate.disable="'required'"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          data-vv-as="Clients name"
          class="default"
          solo/>
        <p class="label-text">Email</p>
        <v-text-field
          v-model="formData.email"
          v-validate.disable="'required|email'"
          :error-messages="errors.collect('mail')"
          data-vv-name="mail"
          data-vv-as="Email"
          class="default"
          solo/>
        <div class="layout mt-10">
          <v-spacer />
          <v-btn
            class="custom-btn-secondary fixed-width"
            :disabled="clientInfoMissing"
            @click="next('campaign')"
          >
            Next
          </v-btn>
        </div>
      </div>
      <div v-if="step === 'campaign'" class="client-management__wizard-main">
        <div class="layout">
          <div class="flex xs6">
            <p class="label-text">Select Campaign</p>
            <custom-select
              v-model="membershipId"
              :items="membershipSelect"
              item-title="title"
              item-value="id"
              @change="updateMembershipIds"
            />
            <p v-if="campaignError" class="mt-1 primary-text red--text">
              Please select campaign
            </p>
          </div>
          <div class="flex xs6 ml-15">
            <p class="label-text">Select Courses</p>
            <multi-select
              v-model="formData.courseIds"
              :items="courseList"
              :enable-select-all="true"
              :show-selected="true"
              item-name="course"
              placeholder="Select Courses"
            />
            <p v-if="courseError" class="primary-text red--text">Please select course</p>
          </div>
        </div>
        <p class="label-text mt-20">Select Permissions</p>
        <permission-select-wizard v-model="formData.permissions" placeholder="Select Permissions" />
        <p class="label-text mt-20">Select Settings Profile (Optional)</p>
        <custom-select
          v-model="formData.clientProfileId"
          :items="clientProfiles"
          item-title="name"
          item-value="id"
        />
        <p class="flex-text mt-2">
          <icon-info-x class="icon-xs mr-2" />
          You can create settings profile in the “Agency” section of the
          <a
            class="violet-text"
            @click="$router.push({name: 'dashboard.account-setting.agency-setting'})"
          >
            profile setting.
          </a>
        </p>
        <div class="layout justify-space-between mt-30">
          <v-btn class="custom-btn-normal fixed-width" @click="next('client')">
            <icon-arrow-left class="mr-2 icon-default" />
            Back
          </v-btn>
          <v-btn
            class="custom-btn-secondary fixed-width"
            :disabled="permissionInfoMissing"
            @click="next('payment')"
          >
            Next
          </v-btn>
        </div>
      </div>
      <div v-if="step === 'payment'" class="client-management__wizard-main">
        <div v-if="canShowFree" class="mb-5">
          <p class="label-text">Type</p>
          <custom-select
            v-model="formData.paymentType"
            :items="paymentTypes"
            item-value="value"
            item-title="label"
          />
        </div>
        <p v-if="formData.paymentType === 'internal' && !hasStripeAccount"
           class="primary-text red--text">
          You need to connect stripe first to be able to using the Internal System
        </p>
        <p v-if="formData.paymentType === 'internal'
             && formData.paymentAmount && formData.paymentAmount < 2"
           class="primary-text red--text">
          The minimum amount need to be greater than 1
        </p>
        <template v-if="formData.paymentType === 'internal'">
          <div class="layout mt-20">
            <div class="flex xs6">
              <p class="label-text">Payment Type</p>
              <custom-select
                v-model="formData.paymentSubscriptionLevel"
                :items="subscriptionLevels"
                item-value="id"
                item-title="title"
              />
            </div>
            <div class="flex xs6 ml-15">
              <p class="label-text">Amount</p>
              <div class="layout custom-input input-block">
                <div
                  v-show="formData.paymentAmount > 0"
                  class="flex flex-select">
                  <custom-select
                    v-model="formData.paymentCurrency"
                    custom-class="half-select currency-select blue-gray-x-bg"
                    :items="currencies"
                    item-title="title"
                    item-value="id"
                    outline
                  />
                </div>
                <div
                  class="flex flex-input"
                  :class="{ 'half' : formData.paymentAmount > 0 }">
                  <v-text-field
                    v-model="formData.paymentAmount"
                    :class="{ 'half-text': formData.paymentAmount > 0 }"
                    :min="formData.price === '' ? null : 0"
                    type="number"
                    class="default"
                    solo
                    placeholder="Amount"/>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="label-text">Payment due date</p>
            <date-picker v-model="formData.paymentDueDate" :top="true" />
          </div>
        </template>
        <div class="layout justify-space-between mt-30">
          <v-btn class="custom-btn-normal fixed-width" @click="next('campaign')">
            <icon-arrow-left class="mr-2 icon-default" />
            Back
          </v-btn>
          <v-btn class="custom-btn-secondary fixed-width"
                 :loading="loading" :disabled="clientPaymentMissing" @click="submit">
            Complete
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomSelect from '@/components/select/CustomSelect';
  import constant from '@/config/constant';
  import PermissionSelectWizard from './PermissionSelectWizard';
  import MultiSelect from '@/components/select/MultiSelect';
  import DatePicker from '@/components/select/DatePicker';
  import roleHelpers from '@/mixins/roleHelpers';

  export default {
    components: {
      DatePicker,
      MultiSelect,
      CustomSelect,
      PermissionSelectWizard,
    },
    mixins: [roleHelpers],
    data() {
      return {
        firstLine: {
          top: 0,
          width: 0,
          left: 0,
        },
        secondLine: {
          top: 0,
          width: 0,
          left: 0,
        },
        courseError: false,
        campaignError: false,
        paymentTypes: constant.clientPaymentTypes,
        subscriptionLevels: constant.clientSubscriptionLevels,
        currencies: constant.currencies,
        membershipId: '',
        step: 'client',
        loading: false,
        formData: {
          clientProfileId: null,
          name: '',
          email: '',
          membershipIds: [],
          courseIds: [],
          paymentType: 'internal',
          permissions: [],
          paymentSubscriptionLevel: '',
          paymentAmount: '',
          paymentCurrency: 1,
          paymentDueDate: '',
        },
      };
    },
    computed: {
      ...mapGetters(['membershipList', 'whoIam', 'userCourses']),
      ...mapGetters('agency', ['clientProfileList']),
      canShowFree() {
        return this.whoIam && ['paledev66@gmail.com', 'thaianhtu1993@gmail.com'].includes(this.whoIam.email);
      },
      clientProfiles() {
        return this.clientProfileList.filter(c => !c.clientId).map(c => ({
          name: c.name,
          id: c.id,
        }));
      },
      hasStripeAccount() {
        return this.whoIam && this.whoIam.stripeAccount;
      },
      styleFirstLine() {
        return `width: ${this.firstLine.width}px; left: ${this.firstLine.left}px; top: ${this.firstLine.top}px`;
      },
      styleSecondLine() {
        return `width: ${this.secondLine.width}px; left: ${this.secondLine.left}px; top: ${this.secondLine.top}px`;
      },
      permissionInfoComplete() {
        return this.step !== 'campaign' && this.formData.permissions.length
          && this.formData.membershipIds.length && this.formData.courseIds.length;
      },
      permissionInfoMissing() {
        return !this.formData.permissions.length
          || !this.formData.membershipIds.length || !this.formData.courseIds.length;
      },
      clientInfoComplete() {
        return this.step !== 'client' && this.formData.email && this.formData.name;
      },
      clientInfoMissing() {
        return !this.formData.email || !this.formData.name;
      },
      clientPaymentMissing() {
        return this.formData.paymentType === 'internal'
          && (!this.formData.paymentAmount
          || !this.formData.paymentDueDate
          || !this.formData.paymentSubscriptionLevel
          || !this.formData.paymentDueDate
          || this.formData.paymentAmount < 2
          );
      },
      membershipSelect() {
        return this.membershipList.filter(m => !m.clientMemberships.length && m.courses.length > 0)
          .map(m => ({ id: m.id, title: m.title }));
      },
      courseList() {
        const row = [];
        if (!this.formData.membershipIds.length) {
          return row;
        }
        this.userCourses.forEach((course) => {
          if (this.formData.membershipIds.includes(course.membershipId)) {
            row.push({
              id: course.id,
              title: course.title,
            });
          }
        });
        return row;
      },
    },
    mounted() {
      this.getClientProfiles();
      const first = this.$refs.firstPoint.offsetLeft;
      const second = this.$refs.secondPoint.offsetLeft;
      const third = this.$refs.thirdPoint.offsetLeft;
      const top = this.$refs.firstPoint.offsetTop;
      this.firstLine = {
        top: top + 9,
        left: first + 30,
        width: second - first + 2,
      };
      this.secondLine = {
        top: top + 9,
        left: second + 38,
        width: third - second - 10,
      };
    },
    methods: {
      ...mapActions(['closeModal']),
      ...mapActions('agency', ['addClient', 'getClientProfiles']),
      next(tab) {
        if (tab === 'campaign') {
          this.$validator.validateAll().then(
            (result) => {
              if (result) {
                this.step = tab;
              }
            },
          );
        } else if (tab === 'payment') {
          if (!this.formData.membershipIds.length) {
            this.campaignError = true;
            return;
          }
          this.campaignError = false;
          if (!this.formData.courseIds.length) {
            this.courseError = true;
            return;
          }
          this.courseError = false;
          this.step = tab;
        } else if (tab === 'client') {
          this.step = tab;
        }
      },
      submit() {
        if (!this.hasStripeAccount && this.formData.paymentType === 'internal') {
          return;
        }
        this.loading = true;
        this.addClient(this.formData)
          .then(() => {
            this.loading = true;
            this.closeModal();
          })
          .catch(() => {
            this.loading = false;
            this.closeModal();
          });
      },
      updateMembershipIds() {
        this.formData.membershipIds = [this.membershipId];
        this.reselectCourseIds();
      },
      reselectCourseIds() {
        this.formData.courseIds = [];
        this.userCourses.forEach((course) => {
          if (this.formData.membershipIds.includes(course.membershipId)) {
            this.formData.courseIds.push(course.id);
          }
        });
      },
    },
  };
</script>
