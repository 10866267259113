<template>
  <v-fade-transition>
    <div v-if="modalCustomOpen" class="custom-modal__wrapper">
      <div class="custom-modal__container" :style="styleModal" :class="modalClass">
        <v-card class="px-4">
          <v-card-title>
            <img
              v-if="modalComponentProps && modalComponentProps.icon" :src="modalComponentProps.icon"
              class="modal-title-image">
            <h1 class="custom-modal__title" v-html="modalTitle"/>
            <v-spacer/>
            <v-btn icon class="modal__close mr-0" @click="innerCloseModal">
              <fa-icon :icon="['fal', 'times']" />
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <component :is="modalComponent" v-if="modalComponent" />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters([
        'modalCustomOpen',
        'modalComponent',
        'modalTitle',
        'modalComponentProps',
        'modalWidth',
        'modalClass',
        'modalTransition',
      ]),
      styleModal() {
        return `width: ${this.modalWidth ? this.modalWidth : '600px'} !important;`;
      },
    },
    watch: {
      modalCustomOpen(val) {
        if (document.getElementById('launcher')) {
          if (val) {
            document.getElementById('launcher').style.display = 'none';
          } else {
            document.getElementById('launcher').style.display = 'block';
          }
        }
      },
    },
    methods: {
      ...mapActions([
        'closeModal',
      ]),
      innerCloseModal() {
        if (this.modalComponentProps && this.modalComponentProps.onCloseCallback) {
          this.modalComponentProps.onCloseCallback();
        } else {
          this.closeModal();
        }
      },
    },
  };
</script>
