<template>
  <v-menu
    v-model="activeStatus"
    class="multi-select"
    offset-y
    transition="slide-y-transition"
    :left="true"
    :disabled="disabled"
    :close-on-content-click="false"
    content-class="multi-select-content">
    <template v-slot:activator="{ on }">
      <div class="multi-select-text" :class="textHolderClass" v-on="on">
        <slot />
        <template v-if="showSelected">
          <div v-if="!selectedBadge" class="multi-select__selected-item">
            <span v-if="selectAll" class="name">All {{ `${itemName}s` }} ({{ value.length }})</span>
            <span v-else-if="value.length > 1" class="name">
              {{ `${itemName}s` }} ({{ value.length }})
            </span>
            <span v-else-if="value.length && value.length > 0" class="name">
              {{ getValueLabel(value[0]) }}
            </span>
            <span v-else class="name placeholder">{{ placeholder }}</span>
          </div>
          <div v-else class="multi-select__selected-badge">
            <span v-if="!value.length" class="name">{{ placeholder }}</span>
            <template v-else>
              <template v-for="(data,index) in value">
                <span v-if="index < 2" :key="index" class="multi-select-badge">
                  {{ trim(getValueLabel(data), 15) }}
                </span>
                <span v-else-if="index === 2" :key="index"
                      class="multi-select-badge">+{{ value.length - 2 }}</span>
              </template>
            </template>
          </div>
          <icon-dropdown class="dropdown" />
        </template>
      </div>
    </template>
    <div class="multi-select-list">
      <div v-if="enableSelectAll" class="multi-select-list-item">
        <custom-checkbox
          v-model="selectAll"
          @clickNative="reselect">
          Select All {{ `${itemName}s` }}
        </custom-checkbox>
      </div>
      <template v-else-if="itemLength === 0">
        <p class="text-info">There is no data</p>
      </template>
      <template v-if="enableLabelGroup">
        <template v-for="(group, gIndex) in items">
          <div :key="`${gIndex}-${itemName}`" class="multi-select-group-label" >
            {{ group.label }}
          </div>
          <div v-for="(item, index) in group.items"
               :key="`${gIndex}-${index}-${itemName}`"
               class="multi-select-list-item"
          >
            <custom-checkbox
              v-model="currentSelect[`${gIndex}-${index}`]"
              @clickNative="updateValue">
              {{ item.title }}
            </custom-checkbox>
          </div>
        </template>
      </template>
      <template v-else>
        <div v-for="(item, index) in items"
             :key="index"
             class="multi-select-list-item"
        >
          <custom-checkbox
            v-model="currentSelect[index]"
            :data="item.id"
            @clickNative="updateValue">
            {{ item.title }}
          </custom-checkbox>
        </div>
      </template>
    </div>
  </v-menu>
</template>

<script>
  import CustomCheckbox from '../shared/CustomCheckBox';

  export default {
    components: {
      CustomCheckbox,
    },
    props: [
      'items',
      'value',
      'showSelected',
      'enableSelectAll',
      'itemName',
      'enableLabelGroup',
      'placeholder',
      'selectedBadge',
      'selectAllOnChange',
      'customClass',
      'disabled',
    ],
    data() {
      return {
        activeStatus: '',
        selectAll: false,
        currentSelect: {},
        itemLength: 0,
      };
    },
    computed: {
      textHolderClass() {
        let className = '';
        if (this.customClass) {
          className += `${this.customClass} `;
        }
        if (this.activeStatus) {
          className += 'active ';
        }
        if (this.disabled) {
          className += 'disabled';
        }
        return className;
      },
    },
    watch: {
      value(val) {
        if (val.length === 0) {
          this.currentSelect = {};
          this.selectAll = false;
        }
      },
      items: {
        handler() {
          this.initValue();
          if (this.selectAllOnChange) {
            this.selectAll = true;
            this.reselect();
          }
        },
        deep: true,
      },
    },
    mounted() {
      this.initValue();
    },
    methods: {
      trim(text, length) {
        if (text && text.length > length) {
          return `${text.slice(0, length)}...`;
        }
        return text;
      },
      reselect() {
        if (this.enableLabelGroup) {
          this.items.forEach((group, gIndex) => {
            group.items.forEach((item, index) => {
              const key = `${gIndex}-${index}`;
              this.$set(this.currentSelect, key, this.selectAll);
            });
          });
        } else {
          Object.keys(this.items).forEach((key) => {
            this.$set(this.currentSelect, key, this.selectAll);
          });
        }

        this.updateValue();
      },
      getValueLabel(data) {
        let text = '';
        this.items.forEach((item) => {
          if (!this.enableLabelGroup) {
            if (item.id === data) {
              text = item.title;
            }
          } else {
            item.items.forEach((itm) => {
              if (itm.id === data) {
                text = itm.title;
              }
            });
          }
        });

        return text;
      },
      updateValue() {
        const selected = [];
        let canMarkAll = true;
        let currentLength = 0;
        Object.keys(this.currentSelect).forEach((key) => {
          currentLength += 1;
          let item = {};
          if (this.enableLabelGroup) {
            const indexArr = key.split('-');
            item = this.items[indexArr[0]].items[indexArr[1]].id;
          } else {
            item = this.items[key].id;
          }

          if (this.currentSelect[key]) {
            selected.push(item);
          } else {
            canMarkAll = false;
            this.selectAll = false;
          }
        });

        if (canMarkAll && currentLength === this.itemLength) {
          this.selectAll = true;
        }
        this.$emit('input', selected);
      },
      reset() {
        this.itemLength = 0;
        this.currentSelect = {};
        this.selectAll = false;
      },
      initValue() {
        if (this.value.length === 0) {
          this.currentSelect = {};
          this.selectAll = false;
          return;
        }
        this.reset();
        let canMarkAll = true;

        if (this.enableLabelGroup) {
          this.items.forEach((group, gIndex) => {
            group.items.forEach((data, index) => {
              const key = `${gIndex}-${index}`;
              if (this.value.includes(data.id)) {
                this.$set(this.currentSelect, key, true);
              } else {
                canMarkAll = false;
                this.$set(this.currentSelect, key, false);
              }
              this.itemLength += 1;
            });
          });
        } else {
          this.items.forEach((data, index) => {
            if (this.value.includes(data.id)) {
              this.$set(this.currentSelect, index, true);
            } else {
              canMarkAll = false;
              this.$set(this.currentSelect, index, false);
            }
            this.itemLength += 1;
          });
        }

        if (canMarkAll && this.items.length > 0) {
          this.selectAll = true;
        }
      },
    },
  };
</script>
