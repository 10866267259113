<template>
  <div class="partnership partnership__partner-selector">
    <p class="label-text">Select a partner</p>
    <div>
      <v-menu
        transition="slide-y-transition"
        content-class="custom-menu membership__box-menu partnership__partner-selector__box"
        offset-y bottom left>
        <template v-slot:activator="{ on }">
          <div v-if="!partner">
            <v-text-field
              v-model="formData.email"
              class="default"
              placeholder="Add an email or select a previous partner"
              @input="change"
              v-on="on"
            />
          </div>
          <div v-else>
            <div class="partnership__partner-selector__selected-box flex-start">
              <div class="partnership__partner-selector__selected-box__content">
                <div class="flex-start">
                  <span class="primary-text text-dark">{{ partner.email }}</span>
                  <fa-icon
                    :icon="['fal', 'times']"
                    class="pointer ml-10 icon-close"
                    @click="onSelect(null)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="partnerList.length > 0" class="custom-action-dropdown">
          <div
            v-for="(partner, idx) in partnerList"
            :key="idx"
            :class="['partner-item flex-start pointer', idx !== 0 ? 'mt-10' : '']"
            @click="onSelect(partner)"
          >
            <div class="avatar-image lg mr-2" :style="getAvatar(partner)">
              <initial-avatar
                v-if="!partner.avatar"
                :name="partner.name"
              />
            </div>
            <div class="membership-setting__member-detail-info">
              <p class="primary-text">
                {{ partner.name }}
              </p>
            </div>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="partnerList.length" class="flex-start mt-10">
      <icon-info-x class="icon-default" />
      <span class="ml-10 primary-text text-dark">
        You can invite existing partner or write an email address to send an invitation
      </span>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';

  export default {
    components: {
      InitialAvatar,
    },
    mixins: [linkHelper],
    props: ['value'],
    data() {
      return {
        partner: null,
        formData: {
          email: '',
          partnershipId: '',
        },
      };
    },
    computed: {
      ...mapGetters('partnership', ['partnerList']),
    },
    mounted() {
      this.formData = this.value;
    },
    methods: {
      getAvatar(member) {
        return member.avatar ? {
          'background-image': `url("${this.imageUrl(member.avatar)}")`,
        } : {};
      },
      onSelect(partner) {
        this.partner = partner;
        this.formData.partnershipId = partner ? partner.id : '';
        this.change();
      },
      change() {
        this.$emit('input', this.formData);
      },
    },
  };
</script>
