<template>
  <div class="layout column">
    <div class="not-found-page__nav">
      <icon-logo-full class="icon-white-logo" />
    </div>
    <div class="not-found-page__body">
      <img src="/img/404.png" alt="Not found image">
      <h2>404</h2>
      <h2>Page cannot be found</h2>
      <p>Unfortunately the page you’re looking for cannot be found.</p>
      <div class="layout">
        <v-btn class="custom-btn-secondary md" @click="goToHome">Homepage</v-btn>
        <v-btn class="custom-btn-secondary md" @click="goToLogin">Login Page</v-btn>
        <v-btn class="custom-btn-secondary md" @click="goToSupport">Support</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      goToSupport() {
        window.open('https://support.sambakker.com/', '_blank');
      },
      goToHome() {
        this.$router.push({ name: 'home' });
      },
      goToLogin() {
        this.$router.push({ name: 'login' });
      },
    },
  };
</script>
