const teamMemberRoles = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  STUDENT_HELPER: 'student_helper',
  SUPPORT: 'support',
};

const teamMemberRoleItems = [
  {
    title: 'Admin',
    value: 'admin',
  },
  {
    title: 'Editor',
    value: 'editor',
  },
  {
    title: 'Support',
    value: 'support',
  },
  {
    title: 'Student Helper',
    value: 'student_helper',
  },
];

export { teamMemberRoles, teamMemberRoleItems };
