export default {
  getAll: () => new Promise((resolve, reject) => {
    window.http.get('/emailSenders').then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  create: data => new Promise((resolve, reject) => {
    window.http.post('/emailSenders', data).then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  update: data => new Promise((resolve, reject) => {
    window.http.put(`/emailSender/${data.id}`, data).then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  delete: emailSenderId => new Promise((resolve, reject) => {
    window.http.delete(`/emailSenders/${emailSenderId}`).then(
      () => { resolve(); },
      (error) => { reject(error.response.data); },
    );
  }),
  requestConfirm: emailSenderId => new Promise((resolve, reject) => {
    window.http.get(`/emailSenders/${emailSenderId}/request-send-confirm`).then(
      response => resolve(response.data),
      (error) => {
        reject(error.response.data);
      },
    );
  }),
};
