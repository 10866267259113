<template>
  <div class="partnership">
    <div class="partnership-student">
      <div class="account-setting__breadcrumb mb-4">
        <a class="primary-text" @click="$router.push({ name: 'dashboard.partnership-contracts' })">
          Contracts
        </a>
        <fa-icon :icon="['fal', 'chevron-right']"/>
        <a class="primary-text active">
          Contract Detail
        </a>
      </div>

      <div v-if="partnerContractDetail" class="partnership-student__info card mt-20">
        <div class="flex-between">
          <div class="flex-start">
            <div class="avatar-image mr-15" :style="getAvatar(partnerContractDetail.partnership)">
              <initial-avatar
                v-if="!partnerContractDetail.partnership.avatar"
                :name="partnerContractDetail.partnership.name" />
            </div>
            <div>
              <div class="primary-text lg semi-bold cursor-pointer">
                {{ partnerContractDetail.partnership.name }}
              </div>
              <div class="primary-text cursor-pointer dark">
                Date created: {{ moment(partnerContractDetail.createdAt).format('DD MMM, YYYY') }}
              </div>
            </div>
          </div>
          <div class="flex-end">
            <div
              class="status-box primary-text semi-bold mr-20"
              :class="[partnerContractDetail.status === 'active' ? 'active' : 'pending' ]"
            >
              {{ partnerContractDetail.status === 'active' ? 'Active' : 'Pending' }}
            </div>
          </div>
        </div>
        <div v-if="partnerContractDetail.status === 'pending'" class="mt-20 layout flex-end">
          <v-btn
            class="custom-btn__danger sm"
            :loading="loading"
            @click="updateContractStatus('decline')"
          >
            Decline
          </v-btn>
          <span class="primary-text mr-2 ml-2">Or</span>
          <v-btn
            class="custom-btn custom-btn-secondary sm"
            :loading="loading"
            @click="updateContractStatus('active')"
          >
            Accept
          </v-btn>
        </div>
        <div class="primary-text lg semi-bold mt-20 mb-10">Contract Terms</div>
        <div class="flex-start">
          <div class="primary-text mr-30">
            <span>Payment Terms:</span>
            <span class="primary-text semi-bold mr-1">
              {{ getPaymentTermLabel(partnerContractDetail.paymentTerm) }}
            </span>
          </div>
          <div class="primary-text">
            <span>Amount:</span>
            <span class="primary-text semi-bold mr-1">
              {{ partnerContractDetail.amount }}%
            </span>
          </div>
        </div>
      </div>
      <div v-if="partnerContractDetail.status !== 'pending'"
           class="partnership-student__content card mt-30">
        <div class="partnership-student__content mb-20">
          <statistic-comp />
        </div>
        <div class="partnership-student__content">
          <table-comp />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import { mapGetters, mapActions } from 'vuex';
  import StatisticComp from './materials/Statistic.vue';
  import TableComp from './materials/Table.vue';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';

  export default {
    components: {
      InitialAvatar,
      StatisticComp,
      TableComp,
    },
    mixins: [linkHelper],
    data() {
      return {
        loading: false,
        moment,
      };
    },
    computed: {
      ...mapGetters('partnership', [
        'partnerContractDetail',
      ]),
    },
    mounted() {
      this.getPartnerContractById(this.$route.params.id);
    },
    methods: {
      ...mapActions('partnership', [
        'getPartnerContractById', 'updateContract',
      ]),
      async updateContractStatus(status) {
        this.loading = true;
        const params = {
          id: this.partnerContractDetail.id,
          status,
        };
        await this.updateContract(params);
        this.loading = false;
        this.$router.push({ name: 'dashboard.partnership-contracts' });
      },
      getAvatar(member) {
        return member.avatar ? { 'background-image': `url("${this.imageUrl(member.avatar)}")` } : {};
      },
      getPaymentTermLabel(key) {
        return {
          percentage_of_sale: 'Percentage of sale',
        }[key];
      },
    },
  };
</script>
