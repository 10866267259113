<template>
  <v-container fill-height fluid class="auth">
    <div class="auth__main fill-height">
      <div class="auth__wrapper">
        <icon-main-logo v-if="!agencyLogo" class="main-logo"/>
        <img v-else :src="agencyLogo" alt="" class="main-logo__img">
        <slot name="title" />
        <div class="auth__box">
          <slot />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import quotes from '@/config/quotes';

  export default {
    computed: {
      ...mapGetters('clientAgency', ['isAgencyDomain', 'agencyProfile']),
      quote() {
        return quotes[Math.floor(Math.random() * quotes.length)];
      },
      agencyLogo() {
        if (!this.isAgencyDomain || !this.agencyProfile.id) {
          return null;
        }
        return this.agencyProfile.logo;
      },
    },
  };
</script>
