<template>
  <v-menu
    v-model="activeStatus"
    class="multi-select"
    offset-y
    transition="slide-y-transition"
    :left="true"
    :close-on-content-click="false"
    content-class="multi-select-content">
    <template v-slot:activator="{ on }">
      <div class="multi-select-text" :class="textHolderClass" v-on="on">
        <div class="multi-select__selected-item">
          <span v-if="value.length > 1" class="name">
            Permissions ({{ value.length }})
          </span>
          <span v-else-if="value.length && value.length > 0" class="name">
            {{ getValueTitle(value[0]) }}
          </span>
          <span v-else class="name placeholder">{{ placeholder }}</span>
        </div>
        <icon-dropdown class="dropdown" />
      </div>
    </template>
    <div class="multi-select-list permission-select">
      <div class="layout permission-select__header align-center">
        <p class="flex-auto primary-text semi-bold dark-text">Feature</p>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-view class="icon-default-sm" />
          Read & Write
        </div>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-edit-x class="icon-default-sm"/>
          Read
        </div>
        <div class="flex xs3 layout primary-text semi-bold dark-text">
          <icon-lock class="icon-default-sm" />
          No Access
        </div>
      </div>
      <div class="permission-select__dropdown-rows">
        <div class="layout align-center">
          <p class="primary-text lg flex-auto">Select All</p>
          <div
            class="flex xs3 layout justify-center"
          >
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="currentSelect = [...allEditPermissions]"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : allEditPermissions.every(i => currentSelect.includes(i)) }"
              />
            </div>
          </div>
          <div class="flex xs3 layout justify-center">
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="currentSelect = [...allViewPermissions]"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : allViewPermissions.every(i => currentSelect.includes(i)) }"
              />
            </div>
          </div>
          <div class="flex xs3 layout justify-center">
            <div
              class="custom-checkbox circle alternative violet-color"
              @click="currentSelect = []"
            >
              <div
                class="member-custom-cb"
                :class="{ checked : currentSelect.length === 0 }"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(group, index) in permissionList"
        :key="index"
        :class="{ active: showDropdown === group.id }"
        class="permission-select__dropdown"
      >
        <p class="dropdown-title" @click="selectDropdown(group.id)">
          {{ group.label }}
          <icon-arrow-up />
        </p>
        <v-slide-y-transition>
          <div v-if="showDropdown === group.id" class="permission-select__dropdown-rows">
            <div
              v-for="(permission, pIndex) in group.items"
              :key="`p-${pIndex}`"
              class="layout align-center"
            >
              <p class="primary-text lg flex-auto">{{ permission.title }}</p>
              <div
                class="flex xs3 layout justify-center"
              >
                <div
                  class="custom-checkbox circle alternative violet-color"
                  @click="selectPermission(permission.permissions[0], permission.id)"
                >
                  <div
                    class="member-custom-cb"
                    :class="{ checked : currentSelect.includes(permission.permissions[0]) }"
                  />
                </div>
              </div>
              <div class="flex xs3 layout justify-center">
                <div
                  class="custom-checkbox circle alternative violet-color"
                  @click="selectPermission(permission.permissions[1], permission.id)"
                >
                  <div
                    class="member-custom-cb"
                    :class="{ checked : currentSelect.includes(permission.permissions[1]) }"
                  />
                </div>
              </div>
              <div class="flex xs3 layout justify-center">
                <div
                  class="custom-checkbox circle alternative violet-color"
                  @click="removePermission(permission.id)"
                >
                  <div
                    class="member-custom-cb"
                    :class="{ checked : !currentSelect
                      .some(r=> permission.permissions.includes(r)) }"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-slide-y-transition>
      </div>
    </div>
  </v-menu>
</template>

<script>
  import {
    clientPermissionList,
    clientPermissionListFe,
    groupPermission,
    allViewPermissions,
    allEditPermissions,
  } from '@/config/clientPermission';
  import roleHelpers from '@/mixins/roleHelpers';

  export default {
    mixins: [roleHelpers],
    props: [
      'items',
      'value',
      'placeholder',
    ],
    data() {
      return {
        allEditPermissions,
        allViewPermissions,
        showDropdown: 'general',
        activeStatus: '',
        currentSelect: [],
      };
    },
    computed: {
      permissionList() {
        if (this.isOTO1Role) {
          return clientPermissionList;
        }
        return clientPermissionListFe;
      },
      textHolderClass() {
        let className = '';
        if (this.activeStatus) {
          className += 'active ';
        }
        return className;
      },
    },
    watch: {
      currentSelect() {
        this.$emit('input', this.currentSelect);
      },
    },
    methods: {
      selectDropdown(groupId) {
        if (this.showDropdown === groupId) {
          this.showDropdown = '';
          return;
        }
        this.showDropdown = groupId;
      },
      removePermission(groupPermissionId) {
        const tmp = groupPermission[groupPermissionId];
        if (tmp.length) {
          tmp.forEach((permission) => {
            const tmpIndex = this.currentSelect.findIndex(c => c === permission);
            if (tmpIndex > -1) {
              this.currentSelect.splice(tmpIndex, 1);
            }
          });
        }
      },
      selectPermission(permissionId, groupPermissionId) {
        this.removePermission(groupPermissionId);
        this.currentSelect.push(permissionId);
      },
      getValueTitle(id) {
        return id;
      },
    },
  };
</script>
