import * as types from '@/store/mutation-types';
import Partnership from '@/api/partnership';

export const state = () => ({
  partnershipContractList: [],
  partnershipContract: {},
  paymentMethods: [],

  adminContractStatistic: undefined,
  adminCommissionData: {
    sumary: {},
    commissions: [],
    commissionCount: 0,
  },
  partnerList: [],
  ownContracts: [],

  partnerContractDetail: undefined,
  partnerContractStatistic: undefined,
  partnerContractCommission: undefined,
  partnerContractTransaction: undefined,
});

export const getters = {
  partnershipContractList: state => state.partnershipContractList,
  partnershipContract: state => state.partnershipContract,
  paymentMethods: state => state.paymentMethods,

  adminContractStatistic: state => state.adminContractStatistic,
  adminCommissionData: state => state.adminCommissionData,
  partnerList: state => state.partnerList,
  ownContracts: state => state.ownContracts,

  partnerContractDetail: state => state.partnerContractDetail,
  partnerContractStatistic: state => state.partnerContractStatistic,
  partnerContractCommission: state => state.partnerContractCommission,
  partnerContractTransaction: state => state.partnerContractTransaction,
};

export const actions = {
  getPartnershipContracts({ commit }) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnershipContracts().then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT_LIST, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  createPartnershipContract({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Partnership.createPartnershipContract(payload).then(
        (response) => {
          commit(types.ADD_PARTNERSHIP_CONTRACT_INTO_LIST, response);
          dispatch('fetchOwnMemberships', {}, { root: true });
          resolve(response);
        },
        (error) => {
          dispatch('showAlert', { message: error, type: 'danger' }, { root: true });
          reject(error);
        },
      );
    });
  },
  updatePartnershipContract({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Partnership.updatePartnershipContract(payload).then(
        (response) => {
          dispatch('showAlert', { message: 'Updated partner successful!', type: 'success' }, { root: true });
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPartnershipContract({ commit }, id) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnershipContract(id).then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  deletePartnershipContract({ dispatch, commit }, partnershipId) {
    return new Promise((resolve, reject) => {
      Partnership.deletePartnershipContract(partnershipId).then(
        (response) => {
          dispatch('getPartnership');
          dispatch('showAlert', { message: 'Deleted partner successful!', type: 'success' }, { root: true });
          dispatch('fetchOwnMemberships', {}, { root: true });
          commit(types.DELETE_PARTNERSHIP_CONTRACT_FROM_LIST, partnershipId);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPartnerList({ commit }) {
    return new Promise((resolve, reject) => {
      Partnership.getOwnPartner().then(
        (response) => {
          commit(types.SET_OWN_PARTNER_LIST, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  activePartnership({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      Partnership.activePartnership(data).then(
        (response) => {
          dispatch('showAlert', { message: 'Updated partnership contract successful!!', type: 'success' }, { root: true });
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getOwnPartnershipContract({ commit }) {
    return new Promise((resolve, reject) => {
      Partnership.getOwnContract().then(
        (response) => {
          commit(types.SET_OWN_CONTRACT_LIST, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },

  getStatisticAdminData({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getStatisticAdminData(data).then(
        (response) => {
          commit(types.SET_ADMIN_CONTRACT_STATISTIC, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getCommissionAdminData({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getCommissionAdminData(data).then(
        (response) => {
          commit(types.SET_ADMIN_COMMISSION_DATA, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  adminPayment({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      Partnership.adminPayment(data).then(
        () => {
          dispatch('showAlert', { message: 'Pay commission successful!', type: 'success' }, { root: true });
          resolve();
        },
        (error) => {
          dispatch('showAlert', { message: error, type: 'danger' }, { root: true });
          reject(error);
        },
      );
    });
  },

  getPartnerContractById({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnerContractById(data).then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT_BY_ID, response[0]);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPartnerContractStatisticById({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnerContractStatisticById(data).then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT_STATISTIC_BY_ID, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPartnerContractTransactionById({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnerContractTransactionById(data).then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT_TRANSACTION_BY_ID, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPartnerContractCommissionById({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnerContractCommissionById(data).then(
        (response) => {
          commit(types.SET_PARTNERSHIP_CONTRACT_COMMISSION_BY_ID, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getPaymentMethods({ commit }) {
    return new Promise((resolve, reject) => {
      Partnership.getPartnershipPaymentMethods().then(
        (response) => {
          commit(types.SET_PARTNERSHIP_PAYMENT_METHODS, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  addPaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.createPartnershipPaymentMethod(data).then(
        (response) => {
          commit(types.ADD_PARTNERSHIP_PAYMENT_METHOD, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  updatePaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      Partnership.updatePartnershipPaymentMethod(data).then(
        (response) => {
          commit(types.UPDATE_PARTNERSHIP_PAYMENT_METHOD, response);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  deletePaymentMethod({ commit }, paymentMethodId) {
    return new Promise((resolve, reject) => {
      Partnership.deletePartnershipPaymentMethods(paymentMethodId).then(
        (response) => {
          commit(types.DELETE_PARTNERSHIP_PAYMENT_METHOD, paymentMethodId);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  updateContract({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      Partnership.updateContract(data).then(
        (response) => {
          resolve(response);
          dispatch('showAlert', 'Your contract is updated', { root: true });
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export const mutations = {
  [types.SET_OWN_PARTNER_LIST](state, payload) {
    state.partnerList = payload;
  },
  [types.SET_OWN_CONTRACT_LIST](state, payload) {
    state.ownContracts = payload;
  },
  [types.SET_PARTNERSHIP_CONTRACT_LIST](state, payload) {
    state.partnershipContractList = payload;
  },
  [types.SET_PARTNERSHIP_CONTRACT](state, payload) {
    state.partnershipContract = payload;
  },
  [types.SET_ADMIN_CONTRACT_STATISTIC](state, payload) {
    state.adminContractStatistic = payload;
  },
  [types.SET_ADMIN_COMMISSION_DATA](state, payload) {
    state.adminCommissionData = payload;
  },
  [types.ADD_PARTNERSHIP_CONTRACT_INTO_LIST](state, payload) {
    state.partnershipContractList.push(payload);
  },
  [types.UPDATE_PARTNERSHIP_CONTRACT_IN_LIST](state, payload) {
    const index = state.partnershipContractList.findIndex(c => c.id === payload.id);
    state.partnershipContractList[index] = payload;
  },
  [types.DELETE_PARTNERSHIP_CONTRACT_FROM_LIST](state, id) {
    const index = state.partnershipContractList.findIndex(c => c.id === id);
    state.partnershipContractList.splice(index, 1);
  },

  [types.SET_PARTNERSHIP_CONTRACT_BY_ID](state, payload) {
    state.partnerContractDetail = payload;
  },
  [types.SET_PARTNERSHIP_CONTRACT_STATISTIC_BY_ID](state, payload) {
    state.partnerContractStatistic = payload;
  },
  [types.SET_PARTNERSHIP_CONTRACT_TRANSACTION_BY_ID](state, payload) {
    state.partnerContractTransaction = payload;
  },
  [types.SET_PARTNERSHIP_CONTRACT_COMMISSION_BY_ID](state, payload) {
    state.partnerContractCommission = payload;
  },

  [types.SET_PARTNERSHIP_PAYMENT_METHODS](state, payload) {
    state.paymentMethods = payload;
  },
  [types.ADD_PARTNERSHIP_PAYMENT_METHOD](state, payload) {
    state.paymentMethods.push(payload);
  },
  [types.UPDATE_PARTNERSHIP_PAYMENT_METHOD](state, payload) {
    const index = state.paymentMethods.findIndex(c => c.id === payload.id);
    state.paymentMethods[index] = payload;
  },
  [types.DELETE_PARTNERSHIP_PAYMENT_METHOD](state, id) {
    const index = state.paymentMethods.findIndex(c => c.id === id);
    state.paymentMethods.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
