<template>
  <div class="default-layout">
    <navbar />
    <div class="default-body">
      <router-view/>
    </div>
    <main-footer />
  </div>
</template>

<script>
  import Navbar from '@/components/element/Navbar';
  import MainFooter from '@/components/element/Footer';

  export default {
    components: {
      Navbar,
      MainFooter,
    },
  };
</script>
