<template>
  <div>
    <div class="mt-20">
      <p class="label-text">Settings Profile Name</p>
      <v-text-field
        v-model="formData.name"
        class="default no-error"
        placeholder="Enter profile name"
        solo
      />
    </div>
    <div class="mt-20">
      <p class="label-text">Assign Client</p>
      <custom-select
        v-model="formData.clientId"
        :items="clientSelects"
        :disabled="shouldDisabled"
        item-title="name"
        item-value="id"
      />
    </div>
    <custom-checkbox
      v-if="formData.clientId && !shouldDisabled"
      v-model="formData.deleteOldProfile" class="mt-2"
    >
      Delete Old Profile
    </custom-checkbox>
    <div class="layout mt-30 justify-end">
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn :loading="loading" class="custom-btn-secondary fixed-width" @click="create">
        {{ isEdit ? 'Save' : 'Create Profile' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CustomSelect from '@/components/select/CustomSelect';
  import CustomCheckbox from '@/components/shared/CustomCheckBox';

  export default {
    components: {
      CustomCheckbox,
      CustomSelect,
    },
    data() {
      return {
        loading: false,
        formData: {
          name: '',
          clientId: null,
          deleteOldProfile: true,
        },
        shouldDisabled: false,
      };
    },
    computed: {
      ...mapGetters('agency', ['clientList']),
      ...mapGetters(['modalComponentProps']),
      isEdit() {
        return this.modalComponentProps && this.modalComponentProps.isEdit;
      },
      clientSelects() {
        return this.clientList.filter(c => c.status === 'active').map(c => ({
          name: c.name,
          id: c.id,
        }));
      },
    },
    mounted() {
      if (this.isEdit) {
        this.formData.name = this.modalComponentProps.clientProfile.name;
        if (this.modalComponentProps.clientProfile.clientId) {
          this.shouldDisabled = true;
          this.formData.clientId = this.modalComponentProps.clientProfile.clientId;
        }
      }
    },
    methods: {
      ...mapActions('agency', ['createClientProfile', 'updateClientProfile', 'getClientProfiles']),
      ...mapActions(['closeModal']),
      async create() {
        this.loading = true;
        if (this.isEdit) {
          this.formData.id = this.modalComponentProps.clientProfile.id;
          await this.updateClientProfile(this.formData);
          await this.getClientProfiles();
        } else {
          await this.createClientProfile(this.formData);
        }
        this.loading = false;

        this.closeModal();
      },
    },
  };
</script>
