<template>
  <div class="client-management">
    <div class="client-management__box">
      <p class="primary-text xl semi-bold">Partnership Contracts</p>
      <div class="my-datatable__wrapper mt-20">
        <div class="my-datatable__scroller">
          <table class="my-datatable mt-30 last-column-right client-management__table">
            <table-header :headers="headers" />
            <tbody>
              <tr v-for="(contract, index) in ownContracts" :key="index">
                <td>{{ contract.membership.title }}</td>
                <td>
                  <span>{{ getFormatDate(contract.createdAt) }}</span>
                  <span class="dark-text ml-1">{{ getFormatTime(contract.createAt) }}</span>
                </td>
                <td>
                  <active-status :client="contract" />
                </td>
                <td>
                  <span class="badge-btn badge-btn__normal" @click="onViewContract(contract)">
                    <icon-document />
                    View Contract
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import TableHeader from '@/components/table/TableHeader';
  import ActiveStatus from '@/page/client/ActiveStatus';

  export default {
    components: {
      ActiveStatus,
      TableHeader,
    },
    data() {
      return {
        headers: [
          {
            label: 'campaign',
            field: 'name',
            sort: true,
            width: '40%',
          },
          {
            label: 'date created',
            sort: true,
            width: '28%',
          },
          {
            label: 'status',
            field: 'status',
            sort: true,
            width: '12%',
          },
          {
            label: 'action',
            textAlign: 'flex-end',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('partnership', ['ownContracts']),
    },
    created() {
      this.getOwnPartnershipContract();
    },
    methods: {
      ...mapActions('partnership', ['getOwnPartnershipContract']),
      getFormatTime(time) {
        return moment(time).format('HH:mm');
      },
      getFormatDate(time) {
        return moment(time).format('MMM D, YYYY');
      },
      onViewContract(contract) {
        this.$router.push({ name: 'dashboard.partnership-contract', params: { id: contract.id } });
      },
    },
  };
</script>
