<template>
  <div class="domain-setting account-setting__form">
    <div class="layout justify-space-between align-center">
      <div class="domain-setting__title primary-text xl semi-bold">Active Sessions</div>
      <v-btn class="custom-btn-secondary outline-red" @click="revokeAllSession">
        Revoke All
      </v-btn>
    </div>
    <table class="my-datatable mt-20 last-column-right partnership-table">
      <table-header :headers="headers" />
      <tbody>
        <tr v-for="(session, index) in sessions" :key="index">
          <td>
            <p class="primary-text semi-bold">
              {{ getDeviceText(session.loginInfo) }}
            </p>
            <p v-if="session.loginInfo.ip" class="primary-text dark-text">
              {{ session.loginInfo.ip }}
            </p>
            <p
              v-if="session.loginInfo.city || session.loginInfo.country"
              class="primary-text dark-text"
            >
              <template v-if="session.loginInfo.city">
                {{ session.loginInfo.city }}
              </template>
              <template v-if="session.loginInfo.country">
                - {{ countryCode[session.loginInfo.country] }}
              </template>
            </p>
          </td>
          <td>
            <p v-if="session.fingerprint === fingerprint" class="primary-text main-green semi-bold">
              Active Now
            </p>
            <template v-else>
              <p class="primary-text semi-bold">
                {{ getDate(session.lastActivity) }}
              </p>
              <p class="primary-text dark-text">
                {{ getTime(session.lastActivity) }}
              </p>
            </template>
          </td>
          <td>
            <span v-if="session.online" class="badge badge-success">Online</span>
            <span v-else class="badge badge-error">Offline</span>
          </td>
          <td>
            <span
              v-if="session.fingerprint !== fingerprint"
              class="cursor-pointer"
              @click="deleteSession(session.fingerprint)"
            >
              <icon-close class="icon-default" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import TableHeader from '@/components/table/TableHeader';
  import ActiveSessionApi from '@/api/activeSession';
  import countryCode from '@/config/countryCode';

  export default {
    components: {
      TableHeader,
    },
    data() {
      return {
        sessions: [],
        countryCode,
        headers: [
          {
            label: 'device',
            width: '50%',
          },
          {
            label: 'Most recent activity',
            width: '28%',
          },
          {
            label: 'status',
            width: '12%',
          },
          {
            label: 'action',
            textAlign: 'flex-center',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['fingerprint']),
    },
    created() {
      this.getList();
    },
    methods: {
      ...mapActions(['revokeAllUser', 'revokeOneUser']),
      getList() {
        ActiveSessionApi.list().then((res) => {
          const result = [];
          for (let i = 0; i < res.length; i += 1) {
            if (res[i].fingerprint === this.fingerprint) {
              result.push(res[i]);
            }
          }
          for (let i = 0; i < res.length; i += 1) {
            if (res[i].fingerprint !== this.fingerprint) {
              result.push(res[i]);
            }
          }
          this.sessions = result;
        });
      },
      deleteSession(fingerprint) {
        this.revokeOneUser(fingerprint).then(() => {
          this.getList();
        });
      },
      revokeAllSession() {
        this.revokeAllUser().then(() => {
          this.getList();
        });
      },
      getDeviceText(loginInfo) {
        let text = loginInfo.browser ? loginInfo.browser.name : 'Unknown Browser';
        text = `${text}, ${loginInfo.os ? `${loginInfo.os.name} ${loginInfo.os.version}` : 'Unknown OS'}`;
        return text;
      },
      getDate(time) {
        return moment(time).format('MMM DD, YYYY');
      },
      getTime(time) {
        return moment(time).format('HH:mm');
      },
    },
  };
</script>
