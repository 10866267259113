export default {
  create: data => new Promise((resolve, reject) => {
    window.http.post('/lecturer', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updateById: (memberId, data) => new Promise((resolve, reject) => {
    window.http.put(`/lecturer/${memberId}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  uploadAvatar: data => new Promise((resolve, reject) => {
    window.http.post('/lecturer-upload-avatar', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  fetchById: lecturerId => new Promise((resolve, reject) => {
    window.http.get(`/lecturer/${lecturerId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  deleteLecturer: memberId => new Promise((resolve, reject) => {
    window.http.delete(`/lecturer/${memberId}`).then(
      () => { resolve(); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getLecturerList: filter => new Promise((resolve, reject) => {
    window.http.get('/lecturers', { params: filter }).then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  checkActiveToken: token => new Promise((resolve, reject) => {
    window.http.get(`/lecturer/check-token/${token}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createPassword: data => new Promise((resolve, reject) => {
    window.http.post('/lecturer/create-password', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
