export default {

  stats: data => new Promise((resolve, reject) => {
    if (!data || !data.courseIds || !data.courseIds.length) {
      resolve([]);
    }
    window.http.post('/video/stats', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),

};
