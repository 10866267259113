import moment from 'moment';
import _cloneDeep from 'lodash.clonedeep';
import * as types from '@/store/mutation-types';
import widgets from '@/config/widgets';
import AnalyticsApi from '@/api/analytics';
import commonHelper from '../../helper/commonHelper';
import ageAnalytic from '../../config/ageAnalytic';
import rateAnalytic from '../../config/rateAnalytic';

const state = {
  currentTabIsCourses: false,
  analyticData: [],
  userAnalyticsStats: [],
  commentsAnalytics: [],
  atSync: false,
  selectedWidget: [...widgets.defaultWidgets],
  userAnalyticSelectedWidgets: [...widgets.userAnalyticDefaultWidgets],
  videoAnalyticSelectedWidgets: [...widgets.videoAnalyticDefaultWidgets],
  testAnalyticsSelectedWidgets: [...widgets.testAnalyticDefaultWidgets],
  commentAnalyticsSelectedWidgets: [...widgets.commentAnalyticDefaultWidgets],
  selectedCourseIds: [],
  selectedMembershipIds: [],
  startDate: null,
  endDate: null,
  analyticsQuizIds: [],
  totalNewAnalytic: {},
};

const getters = {
  courseAnalytic: state => state.userAnalyticsStats,
  analyticsQuizIds: state => state.analyticsQuizIds,
  totalNewAnalytic: state => state.totalNewAnalytic,
  commentsAnalytics: state => state.commentsAnalytics,
  currentTabIsCourses: state => state.currentTabIsCourses,
  getMostPopularLessons: (state) => {
    if (
      !state.analyticData
      || !state.analyticData.populars
    ) {
      return [];
    }
    return state.analyticData.populars.splice(0, 4);
  },
  analyticData: state => state.analyticData,
  analyticAtSync: state => state.atSync,
  selectedWidgets: state => state.selectedWidget,
  userAnalyticSelectedWidgets: state => state.userAnalyticSelectedWidgets,
  videoAnalyticSelectedWidgets: state => state.videoAnalyticSelectedWidgets,
  testAnalyticsSelectedWidgets: state => state.testAnalyticsSelectedWidgets,
  commentAnalyticsSelectedWidgets: state => state.commentAnalyticsSelectedWidgets,
  analyticDropdownOptions: (state, g, rootState) => {
    if (state.currentTabIsCourses) {
      const result = {};
      rootState.dashboard.courseList.forEach((course) => {
        if (Object.keys(result).includes(course.membershipId.toString())) {
          result[course.membershipId].items.push({ id: course.id, title: course.title });
        } else {
          result[course.membershipId] = {
            label: course.membershipTitle,
            items: [{ id: course.id, title: course.title }],
          };
        }
      });
      return Object.keys(result).map(k => result[k]);
    }
    const list = rootState.dashboard.courseList.map(c => ({
      id: c.membershipId, title: c.membershipTitle,
    }));

    return list.filter(((item, index, array) => array.findIndex(i => i.id === item.id) === index));
  },
  analyticsPostData: (state, g, rootState) => {
    const postData = {
      courseIds: [],
      quizIds: [],
    };

    if (state.currentTabIsCourses) {
      postData.courseIds = state.selectedCourseIds;
    } else {
      postData.courseIds = rootState.dashboard.courseList.reduce(
        (accumulator, currentValue) => {
          const a = accumulator;
          if (state.selectedMembershipIds.indexOf(currentValue.membershipId) !== -1) {
            a.push(currentValue.id);
          }
          return a;
        }, [],
      );
    }


    if (state.startDate) {
      postData.startDate = state.startDate;
    }

    if (state.endDate) {
      postData.endDate = state.endDate;
    }

    if (state.analyticsQuizIds && state.analyticsQuizIds.length) {
      postData.quizIds = state.analyticsQuizIds;
    }

    return postData;
  },
  totalUniqueVisitors: (state) => {
    const dataset = [];
    const uniqueMemberIds = [];
    state.userAnalyticsStats.forEach((course) => {
      if (course.visits) {
        course.visits.forEach((visit) => {
          const date = moment.utc(visit.date).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          if (!foundDataset) {
            dataset.push({
              date,
              UniqueVisitors: 1,
            });
            uniqueMemberIds.push(visit.memberId);
          } else if (!uniqueMemberIds.includes(visit.memberId)) {
            foundDataset.UniqueVisitors += 1;
            uniqueMemberIds.push(visit.memberId);
          }
        });
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total: uniqueMemberIds.length,
    };
  },
  totalTimeSpent: (state) => {
    const dataset = [];
    let total = 0;
    state.userAnalyticsStats.forEach((course) => {
      if (course.visits) {
        course.visits.forEach((visit) => {
          const date = moment.utc(visit.date).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          total += visit.timeSpent;
          if (!foundDataset) {
            dataset.push({
              date,
              TimeSpent: visit.timeSpent,
            });
          } else {
            foundDataset.TimeSpent += visit.timeSpent;
          }
        });
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  studentAnalyticFeedback: (state) => {
    let dataset = [];
    let totalRate = 0;
    let totalFeedback = 0;
    const rateDistribution = _cloneDeep(rateAnalytic);
    state.userAnalyticsStats.forEach((course) => {
      if (course.feedback) {
        course.feedback.forEach((rate) => {
          totalFeedback += 1;
          totalRate += rate.rate;

          const date = moment.utc(rate.createdAt).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          if (!foundDataset) {
            dataset.push({
              date,
              totalFeedback: 1,
              totalRate: rate.rate,
            });
          } else {
            foundDataset.totalFeedback += 1;
            foundDataset.totalRate += 1;
          }

          // rate distribution
          rateDistribution[rate.rate] += 1;
        });
      }
    });

    dataset = dataset.map(d => ({
      date: d.date,
      AvgFeedback: Math.round(d.totalRate / d.totalFeedback),
    }));

    dataset = commonHelper.prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      totalAvg: totalFeedback ? Math.round(totalRate / totalFeedback) : 0,
      total: totalFeedback,
      detail: dataset,
      rateDistribution,
    };
  },
  studentAnalytics: (state) => {
    let dataset = [];
    let notStarted = 0;
    let inProgress = 0;
    let completed = 0;
    let total = 0;
    const gender = {
      female: 0,
      male: 0,
      notSelect: 0,
    };
    const uniqueMemberIds = [];
    const ages = _cloneDeep(ageAnalytic);
    state.userAnalyticsStats.forEach((course) => {
      if (course.productMember && course.productMember.length > 0) {
        course.productMember.forEach((pm) => {
          if (pm.member) {
            total += 1;
            let countCompleted = 0;
            if (pm.member.completedLessonIds && pm.member.completedLessonIds[course.id]) {
              countCompleted = pm.member.completedLessonIds[course.id].length;
            }

            // activity
            if (countCompleted === course.lessons) {
              completed += 1;
            } else if (countCompleted > 0) {
              inProgress += 1;
            } else {
              notStarted += 1;
            }

            const lastTime = pm.member.lastTimeMarkComplete;
            // total complete
            if (lastTime
                && lastTime[course.id]
                && pm.member.completedLessonIds
                && pm.member.completedLessonIds[course.id]) {
              const isCompleted = pm.member.completedLessonIds[course.id].length === course.lessons;
              const date = moment.utc(pm.member.lastTimeMarkComplete[course.id]).format('YYYY-MM-DD');
              const foundDataset = dataset.find(obj => obj.date === date);
              if (!foundDataset) {
                dataset.push({
                  date,
                  complete: isCompleted ? 1 : 0,
                });
              } else {
                foundDataset.complete += isCompleted ? 1 : 0;
              }
            }

            if (!uniqueMemberIds.includes(pm.member.id)) {
              uniqueMemberIds.push(pm.member.id);
              // gender
              if (pm.member.gender === 'Female') {
                gender.female += 1;
              } else if (pm.member.gender === 'Male') {
                gender.male += 1;
              } else {
                gender.notSelect += 1;
              }
              // age
              if (pm.member.birthday) {
                const age = moment().diff(pm.member.birthday, 'years');
                if (age >= 17 && age <= 21) {
                  ages['17-21'] += 1;
                } else if (age >= 22 && age <= 26) {
                  ages['22-26'] += 1;
                } else if (age >= 27 && age <= 31) {
                  ages['27-31'] += 1;
                } else if (age >= 32 && age <= 40) {
                  ages['32-40'] += 1;
                } else if (age >= 60) {
                  ages['60-80'] += 1;
                }
              }
            }
          }
        });
      }
    });

    dataset = dataset.map(data => ({
      date: data.date,
      StudentCompletion: parseFloat(((data.complete / total) * 100).toFixed(2)),
    }));

    dataset = commonHelper.prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      total,
      activity: {
        completed,
        inProgress,
        notStarted,
      },
      percentCompleted: ((completed / total) * 100).toFixed(2),
      ages,
      gender,
      completion: {
        detail: dataset,
      },
    };
  },
  userAnalyticFeedback: state => state.userAnalyticsStats.reduce(
    (accumulator, currentValue) => {
      const a = accumulator;
      if (currentValue.feedback) {
        const map = currentValue.feedback.map((f) => {
          const { member } = f;
          return {
            ...f,
            membershipId: currentValue.membershipId,
            memberName: (member && member.member) ? member.member.name : 'No name',
          };
        });

        a.push(...map);
      }
      return a;
    },
    [],
  ),
  courseStatisticOptions: (state) => {
    const options = [];
    state.userAnalyticsStats.forEach((course) => {
      const tmp = {
        id: course.id,
        label: course.title,
      };
      const items = [];
      if (course.modules.length > 0) {
        course.modules.forEach((module) => {
          items.push({
            id: `${course.id}-${module.id}`,
            title: module.title,
          });
        });
      }
      tmp.items = items;
      options.push(tmp);
    });
    return options;
  },
};

const actions = {
  setSelectedWidgets: ({ commit }, data) => {
    commit(types.SET_SELECTED_WIDGET, data);
  },
  setUserAnalyticSelectedWidgets: ({ commit }, data) => {
    commit(types.SET_USER_ANALYTIC_SELECTED_WIDGET, data);
  },
  setVideoAnalyticSelectedWidgets: ({ commit }, data) => {
    commit(types.SET_VIDEO_ANALYTIC_SELECTED_WIDGET, data);
  },
  setTestAnalyticSelectedWidgets: ({ commit }, data) => {
    commit(types.SET_TEST_ANALYTIC_SELECTED_WIDGET, data);
  },
  setCommentAnalyticSelectedWidgets: ({ commit }, data) => {
    commit(types.SET_COMMENT_ANALYTIC_SELECTED_WIDGET, data);
  },
  setCurrentTabIsCourses: ({ commit }, payload) => {
    commit(types.SET_CURRENT_TAB_IS_COURSES, !!payload);

    if (payload) {
      commit(types.SET_SELECTED_ANALYTIC_COURSE_IDS, []);
    } else {
      commit(types.SET_SELECTED_ANALYTIC_MEMBERSHIP_IDS, []);
    }
  },
  setSelectedAnalyticsDropdownIds: ({ commit, state }, data) => new Promise((resolve) => {
    if (state.currentTabIsCourses) {
      commit(types.SET_SELECTED_ANALYTIC_COURSE_IDS, data);
    } else {
      commit(types.SET_SELECTED_ANALYTIC_MEMBERSHIP_IDS, data);
    }

    resolve(data);
  }),
  fetchUserAnalyticStats: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data.courseIds || !data.courseIds.length) {
      commit(types.SET_USER_ANALYTICS_STATS, []);
      return resolve([]);
    }
    return AnalyticsApi.getUserStats(data)
      .then((res) => {
        commit(types.SET_USER_ANALYTICS_STATS, res);
        return resolve(res);
      })
      .catch(err => reject(err));
  }),
  fetchCommentsAnalytics: ({ commit }, data) => new Promise((resolve, reject) => {
    if (!data.courseIds || !data.courseIds.length) {
      commit(types.SET_COMMENTS_ANALYTICS_STATS, []);
      return resolve([]);
    }
    return AnalyticsApi.getCommentsStats(data)
      .then((res) => {
        commit(types.SET_COMMENTS_ANALYTICS_STATS, res);
        return resolve(res);
      })
      .catch(err => reject(err));
  }),
  setAnalyticDates: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_ANALYTICS_FILTER_DATES, data);
    resolve(data);
  }),
  setAnalyticsQuizIds: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_ANALYTICS_QUIZ_IDS, data);
    resolve(data);
  }),
  setTotalNewAnalytic: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_TOTAL_NEW_ANALYTIC, data);
    resolve(true);
  }),
  markSeenComment: ({ commit }, data) => new Promise((resolve, reject) => {
    AnalyticsApi.markCommentSeen(data).then(
      () => {
        commit(types.SET_ANALYTIC_BUSY, false);
        resolve(true);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  fetchTotalNewAnalytic: ({ commit }) => new Promise((resolve, reject) => {
    AnalyticsApi.getTotalNewAnalytic().then(
      (data) => {
        if (data && data.comment && data.member) {
          commit(types.SET_TOTAL_NEW_ANALYTIC,
            {
              commentCount: data.comment.comment_count,
              studentCount: data.member.member_count,
            });
        }
        resolve(data);
      },
      (error) => {
        reject(error);
      },
    );
  }),
};

const mutations = {
  [types.SET_ANALYTIC](state, payload) {
    state.analyticData = payload;
  },
  [types.SET_ANALYTIC_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.SET_SELECTED_WIDGET](state, payload) {
    state.selectedWidget = payload;
  },
  [types.SET_USER_ANALYTIC_SELECTED_WIDGET](state, payload) {
    state.userAnalyticSelectedWidgets = payload;
  },
  [types.SET_VIDEO_ANALYTIC_SELECTED_WIDGET](state, payload) {
    state.videoAnalyticSelectedWidgets = payload;
  },
  [types.SET_TEST_ANALYTIC_SELECTED_WIDGET](state, payload) {
    state.testAnalyticsSelectedWidgets = payload;
  },
  [types.SET_COMMENT_ANALYTIC_SELECTED_WIDGET](state, payload) {
    state.commentAnalyticsSelectedWidgets = payload;
  },
  [types.SET_CURRENT_TAB_IS_COURSES](state, payload) {
    state.currentTabIsCourses = payload;
  },
  [types.SET_SELECTED_ANALYTIC_COURSE_IDS](state, data) {
    state.selectedCourseIds = data;
  },
  [types.SET_SELECTED_ANALYTIC_MEMBERSHIP_IDS](state, data) {
    state.selectedMembershipIds = data;
  },
  [types.SET_USER_ANALYTICS_STATS](state, data) {
    state.userAnalyticsStats = data;
  },
  [types.SET_COMMENTS_ANALYTICS_STATS](state, data) {
    state.commentsAnalytics = data;
  },
  [types.SET_ANALYTICS_QUIZ_IDS](state, data) {
    state.analyticsQuizIds = data;
  },
  [types.SET_ANALYTICS_FILTER_DATES](state, data) {
    state.startDate = data.startDate || state.startDate;
    state.endDate = data.endDate || state.endDate;
  },
  [types.SET_TOTAL_NEW_ANALYTIC](state, data) {
    state.totalNewAnalytic = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
