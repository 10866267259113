var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"membership-storage"},[_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"flex-start"},[_c('div',{staticClass:"membership-storage__used text-black"},[_vm._v(_vm._s(_vm.usedSize)+"%")]),_c('div',{staticClass:"ml-1 primary-text xl text-dark"},[_vm._v("used")])]),_c('div',[(_vm.canShowUpgrade)?_c('v-btn',{staticClass:"custom-btn-secondary",on:{"click":_vm.onUpdatePlan}},[_c('fa-icon',{staticClass:"icon-plan",attrs:{"icon":['fal', 'chevron-right']}}),_vm._v("\n        Upgrade Plan\n      ")],1):_vm._e()],1)]),_c('div',{staticClass:"mt-20 membership-storage__progress"},[_c('div',{staticClass:"w-100 membership-storage__progress-line flex-start"},[_vm._l((_vm.membershipStorageConverter),function(item,idx){return _c('v-tooltip',{key:idx,attrs:{"content-class":"storage-statistics__tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"membership-storage__progress-line__item",style:({
              'width': ((Number(item.size) * 100 / _vm.availableSpace) + "%"),
              'background-color': _vm.listColor[idx],
            })},on))]}}],null,true)},[_c('div',{staticClass:"membership-storage__list__item flex-start w-100"},[_c('div',{staticClass:"mr-3 rounded",style:({
              'width': '10px',
              'height': '10px',
              'background-color': _vm.listColor[idx],
            })}),_c('div',{staticClass:"w-75 primary-text lg text-white"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"ml-3 flex-end flex-1"},[_c('div',{staticClass:"whitespace-nowrap"},[_c('span',{staticClass:"primary-text lg semi-bold mr-1 text-white"},[_vm._v("\n                "+_vm._s((Number(item.size) * 100 / _vm.availableSpace).toFixed(2))+"%\n              ")]),_c('span',{staticClass:"primary-text lg text-white"},[_vm._v("("+_vm._s(_vm.convertByte(item.size))+")")])])])])])}),_c('div',{staticClass:"membership-storage__progress-line__item",style:({
          'width': ((100 - _vm.usedSize) + "%"),
          'background-color': '#EAEAEF',
        })})],2),_c('div',{staticClass:"flex-between mt-10"},[_c('div',{staticClass:"primary-text xl semi-bold text-dark"},[_vm._v(_vm._s(_vm.totalSizeText))]),_c('div',{staticClass:"primary-text xl semi-bold text-dark"},[_vm._v(_vm._s(_vm.parseAvailableSpace))])])]),_c('div',{staticClass:"mt-20 w-50"},_vm._l((_vm.membershipStorageConverter),function(item,idx){return _c('div',{key:idx,staticClass:"membership-storage__list__item flex-start w-100",class:[idx !== 0 ? 'mt-10' : '']},[_c('div',{staticClass:"mr-3 rounded",style:({
          'width': '10px',
          'height': '10px',
          'background-color': _vm.listColor[idx],
        })}),_c('div',{staticClass:"w-75 primary-text lg"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"ml-3 flex-end flex-1"},[_c('div',{staticClass:"whitespace-nowrap"},[_c('span',{staticClass:"primary-text lg semi-bold mr-1"},[_vm._v("\n            "+_vm._s(Math.round((Number(item.size) / _vm.totalSize) * 100))+"%\n          ")]),_c('span',{staticClass:"primary-text lg text-dark"},[_vm._v("("+_vm._s(_vm.convertByte(item.size))+")")])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }