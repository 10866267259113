const affiliateRewardTypes = {
  PERCENTAGE: 1,
  AMOUNT_DOLLAR: 2,
};

const affiliatePaymentTypes = {
  MANUAL: 1,
};

const affiliateApprovalTypes = {
  MANUAL: 1,
  AUTO: 2,
};

export { affiliateApprovalTypes, affiliatePaymentTypes, affiliateRewardTypes };
