<template>
  <div>
    <p class="primary-text">You can always change this later.</p>
    <p class="label-text mt-20">Campaign Name</p>
    <v-text-field
      v-model="membershipName"
      solo/>
    <div class="layout">
      <v-spacer />
      <v-btn class="custom-btn-normal sm mr-15" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary" :loading="loading" @click="submit">
        Create campaign
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';

  export default {
    data() {
      return {
        loading: false,
        membershipName: '',
      };
    },
    computed: {
      ...mapGetters(['whoIam', 'modalComponentProps']),
    },
    methods: {
      ...mapActions(['closeModal', 'createMembership', 'showAlert']),
      generateSubDomain(val) {
        return val.toLowerCase().replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
      },
      async submit() {
        this.loading = true;
        this.createMembership({
          domain: {
            id: -1,
            subDomain: this.generateSubDomain(`${moment().unix()}-${this.membershipName.trim()}`),
          },
          title: this.membershipName.trim(),
        })
          .then((membership) => {
            this.loading = false;
            this.modalComponentProps.onSubmitCallback(membership);
            this.closeModal();
          })
          .catch((error) => {
            this.showAlert({ type: 'danger', message: error });
            this.loading = false;
            this.closeModal();
          });
      },
    },
  };
</script>
