export default {
  create: data => new Promise((resolve, reject) => {
    window.http.post('/legal-document', { legalDocuments: data }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  update: data => new Promise((resolve, reject) => {
    window.http.post('/update-legal-document', { legalDocuments: data }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getList: membershipId => new Promise((resolve, reject) => {
    window.http.get(`/legal-documents/${membershipId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
