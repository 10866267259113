import moment from 'moment';
import * as types from '@/store/mutation-types';
import api from '@/api/affiliateUser';
import commonHelper from '../../helper/commonHelper';

const state = {
  products: [],
  commissions: [],
  paymentMethods: {},
  promoteProduct: {},
  detailAnalytic: {},
  startDate: '',
  endDate: '',
};

const getters = {
  productList: state => state.products,
  commissionList: state => state.commissions,
  affiliateUserPaymentMethods: state => state.paymentMethods,
  promoteProduct: state => state.promoteProduct,
  totalNetCommission: (state, getter) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.commissionTotal) {
      return {};
    }
    state.detailAnalytic.commissionTotal.forEach((commission) => {
      let amount = commission.auc_amount;
      if (commission.auc_currency !== 'usd') {
        amount = commission.auc_amount
          * (getter.rates.USD / getter.rates[commission.auc_currency.toUpperCase()]);
      }

      total += amount;
      const date = moment(commission.auc_created_at).format('YYYY-MM-DD');
      const foundDataset = dataset.find(obj => obj.date === date);
      if (!foundDataset) {
        dataset.push({
          date,
          NetCommission: amount,
        });
      } else {
        foundDataset.NetCommission += amount;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  totalAffiliateClick: (state) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.clicks) {
      return {};
    }
    state.detailAnalytic.clicks.forEach((click) => {
      total += click.click;
      const foundDataset = dataset.find(obj => obj.date === click.date);
      if (!foundDataset) {
        dataset.push({
          date: click.date,
          Click: click.click,
        });
      } else {
        foundDataset.Click += click.click;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  totalCommissionTransaction: (state) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.commissionTotal) {
      return {};
    }
    state.detailAnalytic.commissionTotal.forEach((commission) => {
      total += 1;
      const date = moment(commission.auc_created_at).format('YYYY-MM-DD');
      const foundDataset = dataset.find(obj => obj.date === date);
      if (!foundDataset) {
        dataset.push({
          date,
          Transaction: 1,
        });
      } else {
        foundDataset.Transaction += 1;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  totalAffiliateRefundAmount: (state, getter) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.refunds) {
      return {};
    }
    state.detailAnalytic.refunds.forEach((refund) => {
      let amount = refund.transaction_amount;
      if (refund.transaction_currency !== 'USD') {
        amount = refund.transaction_amount
          * (getter.rates.USD / getter.rates[refund.transaction_currency]);
      } else {
        amount /= 100;
      }
      total += amount;
      const date = moment(refund.transaction_created_at).format('YYYY-MM-DD');
      const foundDataset = dataset.find(obj => obj.date === date);
      if (!foundDataset) {
        dataset.push({
          date,
          RefundAmount: amount,
        });
      } else {
        foundDataset.RefundAmount += amount;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  totalAffiliateRefund: (state) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.refunds) {
      return {};
    }
    state.detailAnalytic.refunds.forEach((refund) => {
      total += 1;
      const date = moment(refund.transaction_created_at).format('YYYY-MM-DD');
      const foundDataset = dataset.find(obj => obj.date === date);
      if (!foundDataset) {
        dataset.push({
          date,
          Refund: 1,
        });
      } else {
        foundDataset.Refund += 1;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
  totalAffiliateRefundRates: (state, getter) => {
    const { totalCommissionTransaction } = getter;
    const { totalAffiliateRefund } = getter;
    if (!totalCommissionTransaction.length) {
      return [];
    }

    const result = [];
    totalCommissionTransaction.forEach((transaction) => {
      const refundMatchDate = totalAffiliateRefund.find(obj => obj.date === transaction.date);
      const rate = parseFloat(((refundMatchDate.Refunds / transaction.Transaction) * 100)
        .toFixed(1));
      result.push({
        date: transaction.date,
        RefundRates: Number.isNaN(rate) ? 0 : rate,
      });
    });

    return result;
  },
  totalAffiliateGrossSale: (state, getter) => {
    const dataset = [];
    let total = 0;
    if (!state.detailAnalytic.sales) {
      return {};
    }
    state.detailAnalytic.sales.forEach((sale) => {
      let amount = sale.transaction_amount;
      if (sale.transaction_currency !== 'USD') {
        amount = sale.transaction_amount
          * (getter.rates.USD / getter.rates[sale.transaction_currency]);
      } else {
        amount /= 100;
      }
      total += amount;
      const date = moment(sale.transaction_created_at).format('YYYY-MM-DD');
      const foundDataset = dataset.find(obj => obj.date === date);
      if (!foundDataset) {
        dataset.push({
          date,
          GrossSale: amount,
        });
      } else {
        foundDataset.Transaction += amount;
      }
    });

    const detail = commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);

    return {
      detail,
      total,
    };
  },
};

const actions = {
  affiliateRegister: ({ dispatch }, data) => new Promise((resolve, reject) => {
    api.registerAffiliateUser(data)
      .then(
        (res) => {
          dispatch('showAlert', 'Your account has been created. An instruction email for create password is sent, please check your email inbox !');
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  applyPromote: ({ dispatch }, data) => new Promise((resolve, reject) => {
    api.applyPromoteProduct(data)
      .then(
        (res) => {
          dispatch('showAlert', 'Apply for promote product success!');
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  affiliateUserCreatePassword: ({ dispatch }, data) => new Promise((resolve, reject) => {
    api.createPasswordAffiliateUser(data)
      .then(
        (res) => {
          dispatch('showAlert', 'Create password success!');
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  fetchAffiliateUserProductPromote: ({ commit }) => new Promise((resolve, reject) => {
    api.getProductPromotes()
      .then(
        (res) => {
          commit(types.SET_AFFILIATE_USER_PRODUCTS, res);
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  fetchAffiliateUserCommissions: ({ commit }) => new Promise((resolve, reject) => {
    api.getCommissions()
      .then(
        (res) => {
          res.forEach((data) => {
            // eslint-disable-next-line
            data.statusColor = data.status === 'Delay' ? '#EB5757' : '#222A3C';
          });
          commit(types.SET_AFFILIATE_USER_COMMISSIONS, res);
          resolve(res);
        },
      )
      .catch(
        (error) => {
          reject(error);
        },
      );
  }),
  getAffiliateUserPaymentMethod({ commit, dispatch }) {
    api.getPaymentMethods()
      .then(
        (data) => {
          commit(types.SET_AFFILIATE_USER_PAYMENTS, data);
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
        },
      );
  },
  addAffiliateUserPaymentMethod: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    api.addPaymentMethod(data)
      .then(
        (res) => {
          commit(types.ADD_AFFILIATE_USER_PAYMENT, res);
          dispatch('showAlert', 'Add new payment method success');
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
          reject(error);
        },
      );
  }),
  deleteAffiliateUserPaymentMethod({ commit, dispatch }, id) {
    api.deletePaymentMethod(id)
      .then(
        () => {
          commit(types.DELETE_AFFILIATE_USER_PAYMENT, id);
          dispatch('showAlert', 'Delete payment method success');
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
        },
      );
  },
  updateAffiliateUserPaymentMethod: ({ commit, dispatch }, payload) => new Promise(
    (resolve, reject) => {
      const isDefault = payload.isDefault ? payload.isDefault : false;
      api.updatePaymentMethod(payload.id, payload.data, isDefault)
        .then(
          () => {
            commit(types.UPDATE_AFFILIATE_USER_PAYMENT, payload);
            dispatch('showAlert', 'Update payment method success');
            resolve(true);
          },
        )
        .catch(
          (error) => {
            dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
            reject(error);
          },
        );
    },
  ),
  fetchPromoteProduct: ({ commit }, hash) => new Promise(
    (resolve, reject) => {
      api.getPromoteProduct(hash)
        .then(
          (res) => {
            commit(types.SET_PROMOTE_COURSE, res);
            resolve(res);
          },
        )
        .catch(
          (error) => {
            reject(error);
          },
        );
    },
  ),
  getAnalyticStats: ({ commit }, data) => new Promise(
    (resolve, reject) => {
      api.getDetailAnalytic(data)
        .then(
          (res) => {
            commit(types.SET_AFFILIATE_USER_DETAIL_ANALYTIC, res);
            resolve(res);
          },
        )
        .catch(
          (error) => {
            reject(error);
          },
        );
    },
  ),
  setAffiliateAnalyticFilterDate: ({ commit }, data) => new Promise(
    (resolve) => {
      commit(types.SET_AFFILIATE_FILTER_DATE, data);
      resolve(data);
    },
  ),
  updatePromoteProduct: ({ dispatch }, data) => new Promise(
    (resolve, reject) => {
      api.updatePromoteProduct(data)
        .then(
          (res) => {
            dispatch('showAlert', 'Your product has been updated');
            resolve(res);
          },
        )
        .catch(
          (error) => {
            reject(error);
          },
        );
    },
  ),
};

const mutations = {
  [types.SET_AFFILIATE_USER_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [types.SET_AFFILIATE_USER_COMMISSIONS](state, payload) {
    state.commissions = payload;
  },
  [types.UPDATE_AFFILIATE_USER_PAYMENT](state, payload) {
    const index = state.paymentMethods.findIndex(s => s.id === payload.id);
    state.paymentMethods[index].setting = payload.data;
  },
  [types.ADD_AFFILIATE_USER_PAYMENT](state, payload) {
    state.paymentMethods.push(payload);
  },
  [types.DELETE_AFFILIATE_USER_PAYMENT](state, id) {
    state.paymentMethods = state.paymentMethods.filter(s => s.id !== id);
  },
  [types.SET_AFFILIATE_USER_PAYMENTS](state, payload) {
    state.paymentMethods = payload;
  },
  [types.SET_PROMOTE_COURSE](state, payload) {
    state.promoteProduct = payload;
  },
  [types.SET_AFFILIATE_USER_DETAIL_ANALYTIC](state, payload) {
    state.detailAnalytic = payload;
  },
  [types.SET_AFFILIATE_FILTER_DATE](state, payload) {
    state.startDate = payload.start;
    state.endDate = payload.end;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
