export default {
  getUserStats: data => new Promise((resolve, reject) => {
    if (!data || !data.courseIds || !data.courseIds.length) {
      resolve([]);
    }

    window.http.post('courses/user-analytics', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getCommentsStats: data => new Promise((resolve, reject) => {
    if (!data || !data.courseIds || !data.courseIds.length) {
      resolve([]);
    }

    window.http.post('courses/comments/stats', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getTotalNewAnalytic: () => new Promise((resolve, reject) => {
    window.http.get('total-new-analytic/')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  markCommentSeen: data => new Promise((resolve, reject) => {
    window.http.post('courses/comments/mark-seen', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
