<template>
  <div v-if="clientProfileDetail"
       class="account-setting__container alternative account-setting__client-profile">
    <v-tabs v-model="tab" class="account-setting__tabs">
      <v-tab class="account-setting__tab" href="#payment">Payment Methods</v-tab>
      <v-tab class="account-setting__tab" href="#email">Sender Emails</v-tab>
      <v-tab class="account-setting__tab" href="#integration">Integrations</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="account-setting__tab-item">
      <v-tab-item value="payment"
                  :transition="false"
                  :reverse-transition="false">
        <p class="flex-text primary-text pointer back-arrow" @click="$emit('back')">
          <icon-arrow-left class="icon-default mr-2" />
          {{ clientProfileDetail.name }}
        </p>
        <payment-setting :client-profile="clientProfileDetail" />
      </v-tab-item>
      <v-tab-item value="email"
                  :transition="false"
                  :reverse-transition="false">
        <p class="flex-text primary-text pointer back-arrow" @click="$emit('back')">
          <icon-arrow-left class="icon-default mr-2" />
          {{ clientProfileDetail.name }}
        </p>
        <email-setting :client-profile="clientProfileDetail" />
      </v-tab-item>
      <v-tab-item value="integration"
                  :transition="false"
                  :reverse-transition="false">
        <p class="flex-text primary-text pointer back-arrow" @click="$emit('back')">
          <icon-arrow-left class="icon-default mr-2" />
          {{ clientProfileDetail.name }}
        </p>
        <integration-setting :client-profile="clientProfileDetail" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import PaymentSetting from '../PaymentSetting';
  import EmailSetting from '../EmailSetting';
  import IntegrationSetting from '../IntegrationSetting';

  export default {
    components: {
      EmailSetting,
      PaymentSetting,
      IntegrationSetting,
    },
    props: ['profileId'],
    data() {
      return {
        tab: 'payment',
      };
    },
    computed: {
      ...mapGetters('agency', ['clientProfileDetail']),
    },
    mounted() {
      this.getClientProfileDetail(this.profileId).then(() => {
        if (this.$route.query.tab) {
          this.tab = this.$route.query.tab;
        }
      });
    },
    methods: {
      ...mapActions('agency', ['getClientProfileDetail']),
    },
  };
</script>
