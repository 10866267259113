import _cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import * as types from '@/store/mutation-types';
import CouponApi from '@/api/coupon';

const state = {
  coupons: [],
  atSync: false,
};

const getters = {
  couponList: state => state.coupons,
  couponListAtSync: state => state.atSync,
};

const actions = {
  syncCoupons: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    commit(types.SET_COUPON_LIST_BUSY, true);
    CouponApi.get().then(
      (coupons) => {
        dispatch('prepareCoupon', coupons);
        commit(types.SET_COUPON_LIST_BUSY, false);
        resolve(coupons);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  addCoupon: ({ commit, dispatch, state }, data) => new Promise((resolve, reject) => {
    commit(types.SET_COUPON_LIST_BUSY, true);
    CouponApi.create({
      code: data.code || '',
      type: data.type,
      startAt: data.canExpire !== 'no' ? data.startAt : null,
      endAt: data.canExpire !== 'no' ? data.endAt : null,
      limitEnabled: data.limitEnabled !== 'no',
      limit: data.limit || 0,
      productsList: data.productsList,
      duration: data.duration,
      amount: data.amount,
    }).then(
      (newCoupon) => {
        commit(types.SET_COUPON_LIST_BUSY, false);
        commit(types.ADD_COUPON_INTO_LIST, newCoupon);
        dispatch('prepareCoupon', state.coupons);
        dispatch('showAlert', 'Add new coupon success');
        resolve(newCoupon);
      },
      (error) => {
        dispatch('showAlert', { type: 'danger', message: error });
        commit(types.SET_COUPON_LIST_BUSY, false);
        reject(error);
      },
    );
  }),
  updateCoupon: ({ commit, dispatch, state }, data) => new Promise((resolve, reject) => {
    commit(types.SET_COUPON_LIST_BUSY, true);
    CouponApi.update(data.id, {
      code: data.code || '',
      type: data.type,
      startAt: data.canExpire !== 'no' ? data.startAt : null,
      endAt: data.canExpire !== 'no' ? data.endAt : null,
      limitEnabled: data.limitEnabled !== 'no',
      limit: data.limit || 0,
      productsList: data.productsList,
      duration: data.duration,
      amount: data.amount,
    }).then(
      (coupon) => {
        commit(types.SET_COUPON_LIST_BUSY, false);
        commit(types.UPDATE_COUPON_IN_CURRENT_LIST, coupon);
        dispatch('showAlert', 'Coupon is updated');
        dispatch('prepareCoupon', state.coupons);
        resolve(coupon);
      },
      (error) => {
        commit(types.SET_COUPON_LIST_BUSY, false);
        reject(error);
      },
    );
  }),
  deleteCoupon: ({ commit, dispatch, state }, coupon) => new Promise((resolve, reject) => {
    commit(types.SET_COUPON_LIST_BUSY, true);
    CouponApi.delete(coupon.id).then(
      () => {
        commit(types.DELETE_COUPON_FROM_LIST, coupon);
        commit(types.SET_COUPON_LIST_BUSY, false);
        dispatch('showAlert', 'Coupon is deleted');
        dispatch('prepareCoupon', state.coupons);
        resolve();
      },
      (error) => {
        commit(types.SET_COUPON_LIST_BUSY, false);
        reject(error);
      },
    );
  }),
  prepareCoupon: ({ commit }, coupons) => new Promise((resolve) => {
    const prepare = [];
    const time = moment().format('YYYY-MM-DD HH:mm:ss');
    let no = 1;
    coupons.forEach((coupon) => {
      const tmp = _cloneDeep(coupon);

      if (!coupon.startAt || (coupon.startAt < time && coupon.endAt > time)) {
        tmp.status = 'Active';
        tmp.statusColor = '#14C8B1';
      } else if (coupon.startAt > time) {
        tmp.status = 'Pending';
        tmp.statusColor = '#FF9900';
      } else {
        tmp.status = 'Expired';
        tmp.statusColor = '#EB5757';
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      tmp.discount = `${formatter.format(tmp.amount)}`;
      if (coupon.type === 'percentage') {
        tmp.discount = `${coupon.amount}%`;
      }

      if (!coupon.uses) {
        tmp.uses = 0;
      }

      if (coupon.limit === 1) {
        tmp.maxUsages = `${coupon.limit} use`;
      } else {
        tmp.maxUsages = `${coupon.limit} uses`;
      }

      if (!coupon.limitEnabled) {
        tmp.maxUsages = '-';
      }

      tmp.no = no;
      prepare.push(tmp);
      no += 1;
    });
    commit(types.SET_COUPON_LIST, prepare);
    resolve(true);
  }),
};

const mutations = {
  [types.SET_COUPON_LIST](state, payload) {
    state.coupons = payload;
  },
  [types.SET_COUPON_LIST_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.UPDATE_COUPON_IN_CURRENT_LIST](state, payload) {
    const idx = state.coupons.findIndex(coupon => payload.id === coupon.id);
    Object.assign(state.coupons[idx], payload);
  },
  [types.ADD_COUPON_INTO_LIST](state, payload) {
    state.coupons.push(payload);
  },
  [types.ADD_COUPON_INTO_LIST](state, payload) {
    state.coupons.push(payload);
  },
  [types.DELETE_COUPON_FROM_LIST](state, payload) {
    const idx = state.coupons.indexOf(payload);
    state.coupons.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
