<template>
  <v-fade-transition>
    <div>
      <div class="affiliate__payment-form-wrapper">
        <div class="affiliate__payment-form">
          <div class="payment-form__header">
            <div class="affiliate__add-payment-logo">
              <icon-paypal />
            </div>
            <p class="payment-form__header-title">PayPal account information</p>
          </div>
          <p class="label-text">Email</p>
          <v-text-field
            v-model="formData.email"
            v-validate="'required|email'"
            :error-messages="errors.collect('email')"
            class="default"
            data-vv-name="email"
            data-vv-as="Paypal email"
            solo
          />
          <p class="label-text">Country</p>
          <custom-select
            v-model="formData.country"
            :items="countryList"
            item-title="title"
            item-value="value"
          />
          <div class="layout">
            <div class="flex xs9">
              <p class="label-text">State</p>
              <custom-select
                v-model="formData.state"
                :items="stateList"
                item-title="title"
                item-value="value"
              />
            </div>
            <div class="flex xs3 ml-20">
              <p class="label-text">Zip code</p>
              <v-text-field
                v-model="formData.zipCode"
                v-validate="'required'"
                :error-messages="errors.collect('zipCode')"
                data-vv-name="zipCode"
                data-vv-as="Paypal zipcode"
                solo
              />
            </div>
          </div>
          <p class="label-text">City</p>
          <custom-select
            v-model="formData.city"
            :items="cityList"
            item-title="title"
            item-value="value"
          />
          <p class="label-text">Street / Building</p>
          <v-text-field
            v-model="formData.detailAddress"
            v-validate="'required'"
            :error-messages="errors.collect('street')"
            data-vv-name="street"
            data-vv-as="Paypal street/building"
            solo
          />
        </div>
      </div>
      <div class="layout payment-form__footer mt-20">
        <v-spacer />
        <v-btn class="custom-btn-normal sm mr-15" @click="$emit('goBack')">
          Cancel
        </v-btn>
        <v-btn class="custom-btn-secondary sm" :loading="loading" @click="save">
          Save Changes
        </v-btn>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
  import CustomSelect from '@/components/select/CustomSelect';
  import validationHelpers from '@/mixins/validationHelpers';
  import partnerPaymentMethod from '@/mixins/partnerPaymentMethod';

  export default {
    components: {
      CustomSelect,
    },
    mixins: [validationHelpers, partnerPaymentMethod],
    data() {
      return {
        formData: {
          email: '',
          country: '',
          state: '',
          zipCode: '',
          city: '',
          detailAddress: '',
        },
      };
    },
    mounted() {
      if (this.paymentData && Object.keys(this.paymentData).length) {
        this.isEdit = true;
        this.init();
      }
    },
    methods: {
      save() {
        this.submit(this.type.PAYPAL);
      },
      init() {
        this.firstInit = true;
        this.formData = {
          email: this.paymentData.setting.email,
          country: this.paymentData.setting.country,
          state: this.paymentData.setting.state,
          zipCode: this.paymentData.setting.zipCode,
          city: this.paymentData.setting.city,
          detailAddress: this.paymentData.setting.detailAddress,
        };
      },
    },
  };
</script>
