export default {
  createPartnershipContract: data => new Promise((resolve, reject) => {
    window.http.post('/partnership-contract', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updatePartnershipContract: data => new Promise((resolve, reject) => {
    window.http.put(`/partnership-contract/${data.id}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  deletePartnershipContract: partnershipId => new Promise((resolve, reject) => {
    window.http.delete(`/partnership-contract/${partnershipId}`).then(
      () => { resolve(); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getPartnershipContract: id => new Promise((resolve, reject) => {
    window.http.get(`/partnership-contract/${id}`).then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  getPartnershipContracts: () => new Promise((resolve, reject) => {
    window.http.get('/partnership-contracts').then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  getPartnershipById: id => new Promise((resolve, reject) => {
    window.http.get(`/partnership/${id}`).then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  getOwnContract: () => new Promise((resolve, reject) => {
    window.http.get('/partnership/own-contract').then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  getOwnPartner: () => new Promise((resolve, reject) => {
    window.http.get('/own-partners').then(
      (res) => {
        resolve(res.data);
      },
    ).catch(
      (err) => {
        reject(err.response.data);
      },
    );
  }),
  activePartnership: formData => new Promise((resolve, reject) => {
    window.http.post('/active-partnership', formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getContractInfoByCode: code => new Promise((resolve, reject) => {
    window.http.get(`/partnership/contract-by-code/${code}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  getStatisticAdminData: params => new Promise((resolve, reject) => {
    window.http.get('/partnership/commissions/statistic', {
      params,
    }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getCommissionAdminData: params => new Promise((resolve, reject) => {
    window.http.post('/partnership-commissions', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  adminPayment: params => new Promise((resolve, reject) => {
    window.http.post('/partnership/commission/process', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  getPartnerContractById: contractId => new Promise((resolve, reject) => {
    window.http.get(`/partnership/contract/${contractId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getPartnerContractStatisticById: params => new Promise((resolve, reject) => {
    window.http.get('/partnership/commission/statistic', {
      params,
    }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getPartnerContractTransactionById: params => new Promise((resolve, reject) => {
    window.http.get('/partnership/commission/transactions', {
      params,
    }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getPartnerContractCommissionById: params => new Promise((resolve, reject) => {
    window.http.post('/partnership/commissions', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getPartnershipPaymentMethods: () => new Promise((resolve, reject) => {
    window.http.get('/partnership/payment-methods').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  createPartnershipPaymentMethod: data => new Promise((resolve, reject) => {
    window.http.post('/partnership/payment-method', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updatePartnershipPaymentMethod: data => new Promise((resolve, reject) => {
    window.http.put(`/partnership/payment-method/${data.id}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  deletePartnershipPaymentMethods: id => new Promise((resolve, reject) => {
    window.http.delete(`/partnership/payment-method/${id}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updateSelf: formData => new Promise((resolve, reject) => {
    window.http.post('/partnership/update', formData).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  updateContract: data => new Promise((resolve, reject) => {
    window.http.put(`/partnership/update-contract/${data.id}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
