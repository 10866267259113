<template>
  <div class="account-setting">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'membership_dashboard' })">
        Campaigns
      </a>
      <icon-arrow-right class="chevron-mg icon-default" />
      <a class="primary-text active">
        Account Settings
      </a>
    </div>
    <div class="layout mt-20 align-top">
      <div class="account-setting__sidebar">
        <div
          v-for="(item, index) in menuList"
          :key="index"
          :class="{ active: $route.name === item.route }"
          class="account-setting__sidebar-item"
          @click="goToRoute(item.route)"
        >
          <component :is="`icon-${item.icon}`" :class="`icon-${item.icon}`" />
          <p class="primary-text">{{ item.name }}</p>
        </div>
      </div>
      <div class="account-setting__box">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import roleHelpers from '@/mixins/roleHelpers';
  import IconArrowRight from '@/assets/icons/arrow-right.svg';

  export default {
    components: {
      IconArrowRight,
    },
    mixins: [roleHelpers],
    computed: {
      ...mapGetters(['isConnectPartnership']),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
      menuList() {
        const tmp = [
          {
            name: 'Profile',
            route: 'dashboard.account-setting.profile',
            icon: 'user-side',
            access: true,
          },
          {
            name: 'Agency',
            route: 'dashboard.account-setting.agency-setting',
            icon: 'agency',
            access: this.whoIam.haveFeatureNew('CLIENT_MANAGEMENT'),
          },
          {
            name: 'Payment',
            route: 'dashboard.account-setting.payment',
            icon: 'card',
            access: this.whoIam.haveFeatureNew('CHECKOUT_SETTING') || this.isAgencyDomain,
          },
          {
            name: 'Integrations',
            route: 'dashboard.account-setting.integrations',
            icon: 'integration',
            access: !this.whoIam.isOnlyTeamMember,
          },
          {
            name: 'API Setting',
            route: 'dashboard.account-setting.api',
            icon: 'tool',
            access: this.whoIam.haveFeatureNew('CHECKOUT_SETTING') && !this.isAgencyDomain,
          },
          {
            name: 'Domains',
            route: 'dashboard.account-setting.domains',
            icon: 'domains',
            access: !this.isAgencyDomain && !this.whoIam.isOnlyTeamMember,
          },
          {
            name: 'Active Sessions',
            route: 'dashboard.account-setting.active-session',
            icon: 'computer',
            access: this.whoIam.haveFeatureNew('ACTIVE_SESSION'),
          },
          /* {
            name: 'Emails',
            route: 'dashboard.account-setting.emails',
            icon: 'email',
            access: this.whoIam.haveFeatureNew('EMAIL_SENDER') || this.isAgencyDomain,
          }, */
          {
            name: 'Team',
            route: 'dashboard.account-setting.team-members',
            icon: 'users',
            access: this.whoIam.haveFeatureNew('TEAM_MANAGEMENT'),
          },
        ];
        const menu = [];
        tmp.forEach((row) => {
          if (row.access) {
            menu.push(row);
          }
        });
        return menu;
      },
    },
    methods: {
      goToRoute(name) {
        if (this.$route.name === name) {
          return;
        }
        this.$router.push({ name });
      },
    },
  };
</script>
