<template>
  <div class="membership-storage-modal">
    <div class="primary-text lg text-gray-n">Choose a plan that suits your requirements </div>
    <div class="mt-20">
      <div
        v-for="(item, idx) in listPackage"
        :key="idx"
        class="cursor-pointer plan-item"
        :class="{ 'active': availableSpace === item.size, 'selected': selectedPlan.id === item.id }"
        @click="selectPlan(item)"
      >
        <div class="flex-between">
          <div>
            <div class="primary-text xl semi-bold mb-1">{{ item.label }}</div>
            <div class="primary-text lg semi-bold text-gray-n">
              <b>{{ convertByte(item.size) }}</b> of storage
            </div>
          </div>
          <div>
            <div class="flex-end mb-1">
              <div class="primary-text xl bold text-blue-bg-d">${{ item.price }} +</div>
              <div class="all-access primary-text bold">All Access</div>
            </div>
            <div class="primary-text semi-bold text-gray-n">
              Monthly Subscription
            </div>
          </div>
        </div>
        <div class="current-plan">
          <div class="current-plan-box primary-text semi-bold text-dark">Current Plan</div>
        </div>
      </div>
    </div>
    <div class="flex-end mt-20">
      <v-btn class="custom-btn-normal mr-15 fixed-width" @click="onCancel">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width"
             :disabled="!selectedPlan" @click="onUpdatePlan">
        Update Plan
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import storageHelper from '@/mixins/storage';

  export default {
    mixins: [storageHelper],
    data() {
      return {
        selectedPlan: '',
        currentPlan: 'starter', // TODO: call API to get data
        listPackage: [{ // TODO: call API to get data
          id: 'starter',
          label: 'Starter Data',
          size: 1000000000000,
          price: 47,
          url: 'https://app.paykickstart.com/checkout/DL34Re7XowpanW6agJzdVMAx1JZv5y9K',
        }, {
          id: 'profesional',
          label: 'Profesional Data',
          size: 3000000000000,
          price: 127,
          url: 'https://app.paykickstart.com/checkout/VNvm3yQDqng4BW1YL7WEZbXKRdaY96G2',
        }, {
          id: 'agency',
          label: 'Agency Data',
          size: 5000000000000,
          price: 197,
          url: 'https://app.paykickstart.com/checkout/085AZMQlJaVyvWLE6ok6m2YLxENo1BRg',
        }],
      };
    },
    computed: {
      ...mapGetters('storage', ['availableSpace']),
    },
    methods: {
      ...mapActions([
        'closeModal',
      ]),
      selectPlan(item) {
        if (this.availableSpace === item.size) {
          return;
        }
        this.selectedPlan = item;
      },
      onCancel() {
        this.closeModal();
      },
      onUpdatePlan() {
        if (this.selectedPlan) {
          window.location.href = this.selectedPlan.url;
        }
      },
    },
  };
</script>
