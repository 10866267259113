const paymentMethodType = {
  BANK: 1,
  PAYPAL: 2,
};

const paymentMethodConfig = {
  [paymentMethodType.BANK]: 'Bank',
  [paymentMethodType.PAYPAL]: 'Paypal',
};

const paymentTerms = {
  percentage_of_sale: 'Percentage of sale',
};

export default {
  paymentMethodType,
  paymentMethodConfig,
  paymentTerms,
};
