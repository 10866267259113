import moment from 'moment';
import * as types from '@/store/mutation-types';
import api from '@/api/course';
import commonHelper from '../../helper/commonHelper';

const state = {
  stats: [],
  compareStats: [],
  courseList: [],
  enableCompare: false,

  statsFetching: false,

  dropdownShowsCourseList: false,

  selectedMembershipIds: [],
  selectedCourseIds: [],
  startDate: null,
  endDate: null,
  compareStartDate: null,
  compareEndDate: null,
  selectedWidgets: [],
};

const getters = {
  enableCompare: state => state.enableCompare,
  dashboardCourseList: state => state.courseList,
  dashboardStats: state => state.stats,
  dropdownCourseOptions: state => state.courseList.map(c => ({ id: c.id, title: c.title })),
  dropdownMembershipOptions: (state) => {
    const list = state.courseList.map(c => ({ id: c.membershipId, title: c.membershipTitle }));
    return list.filter(((item, index, array) => array.findIndex(i => i.id === item.id) === index));
  },
  dropdownOptions: (state) => {
    if (state.dropdownShowsCourseList) {
      const result = {};
      state.courseList.forEach((course) => {
        if (Object.keys(result).includes(course.membershipId.toString())) {
          result[course.membershipId].items.push({ id: course.id, title: course.title });
        } else {
          result[course.membershipId] = {
            label: course.membershipTitle,
            items: [{ id: course.id, title: course.title }],
          };
        }
      });
      return Object.keys(result).map(k => result[k]);
    }
    const list = state.courseList.map(c => ({ id: c.membershipId, title: c.membershipTitle }));
    return list.filter(((item, index, array) => array.findIndex(i => i.id === item.id) === index));
  },
  selectedMembershipIds: state => state.selectedMembershipIds,
  selectedCourseIds: state => state.selectedCourseIds,
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  compareStartDate: state => state.compareStartDate,
  compareEndDate: state => state.compareEndDate,
  getSelectedWidgets: state => state.selectedWidgets,
  dropdownShowsCourseList: state => state.dropdownShowsCourseList,
  totalRevenueWidgetData: (state) => {
    const transactions = state.stats.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.product.productTransactions) {
          const d = currentValue.product.productTransactions.map((pt) => {
            if (pt.currency === 'JPY') {
              return pt;
            }

            return { ...pt, amount: pt.amount / 100 };
          });

          a.push(...d);
        }
        return a;
      },
      [],
    );

    const t = transactions.map((i) => {
      let courseTitle = '';
      let found;
      if (state.courseList) {
        found = state.courseList.find(c => c.productId === i.productId);
      }
      if (found && found.title) {
        courseTitle = found.title;
      }

      return { courseTitle, ...i };
    });

    return t;
  },
  totalRevenueCompareData: (state) => {
    const transactions = state.compareStats.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.product.productTransactions) {
          const d = currentValue.product.productTransactions
            .map(ct => ({ ...ct, amount: ct.amount / 100 }));

          a.push(...d);
        }
        return a;
      },
      [],
    );

    const t = transactions.map((i) => {
      let courseTitle = '';
      let found;
      if (state.courseList) {
        found = state.courseList.find(c => c.productId === i.productId);
      }
      if (found && found.title) {
        courseTitle = found.title;
      }

      return { courseTitle, ...i };
    });

    return t;
  },
  totalRefundsWidgetData: (state) => {
    let { stats } = state;
    if (state.enableCompare) {
      stats = state.compareStats;
    }
    const transactions = stats.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.product.productTransactions) {
          a.push(...currentValue.product.productTransactions);
        }
        return a;
      },
      [],
    );
    return transactions.filter(t => t.type === 'refund');
  },
  totalNewUsersWidgetData: (state) => {
    const members = state.stats.reduce(
      (accumulator, currentValue) => {
        if (currentValue.productMember) {
          accumulator.push(...currentValue.productMember);
        }
        return accumulator;
      }, [],
    );

    const m = members.map((i) => {
      let courseTitle = '';
      let found;
      if (state.courseList) {
        found = state.courseList.find(c => c.id === i.courseId);
      }
      if (found && found.title) {
        courseTitle = found.title;
      }

      return { courseTitle, ...i };
    });

    return m.filter((v, i, a) => a.findIndex(j => j.memberId === v.memberId) === i);
  },
  totalCheckoutVisitsData: (state) => {
    let { stats } = state;
    if (state.enableCompare) {
      stats = state.compareStats;
    }
    return stats.reduce(
      (accumulator, currentValue) => {
        const a = accumulator;
        if (currentValue.checkoutVisits) {
          a.push(...currentValue.checkoutVisits);
        }
        return a;
      },
      [],
    );
  },
  salesSummaryWidgetData: (state) => {
    const rows = [];
    state.stats.forEach((course) => {
      let transactionPurchase = [];
      let transactionRefunds = [];
      const members = [];
      if (course.product.productTransactions) {
        transactionPurchase = course.product.productTransactions.filter(tr => tr.type === 'purchase');
        transactionRefunds = course.product.productTransactions.filter(tr => tr.type === 'refund');
      }

      if (course.productMember) {
        members.push(...course.productMember);
      }

      const revenue = transactionPurchase.reduce(
        (acc, curr) => {
          const aa = acc;

          if (curr.currency === 'JPY') {
            return aa + curr.amount * 100;
          }

          return aa + curr.amount;
        }, 0,
      ) / 100;

      const refunds = transactionRefunds.reduce(
        (acc, curr) => {
          const aa = acc;

          if (curr.currency === 'JPY') {
            return aa + curr.amount * 100;
          }

          return aa + curr.amount;
        }, 0,
      ) / 100;

      if (!state.dropdownShowsCourseList) { // Group by membership
        let found = rows.find(r => r.name === course.membershipTitle);
        if (found) {
          found.newUsers += members.length;
          found.payments += transactionPurchase.length;
          found.refundsCount += transactionRefunds.length;
          found.refunds += refunds;
          found.revenue += revenue;
        } else {
          found = {
            name: course.membershipTitle,
            newUsers: members.length,
            courses: [],
            payments: transactionPurchase.length,
            refundsCount: transactionRefunds.length,
            refunds,
            revenue,
          };
          rows.push(found);
        }
        found.courses.push({
          title: course.title,
          newUsers: members.length,
          payments: transactionPurchase.length,
          refundsCount: transactionRefunds.length,
          refunds,
          revenue,
        });
      } else {
        rows.push({
          name: course.title,
          newUsers: members.length,
          payments: transactionPurchase.length,
          refundsCount: transactionRefunds.length,
          refunds,
          revenue,
        });
      }
    });

    return rows;
  },
  detailRevenue: (state) => {
    const dataset = [];
    state.compareStats.forEach((course) => {
      let transactions = [];
      if (course.product.productTransactions) {
        transactions = course.product.productTransactions
          .filter(tr => tr.type === 'purchase' || tr.type === 'refund')
          .map((tr) => {
            if (tr.currency === 'JPY') {
              return tr;
            }

            return { ...tr, amount: tr.amount / 100 }; // transform 10000 to 100$
          });

        transactions.forEach((data) => {
          const date = moment.utc(data.createdAt).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          let key = course.title;
          if (!state.dropdownShowsCourseList) { // Group by membership
            key = course.membershipTitle;
          }

          let { amount } = data;
          if (data.type === 'refund') {
            amount = -data.amount;
          }
          if (!foundDataset) {
            const tmp = {
              date,
            };
            tmp[key] = amount;
            dataset.push(tmp);
          } else if (!state.dropdownShowsCourseList && key in foundDataset) {
            foundDataset[key] += amount;
          } else {
            foundDataset[key] = amount;
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.compareStartDate, state.compareEndDate, dataset);
  },
  detailCheckoutView: (state) => {
    const dataset = [];
    state.compareStats.forEach((course) => {
      if (course.checkoutVisits && course.checkoutVisits.length > 0) {
        course.checkoutVisits.forEach((visit) => {
          const date = moment.utc(visit.date).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          let key = course.title;
          if (!state.dropdownShowsCourseList) { // Group by membership
            key = course.membershipTitle;
          }
          if (!foundDataset) {
            const tmp = {
              date,
            };
            tmp[key] = visit.visit;
            dataset.push(tmp);
          } else if (!state.dropdownShowsCourseList && key in foundDataset) {
            foundDataset[key] += visit.visit;
          } else {
            foundDataset[key] = visit.visit;
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.compareStartDate, state.compareEndDate, dataset);
  },
  detailRefunds: (state) => {
    const dataset = [];
    let { stats } = state;
    if (state.enableCompare) {
      stats = state.compareStats;
    }
    stats.forEach((course) => {
      if (course.product.productTransactions && course.product.productTransactions.length > 0) {
        course.product.productTransactions.forEach((transaction) => {
          const date = moment.utc(transaction.createdAt).format('YYYY-MM-DD');
          let key = course.title;
          if (!state.dropdownShowsCourseList) { // Group by membership
            key = course.membershipTitle;
          }
          if (transaction.type === 'refund') {
            const foundDataset = dataset.find(obj => obj.date === date);
            if (!foundDataset) {
              const tmp = {
                date,
              };
              tmp[key] = 1;
              dataset.push(tmp);
            } else {
              foundDataset[key] += 1;
            }
          }
        });
      }
    });

    let start = state.startDate;
    let end = state.endDate;
    if (state.enableCompare) {
      start = state.compareStartDate;
      end = state.compareEndDate;
    }

    return commonHelper.prepareLabelDateForAnalytic(start, end, dataset);
  },
  detailSales: (state) => {
    const dataset = [];
    state.compareStats.forEach((course) => {
      if (course.product.productTransactions && course.product.productTransactions.length > 0) {
        course.product.productTransactions.forEach((transaction) => {
          const date = moment.utc(transaction.createdAt).format('YYYY-MM-DD');
          if (transaction.type === 'purchase') {
            const foundDataset = dataset.find(obj => obj.date === date);
            let key = course.title;
            if (!state.dropdownShowsCourseList) { // Group by membership
              key = course.membershipTitle;
            }
            if (!foundDataset) {
              const tmp = {
                date,
              };
              tmp[key] = 1;
              dataset.push(tmp);
            } else {
              foundDataset[key] += 1;
            }
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.compareStartDate, state.compareEndDate, dataset);
  },
  detailCartConversion: (state, getter) => {
    const { detailSales } = getter;
    const detailViews = getter.detailCheckoutView;

    const result = [];
    detailSales.forEach((sale) => {
      const viewMatchDate = detailViews.find(obj => obj.date === sale.date);
      const tmp = {
        date: sale.date,
      };
      Object.keys(sale).forEach((key) => {
        if (key !== 'date') {
          if (key in viewMatchDate) {
            tmp[key] = parseFloat(((sale[key] / viewMatchDate[key]) * 100).toFixed(1));
          } else {
            tmp[key] = 0;
          }
        }
      });
      result.push(tmp);
    });

    return result;
  },
  detailRefundRates: (state, getter) => {
    const { detailSales } = getter;
    const { detailRefunds } = getter;

    const result = [];
    detailSales.forEach((sale) => {
      const refundMatchDate = detailRefunds.find(obj => obj.date === sale.date);
      const tmp = {
        date: sale.date,
      };
      Object.keys(sale).forEach((key) => {
        if (key !== 'date') {
          if (key in refundMatchDate) {
            tmp[key] = parseFloat(((refundMatchDate[key] / sale[key]) * 100).toFixed(1));
          } else {
            tmp[key] = 0;
          }
        }
      });
      result.push(tmp);
    });

    return result;
  },
  avgRevenue: (state, getter) => {
    let start = state.startDate;
    let end = state.endDate;
    if (state.enableCompare) {
      start = state.compareStartDate;
      end = state.compareEndDate;
    }

    if (start) {
      const diff = end.diff(start, 'days');
      let number = 0;
      if (state.enableCompare) {
        getter.totalRevenueCompareData.forEach((data) => {
          if (data.amount) {
            if (data.type === 'purchase') {
              number += data.amount;
            } else {
              number -= data.amount;
            }
          }
        });
      } else {
        getter.totalRevenueWidgetData.forEach((data) => {
          if (data.amount) {
            if (data.type === 'purchase') {
              number += data.amount;
            } else {
              number -= data.amount;
            }
          }
        });
      }
      if (diff < 62) { // dayly avg revenue
        return {
          amount: number / diff,
          text: 'Avg. daily revenue',
        };
      } // monthly avg revenue
      const diffMonth = state.endDate.diff(start, 'months') + 1;
      return {
        amount: number / diffMonth,
        text: 'Avg. monthly revenue',
      };
    }
    return {
      text: 'Avg. daily revenue',
      amount: 0,
    };
  },
  detailRevenueTotal: (state) => {
    const dataset = [];
    state.stats.forEach((course) => {
      if (course.product.productTransactions) {
        const transactions = course.product.productTransactions
          .filter(tr => tr.type === 'purchase' || tr.type === 'refund')
          .map((tr) => {
            if (tr.currency === 'JPY') {
              return tr;
            }

            return { ...tr, amount: tr.amount / 100 }; // transform 10000 to 100$
          });

        transactions.forEach((data) => {
          const date = moment.utc(data.createdAt).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);

          let { amount } = data;
          if (data.type === 'refund') {
            amount = -data.amount;
          }

          if (!foundDataset) {
            dataset.push({
              date,
              Revenue: amount,
            });
          } else {
            foundDataset.Revenue += amount;
          }
        });
      }
    });
    return commonHelper.prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);
  },
  detailCheckoutViewTotal: (state) => {
    const dataset = [];
    state.stats.forEach((course) => {
      if (course.checkoutVisits && course.checkoutVisits.length > 0) {
        course.checkoutVisits.forEach((visit) => {
          const date = moment.utc(visit.date).format('YYYY-MM-DD');
          const foundDataset = dataset.find(obj => obj.date === date);
          if (!foundDataset) {
            dataset.push({
              date,
              Views: visit.visit,
            });
          } else {
            foundDataset.Views += visit.visit;
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);
  },
  detailSalesTotal: (state) => {
    const dataset = [];
    state.stats.forEach((course) => {
      if (course.product.productTransactions && course.product.productTransactions.length > 0) {
        course.product.productTransactions.forEach((transaction) => {
          const date = moment.utc(transaction.createdAt).format('YYYY-MM-DD');
          if (transaction.type === 'purchase') {
            const foundDataset = dataset.find(obj => obj.date === date);
            if (!foundDataset) {
              dataset.push({
                date,
                Sales: 1,
              });
            } else {
              foundDataset.Sales += 1;
            }
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);
  },
  detailRefundsTotal: (state) => {
    const dataset = [];
    state.stats.forEach((course) => {
      if (course.product.productTransactions && course.product.productTransactions.length > 0) {
        course.product.productTransactions.forEach((transaction) => {
          const date = moment.utc(transaction.createdAt).format('YYYY-MM-DD');
          if (transaction.type === 'refund') {
            const foundDataset = dataset.find(obj => obj.date === date);
            if (!foundDataset) {
              dataset.push({
                date,
                Refunds: 1,
              });
            } else {
              foundDataset.Refunds += 1;
            }
          }
        });
      }
    });

    return commonHelper
      .prepareLabelDateForAnalytic(state.startDate, state.endDate, dataset);
  },
  detailCartConversionTotal: (state, getter) => {
    const { detailSalesTotal } = getter;
    const { detailCheckoutViewTotal } = getter;

    const result = [];
    detailSalesTotal.forEach((sale) => {
      const viewMatchDate = detailCheckoutViewTotal.find(obj => obj.date === sale.date);
      const rate = parseFloat(((sale.Sales / viewMatchDate.Views) * 100).toFixed(1));
      result.push({
        date: sale.date,
        CartConversion: Number.isNaN(rate) ? 0 : rate,
      });
    });

    return result;
  },
  detailRefundRatesTotal: (state, getter) => {
    const { detailSalesTotal } = getter;
    const { detailRefundsTotal } = getter;

    const result = [];
    detailSalesTotal.forEach((sale) => {
      const refundMatchDate = detailRefundsTotal.find(obj => obj.date === sale.date);
      const rate = parseFloat(((refundMatchDate.Refunds / sale.Sales) * 100).toFixed(1));
      result.push({
        date: sale.date,
        RefundRates: Number.isNaN(rate) ? 0 : rate,
      });
    });

    return result;
  },
  getFilterPostObject: (state) => {
    const postData = {
      courseIds: [],
      // courseIds: [2626, 2634, 2640, 2641],
    };

    if (state.dropdownShowsCourseList) {
      postData.courseIds = state.selectedCourseIds;
    } else {
      postData.courseIds = state.courseList.reduce(
        (accumulator, currentValue) => {
          const a = accumulator;
          if (state.selectedMembershipIds.indexOf(currentValue.membershipId) !== -1) {
            a.push(currentValue.id);
          }
          return a;
        }, [],
      );
    }


    if (state.startDate) {
      postData.startDate = state.startDate;
    }

    if (state.endDate) {
      postData.endDate = state.endDate;
    }

    return postData;
  },
};

const actions = {

  courseList: ({ commit }) => new Promise((resolve, reject) => {
    api.courseList()
      .then((res) => {
        commit(types.SET_DASHBOARD_COURSE_LIST, res);
        resolve(res);
      })
      .catch(err => reject(err));
  }),

  fetchDashboardStats: ({ state, commit }, data) => new Promise((resolve, reject) => {
    if (state.statsFetching) return resolve(state.stats);

    if (!data.courseIds
      || !data.courseIds.length) {
      commit(types.SET_DASHBOARD_STATS, []);
      return resolve([]);
    }

    commit(types.SET_DASHBOARD_STATS_FETCHING, true);

    return api.stats(data)
      .then((res) => {
        if (state.enableCompare) {
          commit(types.SET_DASHBOARD_COMPARE_STATS, res.result);
          if (!state.compareStartDate && !state.compareEndDate) {
            commit(types.SET_FILTER_COMPARE_DATES,
              { startDate: moment(res.minDate), endDate: moment(res.maxDate) });
          }
        } else {
          commit(types.SET_DASHBOARD_STATS, res.result);
          if (!state.startDate && !state.endDate) {
            commit(types.SET_FILTER_DATES,
              { startDate: moment(res.minDate), endDate: moment(res.maxDate) });
          }
        }

        commit(types.SET_DASHBOARD_STATS_FETCHING, false);
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
        commit(types.SET_DASHBOARD_STATS_FETCHING, false);
      });
  }),

  setFilterDates: ({ state, commit }, data) => new Promise((resolve) => {
    if (state.enableCompare) {
      commit(types.SET_FILTER_COMPARE_DATES, data);
    } else {
      commit(types.SET_FILTER_DATES, data);
    }

    resolve(data);
  }),

  setDropdownShowsCourseList: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_DROPDOWN_SHOWS_COURSE_LIST, !!data);
    if (data) {
      commit(types.RESET_SELECTED_MEMBERSHIP_IDS);
    } else {
      commit(types.RESET_SELECTED_COURSE_IDS);
    }
    resolve(data);
  }),

  setSelectedCourseIds: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_SELECTED_COURSE_IDS, data);
    resolve(data);
  }),

  setSelectedMembershipIds: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_SELECTED_MEMBERSHIP_IDS, data);
    resolve(data);
  }),

  setSelectedDropdownIds: ({ commit, state }, data) => new Promise((resolve) => {
    if (state.dropdownShowsCourseList) {
      commit(types.SET_SELECTED_COURSE_IDS, data);
    } else {
      commit(types.SET_SELECTED_MEMBERSHIP_IDS, data);
    }

    resolve(data);
  }),
  setEnableCompare: ({ commit }, data) => new Promise((resolve) => {
    commit(types.SET_DASHBOARD_ENABLE_COMPARE, data);
    resolve(true);
  }),
};


// ====================================================


const mutations = {

  [types.SET_DASHBOARD_STATS_FETCHING](state, data) {
    state.statsFetching = data;
  },

  [types.SET_DASHBOARD_STATS](state, data) {
    state.stats = data;
  },

  [types.SET_SELECTED_COURSE_IDS](state, data) {
    state.selectedCourseIds = data;
  },
  [types.SET_SELECTED_MEMBERSHIP_IDS](state, data) {
    state.selectedMembershipIds = data;
  },
  [types.RESET_SELECTED_COURSE_IDS](state) {
    state.selectedCourseIds = [];
  },
  [types.RESET_SELECTED_MEMBERSHIP_IDS](state) {
    state.selectedMembershipIds = [];
  },
  [types.SET_FILTER_DATES](state, data) {
    state.startDate = data.startDate;
    state.endDate = data.endDate;
  },
  [types.SET_DASHBOARD_COURSE_LIST](state, data) {
    state.courseList = data;
  },
  [types.SET_DROPDOWN_SHOWS_COURSE_LIST](state, data) {
    state.dropdownShowsCourseList = data;
  },
  [types.SET_DASHBOARD_ENABLE_COMPARE](state, data) {
    state.enableCompare = data;
  },
  [types.SET_DASHBOARD_COMPARE_STATS](state, data) {
    state.compareStats = data;
  },
  [types.SET_FILTER_COMPARE_DATES](state, data) {
    state.compareStartDate = data.startDate;
    state.compareEndDate = data.endDate;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
