import * as types from '@/store/mutation-types';
import setting from '@/api/setting';

const state = {
  apiKey: '',
  listDomain: [],
};

const actions = {
  generateNewAPIKey: ({ commit }) => new Promise((resolve) => {
    setting.generateNewAPIKey().then(
      (res) => {
        commit(types.SET_API_KEY, res.key);
        resolve(res);
      },
    );
  }),
  getAPIKey: ({ commit }) => new Promise((resolve) => {
    setting.getAPIKey().then(
      (res) => {
        commit(types.SET_API_KEY, res.openApiKey.key);
        resolve(res);
      },
    );
  }),
  getListDomain: ({ commit }) => new Promise((resolve) => {
    setting.getListDomain().then(
      (res) => {
        commit(types.SET_LIST_DOMAIN, res);
        resolve(res);
      },
    );
  }),
  reCheckDomain: ({ dispatch }, domain) => new Promise((resolve) => {
    setting.reCheckDomain(domain.id).then(
      (res) => {
        dispatch('showAlert', {
          title: 'Re-Check Domain', message: `Re-Check domain ${domain.name} successful!`, type: 'success', timeout: 5000,
        });
        resolve(res);
      },
    );
  }),
};

const getters = {
  apiKey: state => state.apiKey,
  listDomain: state => state.listDomain,
};

const mutations = {
  [types.SET_API_KEY](state, payload) {
    state.apiKey = payload;
  },
  [types.SET_LIST_DOMAIN](state, payload) {
    state.listDomain = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
