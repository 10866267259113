<template>
  <v-container fill-height fluid class="auth">
    <div class="auth__main fill-height">
      <div class="welcome welcome__create-membership">
        <div class="first-section">
          <icon-main-logo class="main-logo"/>
          <p class="label-text">Name your membership</p>
          <v-text-field
            v-model="name"
            v-validate="'required'"
            data-vv-name="name"
            data-vv-as="Membership name"
            :error-messages="errors.collect('name')"
            solo
            placeholder="Enter a name for your membership"
            class="default"
          />
          <p class="info-text">
            <icon-info-x class="main-info-icon" />
            You can always change it later
          </p>
          <v-btn
            :loading="loading"
            class="custom-btn-secondary full-width"
            @click="submit"
          >
            Create
          </v-btn>
          <div class="skip" @click="skip">
            <a>Skip for now</a>
          </div>
        </div>
        <div class="second-section">
          <img src="/img/welcome_onboarding.png" alt="Welcome onboarding image">
          <h1>Welcome to Member</h1>
          <p class="disabled">
            <span class="badge">1</span>
            Create account
          </p>
          <p>
            <span class="badge">2</span>
            Create your first membership
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import validationHelpers from '@/mixins/validationHelpers';

  export default {
    mixins: [validationHelpers],
    data() {
      return {
        name: '',
        loading: false,
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
    },
    watch: {
      whoIam() {
        this.checkMembership();
      },
    },
    methods: {
      ...mapActions([
        'createMembership',
        'setOnboardingStep',
      ]),
      checkMembership() {
        if (this.whoIam.hasMembership) {
          this.$router.push({ name: 'welcome.questions' });
        }
      },
      generateSubDomain(val) {
        return val.toLowerCase().replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
      },
      skip() {
        this.$router.push({ name: 'welcome.questions' });
      },
      submit() {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              this.loading = true;
              this.createMembership({
                domain: {
                  id: -1,
                  subDomain: this.generateSubDomain(`${moment().unix()}-${this.name.trim()}`),
                },
                title: this.name.trim(),
              })
                .then(() => {
                  this.loading = false;
                  this.$router.push({ name: 'welcome.questions' });
                });
            }
          },
        );
      },
    },
  };
</script>
