import Model from './model';

export default class Product extends Model {
  constructor(data = {}) {
    super();

    const props = [
      'id',
      'name',
      'userId',
      'membershipId',

      'isDraft',
      'price',
      'hashId',

      'courseId',
      'courses',

      'productMember',

      'createdAt',
      'updatedAt',
    ];

    props.forEach((prop) => {
      Object.defineProperty(
        this, prop, {
          value: Object.prototype.hasOwnProperty.call(data, prop) ? data[prop] : null,
          writable: true,
          enumerable: true,
          configurable: false,
        },
      );
    });

    if (this.id === 'new') {
      this.price = '';
    }
  }
}
