<template>
  <div class="storage-statistics__largest-file">
    <div class="largest-file">
      <div v-if="dataTable.length > 0" class="primary-text xl semi-bold text-black">
        Large Files
      </div>
      <div v-if="dataTable.length > 0" class="largest-file__table mt-20">
        <table class="my-datatable custom mt-20 last-column-right partnership-table">
          <table-header :headers="headersConfig" @sort="sortData" />
          <tbody>
            <tr
              v-for="(item, index) in dataTable"
              :key="index"
              class="partnership__custom-table__row"
            >
              <template v-for="(col, idx) in headersConfig">
                <td v-if="col.field === 'name'" :key="idx">
                  <div class="flex-start largest-file__table__file-info">
                    <div class="mr-10">
                      <component :is="getFileIcon(item.ext)" />
                    </div>
                    <div :title="item.name" class="primary-text text-dark semi-bold text-ellipsis">
                      {{ item.name }}
                    </div>
                  </div>
                </td>
                <td v-if="col.field === 'membership'" :key="idx">
                  <div class="primary-text text-dark">
                    {{ item.folder.membership.title }}
                  </div>
                </td>
                <td v-if="col.field === 'createdAt'" :key="idx">
                  <div class="primary-text text-dark">
                    {{ moment(item.createdAt).format('DD MMM, YYYY') }}
                  </div>
                </td>
                <td v-if="col.field === 'size'" :key="idx">
                  <div class="primary-text text-dark">
                    {{ convertByte(item.size) }}
                  </div>
                </td>
                <td v-if="col.field === 'action'" :key="idx">
                  <v-menu
                    transition="slide-y-transition"
                    content-class="custom-menu membership__box-menu partnership-table__action"
                    offset-y bottom left>
                    <template v-slot:activator="{ on }">
                      <button
                        class="custom-action-dropdown__btn mr-2"
                        v-on="on"
                      >
                        <icon-more class="icon-more" />
                      </button>
                    </template>
                    <div class="custom-action-dropdown">
                      <button class="dropdown-btn" @click="goToLocation(item.id)">
                        <icon-file class="icon-default" />
                        <span>File Location</span>
                      </button>
                      <button class="dropdown-btn delete" @click="onDelete(item)">
                        <icon-trash class="icon-default" />
                        <span>Delete</span>
                      </button>
                    </div>
                  </v-menu>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="dataTable.length === 0" class="no-data-storage py-3">
        <div class="flex-center">
          <div class="flex-start">
            <div class="mr-3 flex-start">
              <icon-no-data />
            </div>
            <div>
              <div class="primary-text lg bold text-dark">
                No Data
              </div>
              <div class="primary-text text-dark">
                Unfortunately, there is no data to show you
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import _cloneDeep from 'lodash.clonedeep';
  import { mapActions, mapGetters } from 'vuex';
  import TableHeader from '@/components/table/TableHeader';
  import storageHelper from '@/mixins/storage';
  import IconNoData from '@/assets/icons/no-data-storage.svg';

  export default {
    components: {
      TableHeader,
      IconNoData,
    },
    mixins: [storageHelper],
    data() {
      return {
        moment,
        headersConfig: [{
          label: 'name',
          sort: true,
          field: 'name',
          width: '30%',
        }, {
          label: 'campaign',
          sort: true,
          field: 'membership',
          width: '30%',
        }, {
          label: 'date uploaded',
          sort: true,
          field: 'createdAt',
        }, {
          label: 'file size',
          sort: true,
          field: 'size',
        }, {
          label: 'action',
          sort: false,
          field: 'action',
        }],
      };
    },
    computed: {
      ...mapGetters(['membershipList']),
      ...mapGetters('storage', [
        'listLargestFile',
        'membershipIds',
      ]),
      dataTable() {
        let data = this.listLargestFile
          ? _cloneDeep(this.listLargestFile) : [];
        if (this.isManual) {
          data = data.filter(el => !el.isPaid);
        }
        return data && data.length > 0 ? data
          .sort((a, b) => {
            const tmp1 = this.order === 'ASC' ? _cloneDeep(a) : _cloneDeep(b);
            const tmp2 = this.order === 'ASC' ? _cloneDeep(b) : _cloneDeep(a);
            return tmp1[this.prop] - tmp2[this.prop];
          }) : [];
      },
    },
    watch: {
      membershipIds(val) {
        this.getListLargestFile({
          membershipIds: val,
        });
      },
    },
    mounted() {
      this.getListLargestFile({
        membershipIds: this.membershipIds,
      });
    },
    methods: {
      ...mapActions('storage', [
        'getListLargestFile',
      ]),
      goToLocation(fileId) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/media/redirect-file/${fileId}`, '_blank');
      },
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
      onDelete(file) {
        // eslint-disable-next-line
        console.log(file);
        window.open(`${process.env.VUE_APP_API_BASE_URL}/media/redirect-file/${file.id}`, '_blank');
      },
    },
  };
</script>
