import axios from 'axios';

export default {
  login: credentials => new Promise((resolve, reject) => {
    window.http.post('/users/login', credentials).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  logout: fingerprint => new Promise((resolve) => {
    window.http.post('/users/logout', { fingerprint })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  }),
  getInfo: fingerprint => new Promise((resolve, reject) => {
    let url = '/users/me';

    if (fingerprint) {
      url += `?f=${fingerprint}`;
    }

    window.http.get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response));
  }),
  updateUser: (id, data) => new Promise((resolve, reject) => {
    window.http.put(`/users/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updatePassword: (id, data) => new Promise((resolve, reject) => {
    window.http.post(`/users/${id}/updatePassword`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  forgetPassword: data => new Promise((resolve, reject) => {
    window.http.post('/users/forgetPass', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateEmailSettings: (id, data) => new Promise((resolve, reject) => {
    window.http.put(`/users/${id}/updateEmailSettings`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  checkEmailSettings: () => new Promise((resolve, reject) => {
    window.http.get('users/checkEmailSettings')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  listAgencyMembers: parentId => new Promise((resolve, reject) => {
    window.http.get(`users/${parentId}/list-agency-members`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  registration: data => new Promise((resolve, reject) => {
    window.http.post('users/registration', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deleteClient: clientId => new Promise((resolve, reject) => {
    window.http.delete(`users/${clientId}/delete-client`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateClient: (clientId, data) => new Promise((resolve, reject) => {
    window.http.put(`users/${clientId}/update-client`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getClientInfo: userId => new Promise((resolve, reject) => {
    window.http.get(`users/${userId}/client-info`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getStripeAccount: () => new Promise((resolve, reject) => {
    window.http.get('users/stripe-account')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getAgencyProfile: () => new Promise((resolve, reject) => {
    window.http.get('users/agency-profile')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateAgencyProfile: data => new Promise((resolve, reject) => {
    window.http.post('agency/update', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadUserFile: file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    window.http.post('/client/upload-admin-file', formData).then(
      (res) => {
        resolve(res.data);
      },
      (err) => { reject(err.response.data); },
    );
  }),
  removeAvatar: () => new Promise((resolve, reject) => {
    window.http.post('/users/remove-avatar').then(
      (res) => {
        resolve(res.data);
      },
      (err) => { reject(err.response.data); },
    );
  }),
  uploadAvatar: file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    window.http.post('/users/upload-avatar', formData).then(
      (res) => {
        resolve(res.data);
      },
      (err) => { reject(err.response.data); },
    );
  }),
  usernameChangeCheck: data => new Promise((resolve, reject) => {
    window.http.post('/users/username-change-check', data).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  getUserAffiliateAmount: () => new Promise((resolve, reject) => {
    window.http.get('/users/get-affiliate-amount').then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  checkForgotPassToken: token => new Promise((resolve, reject) => {
    window.http.get(`/users/check-forget-token/${token}`).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  createPassword: data => new Promise((resolve, reject) => {
    window.http.post('/users/create-password', data).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  resendConfirmEmail: () => new Promise((resolve, reject) => {
    window.http.post('/users/resend-verify-email').then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  updateOnboardingStep: data => new Promise((resolve, reject) => {
    window.http.post('/users/update-onboarding-step', data).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  submitWelcomeQuestion: data => new Promise((resolve, reject) => {
    window.http.post('/users/welcome-question', data).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  addBonusContent: () => new Promise((resolve, reject) => {
    window.http.post('/users/add-bonus-content').then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  updateLegalContentWithBusinessName: oldName => new Promise((resolve, reject) => {
    window.http.post('/users/update-legal-document', { oldBusinessName: oldName }).then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  getCountryInfoByIp: () => new Promise((resolve, reject) => {
    axios.get('https://ipinfo.io?token=6459d9e5a06616').then(
      (res) => { resolve(res.data); },
      (err) => { reject(err.response.data); },
    );
  }),
  connectPaypal: data => new Promise((resolve, reject) => {
    console.log('api');
    window.http.post('/integration/paypal/save-paypal-app', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
