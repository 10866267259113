export default {

  deauthorize: stripeUserId => new Promise((resolve, reject) => {
    window.http.get(`/stripe/deauthorize?stripeUserId=${stripeUserId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  paymentIntentCreate: data => new Promise((resolve, reject) => {
    window.http.post('/stripe/payment-intent/create', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),

  paymentIntentCode: code => new Promise((resolve, reject) => {
    window.http.get(`/stripe/payment-intent/${code}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),

  fetchInvoices: () => new Promise((resolve, reject) => {
    window.http.get('/stripe/payment-intent/list')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),

  sendInvoice: paymentIntentId => new Promise((resolve, reject) => {
    window.http.get(`/stripe/send-invoice-email/${paymentIntentId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),


};
