<template>
  <div>
    <p class="primary-text">
      To connect MailChimp to Member App add your ‘API Key’
      found within your MailChimp account.
    </p>
    <div class="mt-20">
      <p class="label-text">Your API Key</p>
      <v-text-field v-model="apiKey"
                    solo
                    class="no-error" placeholder="Enter your MailChimp ApiKey" />
      <p v-if="showError.apiKey" class="primary-text sm red-text mt-1">Please input API key</p>
    </div>
    <div class="layout align-center mt-20">
      <p
        v-if="modalComponentProps.integration.active"
        class="flex-text primary-text sm cursor-pointer icon-red"
        @click="disconnect"
      >
        <icon-close class="icon-xs"/>
        Disconnect
      </p>
      <v-spacer />
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        apiKey: '',
        showError: {},
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps', 'whoIam']),
    },
    created() {
      this.apiKey = this.modalComponentProps.integration.api_key;
    },
    methods: {
      ...mapActions([
        'showAlert', 'closeModal', 'updateIntegrations',
      ]),
      async save() {
        if (this.apiKey === '') {
          this.$set(this.showError, 'apiKey', true);
          return;
        }
        const { integrations } = this.whoIam;
        const data = integrations.mailchimp;

        data.active = true;
        data.api_key = this.apiKey;

        integrations.mailchimp = data;

        await this.updateIntegrations({ integrations });
        this.modalComponentProps.onSuccessCallback();
        this.closeModal();
      },
      disconnect() {
        const { integrations } = this.whoIam;
        const data = integrations.mailchimp;

        data.active = false;
        data.api_key = '';

        integrations.mailchimp = data;
        const params = {
          integrations,
          key: 'mailchimp',
          data,
        };
        this.modalComponentProps.onConfirmDialog('Are you sure you want to disconnect MailChimp integration?', params);
        this.closeModal();
      },
    },
  };
</script>
