import * as types from '@/store/mutation-types';
import Affiliate from '@/api/affiliate';
import affiliatePayment from '@/config/affiliatePayment';

const state = {
  paymentSettings: '',
  analyticData: [],
  atSync: false,
};

const getters = {
  affiliateAnalytic: state => state.analyticData,
  affiliateAtSync: state => state.atSync,
  affiliatePaymentSettings: state => state.paymentSettings,
  paypalSetting: (state) => {
    if (!state.paymentSettings) {
      return {};
    }
    return state.paymentSettings.find(s => s.type === affiliatePayment.type.PAYPAL);
  },
  bankSetting: (state) => {
    if (!state.paymentSettings) {
      return {};
    }
    return state.paymentSettings.find(s => s.type === affiliatePayment.type.BANK);
  },
};

const actions = {
  getAffiliateAnalyticData({ commit }, date) {
    commit(types.SET_AFFILIATE_ANALYTIC_FETCHING, true);
    Affiliate.getAffiliateData(date)
      .then(
        (analyticData) => {
          commit(types.SET_AFFILIATE_ANALYTIC_DATA, analyticData);
          commit(types.SET_AFFILIATE_ANALYTIC_FETCHING, false);
        },
      )
      .catch(
        () => {
          commit(types.SET_AFFILIATE_ANALYTIC_FETCHING, false);
        },
      );
  },
  getAffiliatePaymentSetting({ commit, dispatch }) {
    Affiliate.getPaymentSettings()
      .then(
        (data) => {
          commit(types.SET_AFFILIATE_PAYMENT_SETTINGS, data);
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
        },
      );
  },
  addAffiliatePaymentSetting: ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
    Affiliate.addPaymentSetting(data)
      .then(
        (res) => {
          commit(types.ADD_AFFILIATE_PAYMENT_SETTING, res);
          dispatch('showAlert', 'Add new payment setting success');
          resolve(res);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
          reject(error);
        },
      );
  }),
  deleteAffiliatePaymentSetting({ commit, dispatch }, id) {
    Affiliate.deletePaymentSetting(id)
      .then(
        () => {
          commit(types.DELETE_AFFILIATE_PAYMENT_SETTING, id);
          dispatch('showAlert', 'Delete payment setting success');
        },
      )
      .catch(
        () => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
        },
      );
  },
  updateAffiliatePaymentSetting: ({ commit, dispatch }, payload) => new Promise(
    (resolve, reject) => {
      Affiliate.updatePaymentSeting(payload.id, payload.data)
        .then(
          () => {
            commit(types.UPDATE_AFFILIATE_PAYMENT_SETTING, payload);
            dispatch('showAlert', 'Update payment setting success');
            resolve(true);
          },
        )
        .catch(
          (error) => {
            dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
            reject(error);
          },
        );
    },
  ),
  withdrawBalance: ({ dispatch }, data) => new Promise((resolve, reject) => {
    Affiliate.withdrawBalance(data)
      .then(
        () => {
          dispatch('showAlert', 'Withdraw success');
          resolve(true);
        },
      )
      .catch(
        (error) => {
          dispatch('showAlert', { type: 'danger', message: 'Error. Something went wrong.' });
          reject(error);
        },
      );
  }),
};

const mutations = {
  [types.UPDATE_AFFILIATE_PAYMENT_SETTING](state, payload) {
    const index = state.paymentSettings.findIndex(s => s.id === payload.id);
    state.paymentSettings[index].setting = payload.data;
  },
  [types.ADD_AFFILIATE_PAYMENT_SETTING](state, payload) {
    state.paymentSettings.push(payload);
  },
  [types.DELETE_AFFILIATE_PAYMENT_SETTING](state, id) {
    state.paymentSettings = state.paymentSettings.filter(s => s.id !== id);
  },
  [types.SET_AFFILIATE_PAYMENT_SETTINGS](state, payload) {
    state.paymentSettings = payload;
  },
  [types.SET_AFFILIATE_ANALYTIC_DATA](state, payload) {
    state.analyticData = payload;
  },
  [types.SET_AFFILIATE_ANALYTIC_FETCHING](state, payload) {
    state.atSync = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
