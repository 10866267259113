<template>
  <div>
    <p class="primary-text">
      To connect PayPal to Skola add your ‘Client ID’ and ‘Client Secret’
      found within your <a href="https://developer.paypal.com/developer/applications/" target="_blank" class="text-link">PayPal</a> account.
    </p>
    <div class="mt-20">
      <p class="label-text">Your Client ID</p>
      <v-text-field v-model="clientId"
                    solo
                    class="no-error" placeholder="Enter your Pay Pal Client ID" />
      <p v-if="showError.clientId" class="primary-text sm red-text mt-1">Please input Client ID</p>
    </div>
    <div class="mt-20">
      <p class="label-text">Your Client Secret</p>
      <v-text-field v-model="clientSecret"
                    solo
                    class="no-error" placeholder="Enter your Pay Pal Client Secret" />
      <p v-if="showError.clientSecret" class="primary-text sm red-text mt-1">Please input Client Secret</p>
    </div>
    <div class="layout align-center mt-20">
      <v-spacer />
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width" @click="connect">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import _cloneDeep from 'lodash.clonedeep';

  export default {
    data() {
      return {
        paypal: {},
        clientId: '',
        clientSecret: '',
        showError: {},
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
      ...mapGetters([
        'modalComponentProps',
      ]),
      paypalData() {
        return this.whoIam && this.whoIam.integrations.paypal;
      },
    },
    created() {
      this.clientId = this.modalComponentProps.integration.client_id;
      this.clientSecret = this.modalComponentProps.integration.client_secret;
    },
    methods: {
      ...mapActions(['closeModal', 'connectPayPal', 'updateIntegrations']),
      async connect() {
        const integrations = _cloneDeep(this.whoIam.integrations);
        const data = integrations.paypal;

        data.connected = true;
        data.client_id = this.clientId;
        data.client_secret = this.clientSecret;

        integrations.paypal = data;

        const payload = {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
        };
        await this.connectPayPal(payload);
        await this.updateIntegrations({ integrations });
        this.modalComponentProps.onSuccessCallback();
      },
    },
  };
</script>
