<template>
  <client-account-setting v-if="isAgencyDomain" />
  <div v-else class="account-setting__form">
    <div class="account-setting__avatar-section">
      <div v-if="whoIam.avatar" class="account-setting__avatar" :style="avatarBg"/>
      <initial-avatar v-else :name="`${whoIam.firstName} ${whoIam.lastName}`" />
      <p
        class="flex-text primary-text semi-bold mb-15 violet-text cursor-pointer"
        @click="$refs.avatarInput.click()"
      >
        <icon-upload class="mr-2" />
        Upload New Avatar
      </p>
      <input v-show="false" ref="avatarInput" type="file" @change="upload">
      <div class="my-account-level-row">
        <span v-if="isPro" class="badge-notify level">Pro</span>
        <span v-if="userHavePlusPlan" class="badge-notify level">Plus</span>
        <span v-if="isChallengeUser" class="badge-notify level">Challenge</span>
        <span v-if="isAllAccess" class="badge-notify level">All Access</span>
        <span v-if="isAgency" class="badge-notify level">Agency</span>
      </div>
    </div>
    <p class="label-text">First Name</p>
    <v-text-field
      v-model="formData.firstName"
      solo/>
    <p class="label-text">Last Name</p>
    <v-text-field
      v-model="formData.lastName"
      solo/>
    <p class="label-text">Business Name</p>
    <v-text-field
      v-model="formData.businessName"
      solo/>
    <p class="label-text">Email</p>
    <v-text-field
      v-model="formData.email"
      :readonly="true"
      class="hide-detail"
      solo/>
    <div v-if="!whoIam.emailVerified" class="verify-email">
      <div class="primary-text sm dark">
        Please check your email at <span class="verify-email__value text-blue">
          {{ formData.email }}
        </span> to verify the address.
      </div>
      <div class="verify-email__action" @click="resendConfirmMail">
        <icon-recheck class="mr-1 text-blue" />
        <div class="primary-text sm text-blue">Resend Confirmation</div>
      </div>
    </div>
    <p
      v-if="!showPasswordForm"
      class="flex-text primary-text semi-bold violet-btn mb-25"
      @click="showPasswordForm = true"
    >
      <icon-password class="mr-2"/>
      Edit password
    </p>
    <template v-if="showPasswordForm">
      <p class="label-text">Old Password</p>
      <v-text-field
        v-model="formData.oldPassword"
        type="password"
        solo/>
      <p class="label-text">New Password</p>
      <v-text-field
        v-model="formData.newPassword"
        type="password"
        solo/>
    </template>
    <p class="label-text">Timezone</p>
    <custom-select
      v-model="formData.timezone"
      :items="timeZone"
      item-title="text"
      item-value="value"
    />
    <div class="layout mt-30">
      <v-spacer />
      <v-btn class="custom-btn-secondary md" :loading="submitting" @click="save">
        Save Changes
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import linkHelper from '@/mixins/linkHelper';
  import InitialAvatar from '@/components/InitialAvatar';
  import CustomSelect from '@/components/select/CustomSelect';
  import timeZone from '@/config/timeZone';
  import ClientAccountSetting from '@/page/ClientAccountSetting';
  import api from '@/api/user';
  import { FEATURE_CHALLENGE } from '../../config/permissions';

  export default {
    components: {
      ClientAccountSetting,
      InitialAvatar,
      CustomSelect,
    },
    mixins: [linkHelper],
    data() {
      return {
        showPasswordForm: false,
        submitting: false,
        timeZone,
        formData: {
          firstName: '',
          lastName: '',
          email: '',
          timezone: '',
        },
      };
    },
    computed: {
      ...mapGetters([
        'whoIam',
        'isStaff',
        'isRegularUser',
        'userHavePlusPlan',
        'isPro',
        'isPlanNew',
        'planDisplayName',
      ]),
      ...mapGetters('clientAgency', ['isAgencyDomain']),
      isChallengeUser() {
        return this.whoIam.haveFeature(FEATURE_CHALLENGE) || (this.whoIam.roles && this.whoIam.roles.some(r => r.role === 'fe'));
      },
      isAllAccess() {
        return this.whoIam.roles && this.whoIam.roles.some(r => r.role === 'oto1');
      },
      isAgency() {
        return this.whoIam.roles && this.whoIam.roles.some(r => r.role === 'oto2');
      },
      avatarBg() {
        return { 'background-image': `url("${this.imageUrl(this.whoIam.avatar)}")` };
      },
    },
    watch: {
      whoIam() {
        this.init();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(['showAlert', 'uploadAvatar', 'resendConfirmMail']),
      init() {
        this.formData = {
          firstName: this.whoIam ? this.whoIam.firstName : '',
          lastName: this.whoIam ? this.whoIam.lastName : '',
          email: this.whoIam ? this.whoIam.email : '',
          timezone: this.whoIam ? this.whoIam.timezone : '',
          businessName: this.whoIam ? this.whoIam.businessName : '',
        };
      },
      save() {
        this.submitting = true;
        api.updateUser(this.whoIam.id, this.formData).then(
          (res) => {
            this.submitting = false;
            if (res.error) {
              this.showAlert({ type: 'danger', message: res.error });
            } else {
              this.showAlert('Account information is updated.');
              this.$set(this.whoIam, 'email', res.email);
              this.$set(this.whoIam, 'firstName', res.firstName);
              this.$set(this.whoIam, 'lastName', res.lastName);
              this.$set(this.whoIam, 'timezone', res.timezone);
              this.$set(this.whoIam, 'businessName', res.businessName);
              this.showPasswordForm = false;
              this.formData.oldPassword = '';
              this.formData.newPassword = '';
            }
          },
        ).catch(
          () => {
            this.submitting = false;
            this.showAlert({ type: 'danger', message: 'Something went wrong...' });
          },
        );
      },
      upload() {
        this.uploading = true;
        this.uploadAvatar(this.$refs.avatarInput.files[0]).then((res) => {
          this.uploading = false;
          this.$set(this.whoIam, 'avatar', res);
        });
      },
    },
  };
</script>
