import * as types from '@/store/mutation-types';
import StorageApi from '@/api/storage';

const state = {
  availableSpace: 500000000000,
  membershipStorage: [],
  fileTypeStorage: [],
  listLargestFile: [],
  membershipIds: [],
};

const actions = {
  getAvailableSpace: ({ commit, rootGetters }) => {
    const storage = rootGetters.whoIam.totalStorage * (1000 ** 3);
    commit(types.SET_MAX_SPACE, storage);
  },
  getMembershipStorage: ({ commit }) => new Promise((resolve, reject) => {
    StorageApi.getMembershipStorage().then(
      (res) => {
        commit(types.SET_MEMBERSHIP_STORAGE, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  getFileTypeAnalytic: ({ commit }, params) => new Promise((resolve, reject) => {
    StorageApi.getFileTypeAnalytic(params).then(
      (res) => {
        commit(types.SET_FILE_TYPE_STORAGE, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  getListLargestFile: ({ commit }, params) => new Promise((resolve, reject) => {
    StorageApi.getListLargestFile(params).then(
      (res) => {
        commit(types.SET_LIST_LARGEST_FILE, res);
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  setMembershipIds({ commit }, payload) {
    commit(types.SET_MEMBERSHIP_IDS, payload);
  },
};

const getters = {
  availableSpace: state => state.availableSpace,
  membershipStorage: state => state.membershipStorage,
  fileTypeStorage: state => state.fileTypeStorage,
  listLargestFile: state => state.listLargestFile,
  membershipIds: state => state.membershipIds,
};

const mutations = {
  [types.SET_MAX_SPACE](state, payload) {
    state.availableSpace = payload;
  },
  [types.SET_MEMBERSHIP_STORAGE](state, payload) {
    state.membershipStorage = payload;
  },
  [types.SET_FILE_TYPE_STORAGE](state, payload) {
    state.fileTypeStorage = payload;
  },
  [types.SET_LIST_LARGEST_FILE](state, payload) {
    state.listLargestFile = payload;
  },
  [types.SET_MEMBERSHIP_IDS](state, payload) {
    state.membershipIds = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
