<template>
  <div>
    <div class="affiliate__payment-form-wrapper">
      <div class="affiliate__payment-form">
        <div class="payment-form__header">
          <div class="affiliate__add-payment-logo">
            <icon-bank/>
          </div>
          <p class="payment-form__header-title">
            Bank Information (Direct to U.S. Bank)
          </p>
        </div>
        <p class="label-text">First name</p>
        <v-text-field
          v-model="formData.firstName"
          v-validate="'required'"
          :error-messages="errors.collect('firstName')"
          data-vv-name="firstName"
          data-vv-as="First name"
          solo
        />
        <p class="label-text">Last name</p>
        <v-text-field
          v-model="formData.lastName"
          v-validate="'required'"
          :error-messages="errors.collect('lastName')"
          data-vv-name="lastName"
          data-vv-as="Last name"
          solo
        />
        <p class="label-text">Email</p>
        <v-text-field
          v-model="formData.email"
          v-validate="'required|email'"
          :error-messages="errors.collect('Email')"
          data-vv-name="Email"
          solo
        />
        <p class="label-text">Phone</p>
        <v-text-field
          v-model="formData.phone"
          solo
        />
        <p class="label-text">Country</p>
        <custom-select
          v-model="formData.country"
          :items="countryList"
          item-title="title"
          item-value="value"
        />
        <div class="layout">
          <div class="flex xs9">
            <p class="label-text">State</p>
            <custom-select
              v-model="formData.state"
              :items="stateList"
              item-title="title"
              item-value="value"
            />
          </div>
          <div class="flex xs3 ml-20">
            <p class="label-text">Zip code</p>
            <v-text-field
              v-model="formData.zipCode"
              v-validate="'required'"
              :error-messages="errors.collect('zipCode')"
              data-vv-name="zipCode"
              data-vv-as="Paypal zipcode"
              solo
            />
          </div>
        </div>
        <p class="label-text">City</p>
        <custom-select
          v-model="formData.city"
          :items="cityList"
          item-title="title"
          item-value="value"
        />
        <p class="label-text">Street / Building</p>
        <v-text-field
          v-model="formData.detailAddress"
          v-validate="'required'"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          data-vv-as="Street/building"
          solo
        />
        <p class="payment-form__header-title mt-20 mb-20">Bank Information</p>
        <p class="label-text">Bank number</p>
        <v-text-field
          v-model="formData.bankNumber"
          v-validate="'required'"
          :error-messages="errors.collect('bankNumber')"
          data-vv-name="bankNumber"
          data-vv-as="Bank number"
          solo
        />
        <p class="label-text">IBAN</p>
        <v-text-field
          v-model="formData.IBAN"
          solo
        />
        <p class="label-text">Bank account</p>
        <v-text-field
          v-model="formData.bankAccount"
          v-validate="'required'"
          :error-messages="errors.collect('bankAccount')"
          data-vv-name="bankAccount"
          data-vv-as="Bank account"
          solo
        />
        <p class="label-text">Bank name</p>
        <v-text-field
          v-model="formData.bankName"
          v-validate="'required'"
          :error-messages="errors.collect('bankName')"
          data-vv-name="bankName"
          data-vv-as="Bank name"
          solo
        />
        <div class="layout">
          <div class="flex xs9">
            <p class="label-text">Bank address</p>
            <v-text-field
              v-model="formData.bankAddress"
              v-validate="'required'"
              :error-messages="errors.collect('bankAddress')"
              data-vv-name="bankAddress"
              data-vv-as="Bank address"
              solo
            />
          </div>
          <div class="flex xs3 ml-20">
            <p class="label-text">Postal code</p>
            <v-text-field
              v-model="formData.bankPostalCode"
              v-validate="'required'"
              :error-messages="errors.collect('postalCode')"
              data-vv-name="postalCode"
              data-vv-as="Bank postal code"
              solo
            />
          </div>
        </div>
      </div>
    </div>
    <div class="layout payment-form__footer mt-20">
      <v-spacer/>
      <v-btn class="custom-btn-normal sm mr-15" @click="$emit('goBack')">
        Cancel
      </v-btn>
      <v-btn class="custom-btn-secondary sm" :loading="loading" @click="preSubmit">
        Save Changes
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomSelect from '@/components/select/CustomSelect';
  import validationHelpers from '@/mixins/validationHelpers';
  import partnerPaymentMethod from '@/mixins/partnerPaymentMethod';

  export default {
    components: {
      CustomSelect,
    },
    mixins: [validationHelpers, partnerPaymentMethod],
    data() {
      return {
        readMore: false,
        formData: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          country: '',
          state: '',
          zipCode: '',
          city: '',
          detailAddress: '',
          bankNumber: '',
          IBAN: '',
          bankName: '',
          bankAddress: '',
          bankPostalCode: '',
        },
      };
    },
    mounted() {
      if (this.paymentData && Object.keys(this.paymentData).length) {
        this.isEdit = true;
        this.init();
      }
    },
    methods: {
      preSubmit() {
        this.submit(this.type.BANK);
      },
      init() {
        this.formData = {
          firstName: this.paymentData.setting.firstName,
          lastName: this.paymentData.setting.lastName,
          email: this.paymentData.setting.businessAddress,
          phone: this.paymentData.setting.phone,
          country: this.paymentData.setting.country,
          state: this.paymentData.setting.state,
          zipCode: this.paymentData.setting.zipCode,
          city: this.paymentData.setting.city,
          detailAddress: this.paymentData.setting.detailAddress,
          bankNumber: this.paymentData.setting.bankNumber,
          bankName: this.paymentData.setting.bankName,
          bankAccount: this.paymentData.setting.bankAccount,
          bankPostalCode: this.paymentData.setting.bankPostalCode,
          bankAddress: this.paymentData.setting.bankAddress,
          IBAN: this.paymentData.setting.IBAN,
        };
      },
    },
  };
</script>
