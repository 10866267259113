import * as types from '@/store/mutation-types';
import Member from '@/api/member';
import commonHelper from '../../helper/commonHelper';

const state = {
  list: [],
  atSync: false,
  detailAtSync: false,
  member: {},
};

const actions = {
  updateMember: ({ commit, dispatch }, { memberId, data }) => new Promise(
    (resolve, reject) => {
      Member.updateById(memberId, data).then(
        (res) => {
          commit(types.UPDATE_MEMBER_IN_CURRENT_LIST, res);
          resolve(res);
        },
        (error) => {
          dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
          reject(error);
        },
      );
    },
  ),
  updateMemberMultiple: ({ dispatch }, params) => new Promise(
    (resolve, reject) => {
      Member.updateMultiple(params).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          dispatch('showAlert', error.response.data.message);
          reject(error);
        },
      );
    },
  ),
  deleteMember: ({ commit, dispatch }, member) => new Promise((resolve, reject) => {
    Member.delete(member.id).then(
      () => {
        commit(types.DELETE_MEMBER_FROM_LIST, member);
        resolve();
      },
      (error) => {
        dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
        reject(error);
      },
    );
  }),
  deleteMultiMember: ({ commit, dispatch }, memberIds) => new Promise((resolve, reject) => {
    Member.deleteMultiple(memberIds).then(
      (res) => {
        res.members.forEach((member) => {
          commit(types.DELETE_MEMBER_FROM_LIST, member);
        });
        resolve(true);
      },
      (error) => {
        dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
        reject(error);
      },
    );
  }),
  fetchMember: ({ commit, state }, id) => new Promise((resolve, reject) => {
    state.member = {};
    commit(types.SET_MEMBERS_DETAIL_BUSY, true);
    Member.fetchById(id).then(
      (res) => {
        const tmpArr = [];
        const tmp = res;
        res.otherMembers.forEach((data) => {
          tmpArr.push(commonHelper.prepareCurrentPercentage(data, data));
        });
        tmp.otherMembers = tmpArr;
        commit(types.UPDATE_MEMBER_IN_CURRENT_LIST, tmp);
        commit(types.SET_MEMBERS_DETAIL_BUSY, false);
        resolve(res);
      },
      (error) => {
        commit(types.SET_MEMBERS_DETAIL_BUSY, false);
        reject(error);
      },
    );
  }),
  revokeCourseAccess: ({ commit }, params) => new Promise((resolve, reject) => {
    Member.revokeCourseAccess(params).then(
      (res) => {
        commit(types.DELETE_COURSE_IN_CURRENT_MEMBER, params);
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  getMemberList: ({ commit }, filter) => new Promise((resolve, reject) => {
    commit(types.SET_MEMBERS_LIST_BUSY, true);
    Member.getMemberList(filter).then(
      (res) => {
        commit(types.SET_MEMBERS_LIST_BUSY, false);
        commit(types.SET_MEMBERS_LIST, res);
        resolve(res);
      },
      (err) => {
        commit(types.SET_MEMBERS_LIST_BUSY, false);
        reject(err);
      },
    );
  }),
  createMultipleMember: ({ dispatch }, params) => new Promise((resolve, reject) => {
    Member.createMultipleMember(params).then(
      (res) => {
        dispatch('getMemberList', {});
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  createAndUpdateMultipleMember: ({ commit }, params) => new Promise((resolve, reject) => {
    Member.createAndUpdateMultiple(params).then(
      (res) => {
        commit(types.SET_MEMBERS_DETAIL_BUSY, false);
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  }),
  userCourses: state => state.courses,

};

const getters = {
  memberList: state => state.list,
  memberListAtSync: state => state.atSync,
  memberDetail: state => state.member,
  memberDetailAtSync: state => state.detailAtSync,
};

const mutations = {
  [types.SET_MEMBERS_LIST](state, payload) {
    state.list = payload;
  },
  [types.UPDATE_MEMBER_IN_CURRENT_LIST](state, payload) {
    let tmpData = Object.assign({}, payload);
    tmpData.lastAcitivites = state.member.lastActivites ? state.member.lastActivites : null;
    tmpData = commonHelper.prepareCurrentPercentage(payload, tmpData);
    state.member = tmpData;
  },
  [types.PUSH_MEMBER_TO_CURRENT_LIST](state, member) {
    state.list.push(member);
  },
  [types.DELETE_MEMBER_FROM_LIST](state, member) {
    state.list.splice(state.list.indexOf(member), 1);
  },
  [types.SET_MEMBERS_LIST_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.SET_MEMBERS_DETAIL_BUSY](state, payload) {
    state.detailAtSync = payload;
  },
  [types.DELETE_COURSE_IN_CURRENT_MEMBER](state, payload) {
    const i = state.member.productMember.findIndex(item => item.id === payload.courseId);
    state.member.productMember.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
