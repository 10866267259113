<template>
  <client-profile-detail
    v-if="showDetailId"
    :profile-id="showDetailId"
    @back="showDetailId = null"
  />
  <div v-else class="account-setting__form">
    <p class="primary-text lg semi-bold">Agency Logo</p>
    <p class="primary-text mt-1 dark-text">
      You can upload your agencies logo that will replace the member
      app logo in the top left corner of the admin area
    </p>
    <div class="layout align-center mt-3">
      <div
        class="product-wizard__upload"
        @click="$refs.agencyLogo.click()">
        <icon-upload class="icon-default" />
      </div>
      <div class="ml-10">
        <div class="product-wizard__upload-title pointer" @click="$refs.agencyLogo.click()">
          Upload Agency Logo
        </div>
        <div class="product-wizard__upload-des">
          JPEG, PNG / Up to 10MB / 350 x 200
        </div>
      </div>
      <input
        ref="agencyLogo"
        type="file"
        style="display: none"
        accept="image/*"
        @change="onFilePicked"
      >
      <v-spacer />
      <img v-if="agencyLogo" :src="agencyLogo" alt="" class="mr-12 agency-logo__img">
    </div>
    <div class="account-setting__line" />
    <p class="primary-text lg semi-bold">Domain</p>
    <p class="label-text mt-3">Your Agency Name</p>
    <v-text-field
      v-model="agencyName"
      class="default no-error"
      placeholder="Enter agency name"
      solo
      @change="submit"
    />
    <p class="label-text mt-3">Client Portal</p>
    <div
      class="membership-setting__payment-box"
      :class="{ active: !usingCustomDomain }"
      @click="usingCustomDomain = false"
    >
      <div class="layout select-payment align-center">
        <span class="circle">
          <span class="active-circle" />
        </span>
        <p class="primary-text head-admin-color semi-bold ml-15">
          Default Domain
        </p>
      </div>
      <div v-if="!usingCustomDomain" class="mt-20">
        <p class="label-text mt-20">Your domain</p>
        <v-text-field
          v-model="agencyPortalName"
          class="default agency-domain__input no-error"
          :suffix="agencyDomain"
          placeholder="Enter a sub-domain name"
          solo
          @change="submit"
        />
      </div>
    </div>
    <div
      class="membership-setting__payment-box"
      :class="{ active: usingCustomDomain }"
      @click="usingCustomDomain = true"
    >
      <div class="layout select-payment align-center">
        <span class="circle">
          <span class="active-circle" />
        </span>
        <p class="primary-text head-admin-color semi-bold ml-15">
          Custom Domain
        </p>
      </div>
      <div v-if="usingCustomDomain" class="mt-20">
        <p class="label-text mt-20">Your domain</p>
        <div class="layout align-center">
          <v-text-field
            v-model="customDomainName"
            class="default no-error"
            placeholder="Enter a custom domain"
            solo
          />
          <v-btn
            class="custom-btn-primary fixed-width ml-3"
            :loading="loading"
            :disabled="!customDomainName"
            @click="saveCustomDomain"
          >
            Save
          </v-btn>
        </div>
        <div class="layout mt-10">
          <icon-info-x class="icon-default mr-1" />
          <p class="primary-text blue-text">
            In your domain registrar account,
            add record type A for your domain and point it to: {{ dnsIp}}
            <a class="violet-text-icon" @click="copyIP">
              <icon-copy class="icon-default" />
              Copy
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="account-setting__line" />
    <div class="layout justify-space-between align-center">
      <p class="primary-text lg semi-bold">Settings Profiles</p>
      <v-btn class="custom-btn-secondary" @click="openAddProfileForm">
        <icon-plus-x class="icon-list mr-12" />
        Add New Profile
      </v-btn>
    </div>
    <div class="my-datatable__wrapper mt-20">
      <div class="my-datatable__scroller">
        <table
          class="my-datatable last-column-right account-setting__client-profile__table"
        >
          <table-header :headers="headers" />
          <tbody>
            <tr v-for="(profile, index) in clientProfileList" :key="index">
              <td>
                <p class="primary-text">{{ profile.name }}</p>
                <div v-if="profile.client"  class="layout align-center mt-1">
                  <div class="account-setting__client-profile__avatar mr-2"
                       :style="getAvatar(profile.client)">
                    <initial-avatar
                      v-if="!profile.client.avatar"
                      :name="profile.client.name"
                    />
                  </div>
                  <p class="primary-text semi-bold">
                    {{ profile.client.name }}
                  </p>
                </div>
              </td>
              <td>
                <span v-if="profile.client" class="account-setting__client-profile__membership">
                  {{ profile.client.clientMemberships[0].membership.title }}
                </span>
                <span v-else>-</span>
              </td>
              <td>
                <v-menu
                  transition="slide-y-transition"
                  content-class="custom-menu membership__box-menu partnership-table__action"
                  offset-y bottom left>
                  <template v-slot:activator="{ on }">
                    <button
                      class="custom-action-dropdown__btn mr-2"
                      v-on="on"
                    >
                      <icon-more class="icon-more" />
                    </button>
                  </template>
                  <div class="custom-action-dropdown">
                    <button
                      class="dropdown-btn flex-start"
                      @click="openEditProfileForm(profile)"
                    >
                      <icon-edit class="icon-default" />
                      <span>Edit Profile</span>
                    </button>
                    <button
                      class="dropdown-btn flex-start"
                      @click="showDetailProfile(profile.id)"
                    >
                      <icon-arrow-right class="icon-default" />
                      <span>Profile Details</span>
                    </button>
                    <button
                      class="dropdown-btn flex-start"
                      @click="duplicateProfile(profile.id)"
                    >
                      <icon-copy class="icon-default" />
                      <span>Duplicate</span>
                    </button>
                    <button
                      class="dropdown-btn delete"
                      @click="deleteProfile(profile)"
                    >
                      <icon-trash class="icon-default" />
                      <span>Delete Profile</span>
                    </button>
                  </div>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import copy from 'copy-to-clipboard';
  import AddClientProfileForm from './clientProfile/AddClientProfileForm';
  import ClientProfileDetail from './clientProfile/ClientProfileDetail';
  import InitialAvatar from '@/components/InitialAvatar';
  import TableHeader from '@/components/table/TableHeader';
  import userApi from '@/api/user';
  import DomainApi from '@/api/domain';

  export default {
    components: {
      ClientProfileDetail,
      InitialAvatar,
      TableHeader,
    },
    data() {
      return {
        dnsIp: process.env.VUE_APP_SERVER_IP,
        usingCustomDomain: false,
        customDomainName: '',
        showDetailId: null,
        headers: [
          {
            label: 'Profile Name',
            width: '35%',
          },
          {
            label: 'Assign Campaign',
            width: '40%',
          },
          {
            label: 'actions',
            textAlign: 'flex-end',
          },
        ],
        loading: false,
        agencyPortalName: '',
        agencyName: '',
        agencyLogo: null,
        agencyId: null,
        agencyDomain: process.env.VUE_APP_AGENCY_DOMAIN,
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
      ...mapGetters('agency', ['clientProfileList']),
      agencyProfile() {
        if (this.whoIam) {
          return this.whoIam.agencyProfile;
        }
        return {};
      },
    },
    watch: {
      whoIam() {
        this.init();
      },
    },
    created() {
      if (this.$route.query.profileId) {
        this.showDetailId = this.$route.query.profileId;
      }
    },
    mounted() {
      this.syncClientList();
      this.init();
      this.getClientProfiles();
    },
    methods: {
      ...mapActions(['updateAgencyProfile', 'showAlert', 'openCustomModal']),
      ...mapActions('agency', ['syncClientList', 'getClientProfiles', 'duplicateClientProfile', 'deleteClientProfile']),
      async duplicateProfile(profileId) {
        await this.duplicateClientProfile(profileId);
      },
      async deleteProfile(profile) {
        await this.deleteClientProfile(profile);
      },
      copyIP() {
        copy(this.dnsIp);
        this.showAlert('IP copied to your clipboard');
      },
      openAddProfileForm() {
        this.openCustomModal({
          component: AddClientProfileForm,
          title: 'Name your Settings Profile',
          maxWidth: '430px',
          width: '430px',
          modalClass: 'account-setting__integration-modal',
        });
      },
      openEditProfileForm(clientProfile) {
        this.openCustomModal({
          component: AddClientProfileForm,
          title: 'Edit Client Profile',
          maxWidth: '430px',
          width: '430px',
          modalClass: 'account-setting__integration-modal',
          data: {
            clientProfile,
            isEdit: true,
          },
        });
      },
      async saveCustomDomain() {
        if (this.agencyProfile.customDomain
          && this.customDomainName === this.agencyProfile.customDomain.name) {
          return;
        }
        this.loading = true;
        DomainApi.create({
          name: this.customDomainName,
          custom: true,
          isAgencyDomain: true,
        }).then((res) => {
          this.updateAgencyProfile({
            domainId: res.id,
            agencyId: this.agencyId,
          }).then(() => {
            this.loading = false;
          });
        })
          .catch(() => {
            this.loading = false;
          });
      },
      async submit() {
        this.loading = true;
        if (this.$refs.agencyLogo.files[0]) {
          const res = await userApi.uploadUserFile(this.$refs.agencyLogo.files[0]);
          if (res.path) {
            this.agencyLogo = res.path;
          }
        }
        this.updateAgencyProfile({
          domain: this.agencyPortalName,
          logo: this.agencyLogo,
          agencyId: this.agencyId,
          name: this.agencyName,
        }).then((res) => {
          this.$set(this.whoIam, 'agencyProfile', {
            domain: this.agencyPortalName,
            logo: this.agencyLogo,
            id: res.id,
            name: this.agencyName,
          });
          this.loading = false;
        }).catch((e) => {
          this.showAlert({ message: e || 'Something went wrong', type: 'danger' });
          this.loading = false;
        });
      },
      init() {
        if (this.whoIam.agencyProfile) {
          this.agencyPortalName = this.whoIam.agencyProfile.domain;
          this.agencyLogo = this.whoIam.agencyProfile.logo;
          this.agencyId = this.whoIam.agencyProfile.id;
          this.agencyName = this.whoIam.agencyProfile.name;
          if (this.agencyProfile && this.agencyProfile.customDomain) {
            this.usingCustomDomain = true;
            this.customDomainName = this.agencyProfile.customDomain.name;
          }
        }
      },
      showDetailProfile(profileId) {
        this.showDetailId = profileId;
      },
      onFilePicked() {
        if (!this.$refs.agencyLogo.files[0]) {
          return;
        }

        const file = this.$refs.agencyLogo.files[0];

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.agencyLogo = event.target.result;
            this.submit();
          };
          reader.readAsDataURL(file);
        }
      },
      getAvatar(client) {
        return client.avatar ? {
          'background-image': `url("${client.avatar}")`,
        } : {};
      },
    },
  };
</script>
