const currencies = {
  1: {
    title: 'usd',
    symbol: '$',
  },
  2: {
    title: 'rub',
    symbol: '₽',
  },
  3: {
    title: 'eur',
    symbol: '€',
  },
  4: {
    title: 'jpy',
    symbol: '¥',
  },
  5: {
    title: 'gbp',
    symbol: '£',
  },
  6: {
    title: 'aud',
    symbol: 'A$',
  },
  7: {
    title: 'cad',
    symbol: 'C$',
  },
  8: {
    title: 'chf',
    symbol: 'Fr',
  },
  9: {
    title: 'nzd',
    symbol: '$',
  },
  10: {
    title: 'dkk',
    symbol: 'kr',
  },
  11: {
    title: 'inr',
    symbol: '₹',
  },
};

export default currencies;
