<template>
  <div class="client-detail__container">
    <div>
      <p class="primary-text lg semi-bold">Contact</p>
      <div class="layout mt-20">
        <div class="flex xs6">
          <p class="label-text">Client Name</p>
          <v-text-field
            v-model="formData.name"
            v-validate.disable="'required'"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            data-vv-as="Fullname"
            class="default"
            solo
          />
        </div>
        <div class="flex xs6 ml-15">
          <p class="label-text">Email</p>
          <v-text-field
            v-model="formData.email"
            v-validate.disable="'required'"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            data-vv-as="Fullname"
            class="default"
            solo
          />
        </div>
      </div>
      <p
        v-if="!showPasswordForm"
        class="flex-text primary-text semi-bold violet-btn mb-25"
        @click="showPasswordForm = true"
      >
        <icon-password class="mr-2"/>
        Change Password
      </p>
      <div v-if="showPasswordForm">
        <p class="label-text">Password</p>
        <v-text-field
          v-model="newPassword"
          v-validate.disable="'required'"
          :error-messages="errors.collect('password')"
          data-vv-name="password"
          data-vv-as="Password"
          type="password"
          class="default"
          solo
        />
      </div>
    </div>
    <template v-if="clientDetail.paymentType === 'internal'">
      <div class="client-detail__line" />
      <div v-if="reRenderPayment">
        <p class="primary-text lg semi-bold">Payment</p>
        <div class="layout mt-20">
          <div class="flex xs6">
            <p class="label-text">Subscription Level</p>
            <custom-select
              v-model="formData.paymentSubscriptionLevel"
              :items="subscriptionLevels"
              item-value="id"
              item-title="title"
            />
          </div>
          <div class="flex xs6 ml-15">
            <p class="label-text">Amount</p>
            <div class="layout custom-input input-block">
              <div
                v-show="formData.paymentAmount > 0"
                class="flex flex-select">
                <custom-select
                  v-model="formData.paymentCurrency"
                  custom-class="half-select currency-select blue-gray-x-bg"
                  :items="currencies"
                  item-title="title"
                  item-value="id"
                  outline
                />
              </div>
              <div
                class="flex flex-input"
                :class="{ 'half' : formData.paymentAmount > 0 }">
                <v-text-field
                  v-model="formData.paymentAmount"
                  :class="{ 'half-text': formData.paymentAmount > 0 }"
                  :min="formData.price === '' ? null : 0"
                  type="number"
                  class="default"
                  solo
                  placeholder="Amount"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="label-text">Payment due date</p>
          <date-picker v-model="formData.paymentDueDate" :top="true" />
        </div>
      </div>
    </template>
    <div class="layout justify-end mt-30">
      <v-btn class="custom-btn-normal fixed-width" @click="saveAndExit">Save & Exit</v-btn>
      <v-btn class="ml-15 custom-btn-secondary fixed-width" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import copy from 'copy-to-clipboard';
  import constant from '@/config/constant';
  import CustomSelect from '@/components/select/CustomSelect';
  import DatePicker from '@/components/select/DatePicker';
  import roleHelpers from '@/mixins/roleHelpers';

  export default {
    components: {
      DatePicker,
      CustomSelect,
    },
    mixins: [roleHelpers],
    data() {
      return {
        reRenderPayment: true,
        membershipId: '',
        newPassword: '',
        showPasswordForm: false,
        paymentTypes: constant.clientPaymentTypes,
        subscriptionLevels: constant.clientSubscriptionLevels,
        currencies: constant.currencies,
        formData: {
          id: '',
          name: '',
          email: '',
          paymentType: '',
          paymentSubscriptionLevel: '',
          paymentAmount: '',
          paymentCurrency: '',
          paymentDueDate: '',
        },
      };
    },
    computed: {
      ...mapGetters(['membershipList', 'whoIam']),
      ...mapGetters('agency', ['clientDetail']),
      canShowRegisterLink() {
        return (this.formData.paymentType === 'free' && this.clientDetail.status === 'pending')
          || (this.formData.paymentType === 'external' && this.clientDetail.status === 'paid');
      },
      canShowIpn() {
        return this.formData.paymentType === 'external' && this.clientDetail.status === 'pending';
      },
      ipnUrl() {
        if (!this.clientDetail) {
          return '';
        }
        return `${process.env.VUE_APP_API_BASE_URL}/ipn-client/${this.clientDetail.hashId}`;
      },
      registerLink() {
        if (!this.whoIam || !this.whoIam.agencyProfile) {
          return '';
        }
        const json = JSON.stringify({
          name: this.clientDetail.name,
          id: this.clientDetail.id,
          email: this.clientDetail.email,
        });
        const code = btoa(json);
        return `${this.whoIam.agencyProfile.domain}.${process.env.VUE_APP_AGENCY_DOMAIN}/client/invite/${code}`;
      },
    },
    watch: {
      clientDetail() {
        this.initData();
      },
    },
    created() {
      this.initData();
    },
    methods: {
      ...mapActions('agency', ['updateClient']),
      ...mapActions(['showAlert']),
      reRender() {
        this.reRenderPayment = false;
        this.$nextTick(() => {
          this.reRenderPayment = true;
        });
      },
      initData() {
        if (!this.clientDetail.id) {
          return;
        }
        this.formData.id = this.clientDetail.id;
        this.formData.permissions = this.clientDetail.clientPermissions.map(c => c.permission);
        this.formData.name = this.clientDetail.name;
        this.formData.email = this.clientDetail.email;
        this.formData.paymentType = this.clientDetail.paymentType;
        this.formData.paymentDueDate = this.clientDetail.paymentDueDate;
        this.formData.paymentAmount = this.clientDetail.paymentAmount;
        this.formData.paymentCurrency = this.clientDetail.paymentCurrency;
        this.formData.paymentSubscriptionLevel = this.clientDetail.paymentSubscriptionLevel;
      },
      async save() {
        if (this.newPassword) {
          this.formData.password = this.newPassword;
        }
        await this.updateClient(this.formData);
        this.showPasswordForm = false;
        this.showAlert('Client setting has been saved');
      },
      saveAndExit() {
        this.save();
        this.$router.push({ name: 'dashboard.client-management' });
      },
      copyRegisterLink() {
        copy(this.registerLink);
        this.showAlert('Invite link copied to your clipboard');
      },
      copyIpnUrl() {
        copy(this.ipnUrl);
        this.showAlert('IPN Url copied to your clipboard');
      },
    },
  };
</script>
