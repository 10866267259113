export default {
  registerAffiliateUser: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-user/register', { data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getPromoteProduct: hash => new Promise((resolve, reject) => {
    window.http.get(`/affiliate-user/product-promote/${hash}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  applyPromoteProduct: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-user/apply-promote', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createPasswordAffiliateUser: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-user/create-password', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getProductPromotes: () => new Promise((resolve, reject) => {
    window.http.get('/affiliate-user/products')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getCommissions: () => new Promise((resolve, reject) => {
    window.http.get('/affiliate-user/commissions')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getPaymentMethods: () => new Promise((resolve, reject) => {
    window.http.get('/affiliate-user/payment-methods')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  addPaymentMethod: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-user/payment-methods', { data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updatePaymentMethod: (id, data, isDefault = false) => new Promise((resolve, reject) => {
    window.http.put(`/affiliate-user/payment-methods/${id}`, { setting: data, isDefault })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  deletePaymentMethod: id => new Promise((resolve, reject) => {
    window.http.delete(`/affiliate-user/payment-methods/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateProfile: data => new Promise((resolve, reject) => {
    window.http.post('/affiliate-user/update-profile/', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadProfileAvatar: file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    window.http.post('/affiliate-user/upload-avatar-profile', formData).then(
      (res) => {
        resolve(res.data);
      },
      (err) => { reject(err.response.data); },
    );
  }),
  getDetailAnalytic: data => new Promise((resolve, reject) => {
    window.http.get('/affiliate-user/analytic', { params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updatePromoteProduct: data => new Promise((resolve, reject) => {
    window.http.put(`/affiliate-user/product/${data.id}`, { data: data.params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
