import * as types from '@/store/mutation-types';
import LecturerApi from '@/api/lecturer';
import CourseApi from '@/api/course';

const state = {
  list: [],
  atSync: false,
  detailAtSync: false,
  lecturer: {},
};

const actions = {
  updateAvatar: ({ commit, dispatch }, { data, lecturer }) => new Promise((resolve, reject) => {
    LecturerApi.uploadAvatar(data).then(
      (res) => {
        dispatch('showAlert', 'Avatar is upload!');
        if (lecturer.id) {
          const tmp = lecturer;
          tmp.avatar = res;
          commit(types.UPDATE_LECTURER_IN_CURRENT_LIST, tmp);
        }
        resolve(res);
      },
      (error) => {
        reject(error);
      },
    );
  }),
  updateLecturer: ({ commit, dispatch }, { id, data }) => new Promise(
    (resolve, reject) => {
      LecturerApi.updateById(id, data).then(
        (res) => {
          commit(types.UPDATE_LECTURER_IN_CURRENT_LIST, res);
          resolve(res);
        },
        (error) => {
          dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
          reject(error);
        },
      );
    },
  ),
  addLecturer: ({ dispatch, commit }, { data }) => new Promise((resolve, reject) => {
    LecturerApi.create(data).then(
      (response) => {
        if (response.name === 'Error') {
          reject(response); // Bcs in api errors are also returned as 200
        }
        resolve(response);
        commit(types.PUSH_LECTURER_TO_CURRENT_LIST, response.data);
      },
      (error) => {
        dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
        reject(error);
      },
    );
  }),
  deleteLecturerProfile: ({ commit, dispatch }, lecturer) => new Promise((resolve, reject) => {
    LecturerApi.deleteLecturer(lecturer.id).then(
      () => {
        commit(types.DELETE_LECTURER_FROM_CURRENT_LIST, lecturer);
        resolve();
      },
      (error) => {
        dispatch('showAlert', { message: 'Sorry something went wrong', type: 'danger' });
        reject(error);
      },
    );
  }),
  fetchLecturer: ({ commit, state }, id) => new Promise((resolve) => {
    const idx = state.list.findIndex(lecturer => id === lecturer.id);
    commit(types.UPDATE_LECTURER_IN_CURRENT_LIST, state.list[idx]);
    resolve(true);
  }),
  lecturerCreateNewPassword: ({ dispatch }, data) => new Promise((resolve, reject) => {
    LecturerApi.createPassword(data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        dispatch('showAlert', { message: 'Error. Something went wrong.', type: 'danger' });
        reject(error);
      },
    );
  }),
  getLecturerList: ({ commit }) => new Promise((resolve, reject) => {
    commit(types.SET_LECTURER_LIST_BUSY, true);
    LecturerApi.getLecturerList().then(
      (res) => {
        commit(types.SET_LECTURER_LIST_BUSY, false);
        commit(types.SET_LECTURER_LIST, res);
        resolve(res);
      },
      (err) => {
        commit(types.SET_LECTURER_LIST_BUSY, false);
        reject(err);
      },
    );
  }),
  removeLecturerFromCourse: (
    { commit },
    { lecturer, courseId, membershipId },
  ) => new Promise((resolve, reject) => {
    commit(types.SET_LECTURER_LIST_BUSY, true);
    CourseApi.update(courseId, { lecturerId: -1 }).then(
      () => {
        const tmp = lecturer;
        const idx = tmp.membershipGroups.findIndex(m => m.id === membershipId);
        const courseIdx = tmp.membershipGroups[idx].courseDetails.findIndex(c => c.id === courseId);
        tmp.membershipGroups[idx].courseDetails.splice(courseIdx, 1);
        commit(types.UPDATE_LECTURER_IN_CURRENT_LIST, tmp);
        commit(types.SET_LECTURER_LIST_BUSY, false);
        resolve(true);
      },
      (err) => {
        commit(types.SET_LECTURER_LIST_BUSY, false);
        reject(err);
      },
    );
  }),
};

const getters = {
  lecturerList: state => state.list,
  lecturerListAtSync: state => state.atSync,
  lecturerDetail: state => state.lecturer,
  lecturerDetailAtSync: state => state.detailAtSync,
};

const mutations = {
  [types.SET_LECTURER_LIST](state, payload) {
    state.list = payload;
  },
  [types.UPDATE_LECTURER_IN_CURRENT_LIST](state, payload) {
    state.lecturer = payload;
    const idx = state.list.findIndex(lecturer => payload.id === lecturer.id);
    Object.assign(state.list[idx], payload);
  },
  [types.PUSH_LECTURER_TO_CURRENT_LIST](state, lecturer) {
    state.list.push(lecturer);
  },
  [types.DELETE_LECTURER_FROM_CURRENT_LIST](state, lecturer) {
    state.list.splice(state.list.indexOf(lecturer), 1);
  },
  [types.SET_LECTURER_LIST_BUSY](state, payload) {
    state.atSync = payload;
  },
  [types.SET_LECTURER_DETAIL_BUSY](state, payload) {
    state.detailAtSync = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
