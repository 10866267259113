const restrictRange = {
  dayMonth: [
    {
      title: 'This week',
      value: 'This week',
    },
    {
      title: 'Last week',
      value: 'Last week',
    },
    {
      title: 'This month',
      value: 'This month',
    },
    {
      title: 'Last month',
      value: 'Last month',
    },
  ],
  month: [
    {
      title: 'This month',
      value: 'This month',
    },
    {
      title: 'Last month',
      value: 'Last month',
    },
  ],
  last3month: [
    {
      title: 'Last 3 months',
      value: 'Last 3 months',
    },
    {
      title: 'Last 6 months',
      value: 'Last 6 months',
    },
    {
      title: 'This year',
      value: 'This year',
    },
    {
      title: 'Custom',
      value: 'Custom',
    },
  ],
  last6month: [
    {
      title: 'Last 6 months',
      value: 'Last 6 months',
    },
    {
      title: 'This year',
      value: 'This year',
    },
    {
      title: 'Custom',
      value: 'Custom',
    },
  ],
  year: [
    {
      title: 'This year',
      value: 'This year',
    },
    {
      title: 'Custom',
      value: 'Custom',
    },
  ],
  allTime: [
    {
      title: 'All time',
      value: 'All time',
    },
  ],
};

export default restrictRange;
