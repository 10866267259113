export default {
  getAgencyInfoBySubdomain: subDomain => new Promise((resolve, reject) => {
    window.http.get(`agency-by-domain?domain=${subDomain}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  uploadClientFile: file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    window.http.post('/client/upload-client-file', formData).then(
      (res) => {
        resolve(res.data);
      },
      (err) => { reject(err.response.data); },
    );
  }),
  registerClient: formData => new Promise((resolve, reject) => {
    window.http.post('client/register', formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  loginClient: formData => new Promise((resolve, reject) => {
    window.http.post('client/login', formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  previewLoginJwt: membershipId => new Promise((resolve, reject) => {
    window.http.get(`courses/${membershipId}/preview-login-client`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  updateSelf: formData => new Promise((resolve, reject) => {
    window.http.put('client/edit-profile', formData)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  resetPassword: data => new Promise((resolve, reject) => {
    window.http.post('/client/forget-password', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  checkForgotPassToken: token => new Promise((resolve, reject) => {
    window.http.get(`/client/check-forget-token/${token}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  createNewPassword: data => new Promise((resolve, reject) => {
    window.http.post('/client/create-password', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getOwnInvoices: () => new Promise((resolve, reject) => {
    window.http.get('client/invoice/get-mine')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getInvoiceDataByUuid: uuid => new Promise((resolve, reject) => {
    window.http.get(`/client/invoice/${uuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  chargeInvoice: data => new Promise((resolve, reject) => {
    window.http.post('/client-checkout/charge', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  subscribeInvoice: data => new Promise((resolve, reject) => {
    window.http.post('/client-checkout/subscribe', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  checkInvoiceStatus: invoiceId => new Promise((resolve, reject) => {
    window.http.get(`/client-checkout/check-invoice?clientInvoiceId=${invoiceId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
