<template>
  <div class="partnership-student__content__table border-card">
    <div class="flex-between">
      <div class="flex-start">
        <switch-button v-model="type" :items="options" @change="getDatatable" />
      </div>
      <div>
        <date-range-picker
          v-model="dateRangeTable"
          :customClass="'range'"
          :changeDateOption="dateOptionTable"
          :right="true"
          @change="onDateDatatableChanged"
        />
      </div>
    </div>
    <div>
      <div v-if="type === 'commissions'">
        <commission-table />
      </div>
      <div v-if="type === 'transactions'">
        <transaction-table />
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import _debounce from 'lodash.debounce';
  import { mapActions } from 'vuex';
  import CommissionTable from './Commission';
  import TransactionTable from './Transaction';
  import DateRangePicker from '@/components/select/DateRangePicker';
  import SwitchButton from '@/components/shared/SwitchButton';


  export default {
    components: {
      DateRangePicker,
      SwitchButton,
      CommissionTable,
      TransactionTable,
    },
    data() {
      return {
        type: 'commissions',
        options: [{
          id: 'commissions',
          label: 'Commissions',
        }, {
          id: 'transactions',
          label: 'Transactions',
        }],
        dateOptionTable: 'Year to date',
        dateRangeTable: {
          start: '',
          end: '',
        },
        debounce_filter_table: _debounce(() => {
          this.getDatatable();
        }, 500),
      };
    },
    methods: {
      ...mapActions('partnership', [
        'getPartnerContractCommissionById',
        'getPartnerContractTransactionById',
      ]),
      onDateDatatableChanged(date) {
        this.dateOptionTable = date.option;
        this.debounce_filter_table();
      },
      getDatatable() {
        const params = {
          start: moment(this.dateRangeTable.start).format('YYYY-MM-DD'),
          end: moment(this.dateRangeTable.end).format('YYYY-MM-DD'),
          contractId: this.$route.params.id,
        };
        if (this.type === 'commissions') {
          this.getPartnerContractCommissionById(params);
        } else {
          this.getPartnerContractTransactionById(params);
        }
      },
    },
  };
</script>
