<template>
  <div>
    <p class="primary-text">
      Click ‘Connect’ to connect your AWeber account to Member App.
    </p>
    <div class="layout align-center mt-20">
      <p
        v-if="modalComponentProps.integration.active"
        class="flex-text primary-text sm cursor-pointer icon-red"
        @click="disconnect"
      >
        <icon-close class="icon-xs"/>
        Disconnect
      </p>
      <v-spacer />
      <v-btn class="custom-btn-normal fixed-width mr-3" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary fixed-width" @click="connect">Connect</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import integrationApi from '@/api/integration';

  export default {
    computed: {
      ...mapGetters(['modalComponentProps', 'whoIam']),
    },
    methods: {
      ...mapActions([
        'showAlert', 'closeModal',
      ]),
      connect() {
        const tmp = this.whoIam;
        if (this.modalComponentProps.clientProfile) {
          tmp.clientProfile = this.modalComponentProps.clientProfile;
        }
        integrationApi.getOauthUrl('aweber', tmp)
          .then((response) => {
            window.location.href = response;
          })
          .catch(() => {
            this.showAlert({ message: 'System error, please try again later', type: 'danger' });
          });
      },
      disconnect() {
        const { integrations } = this.whoIam;
        const data = integrations.aweber;

        data.active = false;

        integrations.aweber = data;
        const params = {
          integrations,
          key: 'aweber',
          data,
        };
        this.modalComponentProps.onConfirmDialog('Are you sure you want to disconnect Aweber integration?', params);
        this.closeModal();
      },
    },
  };
</script>
