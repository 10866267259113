export default {
  methods: {
    getFileIcon(fileType) {
      const supportedFile = [
        'pdf', 'docx', 'doc',
        'txt', 'png', 'pptx',
        'ppt',
        'xls', 'csv', 'xlsx',
        'jpg', 'jpeg', 'tif',
        'mp4', 'mov', 'odt',
        'ods', 'gif',
      ];
      if (supportedFile.includes(fileType)) {
        return `icon-file-${fileType}`;
      }
      return 'icon-file-default';
    },
    convertByte(bytes, decimals = -1) {
      if (bytes === 0) return '0 Bytes';

      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
};
