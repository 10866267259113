<template>
  <div v-if="partnershipContract.partnership"  class="partnership-detail card">
    <a
      class="membership-setting__member-back
          primary-text head-admin-color flex-text cursor-pointer"
      @click="$emit('back')"
    >
      <fa-icon :icon="['fal', 'chevron-left']" class="mr-2" />
      Back to Partnerships
    </a>

    <div class="flex-between mt-20">
      <div class="flex-start">
        <div class="member-avatar lg mr-10" :style="getAvatar(partnershipContract.partnership)">
          <initial-avatar
            v-if="!partnershipContract.partnership.avatar"
            :name="partnershipContract.partnership.name"
          />
        </div>
        <div class="membership-setting__member-detail-info">
          <p class="primary-text semi-bold lg head-admin-color">
            {{ partnershipContract.partnership.name }}
          </p>
          <p class="primary-text head-admin-color">{{ partnershipContract.partnership.email }}</p>
          <p class="primary-text dark">
            Contract Since: {{ moment(partnershipContract.createdAt).format('DD MMM, YYYY') }}
          </p>
        </div>
      </div>
      <div class="flex-between">
        <div class="mr-20">
          <active-status :client="partnershipContract" />
        </div>
        <v-btn class="custom-btn custom-btn-secondary mr-20" @click="onEdit">
          Edit Contract
        </v-btn>
        <v-menu
          transition="slide-y-transition"
          content-class="custom-menu membership__box-menu partnership-table__action"
          offset-y bottom left>
          <template v-slot:activator="{ on }">
            <button
              class="custom-action-dropdown__btn mr-2"
              v-on="on"
            >
              <icon-more class="icon-more" />
            </button>
          </template>
          <div class="custom-action-dropdown">
            <button class="dropdown-btn delete" @click="onDelete">
              <icon-trash class="icon-default" />
              <span>Delete</span>
            </button>
          </div>
        </v-menu>
      </div>
    </div>

    <div class="mt-30">
      <div class="primary-text semi-bold lg head-admin-color">
        Contract Terms
      </div>
      <div class="mt-20 flex-start">
        <div class="flex-start mr-30">
          <div class="primary-text">Payment Terms:</div>
          <div class="primary-text semi-bold ml-10">
            {{ paymentTerms[partnershipContract.paymentTerm] }}
          </div>
        </div>
        <div class="flex-start">
          <div class="primary-text">Amount:</div>
          <div class="primary-text semi-bold ml-10">{{ partnershipContract.amount }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import { mapGetters, mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import linkHelper from '@/mixins/linkHelper';
  import ActiveStatus from '@/page/client/ActiveStatus';
  import EditPartnershipContract from './components/EditPartnershipContact';

  export default {
    components: {
      ActiveStatus,
      InitialAvatar,
    },
    mixins: [linkHelper],
    props: {
      partnerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        paymentTerms: {
          percentage_of_sale: 'Percentage of sale',
        },
        moment,
      };
    },
    computed: {
      ...mapGetters('partnership', ['partnershipContract']),
    },
    mounted() {
      this.getPartnershipContract(this.partnerId);
    },
    methods: {
      ...mapActions(['openCustomModal']),
      ...mapActions('partnership', ['getPartnershipContract', 'deletePartnershipContract']),
      getAvatar(member) {
        return member.avatar ? {
          'background-image': `url("${this.imageUrl(member.avatar)}")`,
        } : {};
      },
      onDelete() {
        this.deletePartnershipContract(this.partnershipContract.id);
      },
      onEdit() {
        this.openCustomModal({
          title: 'Edit Partnership Contract',
          component: EditPartnershipContract,
          width: '600px',
          modalClass: ['partnership-modal'],
          data: {
            contract: this.partnershipContract,
          },
        });
      },
    },
  };
</script>
