import Vue from 'vue';
import Vuex from 'vuex';

import affiliate from '@/store/modules/affiliate';
import analytics from '@/store/modules/analytics';
import courses from '@/store/modules/courses';
import countries from '@/store/modules/countries';
import domains from '@/store/modules/domains';
import members from '@/store/modules/members';
import emailSenders from '@/store/modules/emailSenders';
import users from '@/store/modules/users';
import ui from '@/store/modules/ui';
import integrations from '@/store/modules/integrations';
import srtipe from '@/store/modules/stripe';
import membership from '@/store/modules/membership';
import wizard from '@/store/modules/wizard';
import dashboard from '@/store/modules/dashboard';
import videos from '@/store/modules/videos';
import videoAnalytics from '@/store/modules/videoAnalytics';
import quiz from '@/store/modules/quiz';
import quizResults from '@/store/modules/quizResults';
import lecturer from '@/store/modules/lecturer';
import legalDocument from '@/store/modules/legalDocument';
import coupons from '@/store/modules/coupons';
import productWizard from '@/store/modules/productWizard';
import affiliateUser from '@/store/modules/affiliateUser';
import affiliateManagement from '@/store/modules/affiliateManagement';
import lesson from '@/store/modules/lesson';
import settings from '@/store/modules/settings';
import agency from '@/store/modules/agency';
import clientAgency from '@/store/modules/clientAgency';
import partnership from '@/store/modules/partnership';
import storage from '@/store/modules/storage';
import teamMember from '@/store/modules/teamMember';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    affiliate,
    affiliateUser,
    affiliateManagement,
    analytics,
    courses,
    countries,
    domains,
    members,
    emailSenders,
    users,
    ui,
    integrations,
    srtipe,
    membership,
    wizard,
    dashboard,
    videos,
    videoAnalytics,
    quiz,
    quizResults,
    lecturer,
    legalDocument,
    coupons,
    productWizard,
    lesson,
    settings,
    agency,
    clientAgency,
    partnership,
    storage,
    teamMember,
  },
});
