<template>
  <span v-if="client.status === 'active'" class="badge badge-success">
    Active
  </span>
  <span v-else-if="client.status === 'pending'" class="badge badge-warning">
    Pending
  </span>
  <span v-else-if="client.status === 'pause'" class="badge badge-warning">
    Pause
  </span>
  <span v-else-if="client.status === 'paid'" class="badge badge-info">
    Paid
  </span>
  <span v-else-if="client.status === 'decline'" class="badge badge-error">
    Decline
  </span>
</template>

<script>
  export default {
    props: ['client'],
  };
</script>
