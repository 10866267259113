export default {

  own: () => new Promise((resolve, reject) => {
    window.http.get('/membership/own').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  getTeamMemberMembership: () => new Promise((resolve, reject) => {
    window.http.get('/team-member/memberships').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  ownArchive: () => new Promise((resolve, reject) => {
    window.http.get('/membership/own-archive').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  restoreArchive: data => new Promise((resolve, reject) => {
    window.http.post('/membership-archive-restore', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),


  getOne: id => new Promise((resolve, reject) => {
    window.http.get(`/membership/${id}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  create: data => new Promise((resolve, reject) => {
    window.http.post('/membership', data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  update: (id, data) => new Promise((resolve, reject) => {
    // window.http.post(`/membership/update/${id}`, data).then(

    window.http.put(`/membership/${id}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  delete: id => new Promise((resolve, reject) => {
    window.http.delete(`/membership/${id}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  archive: id => new Promise((resolve, reject) => {
    window.http.post(`/membership-archive/${id}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  uploadAsset: (id, mediaType, file) => new Promise((resolve, reject) => {
    if (!id) {
      return reject(new Error('No membership ID was provided.'));
    }
    if (!mediaType) {
      return reject(new Error('No mediaType was provided.'));
    }

    const formData = new FormData();
    formData.append('file', file);
    return window.http.put(`/membership/${id}/media/${mediaType}`, formData).then(
      (response) => { resolve(response.data.path); },
      (error) => { reject(error.response.data); },
    );
  }),
  unpublishAllCourses: id => new Promise((resolve, reject) => {
    window.http.get(`/membership/${id}/unpublish-all-courses`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  updatePositionCourseMembership: memberships => new Promise((resolve, reject) => {
    window.http.post('/membership/move-course-membership', { memberships }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  removeAuthorAvatar: id => new Promise((resolve, reject) => {
    window.http.post('/membership/remove-author-avatar', { membership_id: id }).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  checkMaxCampaign: () => new Promise((resolve, reject) => {
    window.http.get('/membership/check-max-campaign').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  generateSystemDomain: params => new Promise((resolve, reject) => {
    window.http.post('/membership/auto-generate-system-domain', params).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),

  duplicateMembership: membershipId => new Promise((resolve, reject) => {
    window.http.post(`/membership/${membershipId}/clone`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
