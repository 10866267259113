<template>
  <div>
    <template v-if="!isEdit">
      <p class="label-text mt-20">Sender email</p>
      <v-text-field
        v-model="mail"
        solo/>
    </template>
    <p class="label-text">Sender name</p>
    <v-text-field
      v-model="name"
      solo/>
    <div class="layout">
      <v-spacer />
      <v-btn class="custom-btn-normal sm mr-15" @click="closeModal">Cancel</v-btn>
      <v-btn class="custom-btn-secondary" :loading="loading" @click="submit">
        <template v-if="isEdit">Save</template>
        <template v-else>
          Add New Email
        </template>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        loading: false,
        mail: '',
        name: '',
      };
    },
    computed: {
      ...mapGetters(['modalComponentProps']),
      isEdit() {
        return this.modalComponentProps.isEdit;
      },
    },
    mounted() {
      if (this.isEdit) {
        this.name = this.modalComponentProps.sender.name;
      }
    },
    methods: {
      ...mapActions(['closeModal', 'addEmailSender', 'showAlert', 'updateEmailSender']),
      submit() {
        this.loading = true;
        if (this.isEdit) {
          this.updateEmailSender({
            id: this.modalComponentProps.sender.id,
            name: this.name,
          }).then((res) => {
            this.loading = false;
            this.modalComponentProps.onSubmitCallback(res);
            this.closeModal();
          }).catch((err) => {
            this.loading = false;
            this.showAlert({ type: 'danger', message: err });
          });
        } else {
          const params = {
            mail: this.mail,
            name: this.name,
          };
          if (this.modalComponentProps.clientProfile) {
            params.clientProfileId = this.modalComponentProps.clientProfile.id;
          }
          this.addEmailSender(params).then(() => {
            this.loading = false;
            this.showAlert('Check your email to confirm your email address');
            this.closeModal();
          }).catch((err) => {
            this.loading = false;
            this.showAlert({ type: 'danger', message: err });
          });
        }
      },
    },
  };
</script>
