<template>
  <div class="my-datatable__avatar shortcut-name">
    {{ getNameShortCut(name) }}
  </div>
</template>

<script>
  export default {
    props: ['name'],
    methods: {
      getNameShortCut(name) {
        if (!name) {
          return '';
        }
        const matches = name.match(/\b(\w)/g);
        return matches.join('').substring(0, 2);
      },
    },
  };
</script>
