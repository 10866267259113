import axios from 'axios';

export default {
  getExchangeRate: currency => new Promise((resolve, reject) => {
    axios.get(`https://api.exchangerate-api.com/v4/latest/${currency}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  getExchangeRateForAll: () => new Promise((resolve, reject) => {
    axios.get('https://api.exchangerate-api.com/v6/latest').then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
