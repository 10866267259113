import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import ResetPassword from '@/views/ResetPassword';
import ChangeAccount from '@/views/ChangeAccount';

import DefaultLayout from '@/layout/DefaultLayout';
import MembershipDashboard from '@/page/MembershipDashboard';
import TeamMemberMembership from '@/page/TeamMemberMembership';

import EmptyLayout from '@/layout/Empty';

import InvoiceRoutes from '@/routes/dashboard/invoice';

// Setting
import AccountSetting from '@/page/AccountSetting';
import ProfileSetting from '@/page/account/ProfileSetting';
import DomainSetting from '@/page/account/DomainSetting';
import APISetting from '@/page/account/APISetting';
import EmailSetting from '@/page/account/EmailSetting';
import PaymentSetting from '@/page/account/PaymentSetting';
import AgencySetting from '@/page/account/AgencySetting';
import IntegrationSetting from '@/page/account/IntegrationSetting';
import TeamMember from '@/page/account/TeamMember';
import ActiveSession from '@/page/account/ActiveSession';

// Welcome page
import CreateMembership from '@/views/welcome/CreateMembership';
import Questions from '@/views/welcome/Questions';

import UserResetPassword from '@/views/UserResetPassword';
import NotFound from '@/views/NotFound';
import ArchiveList from '@/views/dashboard/membership/ArchiveList';
import ClientManagement from '@/page/ClientManagement';
import ClientDetail from '@/page/ClientDetail';
import Maintenance from '@/views/Maintenance';
import ClientRegister from '@/page/client/ClientRegister';
import PayInvoice from '@/page/client/PayInvoice';
import ClientAccountSetting from '@/page/ClientAccountSetting';

import PartnershipLayout from '@/layout/PartnershipLayout';
import PartnershipRegister from '@/page/partnership/PartnershipRegister';
import PartnershipActivation from '@/page/partnership/PartnershipActivation.vue';
import PartnerOwnContract from '@/page/partnership/OwnContract';
import PartnerContract from '@/page/partnership/student/Contract.vue';
import PartnerAccountSetting from '@/page/partnership/PartnerAccountSetting';
import HomePage from '@/views/Home';

import StorageStatistics from '@/page/storage-statistics/StorageStatistics';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
      meta: {
        auth: false,
        title: 'Home',
      },
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: NotFound,
      meta: {
        auth: false,
        title: 'Not Found',
      },
    },
    {
      path: '/settings',
      redirect: {
        name: 'dashboard.account-setting.profile',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        redirectAuthenticated: true,
        title: 'Login',
      },
    },
    {
      path: '/create-new-password',
      name: 'user-create-password',
      component: UserResetPassword,
      meta: {
        auth: false,
        title: 'Create Password',
      },
    },
    {
      path: '/forgot-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        redirectAuthenticated: true,
        title: 'Password Reset',
      },
    },
    {
      path: '/public',
      component: EmptyLayout,
      meta: { auth: false },
      children: [InvoiceRoutes],
    },
    {
      path: '/maintenance',
      component: Maintenance,
      name: 'maintenance',
      meta: {
        title: 'Maintenance',
        auth: false,
      },
    },
    {
      path: '/partnership/invite/:code',
      name: 'partnership-invite',
      component: PartnershipActivation,
      meta: {
        auth: false,
        title: 'Partnership Invite',
      },
    },
    {
      path: '/partnership/register/:code',
      name: 'partnership-register',
      component: PartnershipRegister,
      meta: {
        auth: false,
        title: 'Partnership Register',
      },
    },
    {
      path: '/client/invite/:code',
      component: ClientRegister,
      name: 'client-register',
      meta: {
        title: 'Client Registration',
        auth: false,
      },
    },
    {
      path: '/client/invoice/:uuid',
      component: PayInvoice,
      name: 'client-invoice',
      meta: {
        title: 'Invoice',
        auth: false,
      },
    },
    {
      path: '/membership-archive',
      component: ArchiveList,
      meta: {
        auth: true,
        title: 'Membership Archive',
      },
      name: 'membership-archive',
    },
    {
      path: '/welcome/create-membership',
      name: 'welcome.create-membership',
      component: CreateMembership,
      meta: {
        auth: true,
        title: 'Welcome - Create Membership',
        ignoreOnboarding: true,
      },
    },
    {
      path: '/welcome/questions',
      name: 'welcome.questions',
      component: Questions,
      meta: {
        auth: true,
        title: 'Welcome - Questions Membership',
        ignoreOnboarding: true,
      },
    },
    {
      path: '/select-account',
      name: 'auth.select_account',
      component: ChangeAccount,
      meta: { auth: true, title: 'Change Account' },
    },
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        auth: true,
        title: 'Dashboard',
      },
      children: [
        {
          path: '/campaigns',
          name: 'membership_dashboard',
          component: MembershipDashboard,
          meta: {
            auth: true,
            title: 'Campaign Dashboard',
          },
        },
        {
          path: '/team-campaigns',
          name: 'team.membership_dashboard',
          component: TeamMemberMembership,
          meta: {
            auth: true,
            title: 'Campaign Dashboard',
          },
        },
        {
          path: 'account-settings',
          name: 'dashboard.account-setting',
          component: AccountSetting,
          meta: {
            auth: true,
            title: 'Account Setting',
          },
          children: [
            {
              path: 'profile',
              name: 'dashboard.account-setting.profile',
              component: ProfileSetting,
              meta: {
                auth: true,
                title: 'Account Setting - Profile',
              },
            },
            {
              path: 'agency-settings',
              name: 'dashboard.account-setting.agency-setting',
              component: AgencySetting,
              meta: {
                auth: true,
                title: 'Account Setting - Agency',
              },
            },
            {
              path: 'integrations',
              name: 'dashboard.account-setting.integrations',
              component: IntegrationSetting,
              meta: {
                auth: true,
                title: 'Account Setting - Integrations',
              },
            },
            {
              path: 'payments',
              name: 'dashboard.account-setting.payment',
              component: PaymentSetting,
              meta: {
                auth: true,
                title: 'Account Setting - Payments',
              },
            },
            {
              path: 'domains',
              name: 'dashboard.account-setting.domains',
              component: DomainSetting,
              meta: {
                auth: true,
                title: 'Account Setting - Domains',
              },
            },
            {
              path: 'emails',
              name: 'dashboard.account-setting.emails',
              component: EmailSetting,
              meta: {
                auth: true,
                title: 'Account Setting - Emails',
              },
            },
            {
              path: 'api-setting',
              name: 'dashboard.account-setting.api',
              component: APISetting,
              meta: {
                auth: true,
                title: 'Account Setting - API Setting',
              },
            },
            {
              path: 'active-sessions',
              name: 'dashboard.account-setting.active-session',
              component: ActiveSession,
              meta: {
                auth: true,
                title: 'Account Setting - Active Sessions',
              },
            },
            {
              path: 'team-members',
              name: 'dashboard.account-setting.team-members',
              component: TeamMember,
              meta: {
                auth: true,
                title: 'Account Setting - Team Members',
              },
            },
          ],
        },
        {
          path: 'client-management',
          name: 'dashboard.client-management',
          component: ClientManagement,
          meta: {
            auth: true,
            title: 'Client Management',
          },
        },
        {
          path: 'client-management/:id',
          name: 'dashboard.client-management.detail',
          component: ClientDetail,
          meta: {
            auth: true,
            title: 'Client Details',
          },
        },
        {
          path: 'partnership-management',
          name: 'dashboard.partnership-management',
          component: PartnershipLayout,
          meta: {
            auth: true,
            title: 'Partnership Management',
          },
        },
        {
          path: 'partnership/contracts',
          name: 'dashboard.partnership-contracts',
          component: PartnerOwnContract,
          meta: {
            auth: true,
            title: 'Partnership Own Contract',
          },
        },
        {
          path: 'partnership/contract/:id',
          name: 'dashboard.partnership-contract',
          component: PartnerContract,
          meta: {
            auth: true,
            title: 'Partnership Contract',
          },
        },
        {
          path: 'partnership/account',
          name: 'dashboard.partnership-account',
          component: PartnerAccountSetting,
          meta: {
            auth: true,
            title: 'Partnership Own Contract',
          },
        },
        {
          path: 'client/account-settings',
          name: 'client.account-setting',
          component: ClientAccountSetting,
          meta: {
            auth: true,
            title: 'Account Setting',
          },
        },
        {
          path: 'storage-statistics',
          name: 'dashboard.storage-statistics',
          component: StorageStatistics,
          meta: {
            auth: true,
            title: 'Storage Statistics',
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/not-found',
    },
  ],
});

/**
 * Middleware
 */

routes.beforeEach((to, from, next) => {
  if (
    process.env.VUE_APP_MAINTENANCE_MODE === '1'
    && to.name !== 'maintenance'
  ) {
    return next({ name: 'maintenance' });
  }

  if (to.name === 'login' && to.query && to.query.jwt) {
    localStorage.setItem('jwtToken', to.query.jwt);
    window.location.href = '/';
  }

  // Setting Document Title
  const matchedRoute = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  if (matchedRoute) {
    document.title = `${process.env.VUE_APP_DOCUMENT_TITLE} - ${
      matchedRoute.meta.title
    }`;
  }
  // -------------------------------

  if (to.matched.some(record => record.meta.auth)) {
    if (!localStorage.getItem('jwtToken')) {
      return next({ name: 'login' });
    }
  }

  if (
    !to.matched.some(record => record.meta.ignoreOnboarding)
    && to.name !== 'login'
  ) {
    if (localStorage.getItem('welcomePage')) {
      return next({ name: 'welcome.create-membership' });
    }
  }

  if (to.matched.some(record => record.meta.redirectAuthenticated)) {
    if (localStorage.getItem('jwtToken') && !to.query.main) {
      return next({ name: 'membership_dashboard' });
    }
  }

  return next();
});

export default routes;
