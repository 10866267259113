<template>
  <div class="partnership">
    <div class="account-setting__breadcrumb">
      <a class="primary-text" @click="$router.push({ name: 'membership_dashboard' })">
        Campaigns
      </a>
      <fa-icon :icon="['fal', 'chevron-right']"/>
      <a class="primary-text active">
        Partnership Management
      </a>
    </div>

    <div class="card mt-20 p-0">
      <div class="partnership-tab__control">
        <v-tabs>
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            @click="selectTab(tab.value)"
          >{{ tab.name }}</v-tab>
        </v-tabs>
      </div>
      <div class="partnership-tab__content">
        <template v-if="tab === 'partner'">
          <template v-if="partnerId">
            <partnership-detail :partnerId="partnerId" @back="partnerId = undefined" />
          </template>
          <template v-else>
            <partnership-main @view="onView" />
          </template>
        </template>
        <template v-if="tab === 'transactions'">
          <transaction-main />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import PartnershipMain from '@/page/partnership/PartnershipMain';
  import PartnershipDetail from '@/page/partnership/PartnershipDetail';
  import TransactionMain from '@/page/partnership/commission/Main';

  export default {
    components: {
      PartnershipMain,
      PartnershipDetail,
      TransactionMain,
    },
    data() {
      return {
        partnerId: undefined,
        tab: 'partner',
        tabs: [{
          value: 'partner',
          name: 'Partner',
        }, {
          value: 'transactions',
          name: 'Transactions',
        }],
      };
    },
    methods: {
      selectTab(value) {
        this.tab = value;
      },
      onView(item) {
        this.partnerId = item.id.toString();
      },
    },
  };
</script>
