<template>
  <div class="client-management__wizard layout column align-center">
    <div class="client-management__detail-avatar larger-avatar" :style="avatarBg">
      <initial-avatar v-if="!whoIam.avatar" :name="whoIam.name"/>
    </div>
    <p
      class="flex-text primary-text semi-bold mb-15 mt-1 violet-text cursor-pointer"
      @click="$refs.avatarInput.click()"
    >
      <icon-upload class="mr-2" />
      Upload New Avatar
    </p>
    <input v-show="false" ref="avatarInput" type="file" @change="upload">
    <div class="mt-20 w-100">
      <p class="label-text">Fullname</p>
      <v-text-field
        v-model="formData.name"
        class="default"
        solo/>
      <p class="label-text">Email</p>
      <v-text-field
        v-model="formData.email"
        class="default"
        readonly="readonly"
        solo/>
      <p
        v-if="!showPasswordForm"
        class="flex-text primary-text semi-bold violet-btn mb-25"
        @click="showPasswordForm = true"
      >
        <icon-password class="mr-2"/>
        Edit password
      </p>
      <div v-if="showPasswordForm" class="layout">
        <div class="flex xs6">
          <p class="label-text">Old Password</p>
          <v-text-field
            v-model="formData.oldPassword"
            type="password"
            solo/>
        </div>
        <div class="flex xs6 ml-15">
          <p class="label-text">New Password</p>
          <v-text-field
            v-model="formData.newPassword"
            type="password"
            solo/>
        </div>
      </div>
      <div class="layout justify-end mt-15">
        <v-btn class="custom-btn-secondary fixed-width" :loading="loading"
               @click="submit">Save Changes</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import InitialAvatar from '@/components/InitialAvatar';
  import ClientAgencyApi from '@/api/clientAgency';

  export default {
    components: { InitialAvatar },
    data() {
      return {
        loading: false,
        showPasswordForm: false,
        formData: {
          name: '',
          avatar: '',
          email: '',
          oldPassword: '',
          newPassword: '',
        },
      };
    },
    computed: {
      ...mapGetters(['whoIam']),
      avatarBg() {
        return { 'background-image': `url("${this.whoIam.avatar}")` };
      },
    },
    watch: {
      whoIam() {
        this.init();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(['showAlert', 'closeModal', 'setUserClientProfile']),
      init() {
        this.formData = {
          name: this.whoIam ? this.whoIam.name : '',
          email: this.whoIam ? this.whoIam.email : '',
          avatar: this.whoIam ? this.whoIam.avatar : '',
        };
      },
      upload() {
        this.loading = true;
        ClientAgencyApi.uploadClientFile(this.$refs.avatarInput.files[0]).then((res) => {
          this.loading = false;
          this.formData.avatar = res.path;
          this.setUserClientProfile(this.formData);
          ClientAgencyApi.updateSelf({ avatar: res.path });
        });
      },
      submit() {
        this.loading = true;
        ClientAgencyApi.updateSelf(this.formData).then(() => {
          this.loading = false;
          this.showAlert('Your profile has been updated');
          this.setUserClientProfile(this.formData);
          this.closeModal();
        });
      },
    },
  };
</script>
