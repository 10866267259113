<template>
  <v-dialog
    v-model="dialog"
    max-width="537"
    :content-class="`membership-wizard__confirm ${customClass}`"
  >
    <v-card>
      <v-card-text>
        <icon-confirm-warning />
        {{ message }}
      </v-card-text>
      <p v-if="options.description">{{ options.description }}</p>
      <v-card-actions>
        <v-spacer />
        <button class="close-btn" @click="dialog = false">
          <icon-close class="icon-default" />
        </button>
        <v-btn
          class="custom-btn-normal light confirm"
          @click.native="agree"
        >
          {{ options.buttonTrueText }}
        </v-btn>
        <v-btn
          class="custom-btn-secondary confirm"
          @click.native="cancel"
        >
          {{ options.buttonFalseText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['customClass'],
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      click: false,
      options: {},
    }),
    watch: {
      dialog(val) {
        if (val) {
          this.click = false;
        }
        if (!val && !this.click) {
          this.$emit('noAction');
        }
      },
    },
    methods: {
      open(message, options) {
        if (options === undefined || Object.keys(options).length === 0) {
          this.options = {
            buttonTrueText: 'Yes',
            buttonFalseText: 'No',
          };
        } else {
          this.options = Object.assign(this.options, options);
        }
        this.dialog = true;
        this.message = message;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
        this.click = true;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
        this.click = true;
      },
    },
  };
</script>
