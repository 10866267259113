<template>
  <div class="partnership__custom-table transaction mt-3">
    <table class="my-datatable custom last-column-right partnership-table">
      <table-header :headers="headers" @sort="sortData" />
      <tbody>
        <tr v-for="(item, index) in dataTable" :key="index">
          <td class="primary-text lg dark">{{ moment(item.createdAt).format('DD MMM, YYYY') }}</td>
          <td class="primary-text lg">{{ item.productTransaction.member.name }}</td>
          <td class="primary-text lg">
            {{ item.productTransaction.type === 'refund'
              ? 'Refund' : subscriptionTextMapping[item.productTransaction.subscriptionType] }}
          </td>
          <td>
            <div
              class="primary-text xl semi-bold"
              :class="[item.productTransaction.type === 'purchase' ? 'text-green' : 'text-red']">
              {{ item.productTransaction.type === 'purchase'
                ? '+' : '-' }}${{ item.productTransaction.amountUsd }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import _cloneDeep from 'lodash.clonedeep';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import TableHeader from '@/components/table/TableHeader';
  import partnershipConfig from '@/config/partnershipConfig';
  import paymentCourse from '@/mixins/paymentCourse';

  export default {
    components: {
      TableHeader,
    },
    mixins: [
      paymentCourse,
    ],
    data() {
      return {
        moment,
        partnershipConfig,
        prop: 'createdAt',
        order: 'ASC',
        headers: [{
          label: 'date',
          sort: true,
          field: 'createdAt',
          width: '140px',
        }, {
          label: 'student',
          sort: true,
          field: 'student',
          width: '35%',
        }, {
          label: 'description',
          sort: true,
          field: 'description',
          width: '35%',
        }, {
          label: 'amount',
          sort: true,
          field: 'amountUsd',
        }],
      };
    },
    computed: {
      ...mapGetters('partnership', [
        'partnerContractTransaction',
      ]),
      dataTable() {
        const data = this.partnerContractTransaction
          ? _cloneDeep(this.partnerContractTransaction) : [];
        return data && data.length > 0 ? data
          .sort((a, b) => {
            const tmp1 = this.order === 'ASC' ? _cloneDeep(a) : _cloneDeep(b);
            const tmp2 = this.order === 'ASC' ? _cloneDeep(b) : _cloneDeep(a);
            if (this.prop === 'student') {
              return tmp1.productTransaction.member.name.toString()
                .localeCompare(tmp2.productTransaction.member.name.toString());
            }
            if (this.prop === 'description') {
              return tmp1.description.toString().localeCompare(tmp2.description.toString());
            }
            if (this.prop === 'createdAt') {
              return new Date(tmp1[this.prop]).getTime() - new Date(tmp2[this.prop]).getTime();
            }
            return tmp1[this.prop] - tmp2[this.prop];
          }) : [];
      },
    },
    methods: {
      sortData(sort) {
        this.prop = sort.field || 'createdAt';
        this.order = sort.order || 'ASC';
      },
    },
  };
</script>
