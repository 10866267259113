import axios from 'axios';

export default {
  getToken: () => new Promise((resolve, reject) => {
    axios.get('https://www.universal-tutorial.com/api/getaccesstoken', {
      headers: {
        Accept: 'application/json',
        'api-token': process.env.VUE_APP_COUNTRY_API_TOKEN,
        'user-email': process.env.VUE_APP_COUNTRY_API_EMAIL,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getCountries: authToken => new Promise((resolve, reject) => {
    axios.get('https://www.universal-tutorial.com/api/countries/', {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getStates: (authToken, country) => new Promise((resolve, reject) => {
    axios.get(`https://www.universal-tutorial.com/api/states/${country}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
  getCities: (authToken, state) => new Promise((resolve, reject) => {
    axios.get(`https://www.universal-tutorial.com/api/cities/${state}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  }),
};
