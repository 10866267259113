export default {
  get: () => new Promise((resolve, reject) => {
    window.http.get('/coupons').then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  create: data => new Promise((resolve, reject) => {
    window.http.post('/coupons', data).then(
      (response) => { resolve(response.data); },
      (error) => { reject(error.response.data); },
    );
  }),
  update: (couponId, data) => new Promise((resolve, reject) => {
    window.http.post(`/coupons/${couponId}`, data).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
  delete: couponId => new Promise((resolve, reject) => {
    window.http.delete(`/coupons/${couponId}`).then(
      (response) => { resolve(response.data); },
    ).catch(
      (error) => { reject(error.response.data); },
    );
  }),
};
