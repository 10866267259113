const quotes = [
  {
    text: '“Don’t take too much advice. Most people who have a lot of advice to give — with a few exceptions — generalize whatever they did. Don’t over-analyze everything.  I myself have been guilty of over-thinking problems. Just build things and find out if they work.”',
    author: 'Ben Silbermann, founder of Pinterest',
  },
  {
    text: '“The secret of change is to focus all your energy not on fighting the old but on building the new.”',
    author: 'Socrates, father of Western philosophy',
  },
  {
    text: '“Always deliver more than expected.”',
    author: 'Larry Page, co-founder of Google',
  },
  {
    text: '“If your actions inspire others to dream more, learn more, do more and become more, you are a leader.”',
    author: 'John Quincy Adams, 6th President of the United States',
  },
  {
    text: '“Be undeniably good. No marketing effort or social media buzzword can be a substitute for that.”',
    author: 'Anthony Volodkin, founder of Hype Machine',
  },
  {
    text: '“If people like you, they’ll listen to you, but if they trust you, they’ll do business with you.”',
    author: 'Zig Ziglar, author, salesman, and motivational speaker',
  },
];

export default quotes;
